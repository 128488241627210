import {
  Box,
  Button,
  Chip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { Container } from '@mui/system';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DynamicDropDown } from '../styles/styledComponent/DropDownStyled';
import ErrorPage from '../components/Error/ErrorPage';
import FilterListIcon from '@mui/icons-material/FilterList';
import LoaderAnimation from '../components/LoaderComp/LoaderAnimation';
import SurveyAnalysis from '../components/survey/SurveyAnalysis';
import Toast from '../components/Tost';
import useApiService from '../hooks/api-services/use-service';
import { useParams } from 'react-router-dom';
import { useTitle } from '../contexts/TitleContext';
import { useToast } from '../hooks/custom-hook-functions/useToast';

const SurveyResponseAnalysisScore = () => {
  const { surveyId } = useParams();
  const decodedSurveyId = atob(surveyId).split('_')[1];
  const [isLoading, setIsLoading] = useState(false);
  const { showToast, setToast, toast, toastMessage, toastType } = useToast();

  const [analysesResponse, setAnalysesResponse] = useState({});
  const { getSurveyResponsesAnalyticsScore } = useApiService();
  const [error, setError] = useState(false);
  const { setTitle } = useTitle();
  const [selectedFilters, setSelectedFilters] = useState([]);

  const [filter, setFilter] = useState([]);
  const [apiFilterString, setApiFilterString] = useState('');
  const theme = useTheme();
  const isXtraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallToMediumScreen = useMediaQuery(
    theme.breakpoints.between('xs', 'md')
  );

  const handleDeleteChip = category => {
    setFilter(prev => {
      const updatedFilter = prev.filter(item => {
        if (category.value.question.question_type === 'multiple_choice') {
          return item.value.choiseId !== category.value.choiseId;
        } else {
          return item.value.questionId !== category.value.questionId;
        }
      });

      // Update selected filters with the new filtered list
      setSelectedFilters(updatedFilter);
      return updatedFilter; // Ensure state is updated correctly
    });
  };

  const handleClearFilters = () => {
    setFilter([]);
    setSelectedFilters([]);
  };

  const handleAddFilter = value => {
    setFilter(prev => {
      // Check if the value already exists in the filter
      const exists =
        prev.some(item => item.value.choiseText === value.choiseText) &&
        value?.question?.question_type !== 'multiple_choice';
      if (exists) {
        return prev; // Return the previous state if the value already exists
      }

      // Remove any existing choice from the same question
      const updatedFilter = prev.filter(item => {
        if (value?.question?.question_type === 'multiple_choice') {
          return item.value.choiseId !== value.choiseId;
        } else {
          return item.value.questionId !== value.questionId;
        }
      });

      // Add the new choice
      return [...updatedFilter, { value }];
    });
  };

  useEffect(() => {
    if (filter.length === 0) {
      setApiFilterString('');
    }
  }, [filter]);

  const getSurveyAnalysis = async filterString => {
    try {
      setIsLoading(true);
      let response;
      if (filterString) {
        response = await getSurveyResponsesAnalyticsScore(
          decodedSurveyId,
          true,
          filterString
        );
      } else {
        response = await getSurveyResponsesAnalyticsScore(decodedSurveyId);
      }
      const filteredQuestionData =
        response?.data &&
        response?.data?.filter_question?.filter(
          question => question?.question_type !== 'yes_no'
        );
      setAnalysesResponse({
        ...response.data,
        filteredQuestions: filteredQuestionData,
      });
      setTitle(response?.data?.title);
    } catch (error) {
      showToast('Something went wrong. Please try again!', 'error');
      setIsLoading(false);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSurveyAnalysis();
  }, [decodedSurveyId]);

  const handleApplyFilter = () => {
    const choiceIds = filter.reduce((acc, curr, index) => {
      return `${acc}${curr.value.choiseId}${
        index === filter.length - 1 ? '' : ','
      }`;
    }, '');
    setApiFilterString(`?choice_filter=${choiceIds}`);
    setSelectedFilters(filter);
  };

  useEffect(() => {
    if (apiFilterString) {
      getSurveyAnalysis(apiFilterString);
    } else if (apiFilterString === '' && filter.length === 0) {
      getSurveyAnalysis();
    }
  }, [apiFilterString]);

  if (isLoading) {
    return <LoaderAnimation />;
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <>
      <Box>
        <Container sx={{ mt: 6 }}>
          {analysesResponse?.filteredQuestions?.length === 0 ||
          analysesResponse.overall_respondents ===
            0 ? null : analysesResponse?.filteredQuestions &&
            analysesResponse?.filteredQuestions?.length ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: isSmallToMediumScreen ? 'column' : 'row',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                mb: 2,
                gap: '0.5rem',
                border: '1px solid #AFB9C1',
                p: 1,
                borderRadius: '5px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexWrap: 'wrap',
                  width: '100%',
                  alignSelf: 'flex-start',
                }}
              >
                {filter?.map(category => (
                  <Chip
                    key={category.value.choiseId}
                    label={category.value.choiseText}
                    onDelete={() => handleDeleteChip(category)}
                    sx={{
                      // icon color for close icon
                      '& .MuiChip-deleteIcon': {
                        color: '#ffffff',
                      },
                      fontWeight: 'bold',
                      letterSpacing: '0.05rem',
                      mr: 1,
                      mb: 1,
                      backgroundColor: '#E8833A', // Modern orange color
                      color: '#ffffff', // White text color
                      '&:hover': {
                        backgroundColor: '#D0722E', // Darker orange on hover
                      },
                      boxShadow: '0 3px 5px 2px rgba(76, 175, 80, .3)', // Subtle shadow for depth
                    }}
                  />
                ))}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isXtraSmallScreen ? 'column' : 'row',
                  gap: '0.5rem',
                  alignSelf: 'flex-start',
                  justifyContent: isSmallToMediumScreen
                    ? 'flex-start'
                    : 'flex-end',
                  minWidth: isSmallToMediumScreen ? '100%' : 'fit-content',
                }}
              >
                {filter.length !== 0 && (
                  <Button
                    variant="text"
                    // startIcon={<DeleteForeverIcon/>}
                    sx={{
                      fontWeight: 'bold',
                      color: '#6558F5',
                      fontSize: '0.9rem',
                      letterSpacing: '0.1rem',
                      '&:hover': {
                        borderColor: '#d32f2f', // Darker on hover
                        color: '#6558F5', // Darker on hover
                        backgroundColor: '#D1C4E9', // light color of this #6558F5
                      },
                    }}
                    onClick={handleClearFilters}
                  >
                    Reset
                  </Button>
                )}

                <DynamicDropDown
                  handleAddFilter={handleAddFilter}
                  filteredQuestions={analysesResponse?.filteredQuestions}
                  filter={filter}
                />

                <Button
                  variant="contained"
                  startIcon={<FilterListIcon />}
                  sx={{
                    backgroundColor: '#6558F5', // Modern blue color
                    color: '#ffffff', // White text color
                    '&:hover': {
                      backgroundColor: '#4B3DBD', // Darker blue on hover
                    },
                    boxShadow: '0 3px 5px 2px rgba(101, 88, 245, .3)', // Subtle shadow for depth
                  }}
                  onClick={handleApplyFilter}
                  disabled={filter.length === 0}
                >
                  Apply Filter
                </Button>
              </Box>
            </Box>
          ) : null}

          <SurveyAnalysis
            surveyData={analysesResponse}
            categories={analysesResponse?.categories}
            selectedFilters={selectedFilters}
            withScore={true}
          />
        </Container>
        {toast && (
          <Toast
            setToast={setToast}
            isLoading={isLoading}
            toast={toast}
            toastType={toastType}
            toastMessage={toastMessage}
          />
        )}
      </Box>
    </>
  );
};

export default SurveyResponseAnalysisScore;
