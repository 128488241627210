import {
  Box,
  Button,
  Chip,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import RenderAnswerType from '../../components/RenderAnswerType';
import { getMediaType } from '../../utils/helpers';
import {
  ArrowBack,
  ArrowForward,
  Check,
  CheckCircle,
  Replay,
} from '@mui/icons-material';

const SurveyQuestionCategoryWizard = props => {
  const {
    fullData,
    finalDataArry,
    setFinalDataArry,
    formErrors,
    validateAnswers,
    setErrors,
  } = props;
  const [steps, setSteps] = useState([]);
  const [mappedData, setMappedData] = useState(null);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (fullData) {
      const mappedData = fullData.question_category
        .map(item => {
          const questionFilter = fullData.questions?.filter(
            ques =>
              ques.question_category === item.id &&
              ques.is_filter_question !== true
          );
          return {
            ...item,
            questions: questionFilter,
          };
        })
        .filter(item => item.questions.length > 0); // Filter out categories with no questions

      setMappedData(mappedData);
      setSteps(
        mappedData.map(item => ({
          label: item.question_category_name,
          description: item.category_description,
          questions: item.questions,
        }))
      );
    }
  }, [fullData]);

  const handleNext = e => {
    const { hasError, newErrors } = validateAnswers(
      mappedData[activeStep]?.questions,
      finalDataArry
    );
    if (hasError) {
      setErrors(newErrors);
      return;
    }
    if (!hasError) {
      setErrors({});
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const maxWidth = isSmallScreen ? '100%' : '50%';

  return (
    <Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              StepIconProps={{
                sx: {
                  color: activeStep === index ? 'primary.main' : 'grey.400',
                  '& .MuiStepIcon-text': {
                    fill: activeStep === index ? 'white' : 'grey.700',
                  },
                },
              }}
            >
              {/* Enhanced header card for step label and description */}
              <Paper
                elevation={activeStep === index ? 2 : 0}
                sx={{
                  p: 1.5,
                  pl: 2,
                  borderRadius: 2,
                  borderLeft: activeStep === index ? '4px solid' : 'none',
                  borderColor: 'primary.light',
                  bgcolor: activeStep === index ? '#f5f5f5' : 'transparent',
                  opacity: activeStep >= index ? 1 : 0.7,
                  width: '100%',
                  // maxWidth: 600,
                }}
              >
                <Box display="flex" flexDirection="column" gap={0.5}>
                  {/* Step number and label in a row */}
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      color={
                        activeStep === index ? 'primary.dark' : 'text.primary'
                      }
                    >
                      {step.label}
                    </Typography>

                    {index === steps.length - 1 && (
                      <Chip
                        label="Final Step"
                        size="small"
                        color="primary"
                        variant="outlined"
                        sx={{ height: 20, fontSize: '0.7rem' }}
                      />
                    )}
                  </Box>

                  {/* Description with icon */}
                  {step.description && (
                    <Box display="flex" alignItems="center" gap={1}>
                      <InfoOutlinedIcon
                        sx={{ fontSize: 16, color: 'text.secondary' }}
                      />
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          fontStyle: 'italic',
                          fontSize: '0.875rem',
                          lineHeight: 1.4,
                        }}
                      >
                        {step.description}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Paper>
            </StepLabel>
            <StepContent>
              <Box
                sx={{
                  mt: 2,
                  mb: 2,
                  borderLeft: '1px dashed rgba(0, 0, 0, 0.12)',
                  pl: 2,
                }}
              >
                {mappedData &&
                  mappedData[index]?.questions.map((question, quesInd) => (
                    <Box
                      key={question.id}
                      sx={{
                        mb: 3,
                        p: 2,
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        fontWeight={700}
                        sx={{ minWidth: 'fit-content', mb: 1 }}
                      >
                        <Box
                          component="span"
                          sx={{
                            mr: 0.8,
                          }}
                        >
                          {quesInd + 1}.
                        </Box>
                        {question.question}{' '}
                        {question?.required && (
                          <Chip
                            label="Required"
                            size="small"
                            sx={{
                              ml: 1,
                              height: 20,
                              fontSize: '0.7rem',
                              bgcolor: 'error.light',
                              color: 'error.dark',
                            }}
                          />
                        )}
                      </Typography>

                      {question?.video_or_image_url && (
                        <Box sx={{ maxWidth: maxWidth, mt: 1, mb: 2 }}>
                          {getMediaType(`${question?.video_or_image_url}`) ===
                          'image' ? (
                            <Box
                              sx={{
                                position: 'relative',
                                borderRadius: '12px',
                                overflow: 'hidden',
                              }}
                            >
                              <img
                                src={question?.video_or_image_url}
                                alt="Question"
                                width="100%"
                                height={300}
                                style={{ objectFit: 'cover' }}
                              />
                            </Box>
                          ) : (
                            <Box
                              sx={{ borderRadius: '12px', overflow: 'hidden' }}
                            >
                              <video width="100%" height={300} controls>
                                <source
                                  src={`${question?.video_or_image_url}`}
                                  type={`video/${question?.image_video
                                    .split('.')
                                    .pop()}`}
                                />
                              </video>
                            </Box>
                          )}
                        </Box>
                      )}

                      <Box sx={{ mt: 2 }}>
                        <RenderAnswerType
                          question={question}
                          setFinalDataArry={setFinalDataArry}
                          finalDataArry={finalDataArry}
                          formErrors={formErrors}
                        />
                      </Box>
                    </Box>
                  ))}

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 3,
                    gap: 1,
                  }}
                >
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    startIcon={<ArrowBack />}
                    variant="outlined"
                    sx={{ borderRadius: 2 }}
                  >
                    Back
                  </Button>

                  {index !== steps.length - 1 ? (
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      endIcon={<ArrowForward />}
                      sx={{ borderRadius: 2 }}
                    >
                      Continue
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      endIcon={<Check />}
                      sx={{ borderRadius: 2 }}
                    >
                      Complete
                    </Button>
                  )}
                </Box>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {/* {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you're finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )} */}
      {activeStep === steps.length && (
        <Paper
          elevation={3}
          sx={{
            p: 4,
            mt: 3,
            borderRadius: 2,
            textAlign: 'center',
            bgcolor: 'primary.light',
          }}
        >
          <CheckCircle sx={{ fontSize: 60, color: '#fff', mb: 2 }} />
          <Typography variant="h5" color="#fff" gutterBottom>
            All steps completed!
          </Typography>
          <Typography variant="body1" color="#fff" paragraph>
            Thank you for completing all the required information.
          </Typography>
          <Button
            onClick={handleReset}
            variant="contained"
            startIcon={<Replay />}
            sx={{ mt: 2, borderRadius: 2 }}
          >
            Start Over
          </Button>
        </Paper>
      )}
    </Box>
  );
};

export default SurveyQuestionCategoryWizard;
