/* eslint-disable react-hooks/exhaustive-deps */
import {
  Add as AddIcon,
  CalendarMonth as CalendarMonthIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CategoryActionCell from './TableCells/CategoryActionCell';
import { CustomButtom } from '../../CustomButton';
import CustomChip from '../../chips/CustomChip';
import { CustomTable } from '../../TableComponents/CustomTable';
import DateCell from '../../TableComponents/TableCells/DateCell';
import FormatDateTime from '../../Functions/FormatDateTime';
import LoaderAnimation from '../../LoaderComp/LoaderAnimation';
import Model from '../../Model';
import NormalCell from '../../TableComponents/TableCells/NormalCell';
import OverlayLoader from '../../LoaderComp/OverlayLoader';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Toast from '../../Tost';
import { capitalizeFirstCharacter } from '../../../utils/helpers';
import useApiService from '../../../hooks/api-services/use-service';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import { useToast } from '../../../hooks/custom-hook-functions/useToast';

const CreateQuestionCategory = () => {
  const { surveyId } = useParams();
  const {
    getSurveyById,
    getSurveyQuestionCategory,
    createQuestionCategory,
    updateQuestionCategory,
    swapQuestionCategory,
    deleteQuestionCategory,
  } = useApiService();
  const [survey, setSurveyData] = useState({});
  const [surveyLoading, setSurveyLoading] = useState(false);
  const { setToast, showToast, toast, toastMessage, toastType } = useToast();
  const [openCategoryCrudModel, setOpenCategoryCrudModel] = useState(false);
  const [openCategoryEditModel, setOpenCategoryEditModel] = useState(false);
  const [customButtonRequired, setCustomButtonRequired] = useState(true);
  const [surveyQuestionCategory, setSurveyQuestionCategory] = useState([]);
  const [saveAndAddNew, setSaveAndAddNew] = useState(false);
  const [swappedLoading, setSwappedLoading] = useState(false);
  const [overLayLoading, setOverLayLoading] = useState(false);
  const [questionCategoryLoading, setQuestionCategoryLoading] = useState(false);

  // filter states
  const [categoryTypeFilter, setCategoryTypeFilter] = useState('All');
  const [textFiltration, setTextFiltration] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const decodedString = surveyId && atob(surveyId).split('_')[1];

  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      if (surveyId) {
        if (decodedString) {
          await fetchSurvey(Number(decodedString));
        }
      }
    };

    loadData();
  }, [surveyId]);

  useEffect(() => {
    if (survey && Object.keys(survey).length && !surveyLoading) {
      fetchSurveyQuestionCategory(Number(decodedString));
    }
  }, [survey]);

  useEffect(() => {
    if (surveyQuestionCategory.length) {
      setFilteredData(surveyQuestionCategory);
    }
  }, [surveyQuestionCategory]);

  useEffect(() => {
    const filterData = () => {
      let filtered = surveyQuestionCategory;

      if (categoryTypeFilter !== 'All') {
        filtered = filtered.filter(categ =>
          categ.question_category_name.includes(categoryTypeFilter)
        );
      }

      if (textFiltration) {
        filtered = filtered.filter(categ =>
          categ.question_category_name
            .toLowerCase()
            .includes(textFiltration.toLowerCase())
        );
      }

      setFilteredData(filtered);
    };

    filterData();
  }, [categoryTypeFilter, textFiltration, surveyQuestionCategory]);

  const fetchSurvey = async id => {
    try {
      setSurveyLoading(true);
      const response = await getSurveyById(id);
      if (response.status === 200) {
        setSurveyData(response.data);
        setSurveyLoading(false);
        return true;
      }
    } catch (error) {
      setSurveyLoading(false);
      showToast('Error in fetching survey', 'error');
      console.log('Error in fetching survey', error);
      return false;
    } finally {
      setSurveyLoading(false);
    }
  };

  const fetchSurveyQuestionCategory = async (id, disabledLoading = false) => {
    try {
      setQuestionCategoryLoading(disabledLoading ? false : true);
      const response = await getSurveyQuestionCategory(id);
      if (response.status === 200) {
        const mappedData = response.data.map(categ => {
          const getAssociatedQuestions =
            survey &&
            survey.questions.filter(
              question => question?.question_category === categ?.id
            );
          return {
            ...categ,
            question_count: getAssociatedQuestions
              ? getAssociatedQuestions.length
              : 0,
          };
        });
        setSurveyQuestionCategory(mappedData);
      }
    } catch (error) {
      setQuestionCategoryLoading(false);
      showToast('Error in fetching survey question category', 'error');
      console.log('Error in fetching survey question category', error);
    } finally {
      setQuestionCategoryLoading(false);
    }
  };

  // ERROR HANDLING

  const validate = values => {
    const errors = {};
    if (!values.question_category_name) {
      errors.question_category_name = 'Question Category is required';
    }
    return errors;
  };

  const categoryFormik = useFormik({
    initialValues: {
      question_category_name: '',
      category_description: '',
      id: '',
    },
    validate,
    onSubmit: async values => {
      openCategoryEditModel
        ? await updateCategory(values)
        : await createCategory(values);
    },
  });

  const createCategory = async values => {
    try {
      setOverLayLoading(true);
      const response = await createQuestionCategory(decodedString, {
        question_category_name: values.question_category_name,
        category_description: values.category_description,
      });
      if (response.status === 201 || response.status === 200) {
        fetchSurveyQuestionCategory(Number(decodedString));
        setSurveyLoading(false);
        setOpenCategoryCrudModel(false);
        showToast('Survey question category created successfully', 'success');
        if (saveAndAddNew) {
          categoryFormik.resetForm();
          setOpenCategoryCrudModel(true);
        } else {
          setOpenCategoryCrudModel(false);
          setOpenCategoryEditModel(false);
        }
      }
    } catch (error) {
      setOverLayLoading(false);
      showToast('Error in creating survey question category', 'error');
      console.log('Error in updating survey question category', error);
    } finally {
      setOverLayLoading(false);
    }
  };

  const handleUpdateCategory = values => {
    if (values.id && values.question_category_name) {
      categoryFormik.setFieldValue(
        'question_category_name',
        values.question_category_name
      );
      categoryFormik.setFieldValue(
        'category_description',
        values.category_description
      );

      categoryFormik.setFieldValue('id', values.id);
    }
    setOpenCategoryCrudModel(true);
    setOpenCategoryEditModel(true);
  };

  const updateCategory = async values => {
    try {
      setSaveAndAddNew(false);
      const decodedString = atob(surveyId).split('_')[1];
      setSurveyLoading(true);
      const response = await updateQuestionCategory(decodedString, values.id, {
        question_category_name: values.question_category_name,
        category_description: values.category_description,
      });
      if (response.status === 200) {
        fetchSurveyQuestionCategory(Number(decodedString));
        setSurveyLoading(false);
        showToast('Survey question category updated successfully', 'success');
        setOpenCategoryEditModel(false);
        setOpenCategoryCrudModel(false);
      }
    } catch (error) {
      setSurveyLoading(false);
      showToast('Error in updating survey question category', 'error');
      console.log('Error in updating survey question category', error);
    } finally {
      setSurveyLoading(false);
    }
  };

  const handleDeleteCategory = async categoryId => {
    try {
      setSurveyLoading(true);
      const response = await deleteQuestionCategory(decodedString, categoryId);
      if (response.status === 200 || response.status === 204) {
        fetchSurveyQuestionCategory(Number(decodedString));
        setSurveyLoading(false);
        showToast('Survey question category deleted successfully', 'success');
      }
    } catch (error) {
      setSurveyLoading(false);
      showToast('Error in deleting survey question category', 'error');
      console.log('Error in deleting survey question category', error);
    } finally {
      setSurveyLoading(false);
    }
  };

  const handleSwapCategory = async payload => {
    try {
      setSwappedLoading(true);
      const decodedString = atob(surveyId).split('_')[1];
      const response = await swapQuestionCategory(payload);
      if (response.status === 200 || response.status === 201) {
        fetchSurveyQuestionCategory(Number(decodedString));
        setSwappedLoading(false);
      }
    } catch (error) {
      setSwappedLoading(false);
      showToast('Error in swapping survey question category', 'error');
      console.log('Error in swapping survey question category', error);
    } finally {
      setSwappedLoading(false);
    }
  };

  const headers = useMemo(() => {
    return [
      {
        name: 'serial_no',
        displayName: 'Sort Order',
        width: `${100 / 30}%`,
        cell: ({ row }) => (
          <NormalCell row={{ ...row, text: row?.sort_order }} />
        ),
      },
      {
        name: 'question_category_name',
        displayName: 'Category Name',
        width: `${100 / 3}%`,
        cell: ({ row }) => (
          <NormalCell row={{ ...row, text: row?.question_category_name }} />
        ),
      },
      {
        name: 'question_count',
        displayName: 'Question Count',
        width: `${100 / 6}%`,
        cell: ({ row }) => (
          <NormalCell
            type="number"
            row={{ ...row, text: row?.question_count }}
          />
        ),
      },
      {
        name: 'updated_at',
        displayName: 'Updated Date',
        width: `${100 / 4}%`,
        cell: ({ row }) => (
          <DateCell row={{ ...row, text: row?.updated_at.split('T')[0] }} />
        ),
      },
      {
        name: 'actions',
        displayName: 'Actions',
        width: `${100 / 6}%`,
        cell: ({ row }) => (
          <CategoryActionCell
            row={{ ...row }}
            isSurveyPublished={survey?.status === 'published_on' ? true : false}
            handleUpdateCategory={handleUpdateCategory}
            setCustomButtonRequired={setCustomButtonRequired}
            handleDeleteCategory={handleDeleteCategory}
          />
        ),
      },
    ];
  }, [survey]);

  const handleReorderedRows = swappedRows => {
    if (swappedRows.length === 0) return;
    const payload = swappedRows.reduce((acc, row, index) => {
      acc[`category_id_${index + 1}`] = row.id;
      return acc;
    }, {});
    handleSwapCategory(payload);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleActions = ({ row }) => (
    <div>
      <button onClick={() => console.log('Edit', row)}>Edit</button>
      <button onClick={() => console.log('Delete', row)}>Delete</button>
    </div>
  );

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        categoryFormik.handleSubmit();
        setSaveAndAddNew(true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleOnlySave = () => {
    categoryFormik.handleSubmit();
    setSaveAndAddNew(false);
  };

  const handleSaveAndAddNew = () => {
    categoryFormik.handleSubmit();
    setSaveAndAddNew(true);
  };

  const getCustomFooterButton = () => {
    return (
      <div>
        <CustomButtom
          text={'Save and add new'}
          loading={surveyLoading}
          disabled={surveyLoading}
          variant="contained"
          type="button"
          size="medium"
          onClick={handleSaveAndAddNew}
        />
      </div>
    );
  };

  if (surveyLoading) {
    return <LoaderAnimation />;
  }

  return (
    <Container maxWidth="fuild" sx={{ mt: 6 }}>
      <Box sx={{ px: 4 }}>
        <Box sx={{ mb: 2 }}>
          <Stack
            direction="row"
            marginBottom={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant="h4" component="h5" gutterBottom>
                <strong>Survey Title: </strong>
                {survey?.title}{' '}
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
                {survey?.is_anonymous && (
                  <CustomChip
                    label={'Anonymous'}
                    size="small"
                    status={'anonymous'}
                  />
                )}

                {survey?.end_date && new Date() > new Date(survey?.end_date) ? (
                  <CustomChip
                    label={'Closed'}
                    status={'expired'}
                    size="small"
                  />
                ) : survey?.is_archived ? (
                  <CustomChip
                    label={'Archived'}
                    status={'archive'}
                    size="small"
                  />
                ) : (
                  <CustomChip
                    label={capitalizeFirstCharacter(
                      survey.status === 'published' ? 'Live' : survey.status
                    )}
                    status={survey.status}
                    size="small"
                  />
                )}
              </Box>
            </Box>
            <Button
              variant="contained"
              color="primary"
              startIcon={<QuestionMarkIcon />}
              onClick={() => navigate(`/survey-details/${surveyId}`)}
            >
              Manage Questions
            </Button>
          </Stack>
          {survey?.description && (
            <Typography variant="subtitle1" marginBottom={2}>
              <strong style={{ color: '#232946' }}>Description: </strong>
              {survey?.description}
            </Typography>
          )}
          <Stack direction={'row'} alignItems={'center'} spacing={0.6}>
            <CalendarMonthIcon style={{ width: '16px', height: '16px' }} />
            <Typography variant="subtitle1">
              {survey?.updated_at ? (
                <>
                  <strong>Last Updated Date:</strong>{' '}
                  {survey?.updated_at
                    ? FormatDateTime(survey?.updated_at)
                    : '--'}
                </>
              ) : (
                <>
                  <strong>Created Date:</strong>{' '}
                  {survey?.created_at
                    ? FormatDateTime(survey?.created_at)
                    : '--'}
                </>
              )}
            </Typography>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} spacing={0.6}>
            {survey?.published_on && (
              <>
                <CalendarMonthIcon style={{ width: '16px', height: '16px' }} />
                <Typography variant="subtitle1">
                  <strong>Published Date:</strong>{' '}
                  {survey?.published_on
                    ? FormatDateTime(survey?.published_on)
                    : '--'}
                </Typography>
              </>
            )}
          </Stack>
          <Stack direction={'row'} spacing={4}>
            {survey?.start_date && (
              <Typography variant="subtitle1">
                <strong>Starts On:</strong>{' '}
                {survey?.start_date
                  ? FormatDateTime(survey?.start_date)
                  : survey?.published_on
                  ? FormatDateTime(survey?.published_on)
                  : '--'}
              </Typography>
            )}
            {survey?.end_date && (
              <Typography variant="subtitle1">
                <strong>Ends On:</strong>{' '}
                {survey?.end_date && FormatDateTime(survey?.end_date)}
              </Typography>
            )}
          </Stack>
        </Box>

        <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by Category"
            value={textFiltration}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={e => setTextFiltration(e.target.value)}
          />
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Category Type</InputLabel>
            <Select
              value={categoryTypeFilter}
              onChange={e => setCategoryTypeFilter(e.target.value)}
              label="Category Type"
            >
              <MenuItem value="All">All</MenuItem>
              {surveyQuestionCategory.map(categ => (
                <MenuItem key={categ.id} value={categ.question_category_name}>
                  {categ.question_category_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              disabled={false}
              sx={{ fontSize: 11, height: '100%', minWidth: 'max-content' }}
              onClick={() => {
                setOpenCategoryCrudModel(true);
                setOpenCategoryEditModel(false);
              }}
            >
              Add Question Category
            </Button>

            {overLayLoading ? (
              <OverlayLoader />
            ) : (
              <Model
                header
                headerText={
                  openCategoryEditModel
                    ? 'Edit Question Category'
                    : 'Add Question Category'
                }
                open={openCategoryCrudModel}
                close
                handleClose={() => setOpenCategoryCrudModel(false)}
                handleSubmit={handleOnlySave}
                resetForm={categoryFormik.resetForm}
                loading={surveyLoading}
                footer
                customWidth={true}
                requiredIndicates={false}
                customFooterButton={
                  customButtonRequired ? getCustomFooterButton : null
                }
              >
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <TextField
                      label="Question Category"
                      name="question_category_name"
                      type="text"
                      fullWidth
                      value={categoryFormik.values.question_category_name}
                      onChange={categoryFormik.handleChange}
                      onBlur={categoryFormik.handleBlur}
                      error={Boolean(
                        categoryFormik.touched.question_category_name &&
                          categoryFormik.errors.question_category_name
                      )}
                      helperText={
                        categoryFormik.touched.question_category_name &&
                        categoryFormik.errors.question_category_name
                      }
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Category Description"
                      name="category_description"
                      type="text"
                      fullWidth
                      value={categoryFormik.values.category_description}
                      onChange={categoryFormik.handleChange}
                      onBlur={categoryFormik.handleBlur}
                      error={Boolean(
                        categoryFormik.touched.category_description &&
                          categoryFormik.errors.category_description
                      )}
                      helperText={
                        categoryFormik.touched.category_description &&
                        categoryFormik.errors.category_description
                      }
                    />
                  </Grid>
                </Grid>
              </Model>
            )}
          </Box>
        </Stack>

        {swappedLoading || questionCategoryLoading ? (
          <OverlayLoader />
        ) : (
          <CustomTable
            count={filteredData?.length}
            headers={headers}
            rows={filteredData}
            onRowReorder={handleReorderedRows}
            enableRowReorder={true}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            handleActions={handleActions}
            listLoading={questionCategoryLoading}
            emptyRecordText={'No questions category has been found.'}
          />
        )}
        {toast && (
          <Toast
            setToast={setToast}
            isLoading={surveyLoading}
            toast={toast}
            toastType={toastType}
            toastMessage={toastMessage}
          />
        )}
      </Box>
    </Container>
  );
};

export default CreateQuestionCategory;
