import React, { FC } from "react";

const SvgComponent= ({ width = 256, height = 256 }) => (
<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
  <path d="m22,0h-3c-.552,0-1,.448-1,1s.448,1,1,1h1.586l-5.115,5.115c-.981-.699-2.177-1.115-3.471-1.115s-2.49.416-3.471,1.115l-1.307-1.307.793-.793c.391-.391.391-1.023,0-1.414s-1.023-.391-1.414,0l-.793.793-2.394-2.394h1.586c.552,0,1-.448,1-1s-.448-1-1-1h-3C.897,0,0,.897,0,2v3c0,.552.448,1,1,1s1-.448,1-1v-1.586l2.394,2.394-.793.793c-.391.391-.391,1.023,0,1.414.195.195.451.293.707.293s.512-.098.707-.293l.793-.793,1.307,1.307c-.699.981-1.115,2.177-1.115,3.471,0,2.967,2.167,5.431,5,5.91v2.09h-1c-.552,0-1,.448-1,1s.448,1,1,1h1v1c0,.552.448,1,1,1s1-.448,1-1v-1h1c.552,0,1-.448,1-1s-.448-1-1-1h-1v-2.09c2.833-.478,5-2.942,5-5.91,0-1.294-.416-2.49-1.115-3.471l5.115-5.115v1.586c0,.552.448,1,1,1s1-.448,1-1v-3c0-1.103-.897-2-2-2Zm-10,16c-2.206,0-4-1.794-4-4s1.794-4,4-4,4,1.794,4,4-1.794,4-4,4Z"/>
</svg>

);

export default SvgComponent;
