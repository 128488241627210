import * as React from 'react';

import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';

import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogAlert from '../DialogAlert/DialogAlert';
import IconButton from '@mui/material/IconButton';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import { SURVEY_STATUS_ENUM } from '../../utils/enums';
import Toast from '../Tost';
import Typography from '@mui/material/Typography';
import { capitalizeFirstCharacter } from '../../utils/helpers';
import { styled } from '@mui/material/styles';
import useApiService from '../../hooks/api-services/use-service';
import { useState } from 'react';
import { useToast } from '../../hooks/custom-hook-functions/useToast';

const SurveyStatusDialog = styled(Dialog)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiPaper-root": {
      width: "700px"
    }
}));

export function SurveyStatusUpdateModel(props) {
    const { open, onClose, handleGetSurveys, survey } = props;
  const [error, setError] = useState(false);
  const [surveyStatus, setSurveyStatus] = useState('');
  const { updateSurveyStatus } = useApiService();
  const { setToast, showToast, toast, toastMessage, toastType } = useToast();
  const [isLoading, setIsLoading] = useState(false);
 const [updatePreventWarning, setUpdatePreventWarning] = useState(false);
  const handleClose = () => {
    onClose(false);
  };

  const handleStatusUpdate = async () => {
    if (!surveyStatus) {
      setError(true);
      return;
    }
    if (!survey?.published_on) {
      setUpdatePreventWarning(true);
      return;
    }
    try {
      setIsLoading(true);
      const response = await updateSurveyStatus(survey?.id, { status: surveyStatus });
      showToast("Survey status updated successfully", "success");
      handleGetSurveys();
      onClose(false);
    } catch (error) {
      setIsLoading(false);
      showToast("Failed to update survey status", "error");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

   const handleStatusChange = (event) => {
    setSurveyStatus(event.target.value);
    setError(false);
  };

  return (
    <React.Fragment>
      <SurveyStatusDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
      <Box sx={{ maxWidth: "500px", margin: "auto", display: "flex", flexDirection: "column",
       gap: "0.5rem", justifyContent: "center", alignItems: "center", padding: "1rem",}}>
       <Box sx={{ background: "#E0E8FF", borderRadius: "50px", display: "flex", padding: "0.5rem" }}>
      <NewReleasesOutlinedIcon sx={{ fontSize: 60, color: "#5335E9", pt:2,
      "&.MuiSvgIcon-root":{
        padding: "0px",
        width: "0.5em",
        height: "0.5em",
      } }} />
       </Box>
        <Typography variant='h6' sx={{ m: 0, px: 2 }} id="customized-dialog-title">
          You can change your survey status
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          disabled={isLoading}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 14,
            color: theme.palette.grey[500],
            borderRadius: "30px",
            padding: "3px",
            border: "1px solid #ccc",
          })}
        >
          <CloseIcon />
        </IconButton>

              </Box>
              <Box sx={{ padding: "1rem" }}>
              <FormControl fullWidth>
              <InputLabel htmlFor="survey-status">Survey Status</InputLabel>

          <Select
            labelId="survey-status"
            id="survey-status"
            value={surveyStatus}
            label="Survey Status"
            onChange={handleStatusChange}
                  >
                          {Object.keys(SURVEY_STATUS_ENUM).map((status, index) => (
                              <MenuItem key={index} value={SURVEY_STATUS_ENUM[status]}>{capitalizeFirstCharacter(status?.toLowerCase())}</MenuItem>
                          ))}
                  </Select>
              </FormControl>
              {error && <FormHelperText sx={{ marginTop: 0, marginRight: 0, color: "#D32F2F" }}
            >Please select any status before updating.</FormHelperText>}
              </Box>
        <DialogActions sx={{ background: "#F7F7F7", width: "100%", display: "flex" }}>
          <Button autoFocus onClick={handleClose}
            disabled={isLoading}
           variant='contained'
          startIcon={<PreviewOutlinedIcon />}
            sx={{
              backgroundColor: "#9e9e9e",
              color: "white",
              "&:hover": {
                backgroundColor: "#bdbdbd",
              },
            }}>
          Cancel 
          </Button>
          <Button autoFocus disabled={isLoading} onClick={()=>handleStatusUpdate()} variant='contained'
          startIcon={<PublishedWithChangesOutlinedIcon />}
            sx={{
                backgroundColor: "#d50000",
                color: "#fff",
                "&:hover": {
                    backgroundColor: "#c62828",
                },
            }}>
            Update Status
          </Button>
        </DialogActions>
      </SurveyStatusDialog>
      {toast && (
        <Toast
          setToast={setToast}
          isLoading={isLoading}
          toast={toast}
          toastType={toastType}
          toastMessage={toastMessage}
        />
      )}

      <DialogAlert
      title={"Note"}
      open={updatePreventWarning}
      onClose={() => setUpdatePreventWarning(false)}
      message={{
          categoryName: `Only Published Survey`,
          text: `can be updated. Please publish the survey before updating the status.`,
        }}
      />

    </React.Fragment>
  );
}

export default SurveyStatusUpdateModel;