import { Box } from "@mui/system";
import React from "react";
import { Typography } from "@mui/material";

const NameCell = ({ row }) => {
  return (
    <Box>
      <Typography variant="subtitle2">{row?.respondent_name ? row.respondent_name : '--'}</Typography>
    </Box>
  );
};

export default NameCell;
