import * as Yup from "yup";

import {
  Add as AddIcon,
  CalendarMonth as CalendarMonthIcon,
  ContentCopy as ContentCopyIcon,
  Groups2 as Groups2Icon,
  MoreVert as MoreVertIcon,
  Quiz as QuizIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Grid, useMediaQuery } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import CategoryIcon from '@mui/icons-material/Category';
import ConfirmationBox from "../ConfirmationBox";
import CustomChip from "../chips/CustomChip";
import DialogAlert from "../DialogAlert/DialogAlert";
import EmptyRecords from "../EmptyRecord";
import FormatDateTime from "../Functions/FormatDateTime";
import Model from "../Model";
import PATH from "../../router/paths";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { StyledDivider } from "./SurveyDetails";
import SurveyStatusUpdateModel from "../Models/SurveyStatusUpdateModel";
import Toast from "../Tost";
import { capitalizeFirstCharacter } from "../../utils/helpers";
import { surveyMenuOptions } from "./surveyConfig";
import useApiService from "../../hooks/api-services/use-service";
import { useFormik } from "formik";
import { useToast } from "../../hooks/custom-hook-functions/useToast";

const MySurveys = () => {
  const navigate = useNavigate();
  const { postSurvey, getSurveys, deleteSurvey, duplicateSurvey, updateSurveyStatus, archiveSurveyService } = useApiService();
  const [surveys, setSurveys] = useState([]);
  const [filteredSurveys, setFilteredSurveys] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [surveyToDelete, setSurveyToDelete] = useState(null);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [linkDialogOpen, setLinkDialogOpen] = useState(false);
  const [surveyLink, setSurveyLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState("");
  const { showToast, toast, toastMessage, toastType, setToast } = useToast()

  const [surveyStatusModel, setSurveyStatusModel] = useState(false);

  const [warningMsg, setWarningMsg] = useState(false);
  const [dialogActionStates, setDialogActionStates] = useState({
    ctaAction: {},
    bodyMessage: "",
    isWarning: false,
  });

  const generateSurveyLink = (surveyId) => {
    const baseUrl = selectedSurvey.is_anonymous
      ? `${process.env.REACT_APP_BASE_URL}/survey-form`
      : `${process.env.REACT_APP_BASE_URL}/survey-start`;
    const uniqueId = btoa(`survey_${surveyId}_${Date.now()}`);
    return `${baseUrl}/${uniqueId}`;
  };

  const copyLinkToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(surveyLink);
      showToast("Link copied to clipboard!", "success");
    } catch (err) {
      showToast("Failed to copy link", "error");
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  useEffect(() => {
    const filtered = surveys.filter((survey) => {
      const matchesSearch = survey.title.toLowerCase().includes(searchQuery);
      const isArchived = statusFilter === survey.is_archived;
      const isSurveyExpired = statusFilter === "closed" && (survey.end_date && new Date() > new Date(survey.end_date));
      const matchesStatus =
        (statusFilter === "All" && survey.is_archived!==true) || (survey.status === statusFilter && survey.is_archived!==true);
      

      if (isArchived && !matchesStatus) {
        return matchesSearch && isArchived;
      } else if (isSurveyExpired) {
        return matchesSearch && isSurveyExpired;
      } return matchesSearch && matchesStatus;

    });
    setFilteredSurveys(filtered);
  }, [surveys, searchQuery, statusFilter]);

  const handleMenuOpen = (event, survey) => {
    setMenuAnchor(event.currentTarget);
    setSelectedSurvey(survey);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    // TODO: debug and find other way 
    // setSelectedSurvey(null);
  };

  const handleDeleteConfirm = (survey) => {
    setSurveyToDelete(survey);
    setWarningMsg(true);
    setDialogActionStates({
      isWarning: true,
      bodyMessage: `Are you sure you want to delete this survey`,
      ctaAction: {
        actionMessage: "Delete",
        actionColor: "#d50000 !important",
        loading: loading,
        actionFunction: () => {
          handleDeleteSurvey(selectedSurvey?.id);
        }
      }
    })
  };

  const handlePauseAndContinue = (survey) => {
    setWarningMsg(true);
    setDialogActionStates({
      isWarning: false,
      bodyMessage: `Are you sure you want to ${selectedSurvey?.status === "paused" ? "Continue": "Pause"} this survey`,
      ctaAction: {
        actionMessage: `${selectedSurvey?.status === "paused" ? "Continue": "Pause"}`,
        actionColor: "#d50000 !important",
        loading: loading,
        actionFunction: () => {
          handleStatusUpdate(`${selectedSurvey?.status === "paused" ? "published": "paused"}`);
        }
      }
    })
  }

  const handleArchiveAction = (survey) => {
    setWarningMsg(true);
    setDialogActionStates({
      isWarning: false,
      bodyMessage: `Are you sure you want to ${survey.is_archived ? "Un-Archive" : "Archive"} this survey`,
      ctaAction: {
        actionMessage: "Archive",
        actionColor: "#d50000 !important",
        loading: loading,
        actionFunction: () => {
          handleStatusUpdateArchive(!survey.is_archived);
        }
      }
    })
  }

  const handleCompleteAction = (survey) => {
    setWarningMsg(true);
    setDialogActionStates({
      isWarning: true,
      bodyMessage: `Are you sure you want to Mark this survey as complete`,
      ctaAction: {
        actionMessage: "Mark as complete",
        actionColor: "#d50000 !important",
        loading: loading,
        actionFunction: () => {
          handleStatusUpdate("completed");
        }
      }
    })

  }

  useEffect(()=>{
    if (warningMsg) {
      setDialogActionStates({
        ...dialogActionStates,
        ctaAction: {
          ...dialogActionStates.ctaAction,
          loading: loading
        }
      })
    }
  }, [loading])

  const handleDuplicateSurvey = async (surveyId) => {
    try { 
        const response = await duplicateSurvey(surveyId);
        if (response.status === 200 || response.status === 201) {
          await handleGetSurveys();
          showToast("Survey duplicated successfully!", "success");
        }
    } catch(error) {
      showToast("Something went wrong!", "error");
      console.log(error);
    }
  }

  const handleMenuOptionClick = (option) => {
    const uniqueId = btoa(`survey_${selectedSurvey?.id}_${Date.now()}`);
    switch (option) {
      case "Analyze Responses":
        navigate(`/anaylze-responses/${uniqueId}`);
        break;
      case "Delete":
        handleDeleteConfirm(selectedSurvey);
        break;
      case "Duplicate this survey":
        handleDuplicateSurvey(selectedSurvey?.id);
        break;
      case "Edit Survey":
        navigate(`/survey-edit/${uniqueId}`);
        break;
      case "Mark as complete":
        handleCompleteAction(selectedSurvey);
        break;
      case "Pause/Continue Survey":
        handlePauseAndContinue(selectedSurvey);
        break;
      case "Archive":
        handleArchiveAction(selectedSurvey);
        break;
      default:
        break;
    }
    setMenuAnchor(null);
  };

  const handleLinkDialogClose = () => {
    setLinkDialogOpen(false);
    setSurveyLink("");
  };

  const handleGetSurveys = async () => {
    try {
      await getSurveys()
        .then((res) => {
          if (res.status === 200) {
            setSurveys(res?.data);
          } else {
            showToast(`${Object.keys(res.data)[0]}: ${
                res.data[Object.keys(res.data)[0]][0]
              }`, "error");
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          showToast(error.response.data[Object.keys(error.response.data)[0]] !== "<"
          ? error.response.data[Object.keys(error.response.data)[0]]
          : "Something went wrong..!" ||
              `${Object.keys(error?.response?.data)[0]}: ${
                error?.response?.data?.message
              }`, "error");
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteSurvey = async (surveyId) => {
    try {
      setLoading(true);
      await deleteSurvey(surveyId)
        .then((res) => {
          if (res.status === 204) {
            showToast("Survey Deleted Successfully!", "success");
            setDeleteConfirmOpen(false);
            setWarningMsg(false);
            handleGetSurveys();
          } else {
            showToast(`${Object.keys(res.data)[0]}: ${
                res.data[Object.keys(res.data)[0]][0]
              }`, "error");
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          showToast(error.response.data[Object.keys(error.response.data)[0]] !== "<"
          ? error.response.data[Object.keys(error.response.data)[0]]
          : "Something went wrong..!" ||
              `${Object.keys(error?.response?.data)[0]}: ${
                error?.response?.data?.message
              }`, "error")
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusUpdate = async (statusCode) => {
    try {
      setLoading(true);
       await updateSurveyStatus(selectedSurvey?.id, { status: statusCode } );
      showToast("Survey status updated successfully", "success");
      handleGetSurveys();
      setWarningMsg(false);
    } catch (error) {
      setLoading(false);
      showToast("Failed to update survey status", "error");
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const handleStatusUpdateArchive = async (archiveValue) => {
    try {
      setLoading(true);
      await archiveSurveyService({ is_archived: archiveValue }, selectedSurvey?.id);
      showToast("Survey status updated successfully", "success");
      handleGetSurveys();
      setWarningMsg(false);
    } catch (error) {
      setLoading(false);
      showToast("Failed to update survey status", "error");
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      is_anonymous: "",
      status: "draft",
      target_audience: "all",
      survey_logo: "",
      start_date: "",
      end_date: "",
      is_active: true,
      is_respondent_name: "",
      is_respondent_email: "",
      is_respondent_phone: "",
      is_respondent_gender: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().max(250).required("Survey Title is required"),
      description: Yup.string().max(1000),
      is_anonymous: Yup.bool(),
      status: Yup.string(),
      target_audience: Yup.string(),
      survey_logo: Yup.string(),
      start_date: Yup.string(),
      end_date: Yup.string(),
      is_active: Yup.bool(),
      is_respondent_name: Yup.bool(),
      is_respondent_email: Yup.bool(),
      is_respondent_phone: Yup.bool(),
      is_respondent_gender: Yup.bool(),
    }),
    onSubmit: async (values, helpers) => {
      setLoading(true);
      try {
        let formData = new FormData();
        values.survey_logo && formData.append("survey_logo", file);
        values.title && formData.append("title", values.title);
        values.description &&
          formData.append("description", values.description);
        values.is_anonymous &&
          formData.append("is_anonymous", values.is_anonymous);
        values.status && formData.append("status", values.status);
        values.target_audience &&
          formData.append("target_audience", values.target_audience);
        values.start_date && formData.append("start_date", values.start_date);
        values.end_date && formData.append("end_date", values.end_date);
        values.is_active && formData.append("is_active", values.is_active);
        values.is_respondent_name &&
          formData.append("is_respondent_name", values.is_respondent_name);
        values.is_respondent_email &&
          formData.append("is_respondent_email", values.is_respondent_email);
        values.is_respondent_phone &&
          formData.append("is_respondent_phone", values.is_respondent_phone);
        values.is_respondent_gender &&
          formData.append("is_respondent_gender", values.is_respondent_gender);
        postSurvey(formData)
          .then((res) => {
            if (res.status === 201) {
              showToast("Survey Created Successfully!", "success");
              setIsCreating(false);
              handleGetSurveys();
            } else {
              showToast(`${Object?.keys(res.data)[0]}: ${
                  res.data[Object.keys(res.data)[0]]
                }`, "error");
            }
            setLoading(false);
          })
          .catch((error) => {
            if (error?.response) {
              showToast(error?.response?.data.detail ||
                `${Object?.keys(error?.response?.data)[0]}: ${
                  error?.response?.data[
                    Object?.keys(error?.response?.data)[0]
                  ]
                }`, "error");
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const renderLinkDialog = () => (
    <Dialog open={linkDialogOpen} onClose={handleLinkDialogClose} maxWidth="md">
      <DialogTitle sx={{ fontWeight: "bold" }}>Survey Link</DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Share this link to allow people to fill out your survey:
          </Typography>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              gap: 2,
              bgcolor: "grey.50",
            }}
          >
            <TextField
              fullWidth
              value={surveyLink}
              variant="outlined"
              size="small"
              InputProps={{
                readOnly: true,
              }}
            />
            <Button
              variant="contained"
              startIcon={<ContentCopyIcon />}
              onClick={copyLinkToClipboard}
            >
              Copy
            </Button>
          </Paper>
        </Box>
        <Typography variant="body2" color="text.secondary">
          This link will be available until you delete or unpublish the survey.
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2 }}>
        <Button onClick={handleLinkDialogClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );

  useEffect(() => {
    handleGetSurveys();
  }, []);

  useEffect(() => {
    if (formik.values.is_anonymous) {
      formik.setValues({
        ...formik.values,
        is_respondent_name: false,
        is_respondent_email: false,
        is_respondent_phone: false,
        is_respondent_gender: false,
      });
    }
  }, [formik.values.is_anonymous]);

  // add all the disabled menu items here it will be disabled from the menu
  const isSurveyExpired = selectedSurvey?.end_date && new Date() > new Date(selectedSurvey?.end_date);
  const disabledMenuItems = ["Get Web Link", "Analyze Responses"]
  const statusBasedMenuItemDisabled = ["Pause/Continue Survey", "Mark as complete"]
  const actionBasedMenuItemDisabled = ["Edit Survey", "Mark as complete", "Pause/Continue Survey",]
  const sectionDividers = ["Duplicate this survey", "Mark as complete", ]
  const activeStatus = [
    { label: "All (Active) ", value: "All" },
    { label: "Live", value: "published" },
    { label: "Draft", value: "draft" },
    { label: "Paused", value: "paused" },
    { label : "Closed", value: "closed" },
    { label: "Completed", value: "completed" },
    { label: "Archived", value: true }
  ]

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // GET MENU ITEM TEXT FUNCTION
  const getMenuItemText = (option, selectedSurvey) => {
    if (option.action === "Pause/Continue Survey") {
      if (selectedSurvey?.status === "published" && selectedSurvey?.status !== "paused") {
        return "Pause";
      } else if (selectedSurvey?.status === "paused") {
        return "Continue";
      }
    } else if (option.action === "Archive") {
      return selectedSurvey?.is_archived ? "Un-Archive" : "Archive";
    }
    return option.label;
  };

  // GET MENU ICON FUNCTION
  const getMenuItemIcon = (option, selectedSurvey) => {
    if (option.action === "Pause/Continue Survey") {
      if (selectedSurvey?.status === "paused" ) {
        return <PlayArrowIcon fontSize="small" />;
      }  else {
        return option.icon;
      }
  } else {
    return option.icon;
  }
}

  return (
    <Box>
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Typography variant="h4" component="h1">
          My Surveys
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => navigate(PATH.CREATE_SURVEY)}
        >
          Create Survey
        </Button>
      </Box>

      {/* Filters */}
      <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search by Surveys title"
          value={searchQuery}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearchChange}
        />
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Status</InputLabel>
          <Select
            value={statusFilter}
            onChange={handleStatusFilterChange}
            label="Status"
          >
          {
            activeStatus.map((status, index) => {
              return (
                <MenuItem key={index} value={status.value}>{status.label}</MenuItem>

              )}
            )}
          </Select>
        </FormControl>
      </Stack>

      <Stack spacing={2}>
        {filteredSurveys?.length > 0 ? (
          filteredSurveys?.map((survey) => {
            const publishedDate = survey?.published_on;
            const createdDate = survey?.created_at;
            const updatedDate = survey?.updated_at;
            const startDate = survey.start_date;
            const endDate = survey.end_date;
            const publishedStatus = survey.status === "published" ? true : false;
            return (
              <Card key={survey.id}>
                <CardContent>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box>
                        <Box sx={{ display: "flex", flexDirection: isSmallScreen? "column": "row",
                         gap: "0.5rem", alignItems: "center" }}>
                        <Typography variant="h6" sx={{ mb: 1, cursor: "pointer", alignSelf: "flex-start" }}
                          onClick={()=>navigate(`/survey-details/${btoa(
                          `survey_${survey.id}_${Date.now()}`)}`)}>
                          {survey?.title}</Typography>

                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "0.5rem" }}>

                        {survey?.is_anonymous && (
                            <CustomChip
                              label={"Anonymous"}
                              size="small"
                              status={"anonymous"}
                            />
                        ) }

                        {survey?.end_date && new Date() > new Date(survey?.end_date) ? (
                            <CustomChip
                              label={"Closed"}
                              status={"expired"}
                              size="small"
                            />
                          ) :

                          survey?.is_archived ? (
                              <CustomChip 
                                label={"Archived"}
                                status={"archive"}
                                size="small"
                              />
                          ): 
                          <CustomChip
                            label={capitalizeFirstCharacter(survey.status === "published" ? "Live" : survey.status)}
                            status={survey.status}
                            size="small"
                          /> 
                        }
                        </Box>
                        </Box>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        sx={{ textAlign: "justify" }}
                      >
                        {survey?.description}
                      </Typography>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={0.6}
                        my={0.5}
                      >
                        <CalendarMonthIcon
                          style={{ width: "16px", height: "16px" }}
                        />
                        <Typography variant="body2" color="text.secondary">
                          {updatedDate ? (
                            <>
                              <strong>Last Updated Date:</strong>{" "}
                              {updatedDate
                                ? updatedDate && FormatDateTime(updatedDate)
                                : "--"}
                            </>
                          ) : (
                            <>
                              <strong>Created Date:</strong>{" "}
                              {createdDate
                                ? createdDate && FormatDateTime(createdDate)
                                : "--"}
                            </>
                          )}
                        </Typography>
                      </Stack>
                      {publishedStatus &&  <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={0.6}
                        my={0.5}
                      >
                        <CalendarMonthIcon
                          style={{ width: "16px", height: "16px" }}
                        />
                        <Typography variant="body2" color="text.secondary">
                          <strong>Published Date:</strong>{" "}
                          {publishedDate
                            ? publishedDate && FormatDateTime(publishedDate)
                            : "--"}
                        </Typography>
                      </Stack>}
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={2}
                        my={0.5}
                      >
                        {(publishedDate || startDate) && <Typography variant="body2" color="text.secondary">
                          <strong>Starts On: </strong>
                          {startDate ? FormatDateTime(startDate) : publishedDate ? FormatDateTime(publishedDate) : "--"}
                        </Typography>}
                       
                        {endDate && (
                          <Typography variant="body2" color="text.secondary">
                            <strong>Ends On: </strong>
                            {FormatDateTime(endDate)}
                          </Typography>
                        )}
                      </Stack>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "end",
                      }}
                    >
                      <Box>
                        <IconButton
                          onClick={(event) => handleMenuOpen(event, survey)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                    gap={3}
                    sx={{ px: 2, mt: 2 }}
                  >
                      <Stack direction="row" spacing={1} alignItems="center">
                      <CategoryIcon sx={{ color: "Gray" }} />
                      <Link
                        to={`/create-question-category/${btoa(
                          `survey_${survey.id}_${Date.now()}`
                        )}`}

                        style={{
                          textDecoration: "none",
                          color: "blue",
                          cursor: "pointer",
                          borderBottom: "2px solid blue",
                        }}
                      >
                         {survey?.total_category} Categorie(s)
                      </Link>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <QuizIcon sx={{ color: "Gray" }} />
                      <Link
                        to={`/survey-details/${btoa(
                          `survey_${survey.id}_${Date.now()}`
                        )}`}
                        style={{
                          textDecoration: "none",
                          color: "blue",
                          cursor: "pointer",
                          borderBottom: "2px solid blue",
                        }}
                      >
                        {survey.total_questions} Question(s)
                      </Link>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Groups2Icon sx={{ color: "Gray" }} />
                      <Link
                        to={`/survey-responses/${btoa(
                          `survey_${survey.id}_${Date.now()}`
                        )}`}
                        style={{
                          textDecoration: "none",
                          color: "blue",
                          cursor: "pointer",
                          borderBottom: "2px solid blue",
                        }}
                      >
                        {survey?.total_responses
                          ? survey?.total_responses
                          : 0}{" "}
                        Response(s)
                      </Link>
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
            );
          })
        ) : (
          <Card>
            <CardContent>
              <EmptyRecords
                listLoading={isLoading}
                emptyRecordText={"No surveys has been found."}
              />
            </CardContent>
          </Card>
        )}
      </Stack>

      {/* Popup menu */}
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
      >
        {surveyMenuOptions.map((option) => (
          <div key={option.action}>
          <MenuItem
            key={option.action}
            onClick={() => handleMenuOptionClick(option.action)}
            disabled={option.disabled ||
             (disabledMenuItems.includes(option.action) && !selectedSurvey?.published_on) ||
              (actionBasedMenuItemDisabled.includes(option.action) && selectedSurvey?.status === "completed") ||
              (statusBasedMenuItemDisabled.includes(option.action) && selectedSurvey?.status === "draft")
             }
          >
              <ListItemIcon>{getMenuItemIcon(option, selectedSurvey)}</ListItemIcon>
              <ListItemText primary={getMenuItemText(option, selectedSurvey)} />
            </MenuItem>
          {sectionDividers.includes(option.action) &&
          <StyledDivider/>}
          </div>
        ))}
      </Menu>

      <Model
        header
        headerText="Create New Survey"
        open={isCreating}
        close
        handleClose={() => setIsCreating(false)}
        handleSubmit={formik.handleSubmit}
        resetForm={formik.resetForm}
        loading={loading}
        footer
        customWidth={true}
      >
        <Grid container direction="column" spacing={3}>
          {/* Survey Type Section */}
          <Grid item>
            <FormControl fullWidth>
              <Typography variant="h6" fontWeight="bold">
                Survey Type
              </Typography>
              <RadioGroup
                name="is_anonymous"
                value={
                  formik.values.is_anonymous ? "anonymous" : "named_survey"
                }
                onChange={(e) =>
                  formik.setFieldValue(
                    "is_anonymous",
                    e.target.value === "anonymous"
                  )
                }
              >
                <FormControlLabel
                  value="named_survey"
                  control={<Radio />}
                  label="Named Survey (Respondent needs to share personal details)"
                />
                <FormControlLabel
                  value="anonymous"
                  control={<Radio />}
                  label="Anonymous (Respondent details will not be collected)"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {/* Respondent Details Section */}
          {!formik.values.is_anonymous && (
            <Grid item>
              <Typography variant="h6" fontWeight="bold">
                Respondent Details to Be Collected
              </Typography>
              <FormGroup>
                {["Name", "Email", "Phone", "Gender"].map((detail, index) => {
                  const fieldName = `is_respondent_${detail.toLowerCase()}`;
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          name={fieldName}
                          checked={formik.values[fieldName] === true}
                          onChange={(event) =>
                            formik.setFieldValue(
                              fieldName,
                              event.target.checked
                            )
                          }
                        />
                      }
                      label={detail}
                    />
                  );
                })}
              </FormGroup>
            </Grid>
          )}

          {/* Add Banner Section */}
          <Grid item>
            <Typography variant="h6" fontWeight="bold">
              Add Banner
            </Typography>
            <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
            (Upload a banner image to display at the top of the survey page. This can be used to provide a description or highlight important information about the survey.)
            </Typography>
            <TextField
              id="survey_logo"
              name="survey_logo"
              type="file"
              error={
                formik.touched.survey_logo && Boolean(formik.errors.survey_logo)
              }
              helperText={
                formik.touched.survey_logo && formik.errors.survey_logo
              }
              onChange={(e) => {
                setFile(e.target.files[0]);
                formik.handleChange(e);
              }}
              fullWidth
              InputLabelProps={{ shrink: true }}
              sx={{ mt: 1 }}
            />
          </Grid>

          {/* Survey Details Section */}
          <Grid item>
            <Typography variant="h6" fontWeight="bold">
              Survey Details
            </Typography>
            <TextField
              name="title"
              label="Survey Title"
              fullWidth
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
              required
              sx={{ mt: 1 }}
            />
            <TextField
              name="description"
              label="Survey Description"
              fullWidth
              multiline
              rows={3}
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              sx={{ mt: 2 }}
            />
          </Grid>

          {/* Date Section */}
          <Grid container direction={"row"} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                name="start_date"
                label="Start Date"
                fullWidth
                type="datetime-local"
                value={formik.values.start_date}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.start_date && Boolean(formik.errors.start_date)
                }
                helperText={
                  formik.touched.start_date && formik.errors.start_date
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="end_date"
                label="End Date"
                fullWidth
                type="datetime-local"
                value={formik.values.end_date}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.end_date && Boolean(formik.errors.end_date)
                }
                helperText={formik.touched.end_date && formik.errors.end_date}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
              (Leave blank to start immediately and for end date: if blank, the
              survey will run until manually stopped.)
            </Typography>
          </Grid>
        </Grid>
      </Model>

      {renderLinkDialog()}

      <ConfirmationBox
        open={deleteConfirmOpen}
        headerText="Confirm Delete"
        close={() => setDeleteConfirmOpen(false)}
        handleClose={() => setDeleteConfirmOpen(false)}
        handleAction={() => handleDeleteSurvey(surveyToDelete?.id)}
      >
        <Typography variant="body2">
          {`Are you sure want to delete `}
          <strong>{`"${surveyToDelete?.title}"`}</strong>
          {` ?`}
        </Typography>
      </ConfirmationBox>
      {
        surveyStatusModel && 
        <SurveyStatusUpdateModel 
          open={surveyStatusModel}
          survey={selectedSurvey}
          onClose={()=>setSurveyStatusModel(false)}
          handleGetSurveys={handleGetSurveys}
        />
      }
      {toast && (
        <Toast
          setToast={setToast}
          isLoading={isLoading}
          toast={toast}
          toastType={toastType}
          toastMessage={toastMessage}
        />
      )}
    </Container>
      <DialogAlert
        title={"Note"}
        open={warningMsg}
        onClose={() => setWarningMsg(false)}
        handleAction={dialogActionStates.ctaAction}
      >
        <Typography variant="body1" gutterBottom>
          {dialogActionStates.bodyMessage} "<strong>{selectedSurvey?.title}</strong>" ?
        </Typography>
        {dialogActionStates.isWarning && <Typography variant="body2" sx={{ color: "#D32F2F" }}>
          <strong>Warning:</strong>{" "}This action is final and cannot be undone.
        </Typography>}
      </DialogAlert>
    </Box>
  );
};

export default MySurveys;
