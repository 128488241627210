import { Avatar } from '@mui/material'
import React from 'react'

const CustomAvatar = (props) => {
    const { imgUrl, name, height = 80, width = 80, style } = props;
    return (
        <Avatar
            alt={name}
            src={imgUrl}
            sx={{ width: {width}, height: {height}, 
                    "& .MuiAvatar-img":{
                      objectFit: "scale-down"
                    } }}
            style={style && style}
        />
    )
}

export default CustomAvatar