import { Box, Button, Container, Paper, Stack, Typography } from '@mui/material';

import React from 'react';
import { useNavigate } from 'react-router-dom';

const errorImages = {
  404: "/assets/errors/404Error.jpg",
  500: "/assets/errors/500Error.jpg",
  network: "/assets/errors/networkError.jpg",
  default: "/assets/errors/defaultError.jpg",
};

const ErrorPage = ({ errorMessage, errorType }) => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };
  const errorImage = errorImages[errorType] || errorImages.default;

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 6, mb: 4 }}>
          <Stack spacing={2} sx={{ mb: 3 }}>
            <Typography variant="h4" textAlign="center" gutterBottom>
              Something's wrong here...
            </Typography>
            <Box display="flex" justifyContent="center">
              <img src={errorImage} alt="error" style={{ width: "450px",
                 maxHeight: "567px", mixBlendMode: "multiply" }} />
            </Box>
            <Typography color="text.secondary" variant="body2" textAlign="center">
              {errorMessage || "An unexpected error occurred. Please try again later."}
            </Typography>
          </Stack>
          <Stack spacing={2} direction="row" justifyContent="center">
            <Button variant="outlined" color="secondary" onClick={handleGoHome}>
              Go to Home
            </Button>
          </Stack>
      </Box>
    </Container>
  );
};

export default ErrorPage;