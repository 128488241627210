import * as Yup from 'yup';

import { phoneRegExp } from '../utils/helpers';

export const CompanyOnboardingSchema = Yup.object().shape({
    organisation_name: Yup.string().max(255).required("Company Name is required"),
    website_url: Yup.string().max(255),
    phone_no: Yup.string().required("Phone Number is required")
        .matches(phoneRegExp, "Phone number is not valid"),
    state: Yup.string().max(255),
    country: Yup.string().max(255),
    address_line_1: Yup.string().max(255).required("Address Line1 is required"),
    address_line_2: Yup.string().max(255),
    city: Yup.string().max(255),
    zip_code: Yup.string().max(255),
})