import {
  ADMIN_REGISTER,
  CHOICE,
  ONBOARDING,
  SIGN_IN,
  SIGN_OUT,
  SURVEYS,
  VERIFY_ACCOUNT,
  VERIFY_RESEND_EMAIL,
} from '../../config/api-endpoint';
import { axiosAuth, axiosPublic } from '../../config/api-config';

const useApiService = () => {
  const registerUserAdmin = async payload => {
    const URL = ADMIN_REGISTER;
    const res = await axiosPublic.post(URL, payload);
    return res;
  };

  const verifyEmail = async payload => {
    const URL = VERIFY_ACCOUNT;
    const res = await axiosPublic.post(URL, payload);
    return res;
  };

  const verifyResendEmail = async payload => {
    const URL = VERIFY_RESEND_EMAIL;
    const res = await axiosPublic.post(URL, payload);
    return res;
  };

  const onBoardingCompany = async payload => {
    const URL = ONBOARDING;
    const res = await axiosAuth.post(URL, payload);
    return res;
  };

  const updateOnBoardingCompanyDetails = async payload => {
    try {
      const URL = `${ONBOARDING}`;
      const response = await axiosAuth.put(URL, payload);
      return response;
    } catch (error) {
      throw new Error(`Error updating organisation details:`, error);
    }
  };

  const getOrgaisationDetails = async () => {
    try {
      const URL = `${ONBOARDING}`;
      const response = await axiosAuth.get(URL);
      return response;
    } catch (error) {
      throw new Error(`Error fetching organisation details:`, error);
    }
  };

  const signIn = async payload => {
    const URL = SIGN_IN;
    const res = await axiosPublic.post(URL, payload);
    return res;
  };

  const signOut = async payload => {
    const URL = SIGN_OUT;
    const res = await axiosAuth.post(URL, payload);
    return res;
  };

  const postSurvey = async payload => {
    try {
      const URL = SURVEYS;
      const res = await axiosAuth.post(URL, payload);
      return res;
    } catch (error) {
      throw new Error(`Error creating survey:`, error);
    }
  };

  const putSurvey = async (surveyId, payload) => {
    const URL = `${SURVEYS}${surveyId}/`;
    const res = await axiosAuth.put(URL, payload);
    return res;
  };

  const getSurveys = async () => {
    const URL = SURVEYS;
    const res = await axiosAuth.get(URL);
    return res;
  };

  const deleteSurvey = async surveyId => {
    const URL = `${SURVEYS}${surveyId}/`;
    const res = await axiosAuth.delete(URL);
    return res;
  };

  const getSurveyById = async surveyId => {
    const URL = `${SURVEYS}${surveyId}/`;
    const res = await axiosAuth.get(URL);
    return res;
  };

  const getSurveyByIdOpen = async surveyId => {
    const URL = `${SURVEYS}${surveyId}/open/`;
    const res = await axiosAuth.get(URL);
    return res;
  };

  const postSurveyQuestions = async (surveyId, payload) => {
    const URL = `${SURVEYS}${surveyId}/questions/`;
    const res = await axiosAuth.post(URL, payload);
    return res;
  };

  const editSurveyQuestions = async (surveyId, questionId, payload) => {
    const URL = `${SURVEYS}${surveyId}/questions/${questionId}/`;
    const res = await axiosAuth.put(URL, payload);
    return res;
  };

  const deleteSurveyQuestions = async (surveyId, questionId) => {
    const URL = `${SURVEYS}${surveyId}/questions/${questionId}/`;
    const res = await axiosAuth.delete(URL);
    return res;
  };

  const createResponseForSurvey = async (surveyId, payload) => {
    try {
      const URL = `${SURVEYS}${surveyId}/responses/create/`;
      const response = await axiosAuth.post(URL, payload);
      return response.data;
    } catch (error) {
      throw new Error(`Error creating response for survey:`, error);
    }
  };

  const createUserAnswerSurvey = async (surveyId, response_id, payload) => {
    try {
      const URL = `${SURVEYS}${surveyId}/responses/${response_id}/answers/`;
      const response = await axiosAuth.post(URL, payload);
      return response;
    } catch (error) {
      throw new Error(`Something went wrong. Please try again!`, error);
    }
  };

  const getFullSurveyResponse = async (
    surveyId,
    filter = false,
    filterValues
  ) => {
    try {
      let URL = ``;
      if (filter) {
        URL = `${SURVEYS}${surveyId}/responses/${filterValues}`;
      } else {
        URL = `${SURVEYS}${surveyId}/responses/`;
      }
      const response = await axiosAuth.get(URL);
      return response;
    } catch (error) {
      throw new Error(`Error fetching full survey response:`, error);
    }
  };

  const deleteQuestionChoices = async choiceId => {
    try {
      const URL = `${CHOICE}${choiceId}/`;
      const response = await axiosAuth.delete(URL, { choice_id: choiceId });
      return response;
    } catch (error) {
      throw new Error(`Error deleting question choices:`, error);
    }
  };

  const updateQuestionChoices = async (choiceId, payload) => {
    try {
      const URL = `${CHOICE}${choiceId}/`;
      const response = await axiosAuth.put(URL, payload);
      return response;
    } catch (error) {
      throw new Error(`Error updating question choices:`, error);
    }
  };

  const getFullDetailsOfSurveyResponse = async (responseId, surveyId) => {
    try {
      const URL = `${SURVEYS}${surveyId}/responses/${responseId}/`;
      const response = await axiosAuth.get(URL);
      return response;
    } catch (error) {
      throw new Error(`Error fetching full details of survey response:`, error);
    }
  };

  const updateSurveyStatus = async (surveyId, payload) => {
    try {
      const URL = `${SURVEYS}${surveyId}/status/`;
      const response = await axiosAuth.put(URL, payload);
      return response;
    } catch (error) {
      throw new Error(`Error updating survey status:`, error);
    }
  };

  const duplicateSurvey = async surveyId => {
    try {
      const URL = `${SURVEYS}${surveyId}/copy/`;
      const response = await axiosAuth.post(URL);
      return response;
    } catch (error) {
      throw new Error(`Error duplicating survey:`, error);
    }
  };

  const getSurveyResponsesAnaylysis = async (
    surveyId,
    filter = false,
    filterValues
  ) => {
    try {
      let URL = ``;
      if (filter) {
        URL = `${SURVEYS}${surveyId}/all-responses/${filterValues}`;
      } else {
        URL = `${SURVEYS}${surveyId}/all-responses/`;
      }
      const response = await axiosAuth.get(URL);
      return response;
    } catch (error) {
      throw new Error(`Error fetching survey responses analysis:`, error);
    }
  };

  const getSurveyResponsesAnalyticsScore = async (
    surveyId,
    filter = false,
    filterValues
  ) => {
    try {
      let URL = ``;
      if (filter) {
        URL = `${SURVEYS}${surveyId}/analytics/${filterValues}`;
      } else {
        URL = `${SURVEYS}${surveyId}/analytics/`;
      }
      const response = await axiosAuth.get(URL);
      return response;
    } catch (error) {
      throw new Error(`Error fetching survey responses analysis:`, error);
    }
  };

  const getSurveyResponsesExcelData = async (surveyId, data) => {
    try {
      let URL = `${SURVEYS}${surveyId}/all-responses/`;
      if (data.is_csv || data.is_excel || data.is_score_excel)
        URL += `?${new URLSearchParams(data).toString()}`;
      const response = await axiosAuth.get(URL, {
        responseType: 'blob',
      });
      return response;
    } catch (error) {
      throw new Error(`Error fetching survey responses analysis:`, error);
    }
  };

  const getSurveyExecutiveSummary = async (surveyId, data) => {
    try {
      let URL = `${SURVEYS}${surveyId}/engagement-report/`;
      if (data.filter_question_id)
        URL += `?${new URLSearchParams(data).toString()}`;
      const response = await axiosAuth.get(URL, {
        responseType: 'blob',
      });
      return response;
    } catch (error) {
      throw new Error(`Error fetching survey responses analysis:`, error);
    }
  };

  const getSurveyQuestionCategory = async surveyId => {
    try {
      const URL = `${SURVEYS}${surveyId}/question-category/`;
      const response = await axiosAuth.get(URL);
      return response;
    } catch (error) {
      throw new Error(`Error fetching survey question category:`, error);
    }
  };

  const createQuestionCategory = async (surveyId, payload) => {
    try {
      const URL = `${SURVEYS}${surveyId}/question-category/`;
      const response = await axiosAuth.post(URL, payload);
      return response;
    } catch (error) {
      throw new Error(`Error creating question category:`, error);
    }
  };

  const updateQuestionCategory = async (surveyId, categoryId, payload) => {
    try {
      const URL = `${SURVEYS}${surveyId}/question-category/${categoryId}/`;
      const response = await axiosAuth.put(URL, payload);
      return response;
    } catch (error) {
      throw new Error(`Error updating question category:`, error);
    }
  };

  const deleteQuestionCategory = async (surveyId, categoryId) => {
    try {
      const URL = `${SURVEYS}${surveyId}/question-category/${categoryId}/`;
      const response = await axiosAuth.delete(URL);
      return response;
    } catch (error) {
      throw new Error(`Error deleting question category:`, error);
    }
  };

  const copyQuestion = async (surveyId, questionId) => {
    try {
      const URL = `${SURVEYS}${surveyId}/question-copy/${questionId}/`;
      const response = await axiosAuth.post(URL);
      return response;
    } catch (error) {
      throw new Error(`Error copying question:`, error);
    }
  };

  const swapQuestionCategory = async payload => {
    try {
      const URL = `${SURVEYS}swap-question-category/`;
      const response = await axiosAuth.post(URL, payload);
      return response;
    } catch (error) {
      throw new Error(`Error swapping question category:`, error);
    }
  };

  const swapQuestion = async payload => {
    try {
      const URL = `${SURVEYS}swap-question-serial-no/`;
      const response = await axiosAuth.post(URL, payload);
      return response;
    } catch (error) {
      throw new Error(`Error swapping question:`, error);
    }
  };

  const surveyDocumentUpload = async payload => {
    try {
      const URL = `${SURVEYS}document/`;
      const response = await axiosAuth.post(URL, payload);
      return response;
    } catch (error) {
      throw new Error(`Error uploading document:`, error);
    }
  };

  const archiveSurveyService = async (payload, surveyId) => {
    try {
      const URL = `${SURVEYS}${surveyId}/archive/`;
      const response = await axiosAuth.put(URL, payload);
      return response;
    } catch (error) {
      throw new Error(`Error while archiving this survey:`, error);
    }
  };

  const getResultAnalyticsSummary = () => {
    try {
      const URL = `${SURVEYS}summary`;
      const response = axiosAuth.get(URL);
      return response;
    } catch (error) {
      throw new Error(`Error while fetching result analytics summary:`, error);
    }
  };

  return {
    registerUserAdmin,
    verifyEmail,
    verifyResendEmail,
    onBoardingCompany,
    signIn,
    signOut,
    postSurvey,
    putSurvey,
    getSurveys,
    deleteSurvey,
    getSurveyById,
    getSurveyByIdOpen,
    postSurveyQuestions,
    editSurveyQuestions,
    deleteSurveyQuestions,
    createResponseForSurvey,
    createUserAnswerSurvey,
    getFullSurveyResponse,
    deleteQuestionChoices,
    updateQuestionChoices,
    getFullDetailsOfSurveyResponse,
    updateSurveyStatus,
    duplicateSurvey,
    getOrgaisationDetails,
    updateOnBoardingCompanyDetails,
    getSurveyResponsesAnaylysis,
    getSurveyResponsesAnalyticsScore,
    getSurveyQuestionCategory,
    createQuestionCategory,
    updateQuestionCategory,
    swapQuestionCategory,
    swapQuestion,
    deleteQuestionCategory,
    copyQuestion,
    surveyDocumentUpload,
    getSurveyResponsesExcelData,
    getSurveyExecutiveSummary,
    archiveSurveyService,
    getResultAnalyticsSummary,
  };
};

export default useApiService;
