// Ques: is Required 
export const isQuestionRequired = [
    {label: "Yes", value: true},
    {label: "No", value: false},
]

export const questionStatusType = [
    {label: "Enable", value: "enable"},
    {label: "Disable", value: "disable"},
]

// is Filter Question 
export const isFilterQuestion = [
    {label: "Yes", value: true},
    {label: "No", value: false},
]