import {
  Box,
  Container,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Business,
  Flag,
  Language,
  LocationCity,
  Map,
  Phone,
  PinDrop,
  Place,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";

import { CompanyOnboardingSchema } from "../../form-schemas-validations/CompanySchema";
import { CustomButtom } from "../CustomButton";
import { INDIAN_STATES_NAMES } from "../../utils/params";
import Toast from "../Tost";
import useApiService from "../../hooks/api-services/use-service";
import { useAuthContext } from "../../contexts/AuthContext";
import { useFormik } from "formik";
import { useStorage } from "../../hooks/custom-hook-functions/use-Storage";
import { useToast } from "../../hooks/custom-hook-functions/useToast";

const CompanyOnboarding = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { showToast, setToast, toast, toastMessage, toastType } = useToast();
  const { onBoardingCompany } = useApiService();
  const { setItem } = useStorage();
  const { login } = useAuthContext();
  const [file, setFile] = useState("");

  const createFormData = (values, file) => {
    let formData = new FormData();
    values.organisation_logo && formData.append("organisation_logo", file);
    if (file) {
      formData.append("file_name", file.name);
    }else {
      formData.append("file_name", "");
    }
    values.organisation_name && formData.append("organisation_name", values.organisation_name);
    values.phone_no && formData.append("phone_no", values.phone_no);
    values.website_url && formData.append("website_url", values.website_url);
    values.country && formData.append("country", values.country);
    values.city && formData.append("city", values.city);
    values.state && formData.append("state", values.state);
    values.address_line_1 && formData.append("address_line_1", values.address_line_1);
    values.address_line_2 && formData.append("address_line_2", values.address_line_2);
    values.zip_code && formData.append("zip_code", values.zip_code);
    return formData;
  };
  
  const handleResponse = (res) => {
    if (res) {
      setItem("companyDetails", res?.data);
      showToast("OnBoarding Completed Successfully!", "success");
      setTimeout(() => {
        login(JSON.parse(localStorage.getItem("token")));
        navigate("/dashboard");
      }, 2000);
    } else {
      showToast(`${Object.keys(res.data)[0]}: ${res.data[Object.keys(res.data)[0]]}`, "error");
     }
  };

  const handleError = (error) => {
    showToast(error?.response?.data.detail ||
      `${Object.keys(error?.response?.data)[0]}: ${error?.response?.data[Object.keys(error?.response?.data)[0]]}`, "error");
  };

  const regFormik = useFormik({
    initialValues: {
      organisation_name: "",
      website_url: "",
      organisation_logo: "",
      file_name: "",
      phone_no: "",
      address_line_1: "",
      address_line_2: "",
      country: "India",
      state: "",
      city: "",
      zip_code: "",
    },
    validationSchema: CompanyOnboardingSchema,
    onSubmit: async (values, helpers) => {
      try {
        const formData = createFormData(values, file);
        setLoading(true);
        const res = await onBoardingCompany(formData);
        handleResponse(res);
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
        helpers.setSubmitting(false);
      }
    }
  });

  return (
    <>
      <Container maxWidth="sm">
        <Box sx={{ mt: 6, mb: 4 }}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Stack spacing={1} sx={{ mb: 3 }}>
              <Typography variant="h4" textAlign={"center"} gutterBottom>
                Company OnBoarding
              </Typography>
              <Typography color="text.secondary" variant="body2">
                Already have an account? &nbsp;
                <Link to="/login" underline="hover" variant="subtitle2">
                  Sign In
                </Link>
              </Typography>
            </Stack>
            <Box width={"100%"}>
              <form onSubmit={regFormik.handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      required
                      InputLabelProps={{ shrink: true }}
                      error={
                        !!(
                          regFormik.touched.organisation_name &&
                          regFormik.errors.organisation_name
                        )
                      }
                      fullWidth
                      helperText={
                        regFormik.touched.organisation_name &&
                        regFormik.errors.organisation_name
                      }
                      label="Organization Name"
                      name="organisation_name"
                      onBlur={regFormik.handleBlur}
                      type="text"
                      onChange={regFormik.handleChange}
                      value={regFormik.values.organisation_name}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Business />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      error={
                        !!(
                          regFormik.touched.website_url &&
                          regFormik.errors.website_url
                        )
                      }
                      fullWidth
                      helperText={
                        regFormik.touched.website_url &&
                        regFormik.errors.website_url
                      }
                      label="Website URL"
                      name="website_url"
                      onBlur={regFormik.handleBlur}
                      onChange={regFormik.handleChange}
                      type="text"
                      value={regFormik.values.website_url}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Language />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      required
                      InputLabelProps={{ shrink: true }}
                      error={
                        !!(
                          regFormik.touched.phone_no &&
                          regFormik.errors.phone_no
                        )
                      }
                      fullWidth
                      helperText={
                        regFormik.touched.phone_no && regFormik.errors.phone_no
                      }
                      label="Phone Number"
                      name="phone_no"
                      onBlur={regFormik.handleBlur}
                      onChange={regFormik.handleChange}
                      type="text"
                      value={regFormik.values.phone_no}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Phone />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      required
                      InputLabelProps={{ shrink: true }}
                      error={
                        !!(
                          regFormik.touched.address_line_1 &&
                          regFormik.errors.address_line_1
                        )
                      }
                      fullWidth
                      helperText={
                        regFormik.touched.address_line_1 &&
                        regFormik.errors.address_line_1
                      }
                      label="Address 1"
                      name="address_line_1"
                      onBlur={regFormik.handleBlur}
                      onChange={regFormik.handleChange}
                      type="text"
                      value={regFormik.values.address_line_1}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Place />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      error={
                        !!(
                          regFormik.touched.address_line_2 &&
                          regFormik.errors.address_line_2
                        )
                      }
                      fullWidth
                      helperText={
                        regFormik.touched.address_line_2 &&
                        regFormik.errors.address_line_2
                      }
                      label="Address 2"
                      name="address_line_2"
                      onBlur={regFormik.handleBlur}
                      onChange={regFormik.handleChange}
                      type="text"
                      value={regFormik.values.address_line_2}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Place />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      error={
                        !!(
                          regFormik.touched.country && regFormik.errors.country
                        )
                      }
                      fullWidth
                      helperText={
                        regFormik.touched.country && regFormik.errors.country
                      }
                      label="Country"
                      name="country"
                      onBlur={regFormik.handleBlur}
                      onChange={regFormik.handleChange}
                      type="text"
                      select
                      value={regFormik.values.country}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Flag />
                          </InputAdornment>
                        ),
                      }}
                    >
                    <MenuItem value={"India"}>India</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      error={
                        !!(regFormik.touched.state && regFormik.errors.state)
                      }
                      fullWidth
                      helperText={
                        regFormik.touched.state && regFormik.errors.state
                      }
                      label="State"
                      name="state"
                      onBlur={regFormik.handleBlur}
                      onChange={regFormik.handleChange}
                      type="text"
                      select
                      value={regFormik.values.state}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Map />
                          </InputAdornment>
                        ),
                      }}
                    >
                      {
                          INDIAN_STATES_NAMES.map((state, index)=>(
                            <MenuItem key={index} value={state}>{state}</MenuItem>
                          ))
                        }
                    </TextField>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      error={
                        !!(regFormik.touched.city && regFormik.errors.city)
                      }
                      fullWidth
                      helperText={
                        regFormik.touched.city && regFormik.errors.city
                      }
                      label="City"
                      name="city"
                      onBlur={regFormik.handleBlur}
                      onChange={regFormik.handleChange}
                      type="text"
                      value={regFormik.values.city}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocationCity />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      error={
                        !!(
                          regFormik.touched.zip_code &&
                          regFormik.errors.zip_code
                        )
                      }
                      fullWidth
                      helperText={
                        regFormik.touched.zip_code && regFormik.errors.zip_code
                      }
                      label="Zip Code"
                      name="zip_code"
                      onBlur={regFormik.handleBlur}
                      onChange={regFormik.handleChange}
                      type="text"
                      value={regFormik.values.zip_code}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PinDrop />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      error={
                        !!(
                          regFormik.touched.file_name &&
                          regFormik.errors.file_name
                        )
                      }
                      fullWidth
                      helperText={
                        regFormik.touched.file_name &&
                        regFormik.errors.file_name
                      }
                      label="Organization Logo"
                      name="file_name"
                      type="file"
                      onBlur={regFormik.handleBlur}
                      onChange={(e) => {
                        if (!e.target.files.length )return
                        setFile(e.target.files[0]);
                        regFormik.handleChange(e);
                      }}
                    />
                  </Grid>
                </Grid>
                {regFormik.errors.submit && (
                  <Typography color="error" sx={{ mt: 3 }} variant="body2">
                    {regFormik.errors.submit}
                  </Typography>
                )}
                <Box mt={2}>
                  <Typography variant="caption">*Indicate Required</Typography>
                </Box>

                <Box
                  display="flex"
                  alignContent="center"
                  justifyContent="center"
                >
                  <CustomButtom
                    fullWidth
                    size="large"
                    sx={{ mt: 2 }}
                    type="submit"
                    variant="contained"
                    loading={loading}
                    disabled={loading}
                    text="Submit"
                  />
                </Box>
              </form>
            </Box>
          </Paper>
        </Box>
      </Container>
      {loading ||
        (toast && (
          <Toast
            isLoading={loading}
            toast={toast}
            setToast={setToast}
            toastType={toastType}
            toastMessage={toastMessage}
          />
        ))}
    </>
  );
};

export default CompanyOnboarding;
