import React from 'react'
import { format } from 'date-fns'

const formatDate = (date) => {
  return format(new Date(date), 'dd-MMM-yyyy')
}
const formatDateTime = (date) => {
  return format(new Date(date), 'dd-MMM-yyyy HH:mm');
}

const DateCell = ({ row }) => {
  return <div>{`${row.text ? 
  row.lastActivity ? formatDateTime(`${row.text}`) :
  formatDate(`${row.text}`) : '--'}`}</div>
}

export default DateCell
