import * as Yup from 'yup';

import {
  Alert,
  Button,
  FormHelperText,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Box, Container, Stack } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { CustomButtom } from '../CustomButton';
import OTPInput from 'react-otp-input';
import Toast from '../Tost';
import useApiService from '../../hooks/api-services/use-service';
import { useFormik } from 'formik';
import { useStorage } from '../../hooks/custom-hook-functions/use-Storage';
import { useToast } from '../../hooks/custom-hook-functions/useToast';

const EmailVerification = () => {
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const { showToast, setToast, toast, toastMessage, toastType } = useToast();
  const navigate = useNavigate();
  const { verifyEmail, verifyResendEmail } = useApiService();
  const { setItem } = useStorage();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('token')));
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);
  const formik = useFormik({
    initialValues: {
      otp: '',
    },
    validationSchema: Yup.object({
      otp: Yup.number().required('OTP is required'),
    }),
    onSubmit: async (values, helpers) => {
      setLoading(true);
      try {
        const res = await verifyEmail({ ...values, email: user?.user?.email });
        if (res.status === 200) {
          showToast('Email Verified Successfully!', 'success');
          setItem('token', res.data);
          setTimeout(() => {
            navigate('/company-onboarding');
          }, 1000);
          setLoading(false);
        }
      } catch (error) {
        setToast(true);
        console.log(error);
        showToast(
          `${error?.response?.data[Object.keys(error?.response?.data)[0]]}`,
          'error'
        );
        setLoading(false);
        helpers.setStatus({ success: false });
        helpers.setErrors({
          submit: `${
            error?.response?.data[Object.keys(error?.response?.data)[0]]
          }`,
        });
        helpers.setSubmitting(false);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleResendOTP = async () => {
    setLoading(true);
    try {
      const res = await verifyResendEmail({ email: user?.user?.email });
      if (res.status === 200) {
        showToast('Resend OTP Successfully!', 'success');
        setLoading(false);
        setResendDisabled(true);

        let countdown = 60;
        setResendTimer(countdown);

        const timer = setInterval(() => {
          countdown -= 1;
          setResendTimer(countdown);

          if (countdown <= 0) {
            clearInterval(timer);
            setResendDisabled(false);
          }
        }, 1000);
      }
    } catch (error) {
      setToast(true);
      console.log(error);
      showToast(
        `${error?.response?.data[Object.keys(error?.response?.data)[0]]}`,
        'error'
      );
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (otp.length === 6) {
      formik.setFieldValue('otp', otp);
      formik.setFieldError('otp', '');
    } else {
      formik.setFieldError('otp', 'Please Enter a Valid 6 digit Code');
    }
  }, [otp]);

  return (
    <>
      <Box>
        <Container maxWidth="sm">
          <Box sx={{ mt: 6, mb: 4 }}>
            <Paper elevation={3} sx={{ p: 4 }}>
              <Stack spacing={1} sx={{ mb: 3 }}>
                <Typography variant="h4" textAlign={'center'} gutterBottom>
                  Please verify your email
                </Typography>
                <Typography color="text.secondary" variant="body2">
                  Already have an account? &nbsp;
                  <Link to="/login" underline="hover" variant="subtitle2">
                    Sign In
                  </Link>
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography variant="body2">
                  Please check your email. We have sent you one time password
                  (OTP). Please enter that OTP here to verify your email and
                  click continue.
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mt: 1,
                  }}
                >
                  <Typography variant="body2" color="text.secondary">
                    Didn't receive the code?
                  </Typography>
                  <Button
                    onClick={handleResendOTP}
                    disabled={resendDisabled}
                    size="small"
                    sx={{
                      textTransform: 'none',
                      fontWeight: 500,
                      minWidth: 'auto',
                      p: 0.5,
                      '&.Mui-disabled': {
                        color: 'text.disabled',
                      },
                    }}
                  >
                    {resendDisabled
                      ? `Resend in ${resendTimer}s`
                      : 'Resend OTP'}
                  </Button>
                </Box>
              </Stack>
              <form noValidate onSubmit={formik.handleSubmit}>
                <Stack spacing={3}>
                  <OTPInput
                    numInputs={6}
                    value={otp}
                    onChange={setOtp}
                    inputType="number"
                    containerStyle={{
                      width: '100% ',
                      justifyContent: 'space-around',
                      marginTop: '3rem',
                    }}
                    inputStyle={{
                      width: '4rem',
                      height: '4rem',
                      borderRadius: '4px',
                      border: '1px solid rgba(0,0,0,0.3)',
                      fontSize: 18,
                      fontWeight: 600,
                    }}
                    renderInput={props => (
                      <input required {...props} type="text" />
                    )}
                  />
                </Stack>
                {/* {formik.errors.submit ||
                (formik.errors.opt && (
                  <TextField 
                   sx={{ mt: 3, color: "red !important", fontWeight: "bold" }} variant="body2">
                    {formik.errors.submit || formik.errors.opt}
                  </TextField>
                ))} */}
                <div
                  style={{
                    marginTop: '3rem',
                    textAlign: 'center',
                    marginBottom: '2rem',
                  }}
                >
                  <Button
                    onClick={() => setOtp('')}
                    style={{ marginRight: '2rem' }}
                    variant="outlined"
                  >
                    Clear
                  </Button>
                  <CustomButtom
                    loading={loading}
                    disabled={loading}
                    color="primary"
                    size="medium"
                    type={'Submit'}
                    variant="contained"
                    text="Verify"
                  />
                </div>
              </form>
            </Paper>
          </Box>
        </Container>
      </Box>
      {loading ||
        (toast && (
          <Toast
            isLoading={loading}
            toast={toast}
            toastType={toastType}
            toastMessage={toastMessage}
          />
        ))}
    </>
  );
};

export default EmailVerification;
