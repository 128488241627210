import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  Rating,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";

const SurveyList = (props) => {
  const navigate = useNavigate();
  const { data } = props
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);

  const handleViewDetails = (survey) => {
    // setSelectedSurvey(survey);
    // setDetailsOpen(true);
    navigate(`/survey-details/${btoa(`survey_${survey.id}_${Date.now()}`)}`)
  };

  const renderAnswerField = (answerType, answers) => {
    switch (answerType) {
      case "Rating":
        return (
          <Rating
            value={parseInt(answers, 10)}
            readOnly
            precision={1}
            sx={{ mt: 1 }}
          />
        );
      case "Yes/No":
        return (
          <RadioGroup row value={answers}>
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        );
      case "Multiple Choice":
        return (
          <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>
            {answers.map((option, index) => (
              <FormControlLabel
                key={index}
                control={<Checkbox checked />}
                label={option}
              />
            ))}
          </Box>
        );
      case "Text Answer":
        return (
          <Typography variant="body1" sx={{ mt: 1 }}>
            {answers}
          </Typography>
        );
      case "Numeric Answer":
        return (
          <Typography variant="body1" sx={{ mt: 1 }}>
            {answers}
          </Typography>
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
        Recent
      </Typography>
      <Paper sx={{ p: 2 }}>
        <List>
          {data.length ? data.filter((survey=>survey.is_archived!==true)).slice(0,3).map((survey) => (
            <React.Fragment key={survey.id}>
              <ListItem
                display="flex"
                alignItems="center"
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                <ListItemText sx={{ maxWidth: "90%" }}
                  primary={
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1, textWrap: "wrap" }}>
                      {survey.title}
                    </Box>
                  }
                  secondary={survey.description}
                />
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<VisibilityIcon />}
                  onClick={() => handleViewDetails(survey)}
                >
                  View
                </Button>
              </ListItem>
              <Divider component="li" />
            </React.Fragment>
          )): 
            <Typography variant="body1" sx={{ textAlign: "center" }}>
              No surveys found
            </Typography>
          }
        </List>
      </Paper>

      {/* Survey Details Dialog */}
      <Dialog
        open={detailsOpen}
        onClose={() => setDetailsOpen(false)}
        maxWidth="md"
        fullWidth
      >
        {selectedSurvey && (
          <>
            <DialogTitle>
              <Typography variant="h5">{selectedSurvey.title}</Typography>
              <Typography variant="subtitle2" color="text.secondary">
                {selectedSurvey.description}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Typography variant="subtitle1" gutterBottom>
                Questions and Answers
              </Typography>
              <List>
                {selectedSurvey.questions.map((question, index) => (
                  <ListItem key={question.id} alignItems="flex-start">
                    <ListItemText
                      primary={
                        <Typography variant="body1">
                          {index + 1}. {question.question}
                        </Typography>
                      }
                      secondary={
                        <Box sx={{ paddingLeft: "18px" }}>
                          {renderAnswerField(
                            question.answerType,
                            question.answers
                          )}
                        </Box>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDetailsOpen(false)}>Close</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default SurveyList;
