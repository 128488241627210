import React from 'react';
import {
  Typography,
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Grid2,
} from '@mui/material';
import {
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
} from '@mui/icons-material';

const SurveyGlossary = () => {
  const renderMetricsSection = () => (
    <Grid2 container spacing={3}>
      <Grid2 size={{ xs: 12 }} component="main">
        <Typography variant="h6" gutterBottom color="primary">
          Key Metrics Explained
        </Typography>
        <Box component="ul" paddingLeft={2}>
          {[
            {
              label: 'Mean Score',
              description:
                'The average rating on a scale from 1-5, where 5 represents the highest positive response.',
            },
            {
              label: 'Top Box %',
              description:
                'Percentage of respondents selecting the highest rating (5 - Excellent/Strongly Agree).',
            },
            {
              label: 'Bottom Box %',
              description:
                'Percentage of respondents selecting the lowest rating (1 - Critical/Strongly Disagree).',
            },
            {
              label: 'Distribution',
              description:
                'Percentage breakdown of responses across the 5-point scale.',
            },
          ].map((metric, index) => (
            <li key={index}>
              <Typography variant="body2">
                <strong>{metric.label}:</strong> {metric.description}
              </Typography>
            </li>
          ))}
        </Box>
      </Grid2>
    </Grid2>
  );

  const renderScoreInterpretationTable = () => {
    const colorMap = {
      Excellent: '#2e7d32', // Dark Green
      Good: '#43a047', // Green
      Average: '#ffa000', // Yellow/Orange
      Poor: '#e53935', // Red
      Critical: '#d32f2f', // Dark Red
    };

    const topboxColorMap = {
      Outstanding: '#2e7d32', // Very Dark Green
      Exceptional: '#43a047', // Dark Green
      Strong: '#ffa000', // Green
      Average: '#e53935', // Yellow/Orange
      Low: '#d32f2f', // Red
    };

    const bottomboxColorMap = {
      Negligible: '#2e7d32', // Very Dark Green
      Minimal: '#43a047', // Dark Green
      Low: '#ffa000', // Green
      Concerning: '#e53935', // Yellow/Orange
      Critical: '#d32f2f', // Red
    };

    return (
      <Box sx={{ mt: 3 }}>
        <Typography variant="h6" gutterBottom color="primary">
          Scoring Interpretation
        </Typography>
        <Grid2 container spacing={3}>
          <Grid2 size={{ xs: 4 }} component="main">
            <Typography variant="subtitle1">
              Mean Score Interpretation
            </Typography>
            <TableContainer component={Paper} elevation={2}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Score Range</TableCell>
                    <TableCell>Interpretation</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[
                    {
                      range: '4.5-5.0',
                      interpretation: 'Excellent',
                    },
                    {
                      range: '3.5-4.5',
                      interpretation: 'Good',
                    },
                    {
                      range: '2.5-3.5',
                      interpretation: 'Average',
                    },
                    {
                      range: '1.5-2.5',
                      interpretation: 'Poor',
                    },
                    {
                      range: '1.0-1.5',
                      interpretation: 'Critical',
                    },
                  ].map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.range}</TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center" gap={2}>
                          <Box
                            width={20}
                            height={20}
                            bgcolor={colorMap[row.interpretation]}
                            borderRadius={1}
                          />
                          {row.interpretation}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid2>
          <Grid2 size={{ xs: 4 }} component="main">
            <Typography variant="subtitle1">Top Box Percentage</Typography>
            <TableContainer component={Paper} elevation={2}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Percentage</TableCell>
                    <TableCell>Interpretation</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[
                    { range: '0-20%', interpretation: 'Low' },
                    { range: '20-40%', interpretation: 'Average' },
                    { range: '40-60%', interpretation: 'Strong' },
                    { range: '60-80%', interpretation: 'Exceptional' },
                    { range: '80-100%', interpretation: 'Outstanding' },
                  ].map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.range}</TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center" gap={2}>
                          <Box
                            width={20}
                            height={20}
                            bgcolor={topboxColorMap[row.interpretation]}
                            borderRadius={1}
                          />
                          {row.interpretation}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid2>
          <Grid2 size={{ xs: 4 }} component="main">
            <Typography variant="subtitle1">Bottom Box Percentage</Typography>
            <TableContainer component={Paper} elevation={2}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Percentage</TableCell>
                    <TableCell>Interpretation</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[
                    { range: '0-2%', interpretation: 'Negligible' },
                    { range: '2-5%', interpretation: 'Minimal' },
                    { range: '5-15%', interpretation: 'Low' },
                    { range: '15-30%', interpretation: 'Concerning' },
                    { range: '30-100%', interpretation: 'Critical' },
                  ].map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.range}</TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center" gap={2}>
                          <Box
                            width={20}
                            height={20}
                            bgcolor={bottomboxColorMap[row.interpretation]}
                            borderRadius={1}
                          />
                          {row.interpretation}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid2>
        </Grid2>
      </Box>
    );
  };

  const renderTrendIndicators = () => (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6" gutterBottom color="primary">
        Global Trend Indicators
      </Typography>
      <Grid2 container spacing={3}>
        <Grid2 size={{ xs: 6 }} component="main">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            p={2}
            border={1}
            borderRadius={2}
            borderColor="success.light"
          >
            <Box display="flex" alignItems="center" mb={1}>
              <TrendingUpIcon color="success" sx={{ mr: 1 }} />
              <Typography variant="subtitle1" color="success.main">
                Positive Trend
              </Typography>
            </Box>
            <Chip
              label="Threshold: Mean score ≥ 3.5"
              color="success"
              variant="contained"
              sx={{ mb: 1 }}
            />
            <Typography variant="body2">
              • Indicates overall healthy department dynamics
            </Typography>
            <Typography variant="body2">
              • Suggests more strengths than weaknesses
            </Typography>
          </Box>
        </Grid2>
        <Grid2 size={{ xs: 6 }} component="main">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            p={2}
            border={1}
            borderRadius={2}
            borderColor="warning.light"
          >
            <Box display="flex" alignItems="center" mb={1}>
              <TrendingDownIcon color="warning" sx={{ mr: 1 }} />
              <Typography variant="subtitle1" color="warning.main">
                Needs Improvement Trend
              </Typography>
            </Box>
            <Chip
              label="Threshold: Mean score < 3.5"
              color="warning"
              variant="contained"
              sx={{ mb: 1 }}
            />
            <Typography variant="body2">
              • Signals systematic challenges
            </Typography>
            <Typography variant="body2">
              • Recommends comprehensive review and strategic interventions
            </Typography>
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );

  return (
    <Paper elevation={2} style={{ padding: '24px' }}>
      <Typography variant="h5" gutterBottom color="primary">
        Survey Insights Glossary
      </Typography>

      {renderMetricsSection()}
      {renderScoreInterpretationTable()}
      {renderTrendIndicators()}
    </Paper>
  );
};

export default SurveyGlossary;
