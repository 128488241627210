import * as Yup from 'yup';

import {
  Add as AddIcon,
  ArrowDropDown as ArrowDropDownIcon,
  CalendarMonth as CalendarMonthIcon,
  ContentCopy as ContentCopyIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Email as EmailIcon,
  Groups2 as Groups2Icon,
  Leaderboard as LeaderBoardIcon,
  Link as LinkIcon,
  Pause,
  PlayArrow,
  Search as SearchIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { Box, Container, Grid, Stack } from '@mui/system';
import {
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grow,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import {
  capitalizeFirstCharacter,
  getMimeType,
  urlToFile,
} from '../../utils/helpers';
import {
  isFilterQuestion,
  isQuestionRequired,
  questionStatusType,
} from './form-options/surveyQuestionOptions';
import { useNavigate, useParams } from 'react-router-dom';

import ActionCell from '../TableComponents/TableCells/ActionCell';
import AnswerTypeCell from '../TableComponents/TableCells/AnswerTypeCell';
import CloseIcon from '@mui/icons-material/Close';
import CustomChip from '../chips/CustomChip';
import { CustomTable } from '../TableComponents/CustomTable';
import {
  checkEditSurveyQuestion,
  DOCUMENT_UPLOAD_ENUM,
} from './surveyParams-config';
import DateCell from '../TableComponents/TableCells/DateCell';
import DialogAlert from '../DialogAlert/DialogAlert';
import FormatDateTime from '../Functions/FormatDateTime';
import Model from '../Model';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import NormalCell from '../TableComponents/TableCells/NormalCell';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import ShowMoreLessCell from '../TableComponents/TableCells/ShowMoreLessCell';
import StatusCell from '../TableComponents/TableCells/StatusCell';
import SurveyPublishedModel from '../Models/SurveyPublishModel';
import Toast from '../Tost';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import useApiService from '../../hooks/api-services/use-service';
import { useFormik } from 'formik';
import { useTheme } from '@emotion/react';
import { useTitle } from '../../contexts/TitleContext';
import { useToast } from '../../hooks/custom-hook-functions/useToast';

const StyledSelect = styled(Select)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  fontWeight: theme.typography.fontWeightBold,
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

const SurveyDetails = () => {
  const { surveyId } = useParams();
  const { setToast, showToast, toast, toastMessage, toastType } = useToast();
  const navigate = useNavigate();
  const [survey, setSurvey] = useState([]);
  const [survey_Id, setSurvey_Id] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const {
    getSurveyById,
    putSurvey,
    postSurveyQuestions,
    editSurveyQuestions,
    deleteSurveyQuestions,
    deleteQuestionChoices,
    updateQuestionChoices,
    updateSurveyStatus,
    swapQuestion,
    copyQuestion,
    surveyDocumentUpload,
  } = useApiService();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState('');
  const [answerTypeFilter, setAnswerTypeFilter] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [categoryTypeFilter, setCategoryTypeFilter] = useState('All');
  const [filteredSurveyQuestions, setFilteredSurveyQuestions] = useState([]);
  const [openQestionModel, setOpenQestionModel] = useState(false);
  const [openEditQuestion, setOpenEditQuestion] = useState(false);
  const [editDisabled, setEditDisabled] = useState(false);
  const [openEditSurveyModel, setOpenEditSurveyModel] = useState(false);
  const [question, setQuestion] = useState('');
  const [answerType, setAnswerType] = useState('');
  const [surveyLogoFile, setSurveyLogoFile] = useState('');
  const [imageFile, setImageFile] = useState('');
  const [videoFile, setVideoFile] = useState('');
  const [imageAndVideoFile, setImageAndVideoFile] = useState('');
  const [question_Id, setQuestion_Id] = useState();
  const [editingQuestionId, setEditingQuestionId] = useState();
  const [open, setOpen] = useState(false);
  const [openAnalysis, setOpenAnalysis] = useState(false);
  const shareAnchorRef = useRef(null);
  const analysisAnchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedAnalysisIndex, setSelectedAnalysisIndex] = useState(0);
  const [isSurveyPublished, setIsSurveyPublished] = useState(false);
  const [isScore, setIsScore] = useState(false);
  const [surveyPublishModel, setSurveyPublishModel] = useState(false);
  const [swappedLoading, setSwappedLoading] = useState(false);
  // for dialog alert
  const [warningMsg, setWarningMsg] = useState(false);
  const [dialogActionStates, setDialogActionStates] = useState({
    ctaAction: {},
    bodyMessage: '',
    isWarning: false,
  });

  const [fileUploadLoading, setFileUploadLoading] = useState(false);

  // NEW UPDATE FOR VIDEO OR IMAGE
  const [fileUploadData, setFileUploadData] = useState({
    document_type: DOCUMENT_UPLOAD_ENUM.QUESTION,
    file_name: null,
  });

  const { setTitle } = useTitle();
  useEffect(() => {
    if (survey) {
      if (survey.published_on) {
        setIsSurveyPublished(
          survey.status === 'published' || survey.published_on !== null
        );
      }
      setTitle(survey.title);
      setIsScore(survey.is_score);
    }
  }, [survey]);

  const hanldePublishSurveyModelOpen = async () => {
    if (survey?.questions?.length > 0) {
      setSurveyPublishModel(!surveyPublishModel);
    } else {
      showToast(
        'Please add questions to the survey before publishing!',
        'error'
      );
    }
  };

  const isSurveyDataAvailable = survey && Object.keys(survey).length > 0;

  const QUESTION_CATEGORY =
    survey &&
    survey?.question_category?.map(category => {
      let associatedQuestion = survey?.questions?.filter(
        question => question.question_category === category.id
      );
      return {
        ...category,
        questionCount: associatedQuestion?.length
          ? associatedQuestion.length
          : 0,
      };
    });

  const FILTERQUESTION_COUNT =
    isSurveyDataAvailable &&
    survey?.questions?.filter(question => question?.is_filter_question === true)
      ?.length;

  const handlePushlishSurvey = async () => {
    try {
      setIsLoading(true);
      const response = await updateSurveyStatus(survey_Id, {
        status: 'published',
      });
      if (response.status === 200) {
        showToast('Survey Published Successfully!', 'success');
        await handleGetSurvey(survey_Id);
        setTimeout(() => {
          setToast(false);
        }, 3000);
      }
    } catch (error) {
      setIsLoading(false);
      showToast('Something went wrong while publishing the survey!', 'error');
    }
  };

  const handlePreviewAndPublish = async () => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL}/survey-preview`;
    const newWindowUrl = `${baseUrl}/${surveyId}`;

    window.open(newWindowUrl, '_blank');
  };

  const options = [
    {
      label: 'Copy weblink',
      action: 'Copy weblink',
      icon: <LinkIcon fontSize="small" />,
      isDisabled: false,
    },
    {
      label: 'Share via email',
      action: 'Share via email',
      icon: <EmailIcon fontSize="small" />,
      isDisabled: true,
    },
  ];

  const options2 = [
    {
      label: 'Analysis with score',
      action: 'Analysis with score',
      icon: <LeaderBoardIcon fontSize="small" />,
      isDisabled: false,
    },
    {
      label: 'Analysis without score',
      action: 'Analysis without score',
      icon: <LeaderBoardIcon fontSize="small" />,
      isDisabled: false,
    },
  ];
  const [surveyLink, setSurveyLink] = useState('');
  const [linkDialogOpen, setLinkDialogOpen] = useState(false);

  const handleGetSurvey = async surveyId => {
    try {
      await getSurveyById(surveyId)
        .then(res => {
          if (res.status === 200) {
            setSurvey(res?.data);
            const mappedDataWithCategory = res?.data?.questions?.map(
              question => {
                const getCategory = res?.data?.question_category?.find(
                  category => category.id === question.question_category
                );
                return { ...question, question_category: getCategory };
              }
            );
            setRows(mappedDataWithCategory);
          } else {
            showToast(
              `${Object.keys(res.data)[0]}: ${
                res.data[Object.keys(res.data)[0]][0]
              }`,
              'error'
            );
          }
        })
        .catch(error => {
          console.log(error);
          showToast(
            error.response.data[Object.keys(error.response.data)[0]] !== '<'
              ? error.response.data[Object.keys(error.response.data)[0]]
              : 'Something went wrong..!' ||
                  `${Object.keys(error?.response?.data)[0]}: ${
                    error?.response?.data?.message
                  }`,
            'error'
          );
        });
    } catch (error) {
      console.log(error);
    }
  };

  const decodedString = atob(surveyId).split('_')[1];
  useEffect(() => {
    handleGetSurvey(Number(decodedString));
    setSurvey_Id(decodedString);
  }, [surveyId]);

  const handleSwapQuestion = async payload => {
    try {
      setSwappedLoading(true);
      const response = await swapQuestion(payload);
      if (response.status === 200 || response.status === 201) {
        handleGetSurvey(Number(decodedString));
        setSwappedLoading(false);
      }
    } catch (error) {
      setSwappedLoading(false);
      showToast('Error in swapping survey question category', 'error');
      console.log('Error in swapping survey question category', error);
    } finally {
      setSwappedLoading(false);
    }
  };

  const questionTypes = [
    { value: 'radio', label: 'Single Choice (radio button)' },
    { value: 'dropdown', label: 'Single Choice (dropdown/select)' },
    { value: 'yes_no', label: 'Single Choice (Yes/No)' },
    { value: 'multiple_choice', label: 'Multiple Choices' },
    { value: 'rating', label: 'Rating' },
    { value: 'date', label: 'Date Picker' },
    { value: 'time', label: 'Time Picker' },
    // { value: "file_upload", label: "File Upload" },
    { value: 'slider', label: 'Slider' },
    { value: 'text', label: 'Text Answer' },
    { value: 'multi_line_text', label: 'Multi Line Text Answer' },
  ];

  const questionTypeOfFilterType = [
    { value: 'radio', label: 'Single Choice (radio button)' },
    { value: 'dropdown', label: 'Single Choice (dropdown/select)' },
    // { value: "yes_no", label: "Single Choice (Yes/No)" },
    { value: 'multiple_choice', label: 'Multiple Choices' },
  ];

  const scoreValues = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
  ];

  const handleClick = () => {
    if (options[selectedIndex].action === 'Copy weblink') {
      const link = generateSurveyLink(surveyId);
      setSurveyLink(link);
      setLinkDialogOpen(true);
    } else if (options[selectedIndex].action === 'Share via email') {
      navigate('/email-invitation');
    }
    setOpen(false);
  };

  const generateSurveyLink = surveyId => {
    const baseUrl = survey.is_anonymous
      ? `${process.env.REACT_APP_BASE_URL}/survey-form`
      : `${process.env.REACT_APP_BASE_URL}/survey-start`;
    return `${baseUrl}/${surveyId}`;
  };

  const copyLinkToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(surveyLink);
      showToast('Link copied to clipboard!', 'success');
      setIsLoading(false);
    } catch (err) {
      showToast('Failed to copy link', 'error');
    }
  };
  // share link function
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    if (options[index].action === 'Copy weblink') {
      const link = generateSurveyLink(surveyId);
      setSurveyLink(link);
      setLinkDialogOpen(true);
    } else if (options[index].action === 'Share via email') {
      navigate('/email-invitation');
    }

    setOpen(false);
  };

  const handleAnalysisMenuItemClick = (event, id, index) => {
    setSelectedAnalysisIndex(index);
    if (options2[index].action === 'Analysis with score') {
      navigate(`/anaylze-responses-score/${surveyId}`);
    } else if (options2[index].action === 'Analysis without score') {
      navigate(`/anaylze-responses/${surveyId}`);
    }

    setOpenAnalysis(false);
  };

  const handleLinkDialogClose = () => {
    setLinkDialogOpen(false);
    setSurveyLink('');
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const renderLinkDialog = () => (
    <Dialog open={linkDialogOpen} onClose={handleLinkDialogClose} maxWidth="md">
      <DialogTitle sx={{ fontWeight: 'bold' }}>Survey Link</DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Share this link to allow people to fill out your survey:
          </Typography>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              bgcolor: 'grey.50',
            }}
          >
            <TextField
              fullWidth
              value={surveyLink}
              variant="outlined"
              size="small"
              InputProps={{
                readOnly: true,
              }}
            />
            <Button
              variant="contained"
              startIcon={<ContentCopyIcon />}
              onClick={copyLinkToClipboard}
            >
              Copy
            </Button>
          </Paper>
        </Box>
        <Typography variant="body2" color="text.secondary">
          This link will be available until you delete or unpublish the survey.
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2 }}>
        <Button onClick={handleLinkDialogClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (
      shareAnchorRef.current &&
      shareAnchorRef.current.contains(event.target)
    ) {
      return;
    }

    setOpen(false);
  };
  const handleAnalysisToggle = () => {
    setOpenAnalysis(prevOpen => !prevOpen);
  };

  const handleAnalysisClose = event => {
    if (
      shareAnchorRef.current &&
      shareAnchorRef.current.contains(event.target)
    ) {
      return;
    }

    setOpenAnalysis(false);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatusFilterChange = event => {
    setStatusFilter(event.target.value);
  };

  const handleAnswerTypeFilterChange = event => {
    setAnswerTypeFilter(event.target.value);
  };

  const handleCategoryTypeFilterChange = event => {
    setCategoryTypeFilter(event.target.value);
  };

  const handleSearchChange = event => {
    setSearchQuery(event.target.value.toLowerCase());
    setPage(0);
  };

  const handleReorderedRows = swappedRows => {
    if (swappedRows.length === 0) return;
    const payload = swappedRows.reduce((acc, row, index) => {
      acc[`question_id_${index + 1}`] = row.id;
      return acc;
    }, {});
    handleSwapQuestion(payload);
  };

  const handleDeleteQuestion = async questionId => {
    try {
      await deleteSurveyQuestions(survey_Id, questionId)
        .then(res => {
          if (res.status === 204) {
            showToast('Question Deleted Successfully!', 'success');
            handleGetSurvey(survey_Id);
            setTimeout(() => {
              setToast(false);
            }, 3000);
          } else {
            showToast(
              `${Object.keys(res.data)[0]}: ${
                res.data[Object.keys(res.data)[0]][0]
              }`,
              'error'
            );
          }
          setLoading(false);
        })
        .catch(error => {
          showToast(
            error.response.data[Object.keys(error.response.data)[0]] !== '<'
              ? error.response.data[Object.keys(error.response.data)[0]]
              : 'Something went wrong..!' ||
                  `${Object.keys(error?.response?.data)[0]}: ${
                    error?.response?.data?.message
                  }`,
            'error'
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleQuestionCopy = async data => {
    setLoading(true);
    try {
      const res = await copyQuestion(survey_Id, data?.id);
      if (res.status === 200) {
        showToast('Question Copied Successfully!', 'success');
        await handleGetSurvey(survey_Id);
        setLoading(false);
      }
    } catch (error) {
      showToast('Error while creating copy of this question', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const filteredQuestions = rows?.filter(question => {
      const isFilteredQuestion = question.is_filter_question === true;
      const matchCategory =
        categoryTypeFilter === 'All' ||
        question?.question_category?.id === categoryTypeFilter;
      const matchesSearch = question.question
        .toLowerCase()
        .includes(searchQuery);
      const matchesStatus =
        statusFilter === '' || question?.status === statusFilter;
      const matchesAnswerType =
        answerTypeFilter === '' || question?.question_type === answerTypeFilter;
      // Include all questions if "All" category is selected
      if (categoryTypeFilter === 'All') {
        return matchesSearch && matchesStatus && matchesAnswerType;
      }

      // Give priority to is_filter_question flag
      if (isFilteredQuestion) {
        return (
          categoryTypeFilter === true &&
          matchesSearch &&
          matchesStatus &&
          matchesAnswerType
        );
      }

      return (
        matchesSearch && matchesStatus && matchesAnswerType && matchCategory
      );
    });
    setFilteredSurveyQuestions(filteredQuestions);
  }, [rows, searchQuery, statusFilter, answerTypeFilter, categoryTypeFilter]);

  // converting the URL back to file and setting the formik value
  const convertUrlToFileAndSetFormik = async (key, url, mimeType, formType) => {
    try {
      if (url) {
        const filename = url.split('/').pop(); // Extract the filename from the URL
        const file = await urlToFile(url, filename, mimeType);
        formType.setFieldValue(key, file);
      }
    } catch (error) {
      showToast('Failed to load file', 'error');
      // Optionally, we set an error state in Formik or show a toast message
      formType.setFieldError(key, 'Failed to load file');
    }
  };

  const headers = [
    {
      name: 'serial_no',
      displayName: 'S.No',
      width: `${100 / 20}%`,
      cell: ({ row }) => <NormalCell row={{ ...row, text: row.serial_no }} />,
    },
    {
      name: 'question',
      displayName: 'Question',
      width: `${100 / 3}%`,
      cell: ({ row }) => (
        <ShowMoreLessCell row={{ ...row, text: row.question }} />
      ),
    },
    {
      name: 'answerType',
      displayName: 'Answer Type',
      width: `${100 / 8}%`,
      cell: ({ row }) => (
        <AnswerTypeCell row={{ ...row, text: row.question_type }} />
      ),
    },
    {
      name: 'category',
      displayName: 'Category',
      width: `${100 / 8}%`,
      cell: ({ row }) => (
        <NormalCell
          row={{
            ...row,
            text: row?.question_category
              ? row.question_category?.question_category_name
              : 'Filter Question',
          }}
        />
      ),
    },
    {
      name: 'date',
      displayName: 'Updated Date',
      width: `${100 / 6}%`,
      cell: ({ row }) => (
        <DateCell row={{ ...row, text: row?.updated_at.split('T')[0] }} />
      ),
    },
    {
      name: 'status',
      displayName: 'Status',
      width: `${100 / 6}%`,
      cell: ({ row }) => <StatusCell row={{ ...row, text: row.status }} />,
    },
    {
      name: 'actions',
      displayName: 'Actions',
      width: `${100 / 6}%`,
      cell: ({ row }) => (
        <ActionCell
          row={{ ...row }}
          isEditTable={checkEditSurveyQuestion(
            survey?.status,
            survey?.is_score,
            row?.is_filter_question,
            row?.question_type
          )}
          isSurveyPublished={isSurveyPublished}
          handleDeleteQuestion={handleDeleteQuestion}
          handleEditQuestion={handleEditQuestionData}
          handleQuestionCopy={handleQuestionCopy}
        />
      ),
    },
  ];

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      is_anonymous: '',
      status: 'draft',
      target_audience: 'all',
      survey_logo: '',
      start_date: '',
      end_date: '',
      is_active: true,
      is_respondent_name: '',
      is_respondent_email: '',
      is_respondent_phone: '',
      is_respondent_gender: '',
    },
    validationSchema: Yup.object({
      title: Yup.string().max(250).required('Survey Title is required'),
      description: Yup.string().max(1000),
      is_anonymous: Yup.bool(),
      status: Yup.string(),
      target_audience: Yup.string(),
      survey_logo: Yup.string(),
      start_date: Yup.string(),
      end_date: Yup.string(),
      is_active: Yup.bool(),
      is_respondent_name: Yup.bool(),
      is_respondent_email: Yup.bool(),
      is_respondent_phone: Yup.bool(),
      is_respondent_gender: Yup.bool(),
    }),
    onSubmit: async (values, helpers) => {
      setLoading(true);
      try {
        let formData = new FormData();
        values.survey_logo && formData.append('survey_logo', surveyLogoFile);
        values.title && formData.append('title', values.title);
        values.description &&
          formData.append('description', values.description);

        formData.append('is_anonymous', values.is_anonymous);
        formData.append('status', values.status);
        values.target_audience &&
          formData.append('target_audience', values.target_audience);
        values.start_date && formData.append('start_date', values.start_date);
        values.end_date && formData.append('end_date', values.end_date);
        formData.append('is_active', values.is_active);

        // there is no need to check if the value is true or false
        // if the value is false then it's not saving the updated values
        formData.append('is_respondent_name', values.is_respondent_name);
        formData.append('is_respondent_email', values.is_respondent_email);
        formData.append('is_respondent_phone', values.is_respondent_phone);
        formData.append('is_respondent_gender', values.is_respondent_gender);

        putSurvey(survey_Id, formData)
          .then(res => {
            if (res.status === 200) {
              showToast('Survey Edited Successfully!', 'success');
              setOpenEditSurveyModel(false);
              handleGetSurvey(survey_Id);
            } else {
              showToast(
                `${Object?.keys(res.data)[0]}: ${
                  res.data[Object.keys(res.data)[0]]
                }`,
                'error'
              );
            }
            setLoading(false);
          })
          .catch(error => {
            if (error?.response) {
              showToast(
                error?.response?.data.detail ||
                  `${Object?.keys(error?.response?.data)[0]}: ${
                    error?.response?.data[
                      Object?.keys(error?.response?.data)[0]
                    ]
                  }`,
                'error'
              );
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const handleEditSurvey = () => {
    navigate(`/survey-edit/${surveyId}`);
  };

  const validate = values => {
    const errors = {};

    if (!values.question) {
      errors.question = 'Question is required';
    } else if (values.question.length > 1000) {
      errors.question = 'Question must be at most 1000 characters';
    }

    if (!values.question_type) {
      errors.question_type = 'Question type is required';
    }

    if (values.help_text && values.help_text.length > 250) {
      errors.help_text = 'Help text must be at most 250 characters';
    }

    if (
      values.question_type === 'multiple_choice' ||
      values.question_type === 'radio' ||
      values.question_type === 'dropdown'
    ) {
      if (!values.choice || values.choice.length < 2) {
        errors.choice = 'Minimum 2 options required';
      } else if (values.choice.length < 5 && survey?.is_score === true) {
        errors.choice = `It's recommended to have at least 5 options for better survey results.You have added ${values.choice.length} out of the recommended 5 options.`;
      }
    }

    if (!values.is_filter_question) {
      if (values.question_category === '') {
        errors.question_category = 'Question category is required';
      }
    }

    if (values.question_type === 'rating') {
      if (!values.ratings_max_value) {
        errors.ratings_max_value = 'Ratings max value is required';
      } else if (
        values.ratings_max_value < 2 ||
        values.ratings_max_value > 10
      ) {
        errors.ratings_max_value = 'Ratings max value must be between 2 and 10';
      }
    }

    if (values.question_type === 'multi_line_text') {
      if (!values.text_max_characters) {
        errors.text_max_characters = 'Text max characters value is required';
      }
    }

    if (values.question_type === 'multi_line_text') {
      if (!values.text_display_lines) {
        errors.text_display_lines = 'Text display lines value is required';
      } else if (
        values.text_display_lines < 2 ||
        values.text_display_lines > 10
      ) {
        errors.text_display_lines =
          'Text display lines value must be between 2 and 10';
      }
    }

    if (values.question_type === 'slider') {
      if (!values.slider_min_value) {
        errors.slider_min_value = 'Slider min value is required';
      }
      if (!values.slider_max_value) {
        errors.slider_max_value = 'Slider max value is required';
      }
      if (!values.slider_step_size) {
        errors.slider_step_size = 'Slider step size is required';
      }
    }

    return errors;
  };

  const questionFormik = useFormik({
    initialValues: {
      is_filter_question: false,
      question_category: '',
      question: '',
      question_type: '',
      required: false,
      help_text: '',
      image_video: '',
      status: 'enable',
      choice: [],
      image: '',
      video: '',
      ratings_max_value: '',
      slider_min_value: '',
      slider_max_value: '',
      slider_step_size: '', // default value 1
      text_max_characters: '',
      text_display_lines: '',
      allowed_filetypes: '',
      video_or_image: '',
      video_or_image_url: '',
    },
    validate,
    onSubmit: async (values, helpers) => {
      try {
        openEditQuestion
          ? handleEditQuestion(values, helpers)
          : handleAddQuestion(values, helpers);
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const formIsFilteredValue = questionFormik.values.is_filter_question;

  useEffect(() => {
    if (formIsFilteredValue === true) {
      questionFormik.setFieldValue('question_category', '');
    }
  }, [formIsFilteredValue]);

  const handleAddAnswerOption = () => {
    const currentOptions = questionFormik.values.choice;

    // Determine the next option_value
    const nextOptionValue =
      currentOptions.length > 0
        ? Math.max(...currentOptions.map(opt => opt.option_value)) + 1
        : 1;
    //{id: 1, value: "option 1", option_value: 1}
    const newOptions = [
      ...questionFormik.values.choice,
      { id: null, value: '', option_value: nextOptionValue },
    ];
    questionFormik.setFieldValue('choice', newOptions);
  };

  // Function to update a specific answer option
  const handleAnswerOptionChange = (index, key, value) => {
    const newOptions = [...questionFormik.values.choice];
    if (key === 'value') {
      newOptions[index]['value'] = value;
    } else if (key === 'option_value') {
      newOptions[index]['option_value'] = value;
    }
    questionFormik.setFieldValue('choice', newOptions);
  };

  // Function to check if a score is already used by another option
  const isScoreUsed = (score, currentIndex) => {
    return questionFormik.values.choice.some(
      (option, idx) => option.option_value === score && idx !== currentIndex
    );
  };

  // Function to remove an answer option
  const handleRemoveAnswerOption = async (index, option) => {
    try {
      if (option.id) {
        setIsLoading(true);
        const res = await deleteQuestionChoices(option.id);
        if (res.status === 204) {
          showToast('Option deleted successfully!', 'success');
          const newOptionss = questionFormik.values.choice.filter(
            (_, i) => i !== index
          );
          questionFormik.setFieldValue('choice', newOptionss);
          await handleGetSurvey(survey_Id);
          setIsLoading(false);
        }
      } else {
        const newOptionss = questionFormik.values.choice.filter(
          (_, i) => i !== index
        );
        questionFormik.setFieldValue('choice', newOptionss);
      }
    } catch (error) {
      setIsLoading(false);
      showToast('Something went wrong while deleting this response!', 'error');
    }
  };

  // function to update the multiple-choice-value
  const handleUpdateChoiceAnswer = async (index, option) => {
    try {
      setIsLoading(true);

      // Create a payload that includes both value and option_value (if applicable)
      const payload = {
        choise_text: option.value,
        score: option.option_value, // Add this if your API accepts it
      };

      const response = await updateQuestionChoices(option.id, payload);

      if (response.status === 200) {
        showToast('Option updated successfully!', 'success');

        // Update local state directly without full refetch
        const updatedChoices = [...questionFormik.values.choice];
        updatedChoices[index] = { ...option }; // Ensure we have the latest values
        questionFormik.setFieldValue('choice', updatedChoices);

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      showToast(
        'Something went wrong while updating the choice answer',
        'error'
      );
      console.error('Update choice error:', error);
    }
  };

  const handleQuestionTypeChange = event => {
    const { value } = event.target;
    if (questionFormik.values.choice.length > 0) {
      questionFormik.setFieldValue('choice', []);
    }
    questionFormik.setFieldValue('question_type', value);
  };

  const handleQuestionCategoryChange = event => {
    const { value } = event.target;
    questionFormik.setFieldValue('question_category', value);
  };

  const handleDeleteImage = () => {
    questionFormik.setFieldValue('image_video', null);
    if (questionFormik?.values?.video_or_image_url) {
      questionFormik.setFieldValue('video_or_image_url', '');
    }
    questionFormik.setFieldValue('video_or_image', '');
    setFileUploadData(prev => {
      return {
        ...prev,
        file_name: null,
      };
    });
  };

  // create to handle image and video together user can upload either image or video
  const handleImageVideoChange = event => {
    if (event.currentTarget.files.length === 0) {
      return;
    }
    const file = event.currentTarget.files[0];
    setImageAndVideoFile(file);
    questionFormik.setFieldValue('image_video', file);
    setFileUploadData(prev => {
      return {
        ...prev,
        file_name: file,
      };
    });
  };

  const handleFileUpload = async () => {
    try {
      setFileUploadLoading(true);
      let fileUploadFormData = new FormData();
      if (fileUploadData.file_name) {
        Object.keys(fileUploadData).forEach(key => {
          fileUploadFormData.append(key, fileUploadData[key]);
        });
        const response = await surveyDocumentUpload(fileUploadFormData);
        return response.data;
      }
    } catch (err) {
      showToast('Error uploading your image file', 'error');
      setFileUploadLoading(false);
    } finally {
      setFileUploadLoading(false);
    }
  };

  const excludeFields = ['video_or_image_url'];

  const handleEditQuestionData = (row, isEdited) => {
    setOpenEditQuestion(true);
    setOpenQestionModel(true);
    setQuestion_Id(row.id);
    setEditDisabled(survey.status === 'draft' ? false : isEdited);
    setFileUploadData({
      document_type: DOCUMENT_UPLOAD_ENUM.QUESTION,
      file_name: null,
    }); // clear file upload data
    Object.keys(row).forEach(key => {
      if (questionFormik.values.hasOwnProperty(key) || key === 'choices') {
        if (key === 'choices') {
          questionFormik.setFieldValue(
            'choice',
            row.choices.map(choice => ({
              id: choice.id,
              value: choice.choise_text,
              option_value: choice.score,
            }))
          );
        } else if (key === 'question_category') {
          if (row[key] !== null && row[key] !== undefined) {
            questionFormik.setFieldValue('question_category', row[key]?.id);
          }
        } else if (key === 'image_video') {
          if (row[key] !== null && row[key] !== undefined) {
            const newUrl = `${process.env.REACT_APP_API_BASE_URL}${row[key]}`;
            questionFormik.setFieldValue(key, newUrl);
            if (key === 'image_video') {
              const mimeType = getMimeType(newUrl);
              convertUrlToFileAndSetFormik(
                'image_video',
                newUrl,
                mimeType,
                questionFormik
              );
            }
          }
        } else {
          if (
            key === 'ratings_max_value' &&
            row['ratings_max_value'] === null &&
            row['ratings_max_value'] === undefined
          ) {
            questionFormik.setFieldValue('ratings_max_value', '');
          }
          questionFormik.setFieldValue(key, row[key]);
        }
      }
    });
  };

  const handleAddQuestion = async (values, helpers) => {
    try {
      setIsLoading(true);
      setFileUploadData({
        document_type: DOCUMENT_UPLOAD_ENUM.QUESTION,
        file_name: null,
      }); // clear file upload data
      let formData = new FormData();
      // Append each field from the values object to the FormData
      Object.keys(values).forEach(key => {
        if (!excludeFields.includes(key)) {
          if (key === 'choice') {
            if (values[key].length > 0) {
              values[key].forEach((item, index) => {
                questionFormik.values.is_filter_question === false &&
                survey?.is_score === true
                  ? formData.append(
                      `${key}[${index}]`,
                      `${item.value}:${item.option_value}`
                    )
                  : formData.append(`${key}[${index}]`, item.value);
              });
            }
          } else {
            formData.append(key, values[key]);
          }
        }
      });

      // Append the image and video files to the FormData
      if (imageAndVideoFile) {
        formData.append('image_video', imageAndVideoFile);
      } else {
        formData.append('image_video', '');
      }

      if (imageFile) {
        formData.append('image', imageFile);
      } else {
        formData.append('image', '');
      }
      if (videoFile) {
        formData.append('video', videoFile);
      } else {
        formData.append('video', '');
      }

      // NEW UPDATE FOR VIDEO OR IMAGE
      if (fileUploadData.file_name) {
        const uploadResult = await handleFileUpload();
        if (uploadResult) {
          formData.append('video_or_image', uploadResult.id);
        }
      } else {
        formData.append('video_or_image', '');
      }

      await postSurveyQuestions(survey_Id, formData)
        .then(res => {
          if (res.status === 201 || res.status === 200) {
            showToast('Question Added Successfully!', 'success');
            setOpenQestionModel(false);
            handleGetSurvey(survey_Id);
          } else {
            showToast(
              `${Object?.keys(res.data)[0]}: ${
                res.data[Object.keys(res.data)[0]]
              }`,
              'error'
            );
          }
          setIsLoading(false);
        })
        .catch(error => {
          if (error?.response) {
            showToast(
              error?.response?.data.detail ||
                `${Object?.keys(error?.response?.data)[0]}: ${
                  error?.response?.data[Object?.keys(error?.response?.data)[0]]
                }`,
              'error'
            );
          }
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (err) {
      helpers.setStatus({ success: false });
      helpers.setErrors({ submit: err.message });
      helpers.setSubmitting(false);
    }
  };
  const handleEditQuestion = async (values, helpers) => {
    try {
      setIsLoading(true);
      let formData = new FormData();
      // Append each field from the values object to the FormData
      Object.keys(values).forEach(key => {
        if (!excludeFields.includes(key)) {
          if (key === 'choice') {
            if (values[key].length > 0) {
              values[key].forEach(item => {
                if (item.id === null || item.id === undefined) {
                  formData.append(`choice`, item.value);
                }
              });
            } else {
              formData.append(key, null);
            }
          } else {
            if (
              key === 'ratings_max_value' &&
              (values['ratings_max_value'] === '' ||
                values['ratings_max_value'] === null)
            ) {
              formData.append('ratings_max_value', '');
            } else if (
              key === 'text_max_characters' &&
              (values['text_max_characters'] === '' ||
                values['text_max_characters'] === null)
            ) {
              formData.append('text_max_characters', '');
            } else if (
              key === 'text_display_lines' &&
              (values['text_display_lines'] === '' ||
                values['text_display_lines'] === null)
            ) {
              formData.append('text_display_lines', '');
            } else if (
              key === 'slider_min_value' &&
              (values['slider_min_value'] === '' ||
                values['slider_min_value'] === null)
            ) {
              formData.append('slider_min_value', '');
            } else if (
              key === 'slider_max_value' &&
              (values['slider_max_value'] === '' ||
                values['slider_max_value'] === null)
            ) {
              formData.append('slider_max_value', '');
            } else if (
              key === 'slider_step_size' &&
              (values['slider_step_size'] === '' ||
                values['slider_step_size'] === null)
            ) {
              formData.append('slider_step_size', '');
            } else if (
              key === 'question_category' &&
              (values['question_category'] === '' ||
                values['question_category'] === null)
            ) {
              formData.append('question_category', '');
            } else if (
              key === 'video_or_image' &&
              (values['video_or_image'] === '' ||
                values['video_or_image'] === null)
            ) {
              formData.append('video_or_image', '');
            } else {
              formData.append(key, values[key]);
            }
          }
        }
      });

      if (imageFile) {
        formData.append('image', imageFile);
      } else {
        formData.append('image', '');
      }
      if (videoFile) {
        formData.append('video', videoFile);
      } else {
        formData.append('video', '');
      }
      // Append the image and video files to the FormData
      if (imageAndVideoFile) {
        formData.append('image_video', imageAndVideoFile);
      } else {
        formData.append('image_video', '');
      }

      if (fileUploadData.file_name) {
        const uploadResult = await handleFileUpload();
        if (uploadResult) {
          formData.append('video_or_image', uploadResult.id);
        }
      }

      await editSurveyQuestions(survey_Id, question_Id, formData)
        .then(res => {
          if (res.status === 201 || res.status === 200) {
            showToast('Question Updated Successfully!', 'success');
            setOpenQestionModel(false);
            handleGetSurvey(survey_Id);
          } else {
            showToast(
              `${Object?.keys(res.data)[0]}: ${
                res.data[Object.keys(res.data)[0]]
              }`,
              'error'
            );
          }
          setIsLoading(false);
        })
        .catch(error => {
          if (error?.response) {
            showToast(
              error?.response?.data.detail ||
                `${Object?.keys(error?.response?.data)[0]}: ${
                  error?.response?.data[Object?.keys(error?.response?.data)[0]]
                }`,
              'error'
            );
          }
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (err) {
      helpers.setStatus({ success: false });
      helpers.setErrors({ submit: err.message });
      helpers.setSubmitting(false);
    }
  };

  const handleAddAlogo = event => {
    const file = event.currentTarget.files[0];
    setSurveyLogoFile(file);
    formik.setFieldValue('survey_logo', file);
  };

  const handleActions = ({ row }) => (
    <div>
      <button onClick={() => console.log('Edit', row)}>Edit</button>
      <button onClick={() => console.log('Delete', row)}>Delete</button>
    </div>
  );

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleStatusUpdate = async statusCode => {
    try {
      setLoading(true);
      await updateSurveyStatus(decodedString, { status: statusCode });
      showToast('Survey status updated successfully', 'success');
      handleGetSurvey(Number(decodedString));
      setLoading(false);
      setWarningMsg(false);
    } catch (error) {
      setLoading(false);
      showToast('Failed to update survey status', 'error');
      console.error(error);
      setWarningMsg(false);
    } finally {
      setLoading(false);
      setWarningMsg(false);
    }
  };

  useEffect(() => {
    if (warningMsg) {
      setDialogActionStates({
        ...dialogActionStates,
        ctaAction: {
          ...dialogActionStates.ctaAction,
          loading: loading,
        },
      });
    }
  }, [loading]);

  useEffect(() => {
    if (questionFormik.values.is_filter_question === true) {
      questionFormik.setFieldValue('required', true);
    }
  }, [questionFormik.values.is_filter_question]);

  return (
    <Container maxWidth="fuild" sx={{ mt: 6 }}>
      <Box sx={{ px: 4 }}>
        <Stack direction="row" justifyContent="end" gap={1} mb={4}>
          {(survey?.status === 'paused' || survey?.status === 'published') && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={
                survey?.status === 'published' ? <Pause /> : <PlayArrow />
              }
              // startIcon={<MultipleStopIcon />}
              sx={{
                fontSize: 11,
                '&.Mui-disabled': {
                  backgroundColor: '#B0BEC5', // Light gray for disabled state
                  color: '#ffffff',
                },
                backgroundColor: '#1976D2', // Primary blue color for enabled state
                color: '#ffffff',
                '&:hover': {
                  backgroundColor: '#1565C0', // Darker blue for hover state
                },
              }}
              onClick={() => {
                setWarningMsg(true);
                setDialogActionStates({
                  isWarning: false,
                  bodyMessage: `Are you sure you want to ${
                    survey?.status === 'paused' ? 'Continue' : 'Pause'
                  } this survey`,
                  ctaAction: {
                    actionMessage: `${
                      survey?.status === 'paused' ? 'Continue' : 'Pause'
                    }`,
                    actionColor: '#d50000 !important',
                    loading: loading,
                    actionFunction: () => {
                      handleStatusUpdate(
                        `${
                          survey?.status === 'paused' ? 'published' : 'paused'
                        }`
                      );
                    },
                  },
                });
              }}
            >
              {survey.status === 'paused' ? 'Continue' : 'Pause'} Survey
            </Button>
          )}

          {!isSurveyPublished && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<PublishedWithChangesIcon />}
              sx={{
                fontSize: 11,
                '&.Mui-disabled': {
                  backgroundColor: '#FF6665',
                  color: '#ffffff',
                },
                backgroundColor: '#d50000',
              }}
              disabled={isSurveyPublished}
              onClick={() => {
                hanldePublishSurveyModelOpen();
              }}
            >
              Publish Survey
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<VisibilityIcon />}
            sx={{ fontSize: 11 }}
            onClick={() => {
              const baseUrl = `${process.env.REACT_APP_BASE_URL}/survey-preview`;
              const newWindowUrl = `${baseUrl}/${surveyId}`;

              window.open(newWindowUrl, '_blank');
            }}
          >
            Preview Survey
          </Button>

          <ButtonGroup
            variant="contained"
            ref={shareAnchorRef}
            aria-label="Button group with a nested menu"
            disabled={!isSurveyPublished}
          >
            <Button
              sx={{ fontSize: 11 }}
              onClick={handleClick}
              startIcon={options[selectedIndex]?.icon}
            >
              {options[selectedIndex].label}
            </Button>
            <Button
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            sx={{ zIndex: 1 }}
            open={open}
            anchorEl={shareAnchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {options.map((option, index) => (
                        <MenuItem
                          key={option.action}
                          selected={index === selectedIndex}
                          onClick={event => handleMenuItemClick(event, index)}
                          disabled={option.isDisabled}
                        >
                          <ListItemIcon>{option.icon}</ListItemIcon>
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          {renderLinkDialog()}
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<Groups2Icon />}
            sx={{ fontSize: 11 }}
            onClick={() => navigate(`/survey-responses/${surveyId}`)}
            disabled={!isSurveyPublished}
          >
            Responses
          </Button>
          {isScore ? (
            <>
              <ButtonGroup
                variant="contained"
                ref={analysisAnchorRef}
                aria-label="Button group with a nested menu"
                disabled={!isSurveyPublished}
              >
                <Button
                  sx={{ fontSize: 11 }}
                  onClick={handleAnalysisClose}
                  startIcon={options2[selectedAnalysisIndex]?.icon}
                >
                  {options2[selectedAnalysisIndex].label}
                </Button>
                <Button
                  size="small"
                  aria-controls={open ? 'split-button-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleAnalysisToggle}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper
                sx={{ zIndex: 1 }}
                open={openAnalysis}
                anchorEl={analysisAnchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleAnalysisClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          {options2.map((option, index) => (
                            <MenuItem
                              key={option.action}
                              selected={index === selectedAnalysisIndex}
                              onClick={event =>
                                handleAnalysisMenuItemClick(
                                  event,
                                  surveyId,
                                  index
                                )
                              }
                              disabled={option.isDisabled}
                            >
                              <ListItemIcon>{option.icon}</ListItemIcon>
                              <ListItemText primary={option.label} />
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<LeaderBoardIcon />}
              sx={{ fontSize: 11 }}
              onClick={() => navigate(`/anaylze-responses/${surveyId}`)}
              disabled={!isSurveyPublished}
            >
              Analysis
            </Button>
          )}
        </Stack>
        <Box sx={{ mb: 2 }}>
          <Stack
            direction="row"
            marginBottom={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box sx={{ textAlign: 'left' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isSmallScreen ? 'column' : 'column',
                  gap: '0.5rem',
                  alignItems: 'flex-start',
                }}
              >
                <Typography variant="h4" component="h5">
                  <strong>Survey Title: </strong>
                  {survey?.title}{' '}
                </Typography>

                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
                  {survey?.is_anonymous && (
                    <CustomChip
                      label={'Anonymous'}
                      size="small"
                      status={'anonymous'}
                    />
                  )}

                  {survey?.end_date &&
                  new Date() > new Date(survey?.end_date) ? (
                    <CustomChip
                      label={'Closed'}
                      status={'expired'}
                      size="small"
                    />
                  ) : survey?.is_archived ? (
                    <CustomChip
                      label={'Archived'}
                      status={'archive'}
                      size="small"
                    />
                  ) : (
                    <CustomChip
                      label={capitalizeFirstCharacter(
                        survey.status === 'published' ? 'Live' : survey.status
                      )}
                      status={survey.status}
                      size="small"
                    />
                  )}
                </Box>
              </Box>
              <Model
                header
                headerText="Edit Survey Details"
                open={openEditSurveyModel}
                close
                handleClose={() => setOpenEditSurveyModel(false)}
                handleSubmit={formik.handleSubmit}
                resetForm={formik.resetForm}
                loading={isLoading}
                footer
                customWidth={true}
              >
                <Grid container direction="column" spacing={3}>
                  {/* Survey Type Section */}
                  <Grid item>
                    <FormControl fullWidth>
                      <Typography variant="h6" fontWeight="bold">
                        Survey Type
                      </Typography>
                      <RadioGroup
                        name="is_anonymous"
                        value={
                          formik.values.is_anonymous
                            ? 'anonymous'
                            : 'named_survey'
                        }
                        onChange={e =>
                          formik.setFieldValue(
                            'is_anonymous',
                            e.target.value === 'anonymous'
                          )
                        }
                      >
                        <FormControlLabel
                          disabled={isSurveyPublished}
                          value="named_survey"
                          control={<Radio />}
                          label="Named Survey (Respondent needs to share personal details)"
                        />
                        <FormControlLabel
                          disabled={isSurveyPublished}
                          value="anonymous"
                          control={<Radio />}
                          label="Anonymous (Respondent details will not be collected)"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  {/* Respondent Details Section */}
                  {!formik.values.is_anonymous && (
                    <Grid item>
                      <Typography variant="h6" fontWeight="bold">
                        Respondent Details to Be Collected
                      </Typography>
                      <FormGroup>
                        {['Name', 'Email', 'Phone', 'Gender'].map(
                          (detail, index) => {
                            const fieldName = `is_respondent_${detail.toLowerCase()}`;
                            return (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    name={fieldName}
                                    checked={formik.values[fieldName] === true}
                                    onChange={event =>
                                      formik.setFieldValue(
                                        fieldName,
                                        event.target.checked
                                      )
                                    }
                                  />
                                }
                                label={detail}
                              />
                            );
                          }
                        )}
                      </FormGroup>
                    </Grid>
                  )}

                  {/* Add Logo Section */}
                  <Grid item>
                    <Typography variant="h6" fontWeight="bold">
                      Add Banner
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ color: 'text.secondary' }}
                    >
                      (Upload a banner image to display at the top of the survey
                      page. This can be used to provide a description or
                      highlight important information about the survey.)
                    </Typography>
                    <TextField
                      id="survey_logo"
                      name="survey_logo"
                      type="file"
                      error={
                        formik.touched.survey_logo &&
                        Boolean(formik.errors.survey_logo)
                      }
                      helperText={
                        formik.touched.survey_logo && formik.errors.survey_logo
                      }
                      onChange={handleAddAlogo}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      sx={{ mt: 1 }}
                    />
                  </Grid>

                  {/* Survey Details Section */}
                  <Grid item>
                    <Typography variant="h6" fontWeight="bold">
                      Survey Details
                    </Typography>
                    <TextField
                      name="title"
                      label="Survey Title"
                      fullWidth
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.title && Boolean(formik.errors.title)
                      }
                      helperText={formik.touched.title && formik.errors.title}
                      required
                      sx={{ mt: 1 }}
                    />
                    <TextField
                      name="description"
                      label="Survey Description"
                      fullWidth
                      multiline
                      rows={3}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                      sx={{ mt: 2 }}
                    />
                  </Grid>

                  {/* Date Section */}
                  <Grid container direction={'row'} spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="start_date"
                        label="Start Date"
                        fullWidth
                        type="datetime-local"
                        value={formik.values.start_date}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.start_date &&
                          Boolean(formik.errors.start_date)
                        }
                        helperText={
                          formik.touched.start_date && formik.errors.start_date
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        slotProps={{
                          htmlInput: {
                            min: getCurrentDateTime(),
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="end_date"
                        label="End Date"
                        fullWidth
                        type="datetime-local"
                        value={formik.values.end_date}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.end_date &&
                          Boolean(formik.errors.end_date)
                        }
                        helperText={
                          formik.touched.end_date && formik.errors.end_date
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        slotProps={{
                          htmlInput: {
                            min: getCurrentDateTime(),
                          },
                        }}
                      />
                    </Grid>
                    <Typography
                      variant="subtitle2"
                      sx={{ color: 'text.secondary' }}
                    >
                      (Leave blank to start immediately and for end date: if
                      blank, the survey will run until manually stopped.)
                    </Typography>
                  </Grid>
                </Grid>
              </Model>
            </Box>
            <Stack direction="row" spacing={2} alignItems="center">
              <IconButton onClick={() => handleEditSurvey()} color="info">
                <EditIcon />
              </IconButton>
              <Button
                variant="contained"
                color="primary"
                startIcon={<ViewCompactIcon />}
                onClick={() =>
                  navigate(`/create-question-category/${surveyId}`)
                }
              >
                Manage Question Categories
              </Button>
            </Stack>
          </Stack>
          {survey.description && (
            <Typography variant="subtitle1" marginBottom={2}>
              <strong style={{ color: '#232946' }}>Description: </strong>
              {survey.description}
            </Typography>
          )}
          <Stack direction={'row'} alignItems={'center'} spacing={0.6}>
            <CalendarMonthIcon style={{ width: '16px', height: '16px' }} />
            <Typography variant="subtitle1">
              {survey?.updated_at ? (
                <>
                  <strong>Last Updated Date:</strong>{' '}
                  {survey?.updated_at
                    ? FormatDateTime(survey?.updated_at)
                    : '--'}
                </>
              ) : (
                <>
                  <strong>Created Date:</strong>{' '}
                  {survey?.created_at
                    ? FormatDateTime(survey?.created_at)
                    : '--'}
                </>
              )}
            </Typography>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} spacing={0.6}>
            {survey?.published_on && (
              <>
                <CalendarMonthIcon style={{ width: '16px', height: '16px' }} />
                <Typography variant="subtitle1">
                  <strong>Published Date:</strong>{' '}
                  {survey?.published_on
                    ? FormatDateTime(survey?.published_on)
                    : '--'}
                </Typography>
              </>
            )}
          </Stack>
          <Stack direction={'row'} spacing={4}>
            {survey?.start_date && (
              <Typography variant="subtitle1">
                <strong>Starts On:</strong>{' '}
                {survey?.start_date
                  ? FormatDateTime(survey?.start_date)
                  : survey?.published_on
                  ? FormatDateTime(survey?.published_on)
                  : '--'}
              </Typography>
            )}
            {survey?.end_date && (
              <Typography variant="subtitle1">
                <strong>Ends On:</strong>{' '}
                {survey?.end_date && FormatDateTime(survey?.end_date)}
              </Typography>
            )}
          </Stack>
        </Box>

        <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel variant="outlined">Category</InputLabel>
            <Select
              value={categoryTypeFilter}
              onChange={handleCategoryTypeFilterChange}
              label="Category"
            >
              <MenuItem value="All">{`<<All>> (${survey?.questions?.length})`}</MenuItem>
              <MenuItem
                value={true}
              >{`<<Filtered Questions>> (${FILTERQUESTION_COUNT})`}</MenuItem>
              <StyledDivider />
              {QUESTION_CATEGORY?.map(type => (
                <MenuItem key={type.id} value={type.id}>
                  {type.question_category_name} ({type?.questionCount})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by Question"
            value={searchQuery}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearchChange}
          />
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Answer Type</InputLabel>
            <Select
              value={answerTypeFilter}
              onChange={handleAnswerTypeFilterChange}
              label="Answer Type"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="text">Text Answer</MenuItem>
              <MenuItem value="multiple_choice">
                Multiple Choice (Multiple Answers)
              </MenuItem>
              <MenuItem value="radio">Multiple Choice (Single Answer)</MenuItem>
              <MenuItem value="rating">Rating Scale</MenuItem>
              <MenuItem value="yes_no">Yes/No</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={statusFilter}
              onChange={handleStatusFilterChange}
              label="Status"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="enable">Enabled</MenuItem>
              <MenuItem value="disable">Disabled</MenuItem>
            </Select>
          </FormControl>
          <Box>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              disabled={isSurveyPublished}
              sx={{ fontSize: 11, height: '100%' }}
              onClick={() => {
                setOpenQestionModel(true);
                setQuestion('');
                setAnswerType('');
                setOpenEditQuestion(false);
                setEditingQuestionId(null);
              }}
            >
              Add Question
            </Button>

            <Model
              header
              headerText={openEditQuestion ? 'Edit Question' : 'Add Question'}
              open={openQestionModel}
              close
              handleClose={() => setOpenQestionModel(false)}
              handleSubmit={questionFormik.handleSubmit}
              resetForm={questionFormik.resetForm}
              loading={isLoading || fileUploadLoading}
              footer
              customWidth={true}
            >
              <Grid container direction="column" spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel id="radio-buttons-group-label-is_filter_question">
                      Is Filter Question?
                    </FormLabel>
                    <Box
                      sx={{
                        // border: '1px solid rgba(0, 0, 0, 0.23)',
                        // borderRadius: '4px',
                        // padding: '8.5px 14px',
                        marginTop: '4px',
                      }}
                    >
                      <RadioGroup
                        value={questionFormik.values.is_filter_question}
                        onChange={e => {
                          const filterValue =
                            e.target.value === 'true' ? true : false;
                          questionFormik.setFieldValue(
                            'is_filter_question',
                            filterValue
                          );
                        }}
                        onBlur={questionFormik.handleBlur}
                        name="is_filter_question"
                        row
                        aria-labelledby="radio-buttons-group-label-is_filter_question"
                      >
                        {isFilterQuestion?.map((option, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              value={option.value}
                              disabled={editDisabled && openEditQuestion}
                              control={<Radio />}
                              label={option.label}
                            />
                          );
                        })}
                      </RadioGroup>
                    </Box>
                  </FormControl>
                </Grid>

                {questionFormik.values.is_filter_question === false && (
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      disabled={editDisabled && openEditQuestion}
                      error={Boolean(
                        questionFormik.touched.question_category &&
                          questionFormik.errors.question_category
                      )}
                    >
                      <InputLabel>Question Category</InputLabel>
                      <Select
                        required
                        label="Question Category"
                        name="question_category"
                        value={questionFormik.values.question_category}
                        onChange={handleQuestionCategoryChange}
                        onBlur={questionFormik.handleBlur}
                      >
                        {QUESTION_CATEGORY?.map(type => (
                          <MenuItem key={type.id} value={type.id}>
                            {type?.question_category_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    disabled={editDisabled && openEditQuestion}
                    error={Boolean(
                      questionFormik.touched.question_type &&
                        questionFormik.errors.question_type
                    )}
                  >
                    <InputLabel>Question Type</InputLabel>
                    <Select
                      required
                      label="Question Type"
                      name="question_type"
                      value={questionFormik.values.question_type}
                      onChange={handleQuestionTypeChange}
                      onBlur={questionFormik.handleBlur}
                    >
                      {questionFormik.values.is_filter_question === true
                        ? questionTypeOfFilterType.map(type => (
                            <MenuItem key={type.value} value={type.value}>
                              {type.label}
                            </MenuItem>
                          ))
                        : questionTypes.map(type => (
                            <MenuItem key={type.value} value={type.value}>
                              {type.label}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Question"
                    name="question"
                    fullWidth
                    multiline
                    rows={3}
                    type="text"
                    value={questionFormik.values.question}
                    disabled={editDisabled && openEditQuestion}
                    onChange={questionFormik.handleChange}
                    onBlur={questionFormik.handleBlur}
                    error={Boolean(
                      questionFormik.touched.question &&
                        questionFormik.errors.question
                    )}
                    helperText={
                      questionFormik.touched.question &&
                      questionFormik.errors.question
                    }
                    required
                    variant="outlined"
                    sx={{
                      '& .MuiInputBase-root': {
                        resize: 'vertical',
                        overflow: 'auto',
                        maxWidth: '100%',
                      },
                    }}
                    InputLabelProps={{ shrink: true, maxLength: 500 }}
                  />
                </Grid>

                {/* /IMAGE AND VIDEO */}

                <Grid item>
                  {/* <TextField
                    id="image_video"
                    name="image_video"
                    label="Image/Video(Optional)"
                    type="file"
                    fullWidth
                    error={Boolean(
                      questionFormik.touched.image_video &&
                      questionFormik.errors.image_video
                    )}
                    helperText={
                      questionFormik.touched.image_video &&
                      questionFormik.errors.image_video
                    }
                    onChange={handleImageVideoChange}
                    InputLabelProps={{ shrink: true }}
                    sx={{ mt: 1 }}
                  /> */}

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <input
                      accept="image/*"
                      id="video_or_image"
                      type="file"
                      onChange={handleImageVideoChange}
                      hidden
                    />
                    <label htmlFor="video_or_image">
                      <Button
                        variant="outlined"
                        component="span"
                        disabled={
                          fileUploadLoading ||
                          isLoading ||
                          (editDisabled && openEditQuestion)
                        }
                      >
                        Upload Question Image/Video
                      </Button>
                    </label>
                    {(fileUploadData?.file_name ||
                      questionFormik?.values?.video_or_image_url) && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        sx={{ position: 'relative', mt: 2 }}
                      >
                        <IconButton
                          sx={{
                            position: 'absolute',
                            top: -10,
                            right: -10,
                            zIndex: 1,
                            background:
                              'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                            color: 'white',
                            '&:hover': {
                              background:
                                'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
                            },
                            fontSize: '1rem',
                            borderRadius: '50%',
                          }}
                          disabled={
                            fileUploadLoading ||
                            isLoading ||
                            (editDisabled && openEditQuestion)
                          }
                          onClick={handleDeleteImage}
                        >
                          <CloseIcon />
                        </IconButton>

                        <img
                          src={
                            questionFormik?.values?.video_or_image_url
                              ? questionFormik?.values?.video_or_image_url
                              : URL.createObjectURL(fileUploadData?.file_name)
                          }
                          alt="img"
                          style={{
                            width: '250px',
                            maxHeight: '350px',
                            mixBlendMode: 'multiply',
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Grid>

                {/* IMAGE AND VIDEO END */}

                {(questionFormik.values.question_type === 'multiple_choice' ||
                  questionFormik.values.question_type === 'radio' ||
                  questionFormik.values.question_type === 'dropdown') && (
                  <Grid item>
                    <Box>
                      <Typography variant="subtitle1" gutterBottom>
                        Answer Options
                      </Typography>
                      {questionFormik.values.choice.map((option, index) => (
                        <Stack
                          key={index}
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          sx={{ mb: 2 }}
                        >
                          <TextField
                            fullWidth
                            label={`Option ${index + 1}`}
                            value={option.value}
                            required
                            disabled={editDisabled && openEditQuestion}
                            onChange={e =>
                              handleAnswerOptionChange(
                                index,
                                'value',
                                e.target.value
                              )
                            }
                          />
                          {questionFormik.values.is_filter_question === false &&
                            survey?.is_score === true && (
                              <TextField
                                // label={`Value ${index + 1}`}
                                label="Score"
                                value={option.option_value}
                                required
                                select
                                onChange={e =>
                                  handleAnswerOptionChange(
                                    index,
                                    'option_value',
                                    e.target.value
                                  )
                                }
                                sx={{ width: '100px' }}
                              >
                                {scoreValues.map(scoreOption => (
                                  <MenuItem
                                    key={scoreOption.value}
                                    value={scoreOption.value}
                                    disabled={isScoreUsed(
                                      scoreOption.value,
                                      index
                                    )}
                                  >
                                    {scoreOption.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          {questionFormik.values.choice.length > 0 && (
                            <Box
                              sx={{
                                display: 'flex',
                                gap: '0.1rem',
                                alignItems: 'center',
                              }}
                            >
                              <Tooltip title="Update">
                                <IconButton
                                  color="info"
                                  onClick={() =>
                                    handleUpdateChoiceAnswer(index, option)
                                  }
                                  disabled={option.id === null}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete">
                                <IconButton
                                  color="error"
                                  disabled={editDisabled && openEditQuestion}
                                  onClick={() =>
                                    handleRemoveAnswerOption(index, option)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                        </Stack>
                      ))}
                      {questionFormik.values.choice.length > 0 &&
                        survey?.is_score === true && (
                          <Typography
                            sx={{
                              fontSize: '0.75rem',
                              color: 'text.secondary',
                              mb: 1,
                            }}
                          >
                            *Scores range from 1 to 5, where 5 represents the
                            highest possible score and 1 represents the lowest
                            possible score.
                          </Typography>
                        )}
                      <Button
                        startIcon={<AddIcon />}
                        onClick={handleAddAnswerOption}
                        variant="outlined"
                        disabled={editDisabled && openEditQuestion}
                      >
                        Add Option
                      </Button>
                      {questionFormik.errors.choice && (
                        <FormHelperText
                          sx={{
                            fontSize: '0.75rem',
                            color: '#D32F2F',
                          }}
                        >
                          {questionFormik.errors.choice}
                        </FormHelperText>
                      )}
                    </Box>
                  </Grid>
                )}

                {questionFormik.values.question_type === 'rating' && (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '0.5rem',
                        alignItems: 'center',
                      }}
                    >
                      <InputLabel
                        sx={{ fontWeight: 'bold', minWidth: 'min-content' }}
                      >
                        Rating Max Value:
                      </InputLabel>
                      <TextField
                        label="Ratings Max Value"
                        name="ratings_max_value"
                        type="text"
                        fullWidth
                        value={questionFormik.values.ratings_max_value || ''}
                        onChange={e => {
                          const value = e.target.value;
                          questionFormik.setFieldValue(
                            'ratings_max_value',
                            value ? parseInt(value, 10) : ''
                          );
                        }}
                        onBlur={questionFormik.handleBlur}
                        error={Boolean(
                          questionFormik.touched.ratings_max_value &&
                            questionFormik.errors.ratings_max_value
                        )}
                        helperText={
                          questionFormik.touched.ratings_max_value &&
                          questionFormik.errors.ratings_max_value
                        }
                      />
                    </Box>
                  </>
                )}

                {questionFormik.values.question_type === 'multi_line_text' && (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '0.5rem',
                        alignItems: 'center',
                      }}
                    >
                      <InputLabel
                        sx={{ fontWeight: 'bold', minWidth: 'min-content' }}
                      >
                        Text Max Characters:
                      </InputLabel>
                      <TextField
                        label="Text Max Characters"
                        name="text_max_characters"
                        type="text"
                        fullWidth
                        value={questionFormik.values.text_max_characters || ''}
                        onChange={e => {
                          const value = e.target.value;
                          questionFormik.setFieldValue(
                            'text_max_characters',
                            value ? parseInt(value, 10) : ''
                          );
                        }}
                        onBlur={questionFormik.handleBlur}
                        error={Boolean(
                          questionFormik.touched.text_max_characters &&
                            questionFormik.errors.text_max_characters
                        )}
                        helperText={
                          questionFormik.touched.text_max_characters &&
                          questionFormik.errors.text_max_characters
                        }
                      />
                    </Box>
                  </>
                )}

                {questionFormik.values.question_type === 'multi_line_text' && (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '0.5rem',
                        alignItems: 'center',
                      }}
                    >
                      <InputLabel
                        sx={{ fontWeight: 'bold', minWidth: 'min-content' }}
                      >
                        Text Display Lines:
                      </InputLabel>
                      <TextField
                        label="Text Display Lines"
                        name="text_display_lines"
                        type="text"
                        fullWidth
                        value={questionFormik.values.text_display_lines || ''}
                        onChange={e => {
                          const value = e.target.value;
                          questionFormik.setFieldValue(
                            'text_display_lines',
                            value ? parseInt(value, 10) : ''
                          );
                        }}
                        onBlur={questionFormik.handleBlur}
                        error={Boolean(
                          questionFormik.touched.text_display_lines &&
                            questionFormik.errors.text_display_lines
                        )}
                        helperText={
                          questionFormik.touched.text_display_lines &&
                          questionFormik.errors.text_display_lines
                        }
                      />
                    </Box>
                  </>
                )}

                {questionFormik.values.question_type === 'slider' && (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '1rem',
                        flexDirection: { xs: 'column', md: 'row' },
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '0.5rem',
                          alignItems: 'center',
                        }}
                      >
                        <InputLabel
                          sx={{ fontWeight: 'bold', minWidth: 'min-content' }}
                        >
                          Min Value:
                        </InputLabel>
                        <TextField
                          label="Slider Min Value"
                          name="slider_min_value"
                          type="text"
                          fullWidth
                          value={questionFormik.values.slider_min_value || ''}
                          onChange={e => {
                            const value = e.target.value;
                            questionFormik.setFieldValue(
                              'slider_min_value',
                              value ? parseInt(value, 10) : ''
                            );
                          }}
                          onBlur={questionFormik.handleBlur}
                          error={Boolean(
                            questionFormik.touched.slider_min_value &&
                              questionFormik.errors.slider_min_value
                          )}
                          helperText={
                            questionFormik.touched.slider_min_value &&
                            questionFormik.errors.slider_min_value
                          }
                        />
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '0.5rem',
                          alignItems: 'center',
                        }}
                      >
                        <InputLabel
                          sx={{ fontWeight: 'bold', minWidth: 'min-content' }}
                        >
                          Max Value:
                        </InputLabel>
                        <TextField
                          label="Slider Max Value"
                          name="slider_max_value"
                          type="text"
                          fullWidth
                          value={questionFormik.values.slider_max_value || ''}
                          onChange={e => {
                            const value = e.target.value;
                            questionFormik.setFieldValue(
                              'slider_max_value',
                              value ? parseInt(value, 10) : ''
                            );
                          }}
                          onBlur={questionFormik.handleBlur}
                          error={Boolean(
                            questionFormik.touched.slider_max_value &&
                              questionFormik.errors.slider_max_value
                          )}
                          helperText={
                            questionFormik.touched.slider_max_value &&
                            questionFormik.errors.slider_max_value
                          }
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '0.5rem',
                        alignItems: 'center',
                      }}
                    >
                      <InputLabel
                        sx={{ fontWeight: 'bold', minWidth: 'min-content' }}
                      >
                        Step Size:
                      </InputLabel>
                      <TextField
                        label="Slider Step Size"
                        name="slider_step_size"
                        type="text"
                        fullWidth
                        value={questionFormik.values.slider_step_size || ''}
                        onChange={e => {
                          const value = e.target.value;
                          questionFormik.setFieldValue(
                            'slider_step_size',
                            value ? parseInt(value, 10) : ''
                          );
                        }}
                        onBlur={questionFormik.handleBlur}
                        error={Boolean(
                          questionFormik.touched.slider_step_size &&
                            questionFormik.errors.slider_step_size
                        )}
                        helperText={
                          questionFormik.touched.slider_step_size &&
                          questionFormik.errors.slider_step_size
                        }
                      />
                    </Box>
                  </>
                )}

                <Grid item>
                  <TextField
                    label="Helper Text"
                    name="help_text"
                    type="text"
                    fullWidth
                    value={questionFormik.values.help_text}
                    disabled={editDisabled && openEditQuestion}
                    onChange={questionFormik.handleChange}
                    onBlur={questionFormik.handleBlur}
                    error={Boolean(
                      questionFormik.touched.help_text &&
                        questionFormik.errors.help_text
                    )}
                    helperText={
                      questionFormik.touched.help_text &&
                      questionFormik.errors.help_text
                    }
                  />
                </Grid>

                <Grid item>
                  <FormControl fullWidth>
                    <FormLabel id="radio-buttons-group-label">
                      Is Required
                    </FormLabel>
                    <Box
                      sx={{
                        // border: '1px solid rgba(0, 0, 0, 0.23)',
                        // borderRadius: '4px',
                        // padding: '8.5px 14px',
                        marginTop: '4px',
                      }}
                    >
                      <RadioGroup
                        value={questionFormik.values.required}
                        onChange={e => {
                          const requiredValue =
                            e.target.value === 'true' ? true : false;
                          questionFormik.setFieldValue(
                            'required',
                            requiredValue
                          );
                        }}
                        onBlur={questionFormik.handleBlur}
                        name="required"
                        row
                        aria-labelledby="radio-buttons-group-label"
                      >
                        {isQuestionRequired?.map((option, index) => {
                          return (
                            <FormControlLabel
                              disabled={
                                questionFormik.values.is_filter_question ===
                                  true ||
                                (editDisabled && openEditQuestion)
                              }
                              key={index}
                              value={option.value}
                              control={<Radio />}
                              label={option.label}
                            />
                          );
                        })}
                      </RadioGroup>
                    </Box>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <FormLabel id="radio-buttons-group-label-status">
                      Status
                    </FormLabel>
                    <Box
                      sx={{
                        // border: '1px solid rgba(0, 0, 0, 0.23)',
                        // borderRadius: '4px',
                        // padding: '8.5px 14px',
                        marginTop: '4px',
                      }}
                    >
                      <RadioGroup
                        value={questionFormik.values.status}
                        onChange={questionFormik.handleChange}
                        onBlur={questionFormik.handleBlur}
                        name="status"
                        row
                        aria-labelledby="radio-buttons-group-label-status"
                      >
                        {questionStatusType?.map((option, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              disabled={editDisabled && openEditQuestion}
                              value={option.value}
                              control={<Radio />}
                              label={option.label}
                            />
                          );
                        })}
                      </RadioGroup>
                    </Box>
                  </FormControl>
                </Grid>
              </Grid>
            </Model>
          </Box>
        </Stack>

        <CustomTable
          count={filteredSurveyQuestions.length}
          headers={headers}
          rows={filteredSurveyQuestions}
          onRowReorder={handleReorderedRows}
          enableRowReorder={categoryTypeFilter === 'All' ? false : true}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          handleActions={handleActions}
          listLoading={loading}
          emptyRecordText={'No questions has been found.'}
        />
        {toast && (
          <Toast
            setToast={setToast}
            isLoading={isLoading}
            toast={toast}
            toastType={toastType}
            toastMessage={toastMessage}
          />
        )}
      </Box>
      {surveyPublishModel && (
        <SurveyPublishedModel
          open={surveyPublishModel}
          onClose={setSurveyPublishModel}
          handlePushlishSurvey={handlePushlishSurvey}
          handlePreviewAndPublish={handlePreviewAndPublish}
        />
      )}
      <DialogAlert
        title={'Note'}
        open={warningMsg}
        onClose={() => setWarningMsg(false)}
        handleAction={dialogActionStates.ctaAction}
      >
        <Typography variant="body1" gutterBottom>
          {dialogActionStates.bodyMessage} "<strong>{survey?.title}</strong>" ?
        </Typography>
      </DialogAlert>
    </Container>
  );
};

export default SurveyDetails;
