import React, { useState } from "react";

import PropTypes from "prop-types";

const ShowMoreLess = ({ text, n, width }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    toggleShowMore();
  };

  return (
    <div>
      <div>
        {text.toString().length > n ? (
          <span>
            <div
              style={{
                padding: "0",
                border: "none",
                background: "transparent",
                cursor: "pointer",
                color: "blue",
                fontSize: "15px",
                wordWrap: "break-word",
              }}
              onClick={handleClick}
              onKeyDown={(e) => {e.key = "enter"}}
            >
              <p style={{ fontSize: "14px", color: "black", width: width, margin: "0px" }}>
                {showMore ? text : `${text.slice(0, n)}...   `}
                <span style={{ color: "blue" }}>
                  {showMore ? " Less" : " More"}
                </span>
              </p>
            </div>
          </span>
        ) : (
          <p style={{ color: "black",margin: "0px" }}>{text}</p>
        )}
      </div>
    </div>
  );
};

export default ShowMoreLess;

ShowMoreLess.propTypes = {
  text: PropTypes.string,
  n: PropTypes.number,
  width: PropTypes.string,
};
