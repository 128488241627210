import { useEffect, useState } from 'react'

export const useStorage = () => {
  const [data, setData] = useState(null)

  useEffect(() => {
    setData(() => getItem('token'))
  }, [])

  const getItem = (key) => {
    let value = null
    if (localStorage.getItem(key) !== null) {
      setData(() =>
        localStorage.getItem(key).includes('{') ? JSON.parse(localStorage.getItem(key)) : localStorage.getItem(key)
      )
      value = localStorage.getItem(key)?.includes('{')
        ? JSON.parse(localStorage.getItem(key))
        : localStorage.getItem(key)
    } else {
      setData(() =>
        sessionStorage.getItem(key)?.includes('{')
          ? JSON.parse(sessionStorage.getItem(key))
          : sessionStorage.getItem(key)
      )
      value = sessionStorage.getItem(key)?.includes('{')
        ? JSON.parse(sessionStorage.getItem(key))
        : sessionStorage.getItem(key)
    }
    return value
  }

  const setItem = (key, value) => {
    if (typeof value === 'object') {
      localStorage.setItem(key, JSON.stringify(value))
      sessionStorage.setItem(key, JSON.stringify(value))
    } else {
      localStorage.setItem(key, value)
      sessionStorage.setItem(key, value)
    }
  }

  const removeItem = (key) => {
    localStorage.removeItem(key)
    sessionStorage.removeItem(key)
  }

  return { data, getItem, setItem, removeItem }
}
