import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from 'chart.js';
import { Box, Grid2, Paper, Stack, Typography } from '@mui/material';
import {
  OverviewDetailsStyled,
  SurveyOverViewStyled,
} from '../../pages/UserSurveyResponseDetail/UserSurveyPageStyles';
import {
  getSurveyAnalysisUserDetails,
  getSurveyAnalysisViewDetails,
} from './surveyParams-config';

import BarCharts from '../../pages/AnalysisCharts/BarCharts';
import DoughnutChart from '../../pages/AnalysisCharts/DoughnutChart';
import LineChart from '../../pages/AnalysisCharts/LineChart';
import NoChartAvailable from '../../pages/AnalysisCharts/NoChartAvailable/NoChartAvailable';
import PieChart from '../../pages/AnalysisCharts/PieChart';
import React from 'react';
import { forwardRef } from 'react';
import SurveyReportView from './SurveyReportView';

// Register Chart.js components
ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

const SurveyAnalysisPdf = forwardRef(function AnalysisPdf(props, ref) {
  const { surveyData, questions, categories, selectedFilters, withScore } =
    props;
  const totalActualRespondent = surveyData?.respondents?.filter(
    response => response?.time_spent
  )?.length;

  const getChartForQuestionType = question => {
    switch (question.question_type) {
      case 'multiple_choice':
        return (
          <PieChart
            question={question}
            totalActualRespondent={totalActualRespondent}
          />
        );
      case 'rating':
        return (
          <BarCharts
            question={question}
            totalActualRespondent={totalActualRespondent}
          />
        );
      case 'slider':
        return (
          <LineChart
            question={question}
            totalActualRespondent={totalActualRespondent}
          />
        );
      case 'dropdown':
      case 'radio':
      case 'yes_no':
        return (
          <DoughnutChart
            question={question}
            totalActualRespondent={totalActualRespondent}
          />
        );
      case 'text':
      case 'date':
      case 'time':
      case 'file_upload':
        return <NoChartAvailable />;
      default:
        return (
          <PieChart
            question={question}
            totalActualRespondent={totalActualRespondent}
          />
        );
    }
  };

  const excludeQuestions = [''];
  // const excludeQuestions = ["text", "date", "time", "file_upload"];
  return (
    <Box
      sx={{ mb: '3rem', height: '0px', maxHeight: '0px', overflow: 'hidden' }}
    >
      {surveyData?.respondents?.length === 0 || questions?.length === 0 ? (
        <Stack spacing={2} sx={{ mb: 3 }}>
          <Typography variant="h4" textAlign="center" gutterBottom>
            No Respondent came yet...
          </Typography>
          <Box display="flex" justifyContent="center">
            <img
              src="/assets/surveyImages/nosurvey.jpg"
              alt="error"
              style={{
                width: '450px',
                maxHeight: '567px',
                mixBlendMode: 'multiply',
              }}
            />
          </Box>
          <Typography color="text.secondary" variant="body2" textAlign="center">
            {'Please wait for the respondents to fill the survey.'}
          </Typography>
        </Stack>
      ) : (
        <>
          <Box ref={ref} sx={{ p: 2 }}>
            {withScore ? (
              <SurveyReportView
                surveyData={surveyData || {}}
                categories={categories || []}
                selectedFilters={selectedFilters || []}
              />
            ) : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '0.5rem',
                    justifyContent: 'space-between',
                    mb: 3,
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <SurveyOverViewStyled>
                      <Typography
                        variant="h5"
                        textAlign="center"
                        fontWeight={700}
                      >
                        Survey Analysis
                      </Typography>
                      <OverviewDetailsStyled
                        sx={{ backgroundColor: '#fefefe' }}
                      >
                        <Box className="detailTypes" sx={{ maxWidth: '65%' }}>
                          {surveyData &&
                            getSurveyAnalysisViewDetails(surveyData)?.map(
                              (detail, index) => (
                                <Box key={index} className="details">
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight={700}
                                  >
                                    {detail.title}:
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    className="value"
                                    color="textSecondary"
                                  >
                                    {detail.value}
                                  </Typography>
                                </Box>
                              )
                            )}
                        </Box>
                        <Box className="detailTypes">
                          {Object.keys(surveyData)?.length &&
                            getSurveyAnalysisUserDetails(surveyData).map(
                              (detail, index) => (
                                <Box key={index} className="details">
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight={700}
                                  >
                                    {detail.title}:
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                  >
                                    {detail.value}
                                  </Typography>
                                </Box>
                              )
                            )}
                        </Box>
                      </OverviewDetailsStyled>
                    </SurveyOverViewStyled>
                  </Box>
                </Box>

                <Grid2 container spacing={3}>
                  {questions?.map((question, index) => {
                    return excludeQuestions?.includes(
                      question?.question_type
                    ) ? null : (
                      <Grid2
                        size={{ xs: 12 }}
                        key={question.question_id}
                        component="main"
                      >
                        <Paper
                          elevation={3}
                          className="no-break"
                          sx={{ p: 2, border: '0.2px solid rgba(0,0,0,0.20)' }}
                        >
                          <Typography variant="h6" gutterBottom>
                            {index + 1}. {question.question}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            gutterBottom
                            sx={{ mb: 2, textAlign: 'left' }}
                          >
                            <strong>{question?.response_count}</strong> out of{' '}
                            <strong>{totalActualRespondent}</strong> respondents
                            answer this question!
                          </Typography>
                          {getChartForQuestionType(question)}
                        </Paper>
                      </Grid2>
                    );
                  })}
                </Grid2>
              </>
            )}
          </Box>
        </>
      )}
    </Box>
  );
});

export default SurveyAnalysisPdf;
