import React, { createContext, useContext, useEffect, useState } from 'react';

import PATH from '../router/paths';
import { useLocation } from 'react-router-dom';

const TitleContext = createContext({
  title: '',
  setTitle: () => {},
});

export const TitleProvider = ({ children }) => {
  const location = useLocation();
  const [title, setTitle] = useState('');
  const defaultTitlePages = [
    PATH.HOME,
    PATH.LOGIN,
    PATH.SIGNUP,
    PATH.EMAIL_VERIFICATION,
    PATH.COMPANY_ONBOARDING,
    PATH.MY_SURVEYS,
    PATH.DASHBOARD,
    PATH.ADMIN,
    PATH.RESULT_ANALYTICS,
    PATH.SETTINGS,
    PATH.MY_PROFILE,
    PATH.MANAGER_USERS,
    PATH.CONTACT_SUPPORT,
    PATH.COOKIES,
    PATH.THANK_YOU,
    PATH.PRIVACY_POLICY,
  ];

  useEffect(() => {
    if (defaultTitlePages.includes(location.pathname)) {
      setTitle('');
    }
    document.title =
      `OpinionStream${title ? ` - ${title}` : ''}` || 'OpinionStream';
    return () => {
      document.title = 'OpinionStream';
    };
  }, [title, location.pathname]);

  return (
    <TitleContext.Provider value={{ title, setTitle }}>
      {children}
    </TitleContext.Provider>
  );
};

export const useTitle = () => {
  const context = useContext(TitleContext);
  if (!context) {
    throw new Error(`useTitle must be used within a TitleProvider`);
  }
  return context;
};
