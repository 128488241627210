export const questionAndAnswerTypes = [
  { questionType: 'text', answerType: 'text_answer' },
  { questionType: 'multi_line_text', answerType: 'text_answer' },
  { questionType: 'multiple_choice', answerType: 'multiple_choices' },
  { questionType: 'radio', answerType: 'choice_answer' },
  { questionType: 'rating', answerType: 'rating_answer' },
  { questionType: 'yes_no', answerType: 'yes_no_answer' },
  { questionType: 'dropdown', answerType: 'choice_answer' },
  { questionType: 'date', answerType: 'date_answer' },
  { questionType: 'time', answerType: 'time_answer' },
  { questionType: 'file_upload', answerType: 'File' },
  { questionType: 'slider', answerType: 'slider_value' },
];

export const questionTypes = [
  { value: 'radio', label: 'Single Choice (radio button)' },
  { value: 'dropdown', label: 'Single Choice (dropdown/select)' },
  { value: 'yes_no', label: 'Single Choice (Yes/No)' },
  { value: 'multiple_choice', label: 'Multiple Choices' },
  { value: 'rating', label: 'Rating' },
  { value: 'date', label: 'Date Picker' },
  { value: 'time', label: 'Time Picker' },
  { value: 'file_upload', label: 'File Upload' },
  { value: 'slider', label: 'Slider' },
  { value: 'text', label: 'Text Answer' },
  { value: 'multi_line_text', label: 'Multi Line Text Answer' },
];

export const getErrorMessage = questionType => {
  switch (questionType) {
    case 'text':
      return 'Text is required!';
    case 'multi_line_text':
      return 'Text is required!';
    case 'multiple_choice':
      return 'Please select at least one option!';
    case 'radio':
      return 'Please select an option!';
    case 'rating':
      return 'Rating is required!';
    case 'yes_no':
      return 'Please select yes or no!';
    case 'dropdown':
      return 'Please select an option!';
    case 'date':
      return 'Date is required!';
    case 'time':
      return 'Time is required!';
    case 'file_upload':
      return 'File upload is required!';
    case 'slider':
      return 'Slider value is required!';
    default:
      return 'This field is required!';
  }
};

// STATE NAMES CONFIG
export const INDIAN_STATES_NAMES = [
  'Andaman and Nicobar Islands',
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chandigarh',
  'Chhattisgarh',
  'Dadra and Nagar Haveli',
  'Daman and Diu',
  'Delhi',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu and Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Ladakh',
  'Lakshadweep',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Puducherry',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttar Pradesh',
  'Uttarakhand',
  'West Bengal',
];

export const SURVEY_DISPLAY_TYPES_ENUM = {
  SINGLE_FORM: 'single_form',
  MULTI_STEPS_FORM: 'multi_steps',
};

export const SURVEY_STEP_STYLE_ENUM = {
  ONE_QUESTION_PER_STEP: 'one_question_per_step',
  QUESTION_CATEGORY_PER_STEP: 'question_category',
};
