import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Logout, Settings } from '@mui/icons-material';
import PATH, { NAVIGATATION_BLOCKED_ROUTES } from '../../router/paths';
import { useLocation, useNavigate } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import MenuIcon from '@mui/icons-material/Menu';
import { MenuSlotsPropsConfigStyle } from './navigation-style';
import PeopleIcon from '@mui/icons-material/People';
import { useAuthContext } from '../../contexts/AuthContext';
import { useState } from 'react';

export const Navigation = () => {
  const { user, logout } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleNavigation = path => {
    navigate(path);
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  const handleMyProfile = () => {
    navigate('/my-profile');
    handleClose();
  };

  // Custom active link styling
  const getButtonStyles = path => {
    return {
      backgroundColor:
        location.pathname === path ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
      fontWeight: location.pathname === path ? 'bold' : 'normal',
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: location.pathname === path ? '100%' : 0,
        height: '2px',
        backgroundColor: theme.palette.primary.main,
        transition: 'width 0.3s ease',
      },
      '&:hover::after': {
        width: '100%',
      },
    };
  };

  const handleContactSupport = () => {
    navigate(PATH.CONTACT_SUPPORT);
    handleClose();
  };

  const handleManageUsers = () => {
    navigate(PATH.MANAGER_USERS);
    handleClose();
  };

  const handleSettings = () => {
    navigate(PATH.SETTINGS);
    handleClose();
  };

  const NavigationLinks = ({ inDrawer = false }) => {
    const ButtonComponent = inDrawer ? ListItemButton : Button;
    const buttonProps = inDrawer ? {} : { color: 'inherit' };

    return user?.user ? (
      <>
        <ButtonComponent
          {...buttonProps}
          sx={!inDrawer ? getButtonStyles('/dashboard') : {}}
          onClick={() => handleNavigation('/dashboard')}
        >
          {inDrawer ? <ListItemText primary="Dashboard" /> : 'Dashboard'}
        </ButtonComponent>

        {/* {user.roles?.includes("admin") && ( */}
        {user?.user?.groups?.[0]?.name === 'Admin' && (
          <>
            {/* <ButtonComponent
              {...buttonProps}
              sx={!inDrawer ? getButtonStyles("/admin") : {}}
              onClick={() => handleNavigation("/admin")}
            >
              {inDrawer ? <ListItemText primary="Admin" /> : "Admin"}
            </ButtonComponent> */}
            <ButtonComponent
              {...buttonProps}
              sx={!inDrawer ? getButtonStyles('/my-surveys') : {}}
              onClick={() => handleNavigation('/my-surveys')}
            >
              {inDrawer ? <ListItemText primary="My Surveys" /> : 'My Surveys'}
            </ButtonComponent>
            <ButtonComponent
              {...buttonProps}
              sx={!inDrawer ? getButtonStyles('/result-analytics') : {}}
              onClick={() => handleNavigation('/result-analytics')}
            >
              {inDrawer ? (
                <ListItemText primary="Result Analytics" />
              ) : (
                'Result Analytics'
              )}
            </ButtonComponent>
          </>
        )}

        {/* {user.roles?.includes("user") && ( */}
        {user?.user?.groups?.[0]?.name === 'User' && (
          <ButtonComponent
            {...buttonProps}
            sx={!inDrawer ? getButtonStyles('/survey-responses') : {}}
            onClick={() => handleNavigation('/survey-responses')}
          >
            {inDrawer ? <ListItemText primary="Responses" /> : 'Responses'}
          </ButtonComponent>
        )}
      </>
    ) : (
      <ButtonComponent
        {...buttonProps}
        sx={!inDrawer ? getButtonStyles('/login') : {}}
        onClick={() => handleNavigation('/login')}
      >
        {inDrawer ? <ListItemText primary="Login" /> : 'Login'}
      </ButtonComponent>
    );
  };

  const isHideNavigation = !NAVIGATATION_BLOCKED_ROUTES.some(path =>
    location.pathname.startsWith(path)
  );

  return (
    <AppBar
      position="sticky"
      sx={{ display: !isHideNavigation ? 'none' : 'block' }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent={'start'}
          gap={1}
        >
          {isMobile &&
            user &&
            user?.roles?.[0] !== 'guest' &&
            isHideNavigation && (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            )}
          <Box
            display="flex"
            alignItems="center"
            justifyContent={'start'}
            gap={1}
            sx={{ cursor: 'pointer' }}
            onClick={() => handleNavigation('/dashboard')}
          >
            <img
              alt="OpinionStream"
              src="/favicon.ico"
              width={35}
              height={35}
            />
            <Typography variant="h6" sx={{ color: '#fff' }}>
              OpinionStream
            </Typography>
          </Box>
        </Box>

        {/* Desktop Navigation */}
        <Box>
          {!isMobile &&
            user &&
            user?.roles?.[0] !== 'guest' &&
            isHideNavigation && <NavigationLinks />}

          {/* User Avatar and Menu */}
          {user && user?.roles?.[0] !== 'guest' && isHideNavigation && (
            <>
              <Tooltip title="Account settings">
                <IconButton onClick={handleClick}>
                  <Avatar
                    alt={`${user?.user?.first_name} ${user?.user?.last_name}`}
                    src={`${user?.organisation_details?.organisation_banner}`}
                    sx={{
                      backgroundColor: '#b8c1ec',
                      color: '#232946',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      '& .MuiAvatar-img': {
                        objectFit: 'scale-down',
                      },
                    }}
                  >
                    <Typography variant="button text">
                      {`${user?.user?.first_name?.split(' ')[0][0]}${
                        user?.user?.last_name?.split(' ')[0][0]
                      }`}
                    </Typography>
                  </Avatar>
                </IconButton>
              </Tooltip>

              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={MenuSlotsPropsConfigStyle}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem onClick={handleMyProfile}>
                  <Avatar
                    src={`${process.env.REACT_APP_API_BASE_URL}${user?.organisation_details?.organisation_logo}`}
                    alt={`${user?.user?.first_name} ${user?.user?.last_name}`}
                  />{' '}
                  Manage Profile
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleManageUsers}>
                  <ListItemIcon>
                    <PeopleIcon fontSize="small" />
                  </ListItemIcon>
                  Manage Users
                </MenuItem>
                <MenuItem onClick={handleSettings}>
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  Settings
                </MenuItem>
                <MenuItem onClick={handleContactSupport}>
                  <ListItemIcon>
                    <ContactSupportIcon fontSize="small" />
                  </ListItemIcon>
                  Contact Support
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleLogout();
                  }}
                >
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>

        {/* Mobile Navigation Drawer */}
        <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
          <Box sx={{ width: 250 }}>
            {/* Drawer Header with Close Button */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 2,
                borderBottom: 1,
                borderColor: 'divider',
              }}
            >
              <Typography variant="h6">Menu</Typography>
              <IconButton onClick={handleDrawerToggle}>
                <CloseIcon />
              </IconButton>
            </Box>

            {/* Navigation Links */}
            <List>
              <NavigationLinks inDrawer={true} />
            </List>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};
