import { AuthProvider } from "./contexts/AuthContext";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import React from "react";
import RouterConfig from "./router/router";
import { ThemeProvider } from "@mui/material/styles";
import { TitleProvider } from "./contexts/TitleContext";
import { theme } from "./styles/muiTheme";
const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Box component="main" sx={{ flexGrow: 1, height: "100%" }}>
          <TitleProvider>
          <RouterConfig />
          </TitleProvider>
        </Box>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
