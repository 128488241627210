import { Box, Button, Fade, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Category, QuestionAnswer } from '@mui/icons-material';
import React, { useEffect } from 'react';

import PATH from '../../../router/paths';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import { keyframes } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
`;

const StepsCompletedPage = (props) => {
  const { handleReset, surveySuccessResponse } = props;
  const encodeSurveyId = btoa(`survey_${surveySuccessResponse?.id}_${Date.now()}`);

  const navigate = useNavigate();
  const handleCreateQuestions = () => {
    navigate(`/survey-details/${encodeSurveyId}`, { replace: true, state: { key: new Date().getTime() } });
    handleReset();
  }

  const handleCreateCategories = () => {
    navigate(`/create-question-category/${encodeSurveyId}`, { replace: true, state: { key: new Date().getTime() } });
    handleReset();
  }

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center', animation: `${fadeIn} 1s ease-in-out`
    }}>
      <Typography variant="h4" sx={{ mb: 2, animation: `${bounce} 2s infinite` }}>
        Congratulations!
      </Typography>
      <Typography variant="h6" sx={{ mb: 4 }}>
        Your survey has been successfully created.
      </Typography>
      <Box sx={{ mb: 4 }}>
        <img src="/assets/surveyImages/sureyCreated.png" alt="Survey Created"
          style={{
            width: '100%', maxWidth: '250px', animation: `${fadeIn} 2s ease-in-out`,
            mixBlendMode: "multiply"
          }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          backgroundColor: "#f0f4ff",
          padding: "1rem",
          borderRadius: "8px",
          border: "1px solid #1976d2",
          mt: 4,
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          mb: 2,
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: "bold",  color: "#1976d2" }}>
          What would you like to do next?
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", justifyContent: "space-between" }}>
        <Fade in={true} timeout={3000}>
          <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
            <Button
              variant="contained"
              color="info"
              size={isSmallScreen ? 'medium' : 'large'}
              onClick={handleCreateCategories}
              startIcon={<ViewCompactIcon />}
              sx={{
                animation: `${fadeIn} 3s ease-in-out`,
                mb: 1,
                '&:hover': {
                  backgroundColor: 'info.dark',
                },
              }}
            >
              Create Questions Categories
            </Button>
            <Typography variant="body1" fontWeight={700} color="textSecondary"
              sx={{ fontSize: isSmallScreen ? "0.7rem" : "1rem" }}
            >
              (Optional but recommended)
            </Typography>
          </Box>
        </Fade>
        <Fade in={true} timeout={3000}>
          <Button
            variant="contained"
            color="primary"
            size={isSmallScreen ? 'medium' : 'large'}
            onClick={handleCreateQuestions}
            startIcon={<QuestionMarkIcon />}
            sx={{
              width: "100%",
              animation: `${fadeIn} 3s ease-in-out`,
              '&:hover': {
                backgroundColor: 'primary.dark',
              },
            }}
          >
            Create Questions
          </Button>
        </Fade>
      </Box>

    </Box>
  );
}

export default StepsCompletedPage;