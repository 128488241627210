import React, { useCallback, useEffect, useMemo } from 'react'

import { Bar } from 'react-chartjs-2';
import { Box } from '@mui/material';
import { barChartOptions } from './ChartOptionsConfig';

const BarCharts = (props) => {
    const { question, totalActualRespondent } = props

    // Aggregate Rating Data
    const aggregateRatings = useCallback((currentQuestion) => {

        const data = currentQuestion?.rating_analysis?.analysis?.map((item) => {
            return {
                label: item.rating_value,
                data: item.response_count,
            }
        })

        const newMapData = new Map();

        data.forEach((item) => {
            newMapData.set(item.label, item.data);
        });

        return {
            labels: Array.from(newMapData.keys()),
            data: Array.from(newMapData.values()),
            question: question.question,
        };
    },
        [question]
    );

    const ratingBarData = useMemo(() => {
        const rData = aggregateRatings(question);
        return {
            labels: rData.labels,
            datasets: [
                {
                    label: "Rating Count",
                    data: rData.data,
                    backgroundColor: [
                        "rgba(73,188,170,0.8)",
                        "rgba(72,174,209,0.8)",
                    ],
                },
            ],
        };
    }, [aggregateRatings]);

    const options = {
        ...barChartOptions,
        plugins: {
          tooltip: {
                enabled: false,
                external: function (context) {
                    const dataIndex = context.tooltip.dataPoints[0].dataIndex;
                    const data = context?.tooltip?.dataPoints?.[0]?.dataset?.data?.[dataIndex];
                // Tooltip Element

          let tooltipEl = document.getElementById('chartjs-tooltip');

          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map(getBody);

            let innerHtml = '<thead>';

            titleLines.forEach(function (title, i) {
                const colors = tooltipModel.labelColors[i];
                let style = 'background:' + colors.backgroundColor;
                style += '; border-color:' + colors.borderColor;
                style += '; border-width: 2px';
                const span = '<span style="' + style + '; display: inline-block; width: 10px; height: 10px; margin-right: 5px;"></span>';
                innerHtml += '<tr><th style="text-align: left;">' + span + 'Rating ' + title + '  ' +
                  '(' + ((data / totalActualRespondent) * 100)?.toFixed(1) + '%)' +
                  '</th></tr>';
              });
            innerHtml += '</thead><tbody>';

            bodyLines.forEach(function (body, i) {
              const colors = tooltipModel.labelColors[i];
              let style = 'background:' + colors.backgroundColor;
              style += '; border-color:' + colors.borderColor;
              style += '; border-width: 2px';
              const span = '<span style="' + style + '; display: inline-block; width: 10px; height: 10px; margin-right: 5px;"></span>';
              const bodyText = body[0] || '';
              const [label] = bodyText.split('<br>');
              innerHtml += '<tr><td>' + span + data + '  respondent(s)</td></tr>';
            });
            innerHtml += '</tbody>';

            const tableRoot = tooltipEl.querySelector('table');
            tableRoot.innerHTML = innerHtml;
          }

          const position = context.chart.canvas.getBoundingClientRect();
          tooltipEl.style.opacity = 1;
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 10 + 'px';
          tooltipEl.style.font = tooltipModel.options.bodyFont.string;
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          boxWidth: 25,
          boxHeight: 20,
          padding: 20,
          generateLabels: function (chart) {
            const data = chart.data;
            if (data.labels.length && data.datasets.length) {
              const legendItems =  data.labels.map((label, i) => {
                const meta = chart.getDatasetMeta(0);
                const style = meta.controller.getStyle(i);
                const count = data.datasets[0].data[i];
                const percentage = ((count / totalActualRespondent) * 100).toFixed(1);

                return {
                  text: `Rating ${label}: (${percentage}%) ${count} respondent(s)`,
                  fillStyle: style.backgroundColor,
                  strokeStyle: style.borderColor,
                  lineWidth: style.borderWidth,
                  hidden: !chart.getDataVisibility(i),
                  index: i,
                  percentage: parseFloat(percentage), // Add percentage for sorting
                };
              });
               // Sort legend items by percentage
               legendItems.sort((a, b) => b.percentage - a.percentage);

               return legendItems;
            }
            return [];
          },
        },
      },
        },
      };
      
      useEffect(() => {
        // Create tooltip element
        let tooltipEl = document.getElementById('chartjs-tooltip');
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
          tooltipEl.style.borderRadius = '3px';
          tooltipEl.style.color = 'white';
          tooltipEl.style.opacity = 0;
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.style.transition = 'opacity 0.3s ease';
          tooltipEl.style.padding = '10px';
          tooltipEl.innerHTML = '<table></table>';
          document.body.appendChild(tooltipEl);
        }
      }, []);

    return (
        <>
            {ratingBarData && (
                <Box sx={{ height: 300 }}>
                    <Bar data={ratingBarData}
                        width={100}
                     options={options} />
                </Box>
            )}
        </>
    )
}

export default BarCharts