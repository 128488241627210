export const capitalizeFirstCharacter = (string) => {
    if (typeof string !== 'string') {
        return '';
    }
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

export const urlToFile = async (url, filename, mimeType) => {
    const response = await fetch(url);
    const buffer = await response.arrayBuffer();
    return new File([buffer], filename, { type: mimeType });
  };

export  const getMimeType = (url) => {
    const extension = url.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(extension)) {
      return `image/${extension}`;
    } else if (['mp4', 'webm', 'ogg', 'mov', 'avi', 'mkv'].includes(extension)) {
      return `video/${extension}`;
    } else {
      return 'application/octet-stream'; // Default MIME type for unknown file types
    }
  };


// Get a cookie value by name
export function getCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }
  
  // Set a cookie with a name, value, and expiration in days
export function setCookie(name, value, days) {
    let d = new Date();
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }


// function to return the type of media user has upload to render the media
export const getMediaType = (url) => {
  const extension = url.split('.').pop().toLowerCase();
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg', 'webp'];
  const videoExtensions = ['mp4', 'webm', 'ogg', 'avi', 'mov', 'wmv', 'flv', 'mkv'];

  if (imageExtensions.includes(extension)) {
    return 'image';
  } else if (videoExtensions.includes(extension)) {
    return 'video';
  } else {
    return null;
  }
};

export const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// converting the URL back to file and setting the formik value
export const convertUrlToFileAndSetFormik = async (key, url, mimeType, formType, showToast) => {
  try {
    if (url) {
      const filename = url.split('/').pop(); // Extract the filename from the URL
      const file = await urlToFile(url, filename, mimeType);
      formType.setFieldValue(key, file);
    }
  } catch (error) {
    showToast("Failed to load file", "error");
    // Optionally, we can set an error state in Formik or show a toast message
    formType.setFieldError(key, 'Failed to load file');
  }
};


export const epochTimeToDate = (epochTime) => {
  const hours = Math.floor(epochTime / 3600);
  const minutes = Math.floor((epochTime % 3600) / 60);
  const seconds = Math.floor(epochTime % 60);

  let result = '';

  if (hours > 0) {
    result += `${hours} hour${hours > 1 ? 's' : ''} `;
  }
  if (minutes > 0) {
    result += `${minutes} minute${minutes > 1 ? 's' : ''} `;
  }
  if (seconds > 0 || result === '') {
    result += `${seconds} second${seconds > 1 ? 's' : ''}`;
  }

  return result.trim();
};

/* 
  ** input params : date in string format
  ** output : 2 days ago, 3 months ago, 1 year ago, 1 minute ago, 1 hour ago, 1 second ago
*/
export const timeAgo = (date) => {
  if (!date) {
    return 'N/A';
  }
  const currentDate = new Date();
  const givenDate = new Date(date);
  const seconds = Math.floor((currentDate - givenDate) / 1000);
  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return `${interval} years ago`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return `${interval} months ago`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return `${interval} days ago`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return `${interval} hours ago`;
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return `${interval} minutes ago`;
  }
  return `${Math.floor(seconds)} seconds ago`;
};

// regex which convert string with underscore to normal string
export const underscoreToSpaceString = (string) => {
  return capitalizeFirstCharacter(string.replace(/_/g, ' '));
};