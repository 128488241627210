const surveyDetailsValidationSchema = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = 'Survey Name is required';
    }

    if (values.is_anonymous === null || values.is_anonymous === "") {
      errors.is_anonymous = 'Type of survey is required';
    }

    return errors;
  };


const questionCategoriesValidationSchema = (values) => {
    const errors = {};

    if (values.categories.length === 0) {
      errors.categories = 'At least one category is required';
    }

    return errors;
  };

const configureSurveyPageValidationSchema = (values) => {
    const errors = {};

    if (!values.survey_display_type) {
      errors.survey_display_type = 'Survey Display Type is required';
    }

    if (values.survey_display_type === "multi_steps" && !values.multi_step_style) {
      errors.multi_step_style = 'Survey Step Type is required';
    }

    return errors;
  }

const startingScheduleValidationSchema = (values) => {
    const errors = {};

    if (values.startOption ==="future" && !values.start_date) {
      errors.start_date = 'Survey Start Date & Time is required';
    }

    return errors;
  }
 
const namedRespondentValidationSchema = (values) => {
    const errors = {};

    if (values.is_respondent_name === "" && values.is_respondent_email === "" && values.is_respondent_phone === "" && values) {
      errors.is_respondent_details = 'At least one respondent detail is required';
    }

    return errors;
};  


export const getValidationSchemaNamed = (activeStep) =>{
  switch (activeStep) {
    case 0:
        return surveyDetailsValidationSchema;
    case 1:
        return namedRespondentValidationSchema;
    case 2:
        return configureSurveyPageValidationSchema;
    case 3:
        return startingScheduleValidationSchema;
    default:
        return null;
  }
}

export const getValidationSchema = (activeStep) =>{
      switch (activeStep) {
        case 0:
            return surveyDetailsValidationSchema;
        case 1:
            return configureSurveyPageValidationSchema;
        case 2:
            return startingScheduleValidationSchema;
        default:
            return null;
    }    
}