import '../styles/customStyles.css';

import { Box, Paper } from '@mui/material';
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { Container } from '@mui/system';
import RenderSurvey from '../components/RenderSurvey';
import useApiService from '../hooks/api-services/use-service';
import { useParams } from 'react-router-dom';
import { useTitle } from '../contexts/TitleContext';

const SurveyPreview = () => {
  const { surveyId } = useParams();
  const [survey, setSurvey] = useState();
  const { getSurveyById } = useApiService();
  const { setTitle } = useTitle();

  const handleGetSurvey = async surveyId => {
    try {
      await getSurveyById(surveyId).then(res => {
        if (res.status === 200) {
          setSurvey(res?.data);
          setTitle(res?.data?.title);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const decodedString = Number(atob(surveyId).split('_')[1]);
    handleGetSurvey(decodedString);
  }, [surveyId]);

  return (
    <Box>
      <Container maxWidth="xl" sx={{ my: 4, position: 'relative' }}>
        <Paper elevation={3} sx={{ p: 3, position: 'relative' }}>
          <div className="ribbon ribbon-top-right">
            <span>Preview</span>
          </div>
          <RenderSurvey
            data={survey && survey}
            isPreviewOnly
            handleGetSurvey={handleGetSurvey}
          />
        </Paper>
      </Container>
    </Box>
  );
};

export default SurveyPreview;
