import * as Yup from "yup";

import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Email,
  Lock,
  Person,
  Phone,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import React, { useState } from "react";

import { CustomButtom } from "../CustomButton";
import Toast from "../Tost";
import useApiService from "../../hooks/api-services/use-service";
import { useAuthContext } from "../../contexts/AuthContext";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../hooks/custom-hook-functions/useToast";

const SignupPage = () => {
  const navigate = useNavigate();
  const { signUp } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const { setToast, showToast, toast, toastMessage, toastType } = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const { registerUserAdmin } = useApiService();

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const regFormik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      last_name: "",
      password: "",
      password2: "",
      phone: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
      first_name: Yup.string().max(255).required("First Name is required"),
      phone: Yup.string()
        .required("Phone Number is required")
        .matches(phoneRegExp, "Phone number is not valid"),
      password: Yup.string().max(255).required("Password is required"),
      password2: Yup.string()
        .max(255)
        .required("Password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        setLoading(true);
        const res = await registerUserAdmin(values);
            if (res.status === 201) {
              showToast("User Registered Successfully!", "success");
              signUp(res.data);
              setLoading(false);
              setTimeout(() => {
                navigate("/email-verification");
              }, 1000);
              setLoading(false);
            }
      } catch (error) {
        if (error?.response) {
          showToast(error?.response?.data.detail ||
            `${Object?.keys(error?.response?.data)[0]}: ${
              error?.response?.data[
                Object?.keys(error?.response?.data)[0]
              ]
            }`, "error");
          } 
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: error.message });
        helpers.setSubmitting(false);
        setLoading(false)
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
    <Box>
      <Container maxWidth="sm">
        <Box sx={{ mt: 6, mb: 4 }}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Typography variant="h4" align="center" gutterBottom>
              Create Account
            </Typography>

            <form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                regFormik.handleSubmit(e);
              }}
            >
              <Stack spacing={3}>
                <TextField
                  required
                  InputLabelProps={{ shrink: true }}
                  error={
                    !!(
                      regFormik.touched.first_name &&
                      regFormik.errors.first_name
                    )
                  }
                  fullWidth
                  helperText={
                    regFormik.touched.first_name && regFormik.errors.first_name
                  }
                  label="First Name"
                  name="first_name"
                  onBlur={regFormik.handleBlur}
                  onChange={regFormik.handleChange}
                  value={regFormik.values.first_name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  required
                  InputLabelProps={{ shrink: true }}
                  error={
                    !!(
                      regFormik.touched.last_name && regFormik.errors.last_name
                    )
                  }
                  fullWidth
                  helperText={
                    regFormik.touched.last_name && regFormik.errors.last_name
                  }
                  label="Last Name"
                  name="last_name"
                  onBlur={regFormik.handleBlur}
                  onChange={regFormik.handleChange}
                  value={regFormik.values.last_name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  required
                  InputLabelProps={{ shrink: true }}
                  error={!!(regFormik.touched.email && regFormik.errors.email)}
                  fullWidth
                  helperText={regFormik.touched.email && regFormik.errors.email}
                  label="Email Address"
                  name="email"
                  onBlur={regFormik.handleBlur}
                  onChange={regFormik.handleChange}
                  type="email"
                  value={regFormik.values.email}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  required
                  InputLabelProps={{ shrink: true }}
                  error={!!(regFormik.touched.phone && regFormik.errors.phone)}
                  fullWidth
                  helperText={regFormik.touched.phone && regFormik.errors.phone}
                  label="Phone Number"
                  name="phone"
                  onBlur={regFormik.handleBlur}
                  onChange={regFormik.handleChange}
                  type="text"
                  value={regFormik.values.phone}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  required
                  InputLabelProps={{ shrink: true }}
                  error={
                    !!(regFormik.touched.password && regFormik.errors.password)
                  }
                  fullWidth
                  helperText={
                    regFormik.touched.password && regFormik.errors.password
                  }
                  label="Password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  onBlur={regFormik.handleBlur}
                  onChange={regFormik.handleChange}
                  value={regFormik.values.password}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  required
                  InputLabelProps={{ shrink: true }}
                  error={
                    !!(
                      regFormik.touched.password2 && regFormik.errors.password2
                    )
                  }
                  fullWidth
                  helperText={
                    regFormik.touched.password2 && regFormik.errors.password2
                  }
                  label="Confirm Password"
                  name="password2"
                  onBlur={regFormik.handleBlur}
                  onChange={regFormik.handleChange}
                  type={showPassword ? "text" : "password"}
                  value={regFormik.values.password2}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>

              <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <CustomButtom
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={loading}
                  disabled={loading}
                  text={"Continue"}
                />
              </Box>
            </form>

            <Divider sx={{ my: 3 }} />

            <Typography variant="body2" align="center" color="text.secondary">
              Already have an account?{" "}
              <Button color="primary" onClick={() => navigate("/login")}>
                Sign In
              </Button>
            </Typography>
          </Paper>
        </Box>
      </Container>
      </Box>
      {loading ||
        (toast && (
          <Toast
            isLoading={loading}
            toast={toast}
            toastType={toastType}
            toastMessage={toastMessage}
          />
        ))}
    </>
  );
};

export default SignupPage;
