import { Navigate, useLocation } from "react-router-dom";

import PATH from "../../router/paths";
import React from "react";
import { useAuthContext } from "../../contexts/AuthContext";

const PublicRoutes = ({ Component }) => {
  const { user } = useAuthContext();
  const location = useLocation();
  if (location.pathname === "/" && user) {
    return <Navigate to={PATH.DASHBOARD}/>;
  }

  return <Component/>;


};

export default PublicRoutes;
