import { Box } from '@mui/material'
import Footer from '../../components/Footer/Footer'
import { Navigation } from '../../components/navigation/naviagtion'
import { Outlet } from 'react-router-dom'
import React from 'react'

const MainLayout = () => {
  
  return (
    <>
        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh"}}>
            <Navigation />
            <Box sx={{ flexGrow: 1 }}>
            <Outlet/>
            </Box>
            <Footer/>
        </Box>
    </>
  )
}

export default MainLayout