import { capitalizeFirstCharacter, epochTimeToDate } from "../../utils/helpers";

export const getSurveyOverviewDetails = (surveyDetailsData) => {
    if (!Object.keys(surveyDetailsData).length) return [];
    const surveyOverviewDetails = [
        {
            title: "Survey Title",
            value: capitalizeFirstCharacter(surveyDetailsData?.survey?.title) || "--",
        },
        {
            title: "Description",
            value: capitalizeFirstCharacter(surveyDetailsData?.survey?.description) || "--",
        },
        {
            title: "Survey Type",
            value: `${surveyDetailsData?.survey?.is_anonymous ? "Anonymous" : "Named"} Survey` || "--",
        },
        {
            title: "No of Questions",
            value: surveyDetailsData?.questions_answers?.length || "--",
        },
        {
            title: "Survey Status",
            value: capitalizeFirstCharacter(surveyDetailsData?.survey?.status) || "--",
        }
    ]

    return surveyOverviewDetails;
}


export const getSurveyUserOverviewData = (surveyDetailsData) => {
    if (!Object.keys(surveyDetailsData).length) return [];
    const surveyUserOverviewDetails = [
        {
            title: "Respondent Name",
            value: capitalizeFirstCharacter(surveyDetailsData?.respondent?.respondent_name) || "--",
        },
        {
            title: "Respondent Email",
            value: surveyDetailsData?.respondent?.respondent_email || "--",
        },
        {
            title: "Total Time Spent",
            value: epochTimeToDate(surveyDetailsData?.respondent?.time_spent) || "--",
        }
    ]
    return surveyUserOverviewDetails;
}

