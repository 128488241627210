import { useEffect, useState } from 'react';

// To get the browser info
const getBrowserInfo = () => {
  const userAgent = navigator.userAgent;
  let browserName = 'Unknown';

  if (userAgent.includes('Firefox')) {
    browserName = 'Mozilla Firefox';
  } else if (userAgent.includes('Chrome') && !userAgent.includes('Edg')) {
    browserName = 'Google Chrome';
  } else if (userAgent.includes('Edg')) {
    browserName = 'Microsoft Edge';
  } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
    browserName = 'Apple Safari';
  } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
    browserName = 'Opera';
  } else if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
    browserName = 'Internet Explorer';
  }

  return {
    browserName,
    userAgent,
    platform: navigator.platform,
  };
};

// To get the referring page
const getReferrer = () => document.referrer || 'Direct Visit';

// To detect if it's mobile, tablet, or desktop
const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/android/i.test(ua)) return 'Android Device';
  if (/iPhone|iPad|iPod/i.test(ua)) return 'iOS Device';
  if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(navigator.platform))
    return 'Mac Device';
  if (/Win/.test(navigator.platform)) return 'Windows Device';
  if (/Linux/.test(navigator.platform)) return 'Linux Device';
  return 'Unknown Device';
};

const DeviceInfo = () => {
  const [deviceInfo, setDeviceInfo] = useState({
    browser: '',
    device: '',
    referrer: '',
  });

  useEffect(() => {
    setDeviceInfo({
      browser: getBrowserInfo().browserName,
      device: getDeviceType(),
      referrer: getReferrer(),
    });
  }, []);

  return deviceInfo;
};

export default DeviceInfo;
