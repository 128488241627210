import { Box, Container, Paper, Stack, Typography } from '@mui/material'

import React from 'react'
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const SurveyAlreadyFilled = (props) => {
  const { isSurveyAlreadyFilled } = props
  if (isSurveyAlreadyFilled) {
    return (
      <>
        <Container maxWidth="xl">
          <Box
            sx={{
              mt: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Stack spacing={2} sx={{ mb: 3 }}>
              <Typography variant="h4" textAlign="center" gutterBottom>
                You have already taken this survey
              </Typography>
              <Box display="flex" justifyContent="center">
                <img src="/assets/already_taken.png" alt="error" style={{
                  width: "100%",
                  maxWidth: "450px",
                  height: "auto",
                  mixBlendMode: "multiply"
                }} />
              </Box>
              <Typography color="text.secondary" variant="body2" textAlign="center">
                {"Please contact the survey administrator for more information."}
              </Typography>
            </Stack>
          </Box>
        </Container>
      </>
    )
  }
}

export default SurveyAlreadyFilled