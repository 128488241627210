import * as React from "react";

import {
  Box,
  Checkbox,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EmptyRecords from "../EmptyRecord";
import PropTypes from "prop-types";
import { TableActions } from "./TableActions";
import { visuallyHidden } from "@mui/utils";

// Keep existing utility functions for sorting
function descendingComparator(a, b, orderBy) {
  return (b[orderBy] > a[orderBy]) - (b[orderBy] < a[orderBy]);
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    return order !== 0 ? order : a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// Keep the existing EnhancedTableHead component
function EnhancedTableHead({
  isAction,
  checked_enable,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  headCells,
  enableRowReorder,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        backgroundColor: "#0C0C5E",
        "& .MuiTableSortLabel-root": {
          color: "#FFFFFF !important",
          "&:hover": {
            color: "#b8c1ec !important",
          },
          "& .MuiTableSortLabel-icon": {
            color: "#FFFFFF !important",
          },
        },
        "& .MuiCheckbox-root": {
          color: "#FFFFFF",
          "&:hover": {
            color: "#b8c1ec",
          },
          "&.Mui-checked, &.MuiCheckbox-indeterminate": {
            color: "#b8c1ec",
            "&:hover": {
              color: "#b8c1ec",
            },
          },
        },
      }}
    >
      <TableRow>
        {/* Add drag handle column */}
        {enableRowReorder && rowCount > 1 && <TableCell padding="checkbox" />}

        {checked_enable && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all rows" }}
            />
          </TableCell>
        )}
        {isAction && <TableCell align="left" padding="normal" />}

        {headCells.map((headCell, i) => (
          <TableCell
            key={i}
            align={
              headCell.label === "percentage"
                ? "center"
                : headCell.numeric
                ? "right"
                : "left"
            }
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              width: headCell.width,
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.tooltip ? (
                <Tooltip title={headCell.tooltip} placement="top" arrow>
                  <span>{headCell.label}</span>
                </Tooltip>
              ) : (
                headCell.label
              )}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export const CustomTable = ({
  actionMenuOptions = [],
  handleActions = () => {},
  isAction = false,
  tHeaderText,
  headers = [],
  rows = [],
  rowsPerPageParam,
  page: propPage,
  rowsPerPage: propRowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  onRowReorder,
  enableRowReorder = false,
  checked_enable = false,
  listLoading = false,
  searchQuery,
  emptyRecordText,
}) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(headers[0]?.name);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(propPage || 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    propRowsPerPage || rowsPerPageParam || 10
  );
  const [isActionMenu, setIsActionMenu] = React.useState({
    isOpen: false,
    index: null,
  });
  const [localRows, setLocalRows] = React.useState(rows);
  const [draggedRowIndex, setDraggedRowIndex] = React.useState(null);
  const [swappedData, setSwappedData] = React.useState([]);
  // Sync rows with prop changes
  React.useEffect(() => {
    setLocalRows(rows);
  }, [rows]);

  // Sync page and rows per page with props
  React.useEffect(() => {
    if (searchQuery) {
      setPage(0);
    } else {
      setPage(propPage);
    }
  }, [searchQuery, propPage]);

  React.useEffect(() => {
    setPage(propPage);
  }, [propPage]);

  React.useEffect(() => {
    setRowsPerPage(propRowsPerPage);
  }, [propRowsPerPage]);

  // Prepared head cells with additional metadata
  const headCell = React.useMemo(
    () =>
      headers.map((header) => ({
        ...header,
        id: header.name.toLowerCase(),
        numeric: ["ctc", "in-hand", "percentage"].includes(
          header.name.toLowerCase()
        ),
        disablePadding: false,
        label: header.displayName,
      })),
    [headers]
  );

  // Sort and pagination handlers
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    setSelected(event.target.checked ? localRows.map((n) => n.name) : []);
  };

  const handleChangePage = (event, newPage) => {
    onPageChange?.(event, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    onRowsPerPageChange?.(event);
  };

  // Drag and drop handlers
  const handleDragStart = (e, index) => {
    if (!enableRowReorder) return;
    setDraggedRowIndex(index);
    e.dataTransfer.effectAllowed = "move";
  };


  const handleDragOver = (e, index) => {
    if (!enableRowReorder) return;
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    if (draggedRowIndex === null || draggedRowIndex === index) return;

    // Swap the dragged row with the target row
    const newRows = [...localRows];
    const temp = newRows[draggedRowIndex];
    setSwappedData([newRows[draggedRowIndex], newRows[index]]);
    newRows[draggedRowIndex] = newRows[index];
    newRows[index] = temp;

    setLocalRows(newRows);
    setDraggedRowIndex(null);
  };

  const handleDragEnd = () => {
    onRowReorder?.(swappedData);
    setDraggedRowIndex(null);
    setSwappedData([]);
  };

  // Visible rows calculation
  const visibleRows = React.useMemo(
    () =>
      stableSort(localRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [localRows, order, orderBy, page, rowsPerPage]
  );

  const isSelected = (name) => selected.includes(name);

  return (
    <Box>
      <Paper sx={{ width: "100%", mb: 2 }}>
        {tHeaderText && (
          <Stack>
            <Typography ml="10px" p={2} variant="h6">
              {tHeaderText}
            </Typography>
          </Stack>
        )}
        <TableContainer>
          {visibleRows.length === 0 && (
            <EmptyRecords emptyRecordText={emptyRecordText} listLoading={listLoading}/>
          )}
          {visibleRows.length > 0 && (
            <Table
              sx={{ minWidth: 750, whiteSpace: "nowrap" }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={localRows.length}
                headCells={headCell}
                checked_enable={checked_enable}
                isAction={isAction}
                enableRowReorder={enableRowReorder}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      key={index}
                      draggable={enableRowReorder}
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => handleDragOver(e, index)}
                      onDrop={(e) => handleDrop(e, index)}
                      onDragEnd={handleDragEnd}
                      sx={{
                        cursor: enableRowReorder ? "move" : "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.1) !important",
                        }
                      }}
                      hover
                      onMouseOver={() =>
                        setIsActionMenu({ isOpen: true, index })
                      }
                      onMouseOut={() =>
                        setIsActionMenu({ isOpen: false, index: null })
                      }
                      onTouchStart={() =>
                        setIsActionMenu({ isOpen: true, index })
                      }
                      onTouchEnd={() =>
                        setIsActionMenu({ isOpen: false, index: null })
                      }
                    >
                      {/* Drag handle column */}
                      {enableRowReorder && localRows.length > 1 && (
                        <TableCell
                          style={{
                            cursor: "move",
                            fontWeight: "bold",
                          }}
                        >
                          <DragIndicatorIcon/>
                        </TableCell>
                      )}

                      {checked_enable && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                      )}
                      {isAction && (
                        <TableCell
                          className={
                            headers[0]?.name === "name"
                              ? "action-sec1"
                              : "action-sec"
                          }
                          style={{
                            cursor: "pointer",
                            verticalAlign: "top",
                          }}
                        >
                          {row.id !== null &&
                            index === isActionMenu.index &&
                            isActionMenu.isOpen && (
                              <TableActions
                                onClick={handleActions}
                                id={
                                  row.id ||
                                  row.profile_id ||
                                  row?.user?.profile_id
                                }
                                handleActions={handleActions}
                                actionMenuOptions={actionMenuOptions}
                                isOpen={isActionMenu}
                                handleActionClose={() =>
                                  setIsActionMenu({
                                    isOpen: false,
                                    index: null,
                                  })
                                }
                              />
                            )}
                        </TableCell>
                      )}

                      {headers.map(({ cell = () => {}, ...header }, key) => (
                        <TableCell
                          key={key}
                          style={{ verticalAlign: "middle" }}
                        >
                          {cell({
                            row,
                            value: row[header.name],
                            onHover: setIsActionMenu,
                            index,
                          }) || row[header.name]}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        {visibleRows.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 15, 25 , 100, 200]}
            component="div"
            count={localRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Box>
  );
};

CustomTable.propTypes = {
  actionMenuOptions: PropTypes.array,
  handleActions: PropTypes.func,
  isAction: PropTypes.bool,
  tHeaderText: PropTypes.string,
  headers: PropTypes.array,
  rows: PropTypes.array,
  rowsPerPageParam: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onRowReorder: PropTypes.func,
  enableRowReorder: PropTypes.bool,
  checked_enable: PropTypes.bool,
  listLoading: PropTypes.bool,
  searchQuery: PropTypes.string,
};
