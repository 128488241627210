import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  CompanyStyledBox,
  OverviewDetailsStyled,
  QuestionsResponses,
  SurveryHeaderStyled,
  SurveyDetailContainer,
  SurveyOverViewStyled,
  UserSurveyStyledContainer,
} from './UserSurveyPageStyles';
import React, { useEffect, useState } from 'react';
import { capitalizeFirstCharacter, getMediaType } from '../../utils/helpers';
import {
  getSurveyOverviewDetails,
  getSurveyUserOverviewData,
} from './componentData';
import { useNavigate, useParams } from 'react-router-dom';

import CustomAvatar from './CustomAvatar';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import RenderAnswerView from './RenderAnswerView';
import Toast from '../../components/Tost';
import { format } from 'date-fns';
import useApiService from '../../hooks/api-services/use-service';
import { usePDF } from 'react-to-pdf';
import { useToast } from '../../hooks/custom-hook-functions/useToast';

const UserSurveyResponseDetail = () => {
  const [surveyDetailsData, setSurveyDetailsData] = useState({});

  const options = {
    orientation: 'portrait',
    unit: 'in',
    format: [8.5, 11], // For A4 size
  };

  const { toPDF, targetRef } = usePDF({
    filename: `${
      capitalizeFirstCharacter(surveyDetailsData?.survey?.title) || '--'
    }-survey.pdf`,
    content: () => targetRef.current,

    options,
  });
  const { respondentId, surveyId } = useParams();
  const navigate = useNavigate();
  const { getFullDetailsOfSurveyResponse } = useApiService();
  // SURVEY ID
  const decodedSurveyId = atob(surveyId).split('_')[1];
  const [isLoading, setIsLoading] = useState(false);
  const { setToast, showToast, toast, toastMessage, toastType } = useToast();

  useEffect(() => {
    const getSurveyResponseDetails = async (respondentId, decodedSurveyId) => {
      try {
        setIsLoading(true);
        if (decodedSurveyId && respondentId) {
          const response = await getFullDetailsOfSurveyResponse(
            respondentId,
            decodedSurveyId
          );
          if (response.status === 200) {
            setSurveyDetailsData(response.data);
          }
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        showToast('Error fetching survey response details', 'error');
        setTimeout(() => {
          navigate(-1);
        }, 100);
      }
    };
    getSurveyResponseDetails(respondentId, decodedSurveyId);
  }, [decodedSurveyId, respondentId]);

  const isDataValid = Object.keys(surveyDetailsData)?.length;

  const {
    organisation_name,
    organisation_banner,
    organisation_logo,
    phone_no,
    website_url,
    address_line_1,
    address_line_2,
    city,
    state,
    country,
    zip_code,
  } = isDataValid && surveyDetailsData?.organisation;

  const streetAddress = address_line_2
    ? `${address_line_1}, ${address_line_2}`
    : address_line_1;
  const cityStatePinCode = `${city}, ${state}, ${zip_code}`;
  const countryAndPhone = `${country}, ${phone_no}`;
  const surveryStartDate =
    isDataValid &&
    format(new Date(surveyDetailsData?.survey?.start_date), 'd-MMM-yyyy');
  // Calculate the width dynamically
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isMediumScreen = useMediaQuery('(max-width:960px)');
  const maxWidth = isSmallScreen ? '100%' : '100%';

  return (
    <UserSurveyStyledContainer>
      <SurveyDetailContainer ref={targetRef} sx={{ border: 'none' }}>
        <SurveryHeaderStyled>
          <CustomAvatar
            imgUrl={
              organisation_banner
                ? organisation_banner
                : `${process.env.REACT_APP_API_BASE_URL}${organisation_logo}`
            }
            name={organisation_name}
            height={120}
            width={120}
          />
          <CompanyStyledBox>
            <Box sx={{ display: 'flex', gap: '1.5rem', alignItems: 'center' }}>
              <Typography variant="h5" fontWeight={700}>
                {organisation_name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '0.3rem',
                flexDirection: 'column',
                alignItems: 'start',
              }}
            >
              <Typography variant="h5" fontWeight={700}>
                Address:
              </Typography>
              <Typography variant="h6" color="textSecondary">
                {streetAddress}
              </Typography>
              <Typography variant="h6" color="textSecondary">
                {cityStatePinCode}
              </Typography>
              <Typography variant="h6" color="textSecondary">
                {countryAndPhone}
              </Typography>
            </Box>
          </CompanyStyledBox>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
              justifyContent: 'center',
              alignSelf: 'baseline',
            }}
          >
            <Box sx={{ display: 'flex', gap: '0.3rem' }}>
              <Typography variant="h5" fontWeight={700}>
                Date:
              </Typography>
              <Typography variant="h6" color="textSecondary">
                {surveryStartDate}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              <Box>
                <Typography variant="h6" fontWeight={700}>
                  Export PDF
                </Typography>
              </Box>
              <Tooltip title="Download PDF" arrow>
                <IconButton onClick={() => toPDF()}>
                  <PictureAsPdfIcon color="error" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </SurveryHeaderStyled>

        <SurveyOverViewStyled>
          <Typography variant="h6" fontWeight={700}>
            Survey Overview
          </Typography>
          <OverviewDetailsStyled>
            <Box className="detailTypes" sx={{ maxWidth: '65%' }}>
              {isDataValid &&
                getSurveyOverviewDetails(surveyDetailsData)?.map(
                  (detail, index) => (
                    <Box key={index} className="details">
                      <Typography variant="subtitle1" fontWeight={700}>
                        {detail.title}:
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className="value"
                        color="textSecondary"
                      >
                        {detail.value}
                      </Typography>
                    </Box>
                  )
                )}
            </Box>
            <Box className="detailTypes">
              {Object.keys(surveyDetailsData)?.length &&
                getSurveyUserOverviewData(surveyDetailsData).map(
                  (detail, index) => (
                    <Box key={index} className="details">
                      <Typography variant="subtitle1" fontWeight={700}>
                        {detail.title}:
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        {detail.value}
                      </Typography>
                    </Box>
                  )
                )}
            </Box>
          </OverviewDetailsStyled>
        </SurveyOverViewStyled>

        {isDataValid &&
          Object?.keys(surveyDetailsData?.questions_answers) &&
          surveyDetailsData?.questions_answers?.length > 0 && (
            <QuestionsResponses>
              {surveyDetailsData?.questions_answers?.map((question, index) => (
                <Box key={index} sx={{ width: '100%' }}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: '1rem' }}
                    gutterBottom
                    fontWeight={700}
                  >{`${index + 1}. ${
                    question?.question?.question
                  }?`}</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '0.4rem',
                      flexDirection: 'column',
                    }}
                  >
                    {question?.question?.image_video && (
                      <Box sx={{ maxWidth: maxWidth, mt: 1, mb: 1 }}>
                        {getMediaType(question?.question?.image_video) ===
                        'image' ? (
                          <Box sx={{ position: 'relative' }}>
                            <img
                              src={question?.question?.image_video}
                              onError={e => {
                                e.target.onerror = null;
                                e.target.src =
                                  '/assets/surveyImages/imagenotfound.jpg'; // fallback image
                                e.target.style.width = '280px';
                                e.target.style.height = '250px';
                              }}
                              alt="Question"
                              width="100%"
                              height={300}
                              style={{ borderRadius: '12px' }}
                            />
                            <Box
                              sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                color: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '12px',
                                opacity: 0,
                                transition: 'opacity 0.3s',
                                '&:hover': {
                                  opacity: 1,
                                },
                              }}
                            >
                              <Typography variant="body1">
                                Oops failed to load your desired image!
                              </Typography>
                            </Box>
                          </Box>
                        ) : (
                          <Box sx={{ position: 'relative' }}>
                            <video width="100%" height={300} controls>
                              <source
                                src={question?.question?.image_video}
                                type={`video/${question?.question?.image_video
                                  .split('.')
                                  .pop()}`}
                              />
                            </video>
                            <Box
                              sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                color: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '12px',
                                opacity: 0,
                                transition: 'opacity 0.3s',
                                '&:hover': {
                                  opacity: 1,
                                },
                              }}
                            >
                              <Typography variant="body1">
                                Oops failed to load your desired video!
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    )}
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '0.4rem',
                        alignItems:
                          question?.question?.question_type === 'slider'
                            ? undefined
                            : 'center',
                        flexDirection:
                          question?.question?.question_type === 'slider'
                            ? 'column'
                            : 'row',
                        ml: '1rem',
                      }}
                    >
                      <RenderAnswerView
                        question={question?.question}
                        answer={question?.answer}
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
            </QuestionsResponses>
          )}
      </SurveyDetailContainer>
      {toast && (
        <Toast
          setToast={setToast}
          isLoading={isLoading}
          toast={toast}
          toastType={toastType}
          toastMessage={toastMessage}
        />
      )}
    </UserSurveyStyledContainer>
  );
};

export default UserSurveyResponseDetail;
