import { Container, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';

import CookieIcon from '@mui/icons-material/Cookie';
import React from 'react';

const Section = ({ title, content }) => (
  <>
    <Typography variant="h4" gutterBottom>
      {title}
    </Typography>
    <Typography variant="body1" sx={{ fontSize: "18px" }} paragraph>
      {content}
    </Typography>
  </>
);

const Cookies = () => {
  const listItems = [
    "To remember your preferences and settings",
    "To analyze how our website is used",
    "To personalize content and ads",
    "To improve the overall user experience"
  ];

  const cookieTypes = [
    "Essential cookies: These cookies are necessary for the website to function properly.",
    "Performance cookies: These cookies help us understand how visitors interact with our website by collecting and reporting information anonymously.",
    "Functionality cookies: These cookies allow the website to remember choices you make and provide enhanced, more personal features.",
    "Advertising cookies: These cookies are used to deliver ads that are more relevant to you and your interests."
  ];

  return (
    <Container style={{ padding: '20px' }}>
      <Typography variant="h3" gutterBottom>
        Cookies Policy
      </Typography>
      <Typography variant="body1" sx={{ fontSize: "18px", marginBottom: "2rem" }} paragraph>
        This Cookie Policy explains how our website uses cookies and similar technologies to provide, improve, and protect our services.
      </Typography>
      
      <Section 
        title="What are cookies?" 
        content="Cookies are small text files that are stored on your device when you visit a website. They help the website to remember your preferences and actions over a period of time."
      />
      
      <Section 
        title="How we use cookies" 
        content="We use cookies for various purposes, including:"
      />
      <List sx={{ "& .MuiListItemText-root > span": { fontSize: "18px !important",  } }}>
        {listItems.map((item, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <CookieIcon />
            </ListItemIcon>
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
      
      <Section 
        title="Types of cookies we use" 
        content="We use the following types of cookies:"
      />
      <List sx={{ "& .MuiListItemText-root > span": { fontSize: "18px !important" } }}>
        {cookieTypes.map((type, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <CookieIcon />
            </ListItemIcon>
            <ListItemText primary={type} />
          </ListItem>
        ))}
      </List>
      
      <Section 
        title="Managing cookies" 
        content="You can manage your cookie preferences through your browser settings. Most browsers allow you to block or delete cookies. However, please note that disabling cookies may affect the functionality of our website."
      />
      
      <Section 
        title="Changes to this policy" 
        content="We may update this Cookie Policy from time to time. We encourage you to review this policy periodically to stay informed about how we use cookies."
      />
      
      <Section 
        title="Contact us" 
        content="If you have any questions about our Cookie Policy, please contact us at smartsurvey@gmail.com."
      />
    </Container>
  );
}

export default Cookies;