import { Box, Container, Grid2, InputAdornment, MenuItem, Paper, Stack, TextField, Typography } from '@mui/material';
import { Business, Flag, Language, LocationCity, Map, Phone, PinDrop, Place } from '@mui/icons-material';
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { convertUrlToFileAndSetFormik, getMimeType } from '../../utils/helpers';

import { CompanyOnboardingSchema } from '../../form-schemas-validations/CompanySchema';
import { CustomButtom } from '../../components/CustomButton';
import { INDIAN_STATES_NAMES } from '../../utils/params';
import LoaderAnimation from '../../components/LoaderComp/LoaderAnimation';
import Toast from '../../components/Tost';
import useApiService from '../../hooks/api-services/use-service';
import { useFormik } from 'formik';
import { useStorage } from '../../hooks/custom-hook-functions/use-Storage';
import { useToast } from '../../hooks/custom-hook-functions/useToast';

const MyProfile = () => {
    const [loading, setLoading] = useState(false);
    const { getOrgaisationDetails, updateOnBoardingCompanyDetails } = useApiService();
    const { setItem } = useStorage();
    const {showToast, setToast, toast, toastMessage, toastType  } = useToast();
    const [orgFileLogo, setOrgFileLogo] = useState("");
    const [organisationData, setOrganisationData] = useState(null);
    
    const userDetails = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {};
    
    useLayoutEffect(() => {
      const fetchOrgData = async () => {
        try {
          setLoading(true);
          const orgData = await getOrgaisationDetails();
          if (orgData.status === 200) {
            setOrganisationData(orgData.data);
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
      } finally {
        setLoading(false);
      }
    }
        fetchOrgData();
    }, []);

    const regFormik = useFormik({
      initialValues: {
        organisation_name: organisationData?.organisation_name || "",
        website_url: organisationData?.website_url || "",
        organisation_logo: "",
        file_name: "",
        phone_no: organisationData?.phone_no || "",
        address_line_1: organisationData?.address_line_1 || "",
        address_line_2: organisationData?.address_line_2 || "",
        country: "India",
        state: organisationData?.state || "",
        city: organisationData?.city || "",
        zip_code: organisationData?.zip_code || "",
      },
      enableReinitialize: true,
      validationSchema: CompanyOnboardingSchema,
      onSubmit: async (values, helpers) => {
        try {
          let formData = new FormData();
          formData.append("file_name", values?.file_name || orgFileLogo);
          const fields = [
            "organisation_name",
            "phone_no",
            "website_url",
            "country",
            "city",
            "state",
            "address_line_1",
            "address_line_2",
            "zip_code"
          ];

          fields.forEach(field => {
              formData.append(field, values[field]);
          });
  
          await updateOnBoardingCompanyDetails(formData)
            .then((res) => {
              if (res) {
                setItem("companyDetails", res?.data);
                 userDetails.organisation_details = res?.data;
              setItem("user", userDetails);
                showToast("Company details updated successfully", "success");
              } else {
                showToast(`${Object.keys(res.data)[0]}: ${
                    res.data[Object.keys(res.data)[0]]
                  }`, "error");
              }
              setLoading(false);
            })
            .catch((error) => {
              showToast(`${Object.keys(error?.response?.data)[0]}: ${
                    error?.response?.data[Object.keys(error?.response?.data)[0]]
                  }`, "error");
            })
            .finally(() => {
              setLoading(false);
            });
        } catch (err) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      },
    });



  //   useEffect(() => {
  //     if (organisationData?.organisation_logo) {
  //         const handleSetOrgLogo = async () => {
  //             const newUrl = `${process.env.REACT_APP_API_BASE_URL}${organisationData?.organisation_logo}`;
  //             regFormik.setFieldValue("organisation_logo", newUrl);
  //             const mimeType = getMimeType(newUrl);
  //             await convertUrlToFileAndSetFormik('organisation_logo', newUrl, mimeType, regFormik, showToast);
  //         };
  //         handleSetOrgLogo();
  //     }
  // }, [organisationData]);

    // Handle file change
    const handleFileChange = (event) => {
      if (!event.currentTarget.files.length) return
      const file = event.currentTarget.files[0];
      setOrgFileLogo(file);
      regFormik.setFieldValue("file_name", file);
    };

    if (loading) {
      return (
        <LoaderAnimation/>
      );
    }

  return (
    <>
    <Container maxWidth="sm">
      <Box sx={{ mt: 6, mb: 4 }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Stack spacing={1} sx={{ mb: 3 }}>
            <Typography variant="h4" textAlign={"center"} gutterBottom>
            Update Profile
            </Typography>
          </Stack>
          <Box width={"100%"}>
            <form onSubmit={regFormik.handleSubmit}>
              <Grid2 container spacing={3}>
                <Grid2 size={12}>
                  <TextField
                    required
                    InputLabelProps={{ shrink: true }}
                    error={
                      !!(
                        regFormik.touched.organisation_name &&
                        regFormik.errors.organisation_name
                      )
                    }
                    fullWidth
                    helperText={
                      regFormik.touched.organisation_name &&
                      regFormik.errors.organisation_name
                    }
                    label="Organization Name"
                    name="organisation_name"
                    onBlur={regFormik.handleBlur}
                    type="text"
                    onChange={regFormik.handleChange}
                    value={regFormik.values.organisation_name}
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">
                             <Business />
                        </InputAdornment>
                        )
                      }
                    }}
                  />
                </Grid2>

                <Grid2 size={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    error={
                      !!(
                        regFormik.touched.website_url &&
                        regFormik.errors.website_url
                      )
                    }
                    fullWidth
                    helperText={
                      regFormik.touched.website_url &&
                      regFormik.errors.website_url
                    }
                    label="Website URL"
                    name="website_url"
                    onBlur={regFormik.handleBlur}
                    onChange={regFormik.handleChange}
                    type="text"
                    value={regFormik.values.website_url}
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <Language />
                        </InputAdornment>
                        )
                      }
                    }}
                  />
                </Grid2>

                <Grid2 size={12}>
                  <TextField
                    required
                    InputLabelProps={{ shrink: true }}
                    error={
                      !!(
                        regFormik.touched.phone_no &&
                        regFormik.errors.phone_no
                      )
                    }
                    fullWidth
                    helperText={
                      regFormik.touched.phone_no && regFormik.errors.phone_no
                    }
                    label="Phone Number"
                    name="phone_no"
                    onBlur={regFormik.handleBlur}
                    onChange={regFormik.handleChange}
                    type="text"
                    value={regFormik.values.phone_no}
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">
                          <Phone />
                        </InputAdornment>
                        )
                      }
                    }}
                  />
                </Grid2>

                <Grid2 size={12}>
                  <TextField
                    required
                    InputLabelProps={{ shrink: true }}
                    error={
                      !!(
                        regFormik.touched.address_line_1 &&
                        regFormik.errors.address_line_1
                      )
                    }
                    fullWidth
                    helperText={
                      regFormik.touched.address_line_1 &&
                      regFormik.errors.address_line_1
                    }
                    label="Address 1"
                    name="address_line_1"
                    onBlur={regFormik.handleBlur}
                    onChange={regFormik.handleChange}
                    type="text"
                    value={regFormik.values.address_line_1}
                    slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">
                               <Place />
                            </InputAdornment>
                          )
                        }
                      }}
                  />
                </Grid2>

                <Grid2 size={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    error={
                      !!(
                        regFormik.touched.address_line_2 &&
                        regFormik.errors.address_line_2
                      )
                    }
                    fullWidth
                    helperText={
                      regFormik.touched.address_line_2 &&
                      regFormik.errors.address_line_2
                    }
                    label="Address 2"
                    name="address_line_2"
                    onBlur={regFormik.handleBlur}
                    onChange={regFormik.handleChange}
                    type="text"
                    value={regFormik.values.address_line_2}
                    slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">
                              <Place />
                            </InputAdornment>
                          )
                        }
                      }}
                  />
                </Grid2>

                <Grid2 size={{xs: 12, md: 6}}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    error={
                      !!(
                        regFormik.touched.country && regFormik.errors.country
                      )
                    }
                    fullWidth
                    helperText={
                      regFormik.touched.country && regFormik.errors.country
                    }
                    label="Country"
                    name="country"
                    onBlur={regFormik.handleBlur}
                    onChange={regFormik.handleChange}
                    type="text"
                    select
                    value={regFormik.values.country}
                    slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">
                              <Flag />
                            </InputAdornment>
                          )
                        }
                      }}
                  >
                  <MenuItem value={"India"}>India</MenuItem>
                  </TextField>
                </Grid2>

                <Grid2 size={{xs: 12, md: 6}}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    error={
                      !!(regFormik.touched.state && regFormik.errors.state)
                    }
                    fullWidth
                    helperText={
                      regFormik.touched.state && regFormik.errors.state
                    }
                    label="State"
                    name="state"
                    onBlur={regFormik.handleBlur}
                    onChange={regFormik.handleChange}
                    type="text"
                    select
                    value={regFormik.values.state}
                    slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">
                               <Map />
                            </InputAdornment>
                          )
                        }
                      }}
                  >
                    {
                        INDIAN_STATES_NAMES?.map((state, index)=>(
                          <MenuItem key={index} value={state}>{state}</MenuItem>
                        ))
                      }
                  </TextField>
                </Grid2>

                <Grid2 size={{xs: 12, md: 6}}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    error={
                      !!(regFormik.touched.city && regFormik.errors.city)
                    }
                    fullWidth
                    helperText={
                      regFormik.touched.city && regFormik.errors.city
                    }
                    label="City"
                    name="city"
                    onBlur={regFormik.handleBlur}
                    onChange={regFormik.handleChange}
                    type="text"
                    value={regFormik.values.city}
                      slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">
                               <LocationCity />
                            </InputAdornment>
                          )
                        }
                      }}
                  />
                </Grid2>

                <Grid2 size={{xs: 12, md: 6}}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    error={
                      !!(
                        regFormik.touched.zip_code &&
                        regFormik.errors.zip_code
                      )
                    }
                    fullWidth
                    helperText={
                      regFormik.touched.zip_code && regFormik.errors.zip_code
                    }
                    label="Zip Code"
                    name="zip_code"
                    onBlur={regFormik.handleBlur}
                    onChange={regFormik.handleChange}
                    type="text"
                    value={regFormik.values.zip_code}
                 
                    slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">
                               <PinDrop />
                            </InputAdornment>
                          )
                        }
                      }}
                  />
                </Grid2>

                <Grid2 size={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    error={
                      !!(
                        regFormik.touched.file_name &&
                        regFormik.errors.file_name
                      )
                    }
                    fullWidth
                    helperText={
                      regFormik.touched.file_name &&
                      regFormik.errors.file_name
                    }
                    label="Organization Logo"
                    name="file_name"
                    type="file"
                    onBlur={regFormik.handleBlur}
                    onChange={handleFileChange}
                  />
                  
                </Grid2>
              </Grid2>
              {regFormik.errors.submit && (
                <Typography color="error" sx={{ mt: 3 }} variant="body2">
                  {regFormik.errors.submit}
                </Typography>
              )}
              <Box mt={2}>
                <Typography variant="caption">*Indicate Required</Typography>
              </Box>

              <Box
                display="flex"
                alignContent="center"
                justifyContent="center"
              >
                <CustomButtom
                  fullWidth
                  size="large"
                  sx={{ mt: 2 }}
                  type="submit"
                  variant="contained"
                  loading={loading}
                  disabled={loading}
                  text="Submit"
                />
              </Box>
            </form>
          </Box>
        </Paper>
      </Box>
    </Container>
    {loading ||
      (toast && (
        <Toast
          setToast={setToast}
          isLoading={loading}
          toast={toast}
          toastType={toastType}
          toastMessage={toastMessage}
        />
      ))}
  </>
  )
}

export default MyProfile