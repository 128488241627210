import { Box, Typography } from '@mui/material';

import { Link } from 'react-router-dom';
import PATH from '../../router/paths';
import React from 'react';

const Footer = () => {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'primary.main',
        color: 'white',
        padding: '1rem',
        textAlign: 'center',
        position: 'relative',
        bottom: 0,
      }}
    >
      <Typography variant="body1" component="p">
        &copy; {new Date().getFullYear()} OpinionStream. All rights reserved.
      </Typography>
      <Typography
        variant="body2"
        component="p"
        sx={{
          '& a': {
            color: '#fefefe',
          },
        }}
      >
        <Link to={PATH.PRIVACY_POLICY} underline="hover">
          Privacy Policy
        </Link>
        {' | '}
        <Link to={PATH.CONTACT_SUPPORT} color="inherit" underline="hover">
          Contact Support
        </Link>
        {' | '}
        <Link to={PATH.COOKIES} color="inherit" underline="hover">
          Cookies
        </Link>
      </Typography>
    </Box>
  );
};

export default Footer;
