import { Box, Container, Stack, Typography } from '@mui/material';

import React from 'react'

const SurveyExpired = (props) => {
  const { isSurveyExpired } = props
  if (isSurveyExpired) {
    return (
      <>
        <Container maxWidth="xl">
          <Box
            sx={{
              mt: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Stack spacing={2} sx={{ mb: 3 }}>
              <Typography variant="h4" textAlign="center" gutterBottom>
              We're sorry, but this survey is no longer available. 
              </Typography>
              <Box display="flex" justifyContent="center">
                <img src="/assets/not_available.png" alt="error" style={{
                  width: "100%",
                  maxWidth: "450px",
                  height: "auto",
                  mixBlendMode: "multiply"
                }} />
              </Box>
              <Typography color="text.secondary" variant="body2" textAlign="center">
                {"Please contact the survey administrator for more information."}
              </Typography>
            </Stack>
          </Box>
        </Container>
      </>
    );
  }
}

export default SurveyExpired