import * as React from "react";

import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { CustomButtom } from "./CustomButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

const Model = ({
  children,
  open = false,
  header = false,
  headerText,
  footer,
  close = false,
  handleSubmit = () => {},
  handleClose = () => {},
  loading = false,
  resetForm = () => {},
  checked = null,
  customWidth = false,
  requiredIndicates,
  customFooterButton = null,
}) => {
  React.useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  return (
    <Dialog
      onClose={handleClose}
      fullWidth
      open={open}
      aria-labelledby="draggable-dialog-title"
      sx={{
        ...(customWidth && {
          "& .MuiDialog-paper": {
            maxWidth: "60%",
          },
        }),
      }}
    >
      {/* Fixed Header */}
      {(header || close) && (
        <DialogTitle
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 2,
            backgroundColor: "white",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
          }}
        >
          {header && headerText}
          {close && (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      )}

      <form
        noValidate
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <DialogContent
          dividers
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            padding: 2,
          }}
        >
          {children}
        </DialogContent>

        {footer && (
          <DialogActions
            sx={{
              position: "sticky",
              bottom: 0,
              zIndex: 1,
              backgroundColor: "white",
              borderTop: "1px solid rgba(0, 0, 0, 0.12)",
              p: 2,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Typography ml={3} color={"gray"} sx={{ visibility: requiredIndicates? "visible" : "hidden" }}> 
                * Indicates required
              </Typography>

              <div style={{  display: "flex", gap: "0.5rem", justifyContent: "flex-end", alignItems: "center" }}>
                <Button onClick={handleClose} disabled={loading} variant="outlined" size="medium">
                  Cancel
                </Button>
                <CustomButtom
                  text={"Save"}
                  loading={loading}
                  disabled={(checked !== null ? !checked : false) || loading}
                  variant="contained"
                  type="submit"
                  size="medium"
                />
                {customFooterButton && customFooterButton()}
              </div>
            </div>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};

export default Model;

Model.propTypes = {
  header: PropTypes.bool,
  headerText: PropTypes.string,
  footer: PropTypes.bool,
  close: PropTypes.bool,
  checked: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
};
