export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const ADMIN_REGISTER = '/accounts/signup';
export const VERIFY_ACCOUNT = '/accounts/verify';
export const VERIFY_RESEND_EMAIL = '/accounts/resend-otp';
export const SIGN_IN = '/accounts/login';
export const SIGN_OUT = '/accounts/logout';
export const ONBOARDING = '/organisation';
export const GET_REFRESH_TOKEN = '/accounts/token/refresh';
export const SURVEYS = '/surveys/';
export const CHOICE = '/choices/';
