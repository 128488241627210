import { Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import SurveyAnalytics from "./SurveyAnalytics";
import SurveyList from "../survey/SurveyList";
import Toast from "../Tost";
import useApiService from "../../hooks/api-services/use-service";
import { useAuthContext } from "../../contexts/AuthContext";
import { useToast } from "../../hooks/custom-hook-functions/useToast";

const SurveyDashboard = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('token')))

  const { getSurveys } = useApiService();
  const [surveysData, setSurveysData] = useState([]);
  const { setToast, showToast, toast, toastMessage, toastType } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [mappedDashboardData, setMappingDashboardData] = useState(null);


  const fetchSurveys = async () => {
    try {
      setIsLoading(true);
      const response = await getSurveys();
      if (response.status === 200) {
        setSurveysData(response.data);
      }
    } catch (error) {
      setIsLoading(false);
      showToast("Error fetching surveys", "error");
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(()=>{
    fetchSurveys();
  },[]);

  useEffect(()=>{
    if (surveysData.length) {
      const totalSurveys = surveysData?.length;

      const totalActiveSurveys = surveysData.filter((survey) => survey.published_on).length;

      const totalResponses = surveysData.reduce((acc, survey) => {
        return acc + survey.total_responses;
      }, 0)

      const responseRate = (totalResponses / totalActiveSurveys) * 100;

      setMappingDashboardData({
        totalSurveys,
        totalActiveSurveys,
        totalResponses,
        responseRate
      })
    }

  }, [surveysData]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h6" gutterBottom>
        {`Welcome, ${user?.user?.first_name} ${user?.user?.last_name}`}
      </Typography>
      <Grid container spacing={3}>
        {user?.user?.groups?.[0]?.name === 'Admin' && (
          <Grid item xs={12}>
            <SurveyAnalytics data={mappedDashboardData}/>
          </Grid>
        )}
        <Grid item xs={12}>
          <SurveyList data={surveysData}/>
        </Grid>
        
      </Grid>
      {toast && (
        <Toast
          setToast={setToast}
          isLoading={isLoading}
          toast={toast}
          toastType={toastType}
          toastMessage={toastMessage}
        />
      )}
    </Container>
  );
};

export default SurveyDashboard;
