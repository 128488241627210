import { Navigate, Route, Routes } from 'react-router-dom';

import AdminPage from '../pages/AdminPage';
import CompanyOnboarding from '../components/auth/CompanyOnboarding';
import ContactSupport from '../pages/ContactSupport/ContactSupport';
import Cookies from '../pages/Cookies/Cookies';
import CreateQuestionCategory from '../components/survey/createQuestionCategory/CreateQuestionCategory';
import EmailInvitation from '../components/survey/EmailInvitation';
import EmailVerification from '../components/auth/EmailVerification';
import HomePage from '../pages/HomePage';
import LoginPage from '../components/auth/LoginPage';
import MainLayout from '../pages/MainLayout/MainLayout';
import ManageUser from '../pages/Account/ManageUser';
import MyProfile from '../pages/Account/MyProfile';
import PATH from './paths';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import PrivateRoute from '../components/auth/PrivateRoute';
import PublicRoutes from '../components/auth/PublicRoutes';
import React from 'react';
import ResponseCollection from '../components/survey/ResponseCollection';
import ResultAnalytics from '../pages/ResultAnalytics/ResultAnalytics';
import Settings from '../pages/Account/Settings';
import SignupPage from '../components/auth/SignUpPage';
import SurveyCreate from '../pages/SurveyCreate/SurveyCreate';
import SurveyDashboard from '../components/dashboard/SurveyDashboard';
import SurveyDetails from '../components/survey/SurveyDetails';
import SurveyEdit from '../pages/SurveyCreate/SurveyEdit/SurveyEdit';
import SurveyForm from '../pages/SurveyForm';
import SurveyFormFillHandle from '../pages/SurveyFormFillHandle';
import SurveyPage from '../pages/SurveyPage';
import SurveyPreview from '../pages/SurveyPreview';
import SurveyResponseAnalysis from '../pages/SurveyResponseAnalysis';
import ThankYouPage from '../pages/ThankYouPage';
import { UserSurveyResponseDetail } from '../pages';
import SurveyResponseAnalysisScore from '../pages/SurveyResponseAnalysisScore';

const RouterConfig = () => {
  return (
    <>
      <Routes>
        <Route path="/">
          {/* Public Routes */}
          <Route element={<PublicRoutes Component={MainLayout} />}>
            <Route path={PATH.HOME} element={<HomePage />} />
            <Route path={PATH.LOGIN} element={<LoginPage />} />
            <Route path={PATH.SIGNUP} element={<SignupPage />} />
            <Route
              path={PATH.EMAIL_VERIFICATION}
              element={<EmailVerification />}
            />
            <Route
              path={PATH.COMPANY_ONBOARDING}
              element={<CompanyOnboarding />}
            />
            {/* Public route that allows guest to fill survey */}
            <Route
              path={PATH.SURVEY_START}
              element={<SurveyFormFillHandle />}
            />
            <Route path={PATH.SURVEY_FORM} element={<SurveyForm />} />
            <Route path={PATH.THANK_YOU} element={<ThankYouPage />} />

            {/* Cookies */}
            <Route path={PATH.COOKIES} element={<Cookies />} />
            <Route path={PATH.PRIVACY_POLICY} element={<PrivacyPolicy />} />
            <Route path={PATH.CONTACT_SUPPORT} element={<ContactSupport />} />
          </Route>
          {/* Protected Routes */}
          <Route element={<PrivateRoute Component={MainLayout} />}>
            <Route path={PATH.DASHBOARD} element={<SurveyDashboard />} />
            <Route path={PATH.MY_PROFILE} element={<MyProfile />} />
            <Route path={PATH.MY_SURVEYS} element={<SurveyPage />} />
            <Route path={PATH.SURVEY_DETAILS} element={<SurveyDetails />} />
            <Route path={PATH.SURVEY_PREVIEW} element={<SurveyPreview />} />
            <Route path={PATH.EMAIL_INVITATION} element={<EmailInvitation />} />
            <Route
              path={PATH.ANALYZE_RESPONSES}
              element={<SurveyResponseAnalysis />}
            />
            <Route
              path={PATH.ANALYZE_RESPONSES_SCORE}
              element={<SurveyResponseAnalysisScore />}
            />
            <Route
              path={PATH.SURVEY_RESPONSES}
              element={<ResponseCollection />}
            />
            <Route
              path={PATH.SURVEY_RESPONSE_DETAILS}
              element={<UserSurveyResponseDetail />}
            />
            <Route path={PATH.RESULT_ANALYTICS} element={<ResultAnalytics />} />
            <Route path={PATH.CREATE_SURVEY} element={<SurveyCreate />} />
            <Route path={PATH.SURVEY_EDIT} element={<SurveyEdit />} />
            <Route
              path={PATH.CREATE_QUESTION_CATEGORY}
              element={<CreateQuestionCategory />}
            />
            <Route path={PATH.ADMIN} element={<AdminPage />} />
            <Route path={PATH.MANAGER_USERS} element={<ManageUser />} />
            <Route path={PATH.SETTINGS} element={<Settings />} />
          </Route>
        </Route>
        {/* Catch all route */}
        <Route path={PATH.CATCH_ALL} element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
};

export default RouterConfig;
