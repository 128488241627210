import * as Yup from "yup";

import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { Container } from "@mui/system";
import { CustomButtom } from "../CustomButton";
import Toast from "../Tost";
import useApiService from "../../hooks/api-services/use-service";
import { useAuthContext } from "../../contexts/AuthContext";
import useAuthRedirect from "../../hooks/custom-hook-functions/useAuthRedirect";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../hooks/custom-hook-functions/useToast";

const LoginPage = () => {
  useAuthRedirect();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuthContext();
  const { signIn } = useApiService();
  const { showToast, setToast, toast, toastMessage, toastType } = useToast();
  const handleResponse = (res) => {
    if (res?.status === 200) {
      showToast("Login Successfully!", "success");
      login(res?.data);
      navigate("/dashboard");
    } else {
      showToast(`${Object.keys(res.data)[0]}: ${res.data[Object.keys(res.data)[0]][0]}`, "error");
    }
  };
  
  const handleError = (error) => {
    showToast(error?.response?.data.detail ||
      `${Object.keys(error?.response?.data)[0]}: ${error?.response?.data[Object.keys(error?.response?.data)[0]]}`, "error");
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
      password: Yup.string().max(255).required("Password is required"),
    }),
    onSubmit: async (values, helpers) => {
      setLoading(true);
      try {
        const res = await signIn(values);
        handleResponse(res);
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <>
      <Container
        maxWidth={false}
        sx={{}}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Paper elevation={3} sx={{ p: 4, maxWidth: 400, mt: 10 }}>
            <Typography variant="h5" gutterBottom>
              Login
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                error={!!(formik.touched.email && formik.errors.email)}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                margin="normal"
                label="Email Address"
                name="email"
                type="email"
                value={formik.values.email}
                InputLabelProps={{ shrink: true }}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              <TextField
                error={!!(formik.touched.password && formik.errors.password)}
                fullWidth
                helperText={formik.touched.password && formik.errors.password}
                margin="normal"
                label="Password"
                name="password"
                type={showPassword ? "text" : "password"}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              {formik.errors.submit && (
                <Typography color="error" sx={{ mt: 3 }} variant="body2">
                  {formik.errors.submit}
                </Typography>
              )}
              <CustomButtom
                disabled={loading}
                loading={loading}
                size="large"
                sx={{ mt: 3 }}
                type="submit"
                variant="contained"
                fullWidth={true}
                text={"Login"}
                color={"primary"}
              />
            </form>

            <Divider sx={{ my: 3 }} />

            <Typography variant="body2" align="center" color="text.secondary">
              Create an account?{" "}
              <Button color="primary" onClick={() => navigate("/signup")}>
                Sign up
              </Button>
            </Typography>
          </Paper>
        </Box>
      </Container>
      {toast && (
        <Toast
          isLoading={loading}
          toast={toast}
          setToast={setToast}
          toastType={toastType}
          toastMessage={toastMessage}
        />
      )}
    </>
  );
};

export default LoginPage;
