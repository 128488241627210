const PATH = {
  HOME: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  EMAIL_VERIFICATION: '/email-verification',
  COMPANY_ONBOARDING: '/company-onboarding',
  SURVEY_START: '/survey-start/:surveyId',
  SURVEY_FORM: '/survey-form/:surveyId',
  THANK_YOU: '/thank-you',
  DASHBOARD: '/dashboard',
  MY_SURVEYS: '/my-surveys',
  SURVEY_DETAILS: '/survey-details/:surveyId',
  SURVEY_PREVIEW: '/survey-preview/:surveyId',
  EMAIL_INVITATION: '/email-invitation',
  ANALYZE_RESPONSES: '/anaylze-responses/:surveyId',
  ANALYZE_RESPONSES_SCORE: '/anaylze-responses-score/:surveyId',
  SURVEY_RESPONSES: '/survey-responses/:surveyId',
  SURVEY_RESPONSE_DETAILS: '/survey-response-details/:respondentId/:surveyId',
  RESULT_ANALYTICS: '/result-analytics',
  ADMIN: '/admin',
  COOKIES: '/cookies',
  PRIVACY_POLICY: '/privacy-policy',
  CONTACT_SUPPORT: '/contact-support',
  MY_PROFILE: '/my-profile',
  SETTINGS: '/settings',
  MANAGER_USERS: '/manage-users',
  CREATE_SURVEY: '/create-survey',
  SURVEY_EDIT: '/survey-edit/:surveyId',
  CREATE_QUESTION_CATEGORY: '/create-question-category/:surveyId',
  CATCH_ALL: '*',
};

export const NAVIGATATION_BLOCKED_ROUTES = [
  '/survey-preview/',
  '/survey-start/',
  '/survey-form/',
  '/thank-you',
  '/thank-you/',
];

export default PATH;
