import { IconButton, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';

import ConfirmationBox from "../../../ConfirmationBox";
import {
  Delete as DeleteIcon,
} from "@mui/icons-material";
import DialogAlert from '../../../DialogAlert/DialogAlert';
import EditIcon from '@mui/icons-material/Edit';

const CategoryActionCell = (props) => {
  const { row, handleUpdateCategory, setCustomButtonRequired, handleDeleteCategory } = props;
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deletePreventWarning, setDeletePreventWarning] = useState(false);
  return (
    <div style={{ display: "flex", gap: "0.5rem", alignItems: "center", justifyContent: "flex-start" }}>
    <Tooltip title="Edit Category" arrow>
      <IconButton onClick={() => {
        handleUpdateCategory(row)
        setCustomButtonRequired(false)
      }}>
        <EditIcon />
      </IconButton>
    </Tooltip>
    <Tooltip title="Delete Category" arrow>
      <IconButton onClick={() => {
          if (row?.question_count>0) {
            setDeletePreventWarning(true)
            return
          }
        setDeleteConfirmOpen(true)
        }} color="error" disabled={false}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
      <ConfirmationBox
        open={deleteConfirmOpen}
        headerText="Confirm Delete"
        close={() => setDeleteConfirmOpen(false)}
        handleClose={() => setDeleteConfirmOpen(false)}
        handleAction={() => {
          handleDeleteCategory(row?.id);
          setDeleteConfirmOpen(false);
        }}
      >
        <Typography variant="body2">
          Are you sure you want to delete this Category?
        </Typography>
      </ConfirmationBox>

      <DialogAlert
      title={"Note"}
      open={deletePreventWarning}
      onClose={() => setDeletePreventWarning(false)}
      message={{
          categoryName: `${row?.question_category_name} Questions Category`,
          text: `has ${row?.question_count} question(s). Please reassign all questions to a different category before deleting this one.`,
        }}
      />
    </div>

  )
}

export default CategoryActionCell