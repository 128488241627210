import React, { useState } from "react"

export const useToast = () => {
    const [toast, setToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastType, setToastType] = useState();
    
    const showToast = (message, type) => {
        setToast(true);
        setToastMessage(message);
        setToastType(type);
        setTimeout(() => {
        setToast(false);
        }, 2000);
    };
    
    return { toast, toastMessage, toastType, showToast, setToast, };
}