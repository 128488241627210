import { Box, Typography } from '@mui/material';

import { InfoOutlined } from '@mui/icons-material';
import React from 'react';

const NoChartAvailable = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        textAlign: 'center',
        padding: '2rem',
        backgroundColor: '#f5f5f5',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      }}
    >
      <InfoOutlined sx={{ fontSize: 50, color: '#1976d2', mb: 2 }} />
      <Typography variant="h6" component="div" gutterBottom>
        Charts Not Available
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Charts are not available for this question type.
      </Typography>
    </Box>
  );
};

export default NoChartAvailable;