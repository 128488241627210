import { Box, Button, Container, Paper, Typography } from '@mui/material';

import React from 'react';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <Container
      maxWidth={false}
      sx={{
        height: '100vh',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          zIndex: 1,
        }}
      >
        <Paper elevation={3} sx={{ p: 4, maxWidth: 800, mt: 16 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h3" gutterBottom>
              Welcome to OpinionStream
            </Typography>
            <Typography variant="h6" gutterBottom>
              Create and manage surveys with ease
            </Typography>
            <Box sx={{ mt: 4 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/login')}
                sx={{ mr: 2 }}
              >
                Login
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => navigate('/signup')}
              >
                Sign Up
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default HomePage;
