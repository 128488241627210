/* eslint-disable no-mixed-operators */
import { getCookie, setCookie } from "./helpers";

// Generate a unique identifier (UUID) to track the device/session
export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

// Unique cookie names
const DEVICE_ID_COOKIE = 'dvc_id';
const SESSION_ID_COOKIE = 'ssn_id';
const COMPLETED_SURVEYS_COOKIE = 'cmp_svy';

// Get or generate a device ID (UUID to track the device without registration)
export function getDeviceId() {
  let deviceId = getCookie(DEVICE_ID_COOKIE);
  if (!deviceId) {
    deviceId = generateUUID(); // Generate a unique device ID
    setCookie(DEVICE_ID_COOKIE, deviceId, 365); // Store for 1 year
  }
  return deviceId;
}

// Get or generate a session ID (this can be generated when the survey starts)
export function getSessionId() {
  let sessionId = getCookie(SESSION_ID_COOKIE);
  if (!sessionId) {
    sessionId = Date.now(); // Use timestamp for a session ID
    setCookie(SESSION_ID_COOKIE, sessionId, 7); // Store for 7 days (session expires after 7 days)
  }
  return sessionId;
}

// Store whether a user has completed a survey (for a specific survey ID)
export function setSurveyCompletion(surveyId) {
  let completedSurveys = getCookie(COMPLETED_SURVEYS_COOKIE) || '{}';
  completedSurveys = JSON.parse(completedSurveys);
  completedSurveys[surveyId] = true; // Mark this survey as completed
  setCookie(COMPLETED_SURVEYS_COOKIE, JSON.stringify(completedSurveys), 365); // Store for 1 year
}

// Check if a user has already completed a survey (for a specific survey ID)
export function checkSurveyCompletion(surveyId) {
  let completedSurveys = getCookie(COMPLETED_SURVEYS_COOKIE) || '{}';
  completedSurveys = JSON.parse(completedSurveys);
  
  if (completedSurveys[surveyId]) {
    return true; // Survey already completed
  }
  return false; // Survey not completed
}

// Checking if the user has already filled the survey with a given survey ID
export function onSurveyAccess(surveyId) {
  if (checkSurveyCompletion(surveyId)) {
    return true;
  } else {
    return false;
  }
}

// When the user submits the survey
export function onSurveySubmit(surveyId) {
  setSurveyCompletion(surveyId);
}