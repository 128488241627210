import { StepConnector, stepConnectorClasses, styled } from '@mui/material';

import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import React from 'react'
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import SettingsIcon from '@mui/icons-material/Settings';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';

export const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg, rgb(33, 150, 243) 0%, rgb(33, 203, 243) 50%, rgb(33, 150, 243) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg, rgb(33, 150, 243) 0%, rgb(33, 203, 243) 50%, rgb(33, 150, 243) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
      ...theme.applyStyles('dark', {
        backgroundColor: theme.palette.grey[800],
      }),
    },
  }));
  
  const ColorlibStepIconRoot = styled('div')(({ theme }) => ({
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[700],
    }),
    variants: [
      {
        props: ({ ownerState }) => ownerState.active,
        style: {
          backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
          boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        },
      },
      {
        props: ({ ownerState }) => ownerState.completed,
        style: {
          backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        },
      },
    ],
  }));


const StepIconComp = (props) => {
    const { active, completed, className, surveyType, editMode=false } = props;

    const getIcons = (surveyType) => {
        if (surveyType === true) { // mean it's a anonymous survey
            return {
                1: <SettingsIcon />,
                2: <HelpCenterIcon />,
                3: <VideoLabelIcon />,
                4: <ScheduleSendIcon />,
            };
        } else {
            return {
            1: <SettingsIcon />,
            2: <PermIdentityIcon />,
            3: <HelpCenterIcon />,
            4: <VideoLabelIcon />,
            5: <ScheduleSendIcon />,
            };
    }
}
 
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}
      sx={{ cursor: editMode ? "pointer" : "default" }}
      >
        {getIcons(surveyType)[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
}

export default StepIconComp