import React, { useState } from "react";

import { Box } from "@mui/system";
import { CustomTable } from "../TableComponents/CustomTable";
import NormalCell from "../TableComponents/TableCells/NormalCell";
import { Typography } from "@mui/material";

const UserManagement = () => {
  const users = [
    { id: 1, name: "John Doe", email: "john@gmail.com", role: "admin" },
    { id: 2, name: "Emma Brown", email: "emma@gmail.com", role: "user" },
    { id: 3, name: "Jane Smith", email: "jane@gmail.com", role: "user" },
    { id: 4, name: "Olivia Martin", email: "olivia@gmail.com", role: "user" },
  ];

  const headers = [
    {
      name: "Name",
      displayName: "Name",
      width: `${100 / 3}%`,
      cell: ({ row }) => <NormalCell row={{ ...row, text: row.name }} />,
    },
    {
      name: "Email",
      displayName: "Email",
      width: `${100 / 3}%`,
      cell: ({ row }) => <NormalCell row={{ ...row, text: row.email }} />,
    },
    {
      name: "Role",
      displayName: "Role",
      width: `${100 / 3}%`,
      cell: ({ row }) => <NormalCell row={{ ...row, text: row.role }} />,
    },
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);

  // Handle page change
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        User Management
      </Typography>

      <CustomTable
        count={users.length}
        headers={headers}
        rows={users}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        listLoading={loading}
      />
    </Box>
  );
};

export default UserManagement;
