import React, { useMemo } from 'react';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Typography, Paper, Divider, Grid2 } from '@mui/material';
import { Box } from '@mui/system';
import {
  RemoveCircleOutline,
  ThumbDown,
  ThumbUp,
  TrendingDown,
  TrendingUp,
} from '@mui/icons-material';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Register Chart.js components
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ArcElement,
  ChartDataLabels
);

const SentimentDistributionCharts = ({ allQuestions }) => {
  // Calculate sentiment distribution
  const sentimentDistribution = {
    excellentScore: allQuestions?.filter(q => q.current_mean >= 4.5).length,
    goodScore: allQuestions?.filter(
      q => q.current_mean >= 3.5 && q.current_mean < 4.5
    ).length,
    averageScore: allQuestions?.filter(
      q => q.current_mean >= 2.5 && q.current_mean < 3.5
    ).length,
    poorScore: allQuestions?.filter(
      q => q.current_mean >= 1.5 && q.current_mean < 2.5
    ).length,
    criticalScore: allQuestions?.filter(q => q.current_mean < 1.5).length,
  };

  // Filter out zero values from data before creating the chart
  const filteredSentimentData = [
    {
      label: 'Excellent (4.5-5.0)',
      value: sentimentDistribution.excellentScore,
      color: 'rgba(0, 128, 0, 0.7)',
      borderColor: 'rgba(0, 128, 0, 1)',
      description: 'Highly positive feedback',
    },
    {
      label: 'Good (3.5-4.5)',
      value: sentimentDistribution.goodScore,
      color: 'rgba(76, 175, 80, 0.7)',
      borderColor: 'rgba(76, 175, 80, 1)',
      description: 'Positive feedback',
    },
    {
      label: 'Average (2.5-3.5)',
      value: sentimentDistribution.averageScore,
      color: 'rgba(255, 193, 7, 0.7)',
      borderColor: 'rgba(255, 193, 7, 1)',
      description: 'Neutral feedback',
    },
    {
      label: 'Poor (1.5-2.5)',
      value: sentimentDistribution.poorScore,
      color: 'rgba(255, 87, 34, 0.7)',
      borderColor: 'rgba(255, 87, 34, 1)',
      description: 'Negative feedback',
    },
    {
      label: 'Critical (1.0-1.5)',
      value: sentimentDistribution.criticalScore,
      color: 'rgba(244, 67, 54, 0.7)',
      borderColor: 'rgba(244, 67, 54, 1)',
      description: 'Highly negative feedback',
    },
  ];

  // Calculate total for percentage
  const total = filteredSentimentData.reduce(
    (sum, item) => sum + item.value,
    0
  );

  // Add percentage to each item
  filteredSentimentData.forEach(item => {
    item.percentage = ((item.value / total) * 100).toFixed(1);
  });

  // Create pieData from filtered data
  const pieData = {
    labels: filteredSentimentData.map(item => item.label),
    datasets: [
      {
        data: filteredSentimentData.map(item => item.value),
        backgroundColor: filteredSentimentData.map(item => item.color),
        borderColor: filteredSentimentData.map(item => item.borderColor),
        borderWidth: 1,
      },
    ],
  };

  const sentimentAnalysis = useMemo(() => {
    const totalQuestions = allQuestions?.length || 0;

    const distribution = {
      excellent: {
        count: allQuestions?.filter(q => q.current_mean >= 4.5).length || 0,
        label: 'Excellent (4.5-5.0)',
        color: 'rgba(0, 128, 0, 0.7)',
        borderColor: 'rgba(0, 128, 0, 1)',
        description: 'Outstanding performance with very high satisfaction',
      },
      good: {
        count:
          allQuestions?.filter(
            q => q.current_mean >= 3.5 && q.current_mean < 4.5
          ).length || 0,
        label: 'Good (3.5-4.5)',
        color: 'rgba(76, 175, 80, 0.7)',
        borderColor: 'rgba(76, 175, 80, 1)',
        description: 'Positive sentiment with room for improvement',
      },
      average: {
        count:
          allQuestions?.filter(
            q => q.current_mean >= 2.5 && q.current_mean < 3.5
          ).length || 0,
        label: 'Average (2.5-3.5)',
        color: 'rgba(255, 193, 7, 0.7)',
        borderColor: 'rgba(255, 193, 7, 1)',
        description: 'Neutral sentiment, requires attention',
      },
      poor: {
        count:
          allQuestions?.filter(
            q => q.current_mean >= 1.5 && q.current_mean < 2.5
          ).length || 0,
        label: 'Poor (1.5-2.5)',
        color: 'rgba(255, 87, 34, 0.7)',
        borderColor: 'rgba(255, 87, 34, 1)',
        description: 'Concerning areas needing immediate improvement',
      },
      critical: {
        count: allQuestions?.filter(q => q.current_mean < 1.5).length || 0,
        label: 'Critical (1.0-1.5)',
        color: 'rgba(244, 67, 54, 0.7)',
        borderColor: 'rgba(244, 67, 54, 1)',
        description: 'Severe issues requiring urgent action',
      },
    };

    // Calculate percentages and transform for chart
    const chartData = Object.values(distribution)
      // .filter(item => item.count > 0) // Filter out zero values
      .map(item => ({
        ...item,
        percentage:
          totalQuestions > 0
            ? ((item.count / totalQuestions) * 100).toFixed(1)
            : '0.0',
      }));

    return {
      distribution,
      chartData,
      totalQuestions,
    };
  }, [allQuestions]);

  // Enhanced pie options with more features
  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          boxWidth: 20,
          usePointStyle: true,
          font: {
            size: 12,
            weight: 'bold',
          },
          padding: 15,
          generateLabels: chart => {
            const data = chart.data;
            if (data.labels.length && data.datasets.length) {
              return data.labels.map((label, i) => {
                const value = data.datasets[0].data[i];
                const percentage = ((value / total) * 100).toFixed(1);
                return {
                  text: `${label} (${percentage}%)`,
                  fillStyle: data.datasets[0].backgroundColor[i],
                  strokeStyle: data.datasets[0].borderColor[i],
                  lineWidth: 1,
                  hidden: false,
                  index: i,
                };
              });
            }
            return [];
          },
        },
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleFont: {
          size: 14,
          weight: 'bold',
        },
        bodyFont: {
          size: 12,
        },
        padding: 12,
        cornerRadius: 6,
        callbacks: {
          title: tooltipItems => {
            return tooltipItems[0].label;
          },
          label: context => {
            const dataIndex = context.dataIndex;
            const value = context.dataset.data[dataIndex];
            const percentage = ((value / total) * 100).toFixed(1);
            return [
              `Count: ${value}`,
              `Percentage: ${percentage}%`,
              `Description: ${filteredSentimentData[dataIndex].description}`,
            ];
          },
        },
      },
      datalabels: {
        color: '#fff',
        font: {
          weight: 'bold',
          size: 12,
        },
        formatter: (value, ctx) => {
          if (value < 2) return ''; // Hide labels for very small segments
          return `${((value / total) * 100).toFixed(0)}%`;
        },
        anchor: 'center',
        align: 'center',
        offset: 0,
      },
    },
    layout: {
      padding: 10,
    },
    animation: {
      animateScale: true,
      animateRotate: true,
      duration: 1000,
    },
    // cutout: '15%', // Creates donut chart effect
    elements: {
      arc: {
        borderWidth: 1,
        borderColor: '#fff',
      },
    },
  };

  // Determine overall sentiment
  const overallSentiment =
    sentimentAnalysis.chartData.length > 0
      ? sentimentAnalysis.chartData.reduce(
          (best, current) =>
            parseFloat(current.percentage) > parseFloat(best.percentage)
              ? current
              : best,
          sentimentAnalysis.chartData[0]
        )
      : { label: 'No Data', percentage: '0.0' };

  // Calculate positive and negative sentiment percentages safely
  const getPercentage = indexes => {
    return indexes
      .map(i =>
        sentimentAnalysis.chartData[i]
          ? parseFloat(sentimentAnalysis.chartData[i].percentage)
          : 0
      )
      .reduce((sum, val) => sum + val, 0)
      .toFixed(1);
  };

  const positiveSentimentPercentage = getPercentage([0, 1]);
  const neutralSentimentPercentage = getPercentage([2]);
  const negativeSentimentPercentage = getPercentage([3, 4]);

  const getSentimentIconAndColor = sentiment => {
    switch (sentiment) {
      case 'Excellent (4.5-5.0)':
      case 'Good (3.5-4.5)':
        return {
          icon: <TrendingUp color="success" sx={{ mr: 2 }} />,
          color: 'success.light',
        };
      case 'Average (2.5-3.5)':
        return {
          icon: <RemoveCircleOutline color="warning" sx={{ mr: 2 }} />,
          color: 'warning.light',
        };
      case 'Poor (1.5-2.5)':
      case 'Critical (1.0-1.5)':
        return {
          icon: <TrendingDown color="error" sx={{ mr: 2 }} />,
          color: 'error.light',
        };
      default:
        return {
          icon: <TrendingUp color="disabled" sx={{ mr: 2 }} />,
          color: 'grey.400',
        };
    }
  };

  const { icon, color } = getSentimentIconAndColor(overallSentiment.label);

  return (
    <Paper
      elevation={3}
      className="no-break"
      style={{ padding: '20px', height: '100%' }}
    >
      <Typography variant="h6" color="primary" mb={2} gutterBottom>
        Sentiment Distribution
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 4 }} component="main">
          <Box>
            <Typography variant="h6" color="primary" gutterBottom>
              Sentiment Insights
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              my={2}
              p={2}
              border={1}
              borderRadius={2}
              borderColor={color}
            >
              {icon}
              <Typography variant="subtitle1" color={color}>
                <span style={{ fontWeight: 'bold' }}>Dominant Sentiment:</span>{' '}
                {overallSentiment.label}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={2}>
              <ThumbUp color="success" sx={{ mr: 2 }} />
              <Typography variant="body2">
                <span style={{ fontWeight: 'bold' }}>
                  Positive Sentiments (Excellent + Good):&nbsp;
                </span>
                {positiveSentimentPercentage}%
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={2}>
              <RemoveCircleOutline color="warning" sx={{ mr: 2 }} />
              <Typography variant="body2">
                <span style={{ fontWeight: 'bold' }}>
                  Neutral Sentiments (Average):&nbsp;
                </span>
                {neutralSentimentPercentage}%
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <ThumbDown color="error" sx={{ mr: 2 }} />
              <Typography variant="body2">
                <span style={{ fontWeight: 'bold' }}>
                  Negative Sentiments (Poor + Critical):&nbsp;
                </span>
                {negativeSentimentPercentage}%
              </Typography>
            </Box>
          </Box>
        </Grid2>
        <Grid2 size={{ xs: 6 }} component="main">
          <div style={{ height: '300px', width: '100%' }}>
            <Pie
              data={pieData}
              options={pieOptions}
              plugins={[ChartDataLabels]}
            />
          </div>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              Total Question Responses: {total}
            </Typography>
          </Box>
        </Grid2>
      </Grid2>
    </Paper>
  );
};

export default SentimentDistributionCharts;
