import React from "react";
import { Typography } from "@mui/material";
import { questionTypes } from "../../../utils/params";
const AnswerTypeCell = ({ row, type }) => {
  return (
    <Typography textTransform={"capitalize"} fontSize="15px">
      {type === "number" ? row.text || "0" : 
      
        questionTypes.find((q)=>q.value===row.text)?.label || "--"}
    </Typography>
  );
};

export default AnswerTypeCell;
