export const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: "top",
        },
    },
    scales: {
        x: {
            title: {
                display: true,
                text: "Rating",
            },
        },
        y: {
            title: {
                display: true,
                text: "Count",
            },
        },
    },
};

// Pie chart options
export const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 12, // Adjust the font size
            family: 'Arial', // Adjust the font family
          },
          boxWidth: 20, // Adjust the box width
          padding: 8, // Adjust the padding
          textAlign: 'left', // Adjust the text alignment
        },
      },
    },
};

// Doughnut chart options

export const doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 12, // Adjust the font size
            family: 'Arial', // Adjust the font family
          },
          boxWidth: 20, // Adjust the box width
          padding: 8, // Adjust the padding
          textAlign: 'left', // Adjust the text alignment
        },
      },
    },
  };

// Line chart options
export const lineChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        // text: "X-Axis Label",
      },
    },
    y: {
      beginAtZero: true,
      title: {
        display: true,
        // text: "Y-Axis Label",
      },
    },
  },
}; 