import { Chip } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { getChipDesiredIcon } from './chipIconsConfig';

const statusColors = {
    success: {
      backgroundColor: '#4caf50',
      color: '#fefefe',
    },
    error: {
      backgroundColor: '#f44336',
      color: '#fefefe',
    },
    expired: {
      backgroundColor: '#D3455B',
      color: '#fefefe',
    },
    warning: {
      backgroundColor: '#ff9800',
      color: '#fefefe',
    },
    info: {
      backgroundColor: '#2196f3',
      color: '#fefefe',
    },
    paused: {
      backgroundColor: '#788896',
      color: '#fefefe',
    },
    continue: {
      backgroundColor: '#4caf50',
      color: '#fefefe',
    },
    archive:{
      backgroundColor: '#f44336',
      color: '#fefefe',
    },
    completed: {
      backgroundColor: '#4caf50',
      color: '#fefefe',
    },
    stopped: {
      backgroundColor: '#f44336',
      color: '#fefefe',
    },
    anonymous:{
        backgroundColor: 'purple',
        color: '#fefefe',
    },
    published:{
        backgroundColor: 'green',
        color: '#fefefe',
    },
    draft:{
        backgroundColor: 'gray',
        color: '#fefefe',
    },
    named: {
        backgroundColor: '#f44336',
        color: '#fefefe',
    },
    default: {
      backgroundColor: '#e0e0e0',
      color: '#fefefe',
    },
  };

const CustomChip = ({
  label,
  color = 'default',
  iconType,
  iconPosition = 'left',
  onDelete,
  status,
  chipSize = "0.8rem",
  ...props
}) => {

  const icon = getChipDesiredIcon(iconType);

  return (
    <Chip
      label={label}
      icon={iconPosition === 'left' ? icon : null}
      variant='filled'
      deleteIcon={iconPosition === 'right' ? icon : null}
      onDelete={onDelete}
      sx={{
        fontWeight: "bold",
        padding: chipSize || "0.8rem",
        backgroundColor: statusColors[status]?.backgroundColor || "inherit",
        color: statusColors[status]?.color || "inherit",
        // this class is used for svg icon at root level
        "& .MuiSvgIcon-root": {
            fontSize: "1rem",
            marginLeft: "0px",
            color: statusColors[status]?.color || "inherit",
        },
        // this class is used for MUI chip label
        "& .MuiChip-label": {
            paddingLeft: "0px",
            paddingRight: "0px",
            lineHeight: "normal"
        },
      }}
      {...props}
    />
  );
};

CustomChip.propTypes = {
// color: PropTypes.oneOf(['default', 'primary', 'secondary', 'error', 'info', 'success', 'warning']),
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  iconType: PropTypes.string,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  onDelete: PropTypes.func,
};

export default CustomChip;