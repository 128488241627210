import { Box, MenuItem, TextField, Typography } from '@mui/material'

import React from 'react'

const SurveyDetailsStep = (props) => {
  const { formik, editMode } = props;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h6" gutterBottom>
        Survey Details
      </Typography>
      <TextField
        fullWidth
        label="Survey Name"
        name="title"
        value={formik.values.title}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.title && Boolean(formik.errors.title)}
        helperText={formik.touched.title && formik.errors.title}
        required
        margin="normal"
      />
      <TextField
        fullWidth
        select
        label="Type of survey"
        name="is_anonymous"
        value={formik.values.is_anonymous}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.is_anonymous && Boolean(formik.errors.is_anonymous)}
        helperText={formik.touched.is_anonymous && formik.errors.is_anonymous}
        required
        margin="normal"
        disabled={editMode}
      >
        <MenuItem value={true}>Anonymous</MenuItem>
        <MenuItem value={false}>Named</MenuItem>
      </TextField>
      <TextField
        fullWidth
        label="Introduction Text (optional)"
        name="description"
        value={formik.values.description}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        multiline
        rows={4}
        margin="normal"
      />
    </Box>
  )
}

export default SurveyDetailsStep