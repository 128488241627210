import React from 'react'

const LoaderAnimation = () => {
  return (
    <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <img src='/assets//animation/loadinganimation.gif' alt="loader" />
        </div>
  )
}

export default LoaderAnimation