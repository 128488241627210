import { Box, Button, Container, Stack, Typography } from "@mui/material";

import React from "react";

const ThankYouPage = () => {
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          mt: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Stack spacing={2} sx={{ mb: 3 }}>
          <Box display="flex" justifyContent="center">
            <img src="/assets/thank_you_img.png" alt="thank_you" style={{
              width: "100%",
              maxWidth: "650px",
              height: "auto",
              mixBlendMode: "multiply"
            }} />
          </Box>
          <Typography variant="h4" gutterBottom>
            Thank You!
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ mb: 4 }}>
            We appreciate your time and effort in completing the survey. Your feedback is valuable to us.
          </Typography>
        </Stack>
      </Box>
    </Container>
  );
};

export default ThankYouPage;