import { Navigate } from "react-router-dom";
import PATH from "../../router/paths";
import React from "react";
import { useAuthContext } from "../../contexts/AuthContext";

const PrivateRoute = ({ Component, children, requiredRole, allowGuest = false }) => {
  const { user, hasPermission } = useAuthContext();

  if (allowGuest) {
    return <Component/>;
  }

  if (!user) {
    const currentPath = window.location.pathname;
    sessionStorage.setItem("redirectAfterLogin", currentPath);
    return <Navigate to={PATH.LOGIN} />;
  }

  if (requiredRole && !hasPermission(requiredRole)) {
    return <Navigate to={PATH.HOME} />;
  }

  return <Component/>;
};

export default PrivateRoute;
