import { Box, Button, Container, Grid, Paper, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

const ContactSupport = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    subject: '',
    message: '',
    file: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      file: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Form submitted:', formData);
    alert('Thank you for reaching out. Our team will get back to you soon.');
  };

  return (
    <Box>
    <Container maxWidth="md">
      <Box sx={{ mt: 6, mb: 4 }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Typography variant="h4" textAlign="center" gutterBottom>
            Contact Support
          </Typography>
          <Typography variant="subtitle1" textAlign="center" gutterBottom>
            Have questions or need assistance? Our support team is here for you!
          </Typography>
          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <TextField
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                required
              />
              <TextField
                label="Email Address"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                required
              />
              <TextField
                label="Company/Organization Name"
                name="company"
                value={formData.company}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                label="Subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                fullWidth
                required
              />
              <TextField
                label="Message/Description"
                name="message"
                value={formData.message}
                onChange={handleChange}
                fullWidth
                multiline
                rows={4}
                required
              />
              <Button
                variant="contained"
                component="label"
              >
                Upload File
                <input
                  type="file"
                  hidden
                  onChange={handleFileChange}
                />
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Submit
              </Button>
            </Stack>
          </form>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">Support Channels</Typography>
            <Typography>Email: smartsurvey@gmail.com</Typography>
            <Typography>Phone: +91 8978739213</Typography>
            <Typography>FAQs: Need to discuss this -- if we can add or not</Typography>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">Support Hours</Typography>
            <Typography>Our support team is available Monday to Friday, 9 AM to 6 PM (UTC).</Typography>
          </Box>
        </Paper>
      </Box>
    </Container>
    </Box>
  );
};

export default ContactSupport;