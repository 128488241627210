import React, { useCallback, useMemo } from 'react'

import { Box } from '@mui/material'
import { Pie } from 'react-chartjs-2'
import { pieChartOptions } from './ChartOptionsConfig'

const PieChart = (props) => {
    const { question, totalActualRespondent } = props

    const aggregateMultipleChoice = useCallback((currentQuestion) => {
        const data = currentQuestion?.answers?.map((answer) => {
            return {
                label: answer.choice_text,
                data: answer.choice_count,
            }
        });

        const newMapData = new Map();

        data?.forEach((item) => {
            newMapData.set(item.label, item.data);
        });

        return {
            labels: Array.from(newMapData.keys()),
            data: Array.from(newMapData.values()),
            question: question.question,
        };
    },[question]);

    // Prepare chart data
    const multipleChoiceChartData = useMemo(() => {
        const mcData = aggregateMultipleChoice(question);
        return {
            labels: mcData.labels,
            datasets: [
                {
                    data: mcData.data,
                    backgroundColor: [
                        '#FF6384',
                        '#36A2EB',
                        '#FFCE56',
                        'rgba(73,188,170)',
                        "rgba(49, 235, 24, 0.8)"
                    ],
                    hoverBackgroundColor: [
                        '#FF6384',
                        '#36A2EB',
            '#FFCE56'
        ],
                    borderWidth: 1,
                },
            ],
        };
    }, [aggregateMultipleChoice]);

    const options = {
        ...pieChartOptions,
        plugins: {
          tooltip: {
  enabled: false,
  external: function (context) {
    const dataIndex = context?.tooltip.dataPoints?.[0]?.dataIndex;
    const data = context?.tooltip?.dataPoints?.[0]?.dataset?.data?.[dataIndex];
    // Tooltip Element
    let tooltipEl = document.getElementById('chartjs-tooltip');

    // Hide if no tooltip
    const tooltipModel = context?.tooltip;
    if (!tooltipEl || tooltipModel?.opacity === 0) {
      if (tooltipEl) {
        tooltipEl.style.opacity = 0;
      }
      return;
    }

    // Set caret Position
    tooltipEl?.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel?.yAlign) {
      tooltipEl?.classList?.add(tooltipModel?.yAlign);
    } else {
      tooltipEl?.classList?.add('no-transform');
    }

    function getBody(bodyItem) {
      return bodyItem?.lines;
    }

    // Set Text
    if (tooltipModel.body) {
      const titleLines = tooltipModel?.title || [];
      const bodyLines = tooltipModel?.body?.map(getBody);

      let innerHtml = '<thead>';

      titleLines?.forEach(function (title, i) {
        const colors = tooltipModel?.labelColors[i];
        let style = 'background:' + colors?.backgroundColor;
        style += '; border-color:' + colors?.borderColor;
        style += '; border-width: 2px';
        const span = '<span style="' + style + '; display: inline-block; width: 10px; height: 10px; margin-right: 5px;"></span>';
        innerHtml += '<tr><th style="text-align: left;">' + span + title + '  ' +
          '(' + ((data / totalActualRespondent) * 100)?.toFixed(1) + '%)' +
          '</th></tr>';
      });
      innerHtml += '</thead><tbody>';

      bodyLines?.forEach(function (body, i) {
        const colors = tooltipModel?.labelColors[i];
        let style = 'background:' + colors?.backgroundColor;
        style += '; border-color:' + colors?.borderColor;
        style += '; border-width: 2px';
        const span = '<span style="' + style + '; display: inline-block; width: 10px; height: 10px; margin-right: 5px;"></span>';
        const bodyText = body?.[0] || '';
        const [label] = bodyText?.split('<br>');
        innerHtml += '<tr><td>' + span + data + ' respondent(s)</td></tr>';
      });
      innerHtml += '</tbody>';

      const tableRoot = tooltipEl?.querySelector('table');
      tableRoot.innerHTML = innerHtml;
    }

    const position = context?.chart?.canvas.getBoundingClientRect();
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 10 + 'px';
    tooltipEl.style.font = tooltipModel.options.bodyFont.string;
  },
},
          legend: {
        display: true,
        position: 'left', // Change the position to 'bottom'
        labels: {
          boxWidth: 25,
          boxHeight: 20,
          padding: 20,
          generateLabels: function (chart) {
            const data = chart.data;
            if (data.labels.length && data.datasets.length) {
              const legendItems = data.labels.map((label, i) => {
                const meta = chart.getDatasetMeta(0);
                const style = meta.controller.getStyle(i);
                const count = data.datasets[0].data[i];
                const percentage = ((count / totalActualRespondent) * 100).toFixed(1);

                return {
                  text: `${label}: (${percentage}%) ${count} respondent(s)`,
                  fillStyle: style.backgroundColor,
                  strokeStyle: style.borderColor,
                  lineWidth: style.borderWidth,
                  hidden: !chart.getDataVisibility(i),
                  index: i,
                  percentage: parseFloat(percentage), // Add percentage for sorting
                };
              });
               // Sort legend items by percentage
               legendItems.sort((a, b) => b.percentage - a.percentage);

               return legendItems;
            }
            return [];
          },
        },
      },
        },
      };

    return (
        <>
            {multipleChoiceChartData && (
                <Box
                    sx={{ height: 300 }}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Pie
                        data={multipleChoiceChartData}
                        width={100}
                        options={options}
                    />
                </Box>
            )}
        </>
    )
}

export default PieChart