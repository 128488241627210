import React from 'react';
import { Typography, Box, Chip, Divider, Paper, Grid2 } from '@mui/material';
import {
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
  Report as ReportIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from '@mui/icons-material';

const KeyInsightsCard = ({ categories = [], allQuestions = [] }) => {
  // Validate input data
  if (!categories.length || !allQuestions.length) {
    return (
      <Paper elevation={2} style={{ padding: '24px', height: '100%' }}>
        <Typography variant="body1" color="error">
          Insufficient data to generate insights
        </Typography>
      </Paper>
    );
  }

  // Calculate global mean across all questions
  const globalMean = +(
    allQuestions.reduce((sum, q) => sum + q.current_mean, 0) /
    allQuestions.length
  ).toFixed(2);

  // Determine global trend
  const globalTrend = globalMean >= 3.5 ? 'Positive' : 'Needs Improvement';

  // Filter out categories with zero questions first
  const validCategories = categories.filter(
    category => category.questions && category.questions.length > 0
  );

  // Find most and least positive categories
  const mostPositiveCategory = validCategories.reduce(
    (max, category) => {
      const categoryMean = +(
        category.questions.reduce((sum, q) => sum + q.current_mean, 0) /
        category.questions.length
      ).toFixed(2);
      return categoryMean > max.mean
        ? { ...category, mean: categoryMean }
        : max;
    },
    { ...validCategories[0], mean: 0 }
  );

  const leastPositiveCategory = validCategories.reduce(
    (min, category) => {
      const categoryMean = +(
        category.questions.reduce((sum, q) => sum + q.current_mean, 0) /
        category.questions.length
      ).toFixed(2);
      return categoryMean < min.mean
        ? { ...category, mean: categoryMean }
        : min;
    },
    { ...validCategories[0], mean: 5 }
  );

  // Function to get comparative percentage
  const getCategoryComparison = categoryMean => {
    const avgMean = +(
      validCategories.reduce(
        (sum, category) =>
          sum +
          category.questions.reduce((q_sum, q) => q_sum + q.current_mean, 0) /
            category.questions.length,
        0
      ) / validCategories.length
    ).toFixed(2);

    const difference = (((categoryMean - avgMean) / avgMean) * 100).toFixed(1);
    return {
      value: Math.abs(difference),
      direction: difference > 0 ? 'above' : 'below',
    };
  };

  const cardData = [
    {
      icon: <TrendingUpIcon color="success" sx={{ mr: 1 }} />,
      title: 'Most Positive Category',
      chipLabel: mostPositiveCategory.category_name,
      chipColor: 'success',
      value: mostPositiveCategory.mean,
      comparison: getCategoryComparison(mostPositiveCategory.mean),
    },
    {
      icon: <TrendingDownIcon color="error" sx={{ mr: 1 }} />,
      title: 'Least Positive Category',
      chipLabel: leastPositiveCategory.category_name,
      chipColor: 'error',
      value: leastPositiveCategory.mean,
      comparison: getCategoryComparison(leastPositiveCategory.mean),
    },
    {
      icon:
        globalTrend === 'Positive' ? (
          <ArrowUpwardIcon color="success" sx={{ mr: 1 }} />
        ) : (
          <ArrowDownwardIcon color="error" sx={{ mr: 1 }} />
        ),
      title: 'Global Trend',
      chipLabel: globalTrend,
      chipColor: globalTrend === 'Positive' ? 'success' : 'error',
      value: globalMean,
      comparison: null,
    },
  ];

  return (
    <Paper
      elevation={2}
      className="no-break"
      style={{ padding: '24px', height: '100%' }}
    >
      <Box display="flex" alignItems="center" mb={2}>
        <ReportIcon color="primary" sx={{ mr: 2 }} />
        <Typography variant="h6" color="primary">
          Key Insights Overview
        </Typography>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Grid2 container spacing={2}>
        {cardData.map(
          ({ icon, title, chipLabel, chipColor, value, comparison }, index) => (
            <Grid2 key={index} size={{ xs: 4 }} component="main">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                p={2}
                border={1}
                borderRadius={2}
                borderColor={`${chipColor}.light`}
              >
                <Box display="flex" alignItems="center">
                  {icon}
                  <Typography variant="subtitle1" fontWeight="bold">
                    {title}
                  </Typography>
                </Box>
                <Box
                  mt={1}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap={1}
                >
                  <Chip
                    label={chipLabel}
                    color={chipColor}
                    variant="contained"
                  />
                  <Typography variant="body2" color="text.secondary">
                    {value.toFixed(2)}
                    {comparison &&
                      ` (${comparison.value}% ${comparison.direction} average)`}
                  </Typography>
                </Box>
              </Box>
            </Grid2>
          )
        )}
      </Grid2>
    </Paper>
  );
};

export default KeyInsightsCard;
