import { Box, Button, Container, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  capitalizeFirstCharacter,
  epochTimeToDate,
  timeAgo,
} from '../../utils/helpers';

import CustomChip from '../../components/chips/CustomChip';
import ErrorPage from '../../components/Error/ErrorPage';
import LoaderAnimation from '../../components/LoaderComp/LoaderAnimation';
import Toast from '../../components/Tost';
import useApiService from '../../hooks/api-services/use-service';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../hooks/custom-hook-functions/useToast';

const ResultAnalytics = () => {
  const navigate = useNavigate();
  const { getResultAnalyticsSummary } = useApiService();
  const { showToast, setToast, toast, toastMessage, toastType } = useToast();
  const [summaryDataState, setSummaryDataState] = useState({
    loading: false,
    error: false,
    errorMessage: '',
    data: [],
  });
  const [filteredData, setFilteredData] = useState([]);
  const [selectedBtn, setSelectedBtn] = useState('published');

  useEffect(() => {
    if (
      summaryDataState?.data?.length > 0 &&
      summaryDataState?.loading === false
    ) {
      setFilteredData(
        summaryDataState?.data?.filter(record => record.status === 'published')
      );
    }
  }, [summaryDataState?.data]);

  const statusButtons = [
    { label: 'Live', value: 'published' },
    { label: 'Paused', value: 'paused' },
    { label: 'Closed', value: 'closed' },
    { label: 'Completed', value: 'completed' },
    { label: 'Archived', value: 'archive' },
  ];

  const handleFilteredData = status => () => {
    setSelectedBtn(status);
    const filteredData = summaryDataState?.data?.filter(record => {
      if (status === 'archive') {
        return record?.is_archived === true;
      } else {
        return record.status === status;
      }
    });
    setFilteredData(filteredData);
  };

  const loadData = async () => {
    try {
      setSummaryDataState(prevState => ({
        ...prevState,
        loading: true,
        error: false,
        errorMessage: '',
      }));
      const response = await getResultAnalyticsSummary();
      setSummaryDataState(prevState => ({
        ...prevState,
        data: response.data,
      }));
    } catch (error) {
      setSummaryDataState(prevState => ({
        ...prevState,
        error: true,
        errorMessage: error.message,
      }));
      showToast('Something went wrong', 'error');
    } finally {
      setSummaryDataState(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const SummaryGridCard = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    '& .image_section': {
      width: '100%',
      height: '100%',
      objectFit: 'fill',
      mixBlendMode: 'multiply',
    },
    '& .detail_section': {
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      '& .header_section': {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        '& .header_stats': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          '& .values': {
            display: 'flex',
            flexDirection: 'column',
            gap: '0.2rem',
            '& .value': {
              fontSize: '1.2rem',
              fontWeight: 'bold',
              color: 'black',
            },
            '& .label': {
              fontSize: '0.8rem',
              color: 'black',
            },
          },
        },
      },
      '& .body_section': {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        '& .body_stats': {
          display: 'flex',
          flexDirection: 'column',
          gap: '0.8rem',
          '& .values': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            '& .value': {
              fontSize: '1rem',
              fontWeight: 'bold',
              color: 'black',
            },
            '& .label': {
              fontSize: '0.8rem',
              color: 'black',
            },
          },
        },
        '& .body_footer': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: 2,
        },
      },
    },
  }));

  useEffect(() => {
    loadData();
  }, []);

  const ParentContainer = styled(Box)(({ theme }) => ({
    display: 'grid',
    gap: '2rem',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  }));

  const handleNavigateViewResponse = id => {
    navigate(`/survey-responses/${btoa(`survey_${id}_${Date.now()}`)}`);
  };

  const handleNavigateViewAnalytics = id => {
    navigate(`/anaylze-responses/${btoa(`survey_${id}_${Date.now()}`)}`);
  };
  const handleNavigateViewAnalyticsWithScore = id => {
    navigate(`/anaylze-responses-score/${btoa(`survey_${id}_${Date.now()}`)}`);
  };

  if (summaryDataState.loading) {
    return <LoaderAnimation />;
  }

  if (summaryDataState.error) {
    return <ErrorPage />;
  }

  return (
    <>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
            my: '2rem',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h4">Result Analytics</Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '0.5rem',
                background: 'white',
                width: 'fit-content',
                borderRadius: '50px',
                padding: '0.5rem',
              }}
            >
              {statusButtons.map((button, index) => (
                <Button
                  key={index}
                  variant="contained"
                  color="primary"
                  sx={{
                    borderRadius: '50px',
                    fontWeight: 'bold',
                    background:
                      button.value === selectedBtn ? 'primary' : 'transparent',
                    color: button.value === selectedBtn ? 'white' : 'black',
                    transition: 'background-color 0.3s, color 0.3s', // Add transition for smooth effect
                    '&:hover': {
                      background:
                        button.value === selectedBtn
                          ? 'primary'
                          : 'rgba(0, 0, 0, 0.1)',
                    },
                  }}
                  onClick={handleFilteredData(button.value)}
                >
                  {button.label}
                </Button>
              ))}
            </Box>
          </Box>

          {/* RESULTS */}
          <ParentContainer>
            {filteredData?.length > 0 ? (
              filteredData?.map((record, index) => (
                <SummaryGridCard key={record?.id}>
                  <Box
                    sx={{
                      flexGrow: 1,
                      alignSelf: 'center',
                      width: '100%',
                      maxHeight: '240px',
                      height: '240px',
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      src={
                        record?.banner_image_url ||
                        '/assets/surveyImages/imagenotfound.jpg'
                      }
                      alt="cover_image"
                      className="image_section"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: record?.banner_image_url
                          ? 'fill'
                          : 'contain', // Apply objectFit correctly
                        mixBlendMode: 'multiply',
                      }}
                      onError={e => {
                        e.target.onerror = null;
                        e.target.src = '/assets/surveyImages/imagenotfound.jpg'; // fallback image
                        e.target.alt = 'cover_image';
                        e.target.height = 240;
                        e.target.style.objectFit = 'cover';
                        e.target.style.mixBlendMode = 'multiply';
                        e.target.style.width = '100%';
                        e.target.style.maxWidth = 'fit-content';
                      }}
                    />
                  </Box>
                  <Box className="detail_section">
                    <Box className="header_section">
                      <Typography variant="h5">{record?.title}</Typography>
                      <Box className="header_stats">
                        <Box className="values">
                          <Typography className="label">
                            Total Questions
                          </Typography>
                          <Typography className="value">
                            {record?.total_questions}
                          </Typography>
                        </Box>
                        <Box className="values">
                          <Typography className="label">
                            Total Respondents
                          </Typography>
                          <Typography className="value">
                            {record?.total_respondents}
                          </Typography>
                        </Box>
                        <Box className="values">
                          <Typography className="label">
                            Completion ?
                          </Typography>
                          <Typography className="value">
                            {record?.avg_completion_rate}%{' '}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="body_section">
                      <Box className="body_stats">
                        <Box className="values">
                          <Typography className="label">
                            Last Response
                          </Typography>
                          <Typography className="value">
                            {timeAgo(record?.last_response) || 'N/A'}
                          </Typography>
                        </Box>
                        <Box className="values">
                          <Typography className="label">
                            Average Time
                          </Typography>
                          <Typography className="value">
                            {epochTimeToDate(record?.avg_response_time) ||
                              'N/A'}
                          </Typography>
                        </Box>
                        <Box className="values">
                          <Typography className="label">Status</Typography>
                          {record?.is_archived ? (
                            <CustomChip label="Archived" status="archive" />
                          ) : (
                            <CustomChip
                              label={capitalizeFirstCharacter(record?.status)}
                              status={record?.status}
                            />
                          )}
                        </Box>
                      </Box>
                      <Box className="body_footer">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleNavigateViewResponse(record?.id)}
                          sx={{ fontSize: '13px' }}
                          size="small"
                        >
                          View Responses
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            handleNavigateViewAnalytics(record?.id)
                          }
                          sx={{ fontSize: '13px' }}
                          size="small"
                        >
                          View Analytics
                        </Button>
                        {record.is_score && (
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                              handleNavigateViewAnalyticsWithScore(record?.id)
                            }
                            sx={{ fontSize: '13px' }}
                            size="small"
                          >
                            View Analytics With Score
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </SummaryGridCard>
              ))
            ) : (
              <Typography variant="h5">No data found</Typography>
            )}
          </ParentContainer>
        </Box>
      </Container>
      {toast && (
        <Toast
          setToast={setToast}
          isLoading={summaryDataState.loading}
          toast={toast}
          toastType={toastType}
          toastMessage={toastMessage}
        />
      )}
    </>
  );
};

export default ResultAnalytics;
