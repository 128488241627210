import { Box, Container } from '@mui/system';
import { Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import ErrorPage from '../components/Error/ErrorPage';
import RenderSurvey from '../components/RenderSurvey';
import SurveyAlreadyFilled from './SurveyAlreadyFilled/SurveyAlreadyFilled';
import SurveyExpired from './SurveyExpired/SurveyExpired';
import Toast from '../components/Tost';
import { onSurveyAccess } from '../utils/UUID-each-user-';
import useApiService from '../hooks/api-services/use-service';
import { useParams } from 'react-router-dom';
import { useToast } from '../hooks/custom-hook-functions/useToast';

const SurveyForm = () => {
  const { surveyId } = useParams();
  const [surveyData, setSurveyData] = useState();
  const { getSurveyByIdOpen } = useApiService();
  const { showToast, setToast, toast, toastMessage, toastType } = useToast();
  const [isSurveyExpired, setIsSurveyExpired] = useState(false);
  const [isSurveyAlreadyFilled, setIsSurveyAlreadyFilled] = useState(false);
  const [decodedError, setDecodedError] = useState(false);

  useEffect(() => {
    // check the survey access
    setIsSurveyAlreadyFilled(onSurveyAccess(surveyId));
  }, []);

  const handleGetSurvey = async surveyId => {
    try {
      await getSurveyByIdOpen(surveyId).then(res => {
        if (res.status === 200) {
          setSurveyData(res?.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (surveyData) {
      if (Object?.keys(surveyData)?.length) {
        const endDate = new Date(surveyData?.end_date)?.getTime();
        const currentDate = new Date().getTime();
        const isSurveyNotAvailable =
          surveyData?.status === 'completed' ||
          surveyData?.status === 'paused' ||
          surveyData?.is_archived === true;
        if ((endDate && endDate < currentDate) || isSurveyNotAvailable) {
          showToast('Oops! This survey is not available', 'error');
          setIsSurveyExpired(true);
        }
      }
    }
  }, [surveyData]);

  useEffect(() => {
    try {
      const decodedString = Number(atob(surveyId)?.split('_')[1]);
      if (isNaN(decodedString)) {
        throw new Error('Invalid survey ID');
      }
      handleGetSurvey(decodedString);
      setDecodedError(false);
    } catch (error) {
      console.error('Failed to decode survey ID:', error);
      showToast('Failed to decode survey ID', 'error');
      setDecodedError(true);
    }
  }, [surveyId]);

  const handleSurveySubmit = answers => {
    console.log('Survey Answers:', answers);
  };

  if (isSurveyAlreadyFilled) {
    return (
      <SurveyAlreadyFilled isSurveyAlreadyFilled={isSurveyAlreadyFilled} />
    );
  }

  if (isSurveyExpired) {
    return <SurveyExpired isSurveyExpired={isSurveyExpired} />;
  }

  if (decodedError) {
    return <ErrorPage />;
  }

  return (
    <>
      <Box>
        <Container maxWidth="xl" sx={{ py: 4, width: '100%' }}>
          {surveyData ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Paper
                sx={{
                  p: 3,
                  mb: 3,
                  boxShadow: 3,
                  position: 'relative',
                  width: '100%',
                }}
              >
                <RenderSurvey data={surveyData} onSubmit={handleSurveySubmit} />
              </Paper>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '400px',
                marginBottom: '100px',
              }}
            >
              <CircularProgress size="3rem" />
              <Typography variant="h6" sx={{ mt: 2 }}>
                Loading....
              </Typography>
            </Box>
          )}
        </Container>
      </Box>
      {toast && (
        <Toast
          setToast={setToast}
          toast={toast}
          toastType={toastType}
          toastMessage={toastMessage}
        />
      )}
    </>
  );
};

export default SurveyForm;
