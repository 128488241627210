import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { DateRange, InfoOutlined } from '@mui/icons-material';
import { Box, Grid } from '@mui/system';
import {
  Card,
  CardContent,
  Chip,
  Divider,
  Grid2,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import SentimentDistributionCharts from './SentimentDistributionCharts';
import KeyInsightsCard from './KeyInsightsCard';
import SurveyGlossary from './SurveyGlossary ';
import {
  getBottomScoreBgColor,
  getBottomScoreColor,
  getDistributionColor,
  getScoreBgColor,
  getScoreColor,
} from './surveyConfig';
import FormatDateTime from '../Functions/FormatDateTime';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const SurveyReportView = ({ surveyData, categories, selectedFilters }) => {
  // Guard clause to prevent rendering with undefined data
  if (!surveyData || !categories || categories.length === 0) {
    return <Typography>Loading survey data...</Typography>;
  }

  let serialCounter = 1;

  const validCategories = categories.filter(
    category => category.questions && category.questions.length > 0
  );

  // Safely access allQuestions with proper null checks
  const allQuestions = validCategories.flatMap(
    category => category.questions || []
  );
  const dynamicHeight = Math.max(300, validCategories.length * 50);
  // Calculate category averages
  const categoryData =
    validCategories?.map(category => {
      const questions = category.questions || [];

      // Check if there are any questions before calculating
      if (questions.length === 0) {
        return {
          name: category.category_name,
          mean: 0,
          topbox: 0,
          bottombox: 0,
        };
      }

      // Calculate the sum with proper null checks
      const meanSum = questions.reduce(
        (sum, q) => sum + (q?.current_mean || 0),
        0
      );
      const topboxSum = questions.reduce((sum, q) => sum + (q?.topBox || 0), 0);
      const bottomboxSum = questions.reduce(
        (sum, q) => sum + (q?.bottomBox || 0),
        0
      );

      return {
        name: category.category_name,
        mean: +(meanSum / questions.length).toFixed(2),
        topbox: Math.round(topboxSum / questions.length),
        bottombox: Math.round(bottomboxSum / questions.length),
      };
    }) || [];

  // Mean Score Chart Data
  const meanChartData = {
    labels: categoryData?.map(category => category.name),
    datasets: [
      {
        label: 'Mean Score',
        data: categoryData?.map(category => category.mean),
        backgroundColor: 'rgba(63, 81, 181, 0.8)',
        borderColor: 'rgba(63, 81, 181, 1)',
        borderWidth: 1,
        barThickness: 20,
      },
    ],
  };

  // Top Box & Bottom Box Chart Data
  const topBottomChartData = {
    labels: categoryData?.map(category => category.name),
    datasets: [
      {
        label: 'Top Box',
        data: categoryData?.map(category => category.topbox),
        backgroundColor: 'rgba(76, 175, 80, 0.8)',
        borderColor: 'rgba(76, 175, 80, 1)',
        borderWidth: 1,
        barThickness: 20,
      },
      {
        label: 'Bottom Box',
        data: categoryData?.map(category => category.bottombox),
        backgroundColor: 'rgba(244, 67, 54, 0.8)',
        borderColor: 'rgba(244, 67, 54, 1)',
        borderWidth: 1,
        barThickness: 20,
      },
    ],
  };

  // Chart.js Options
  const chartOptions = (x_label, y_label, min, max, stepSize) => {
    return {
      indexAxis: 'y',
      scales: {
        x: {
          beginAtZero: true,
          min: min,
          max: max,
          ticks: {
            stepSize: stepSize,
          },
          title: {
            display: true,
            text: x_label || 'Score',
          },
        },
        y: {
          title: {
            display: true,
            text: y_label || 'Category',
          },
          barThickness: 20,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              if (context.parsed.x !== null) {
                label += context.parsed.x;
              }
              return label;
            },
          },
        },
        datalabels: {
          display: true,
          anchor: 'end',
          align: 'right',
          clip: false,
          color: 'black',
          font: {
            weight: 'bold',
          },
          formatter: function (value) {
            return value?.toFixed(2);
          },
        },
      },
    };
  };

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12 }} component="main">
          <Typography variant="h5" textAlign="center" fontWeight={700} mb={2}>
            Survey Analysis
          </Typography>
          <Paper
            elevation={2}
            className="no-break"
            style={{ padding: '24px', marginBottom: '24px' }}
          >
            <Typography variant="h4" gutterBottom>
              {surveyData?.title}
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              {surveyData?.description}
            </Typography>
            <Box display={'flex'} alignItems={'center'} gap={0.5}>
              <Typography variant="subtitle1" color="primary">
                Total respondents:
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {surveyData?.total_respondents || 0}
              </Typography>
            </Box>
            {selectedFilters?.map(item => (
              <Box display={'flex'} alignItems={'center'} gap={0.5}>
                <Typography variant="subtitle1" color="primary">
                  {`${item?.value?.question?.question}:`}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {item.value.choiseText}
                </Typography>
              </Box>
            ))}
            <Box display={'flex'} alignItems={'center'} gap={0.5}>
              <Typography variant="subtitle1" color="primary">
                Date:
              </Typography>
              {surveyData?.published_on ? (
                <Box display={'flex'} alignItems={'center'} gap={0.4}>
                  <DateRange sx={{ color: 'grey', height: '18px' }} />
                  <Typography variant="subtitle2" color="textSecondary">
                    {FormatDateTime(surveyData?.published_on)} to{' '}
                    {FormatDateTime(surveyData?.end_date) || '--'}
                  </Typography>
                </Box>
              ) : (
                `--`
              )}
            </Box>
          </Paper>
        </Grid2>
      </Grid2>

      {/* Summary Stats */}
      <Grid2 size={{ xs: 12 }} component="main">
        <Grid2 container spacing={3} mb={3}>
          <Grid2 size={{ xs: 3 }} component="main">
            <Card elevation={2} style={{ height: '100%' }}>
              <CardContent style={{ textAlign: 'center' }}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Overall Mean Score
                </Typography>
                <Typography
                  variant="h3"
                  style={{
                    fontWeight: 'bold',
                    color: getScoreColor(
                      allQuestions?.reduce(
                        (sum, q) => sum + q.current_mean,
                        0
                      ) / allQuestions?.length
                    ),
                  }}
                >
                  {(
                    allQuestions?.reduce((sum, q) => sum + q.current_mean, 0) /
                    allQuestions?.length
                  ).toFixed(2)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  out of 5.00
                </Typography>
              </CardContent>
            </Card>
          </Grid2>
          <Grid2 size={{ xs: 3 }} component="main">
            <Card elevation={2} style={{ height: '100%' }}>
              <CardContent style={{ textAlign: 'center' }}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Average Top Box %
                </Typography>
                <Typography
                  variant="h3"
                  style={{
                    fontWeight: 'bold',
                    color: getScoreColor(
                      Math.round(
                        allQuestions?.reduce((sum, q) => sum + q.topBox, 0) /
                          allQuestions?.length
                      ) / 20
                    ),
                  }}
                >
                  {Math.round(
                    allQuestions?.reduce((sum, q) => sum + q.topBox, 0) /
                      allQuestions?.length
                  )}
                  %
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  "Strongly Agree" responses
                </Typography>
              </CardContent>
            </Card>
          </Grid2>

          <Grid2 size={{ xs: 3 }} component="main">
            <Card elevation={2} style={{ height: '100%' }}>
              <CardContent style={{ textAlign: 'center' }}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Average Middle Box %
                </Typography>
                <Typography
                  variant="h3"
                  style={{
                    fontWeight: 'bold',
                    color: getScoreColor(
                      Math.round(
                        allQuestions?.reduce((sum, q) => sum + q.middleBox, 0) /
                          allQuestions?.length
                      ) / 20
                    ),
                  }}
                >
                  {Math.round(
                    allQuestions?.reduce((sum, q) => sum + q.middleBox, 0) /
                      allQuestions?.length
                  )}
                  %
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  "Agree + Neutral + Disagree" responses
                </Typography>
              </CardContent>
            </Card>
          </Grid2>

          <Grid2 size={{ xs: 3 }} component="main">
            <Card elevation={2} style={{ height: '100%' }}>
              <CardContent style={{ textAlign: 'center' }}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Average Bottom Box %
                </Typography>
                <Typography
                  variant="h3"
                  style={{
                    fontWeight: 'bold',
                    color: getBottomScoreColor(
                      Math.round(
                        allQuestions?.reduce((sum, q) => sum + q.bottomBox, 0) /
                          allQuestions?.length
                      ) / 100
                    ),
                  }}
                >
                  {Math.round(
                    allQuestions?.reduce((sum, q) => sum + q.bottomBox, 0) /
                      allQuestions?.length
                  )}
                  %
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  "Strongly Disagree" responses
                </Typography>
              </CardContent>
            </Card>
          </Grid2>
        </Grid2>
      </Grid2>

      <Grid2 container spacing={2}>
        <Grid
          size={{ xs: 12 }}
          component="main"
          style={{ marginBottom: '24px' }}
        >
          <SentimentDistributionCharts allQuestions={allQuestions} />
        </Grid>
        <Grid
          size={{ xs: 12 }}
          component="main"
          style={{ marginBottom: '24px' }}
        >
          <KeyInsightsCard
            allQuestions={allQuestions}
            categories={categories}
          />
        </Grid>
      </Grid2>

      {/* Category Comparison Mean Chart */}
      <Grid2
        size={{ xs: 12 }}
        component="main"
        style={{ marginBottom: '24px' }}
      >
        <Paper
          elevation={2}
          className="no-break"
          style={{
            padding: '24px',
            marginBottom: '24px',
          }}
        >
          <Typography variant="h5" color="primary" mb={2} gutterBottom>
            Category Performance
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <div style={{ height: dynamicHeight, width: '100%' }}>
            <Bar
              data={meanChartData}
              options={chartOptions(
                'Mean Score',
                'Survey Questions Category',
                0,
                5,
                0.5
              )}
            />
          </div>
        </Paper>
      </Grid2>

      {/* Top Box & Bottom Box Chart */}
      <Grid2
        size={{ xs: 12 }}
        component="main"
        style={{ marginBottom: '24px' }}
      >
        <Paper
          elevation={2}
          className="no-break"
          style={{ padding: '24px', marginBottom: '24px' }}
        >
          <Typography variant="h5" color="primary" mb={2} gutterBottom>
            Top Box & Bottom Box Scores
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Box display={'flex'} flexDirection={'column'} mb={2}>
            <Typography variant="caption">
              <strong>Top Box %:</strong> Percentage of respondents selecting
              the highest rating (5 - Excellent/Strongly Agree).
            </Typography>
            <Typography variant="caption">
              <strong>Bottom Box %:</strong> Percentage of respondents selecting
              the lowest rating (1 - Critical/Strongly Disagree).
            </Typography>
          </Box>
          <div style={{ height: dynamicHeight, width: '100%' }}>
            <Bar
              data={topBottomChartData}
              options={chartOptions(
                'Top & bottom Box Score (%)',
                'Survey Questions Category',
                0,
                100,
                10
              )}
            />
          </div>
        </Paper>
      </Grid2>

      {/* Category Tables */}
      {categories?.map(
        (category, index) =>
          category?.questions?.length > 0 && (
            <Grid2 size={{ xs: 12 }} key={category.id} component="main">
              <Paper
                elevation={2}
                className="no-break"
                style={{ padding: '24px', marginBottom: '24px' }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  marginBottom={2}
                  gap={1}
                >
                  <Box>
                    <Typography variant="h5" gutterBottom>
                      {category.category_name}
                    </Typography>
                    {category.category_description && (
                      <Box
                        display="flex"
                        alignItems="center"
                        gap={1}
                        sx={{ mb: 1 }}
                      >
                        <InfoOutlined
                          sx={{ fontSize: 18, color: 'text.secondary' }}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            fontStyle: 'italic',
                            color: 'text.secondary',
                          }}
                        >
                          {category.category_description}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box display="flex" gap={2}>
                    <Chip
                      label={`Mean: ${(
                        category.questions?.reduce(
                          (sum, q) => sum + q.current_mean,
                          0
                        ) / category.questions?.length
                      ).toFixed(2)}`}
                      style={{
                        backgroundColor: getScoreBgColor(
                          category.questions?.reduce(
                            (sum, q) => sum + q.current_mean,
                            0
                          ) / category.questions?.length
                        ),
                        color: getScoreColor(
                          category.questions?.reduce(
                            (sum, q) => sum + q.current_mean,
                            0
                          ) / category.questions?.length
                        ),
                        fontWeight: 'bold',
                      }}
                    />
                    <Chip
                      label={`Top Box: ${Math.round(
                        category.questions?.reduce(
                          (sum, q) => sum + q.topBox,
                          0
                        ) / category.questions?.length
                      )}%`}
                      style={{
                        backgroundColor: getScoreBgColor(
                          Math.round(
                            category.questions?.reduce(
                              (sum, q) => sum + q.topBox,
                              0
                            ) / category.questions?.length
                          ) / 20
                        ),
                        color: getScoreColor(
                          Math.round(
                            category.questions?.reduce(
                              (sum, q) => sum + q.topBox,
                              0
                            ) / category.questions?.length
                          ) / 20
                        ),
                        fontWeight: 'bold',
                      }}
                    />
                    <Chip
                      label={`Bottom Box: ${Math.round(
                        category.questions?.reduce(
                          (sum, q) => sum + q.bottomBox,
                          0
                        ) / category.questions?.length
                      )}%`}
                      style={{
                        backgroundColor: getBottomScoreBgColor(
                          Math.round(
                            category.questions?.reduce(
                              (sum, q) => sum + q.bottomBox,
                              0
                            ) / category.questions?.length
                          ) / 100
                        ),
                        color: getBottomScoreColor(
                          Math.round(
                            category.questions?.reduce(
                              (sum, q) => sum + q.bottomBox,
                              0
                            ) / category.questions?.length
                          ) / 100
                        ),
                        fontWeight: 'bold',
                      }}
                    />
                  </Box>
                </Box>

                <TableContainer>
                  <Table size="small">
                    <TableHead
                      sx={{
                        '& td, & th': {
                          typography: 'body2',
                          fontWeight: 'bold',
                        },
                      }}
                    >
                      <TableRow sx={{ bgcolor: '#f5f5f5' }}>
                        <TableCell width={'500px'}>Question</TableCell>
                        <TableCell align="center" style={{ width: 100 }}>
                          Mean
                        </TableCell>
                        <TableCell align="center" style={{ width: 100 }}>
                          Top Box
                        </TableCell>
                        <TableCell align="center" style={{ width: 100 }}>
                          Bottom Box
                        </TableCell>
                        {/* <TableCell align="center">Distribution</TableCell> */}
                        <TableCell align="left">
                          <Box>
                            Distribution
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              {[1, 2, 3, 4, 5].map(value => (
                                <>
                                  <Box
                                    sx={{
                                      width: 13,
                                      height: 13,
                                      bgcolor: getDistributionColor(value - 1),
                                    }}
                                  />
                                  {value}
                                </>
                              ))}
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {category.questions?.map(question => {
                        const serialNo = serialCounter++; // Increment after assigning the current value

                        return (
                          <TableRow
                            key={question.id}
                            sx={{
                              '&:nth-of-type(even)': {
                                backgroundColor: '#f9f9f9',
                              },
                            }}
                          >
                            <TableCell width={'500px'}>
                              <Typography variant="body2">
                                <strong>
                                  {serialNo < 10
                                    ? `Q00${serialNo}`
                                    : serialNo < 100
                                    ? `Q0${serialNo}`
                                    : `Q${serialNo}`}
                                  :
                                </strong>{' '}
                                {question.question}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Chip
                                label={question.current_mean}
                                size="small"
                                style={{
                                  backgroundColor: getScoreBgColor(
                                    question.current_mean
                                  ),
                                  color: getScoreColor(question.current_mean),
                                  fontWeight: 'bold',
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Chip
                                label={`${question.topBox}%`}
                                size="small"
                                style={{
                                  backgroundColor: getScoreBgColor(
                                    question.topBox / 20
                                  ),
                                  color: getScoreColor(question.topBox / 20),
                                  fontWeight: 'bold',
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Chip
                                label={`${question.bottomBox}%`}
                                size="small"
                                style={{
                                  backgroundColor: getBottomScoreBgColor(
                                    question.bottomBox / 100
                                  ),
                                  color: getBottomScoreColor(
                                    question.bottomBox / 100
                                  ),
                                  fontWeight: 'bold',
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: '100%',
                                  mb: 0.6,
                                }}
                              >
                                {question.frequency?.map((freq, index) => (
                                  <Typography
                                    key={index}
                                    variant="body2"
                                    sx={{ fontWeight: 'bold', mr: 1 }}
                                  >
                                    {index + 1}:{freq.percentage}%
                                  </Typography>
                                ))}
                              </Box>
                              <Box display="flex" width="100%" height={20}>
                                {question.frequency?.map(
                                  (value, i) =>
                                    value.percentage > 0 && (
                                      <Box
                                        key={i}
                                        width={`${value.percentage}%`}
                                        bgcolor={getDistributionColor(i)}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        color="white"
                                        fontSize={10}
                                        fontWeight="bold"
                                      >
                                        {value.percentage > 8
                                          ? `${value.percentage}%`
                                          : ''}
                                      </Box>
                                    )
                                )}
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid2>
          )
      )}
      {/* Legend */}
      <Grid2 size={{ xs: 12 }} component="main">
        <Paper elevation={2} style={{ padding: '24px', marginBottom: '24px' }}>
          <Typography variant="h5" gutterBottom>
            Response Scale Legend
          </Typography>
          <Grid container spacing={2}>
            {[
              { color: '#f44336', label: '1 - Strongly Disagree' },
              { color: '#ff9800', label: '2 - Disagree' },
              { color: '#ffeb3b', label: '3 - Neutral' },
              { color: '#8bc34a', label: '4 - Agree' },
              { color: '#4caf50', label: '5 - Strongly Agree' },
            ].map((item, index) => (
              <Grid item xs={6} sm={4} md={2} key={index}>
                <Box display="flex" alignItems="center">
                  <Box
                    width={16}
                    height={16}
                    bgcolor={item.color}
                    marginRight={1}
                  />
                  <Typography variant="body2">{item.label}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Grid2>
      {/* Glossary */}
      <Grid2 size={{ xs: 12 }} component="main">
        <SurveyGlossary />
      </Grid2>
    </>
  );
};

export default SurveyReportView;
