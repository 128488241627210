import {
  Box,
  Button,
  Container,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  SURVEY_CREATE_NAMED_STEPS,
  SURVEY_CREATE_STEPS,
} from './SurveyCreateConfig';
import StepIconComp, { ColorlibConnector } from './Components/StepIconComp';
import { getValidationSchema, getValidationSchemaNamed } from './validation';

import ConfigureSurveyPageStep from './stepsPages/ConfigureSurveyPageStep';
import { DOCUMENT_UPLOAD_ENUM } from '../../components/survey/surveyParams-config';
import NamedSurveyStep from './stepsPages/NamedSurveyStep';
import PATH from '../../router/paths';
import StartingScheduleStep from './stepsPages/StartingScheduleStep';
import StepsCompletedPage from './stepsPages/StepsCompletedPage';
import SurveyDetailsStep from './stepsPages/SurveyDetailsStep';
import Toast from '../../components/Tost';
import useApiService from '../../hooks/api-services/use-service';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../hooks/custom-hook-functions/useToast';

const SurveyCreate = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState(SURVEY_CREATE_STEPS);
  const { postSurvey, surveyDocumentUpload } = useApiService();
  const [isLoading, setIsLoading] = useState(false);
  const { showToast, toast, setToast, toastMessage, toastType } = useToast();
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [surveySuccessResponse, setSurveySuccessResponse] = useState(null);
  const [fileUploadData, setFileUploadData] = useState({
    document_type: DOCUMENT_UPLOAD_ENUM.SURVEY,
    file_name: null,
  });

  const surveyFormik = useFormik({
    initialValues: {
      title: '',
      description: '',
      is_anonymous: '',
      status: 'draft',
      target_audience: 'all',
      is_active: true,
      survey_logo: '',
      banner_image: '', // here goes the ID that we will generate from the image upload
      survey_display_type: '', // Single Form, Multi Steps
      multi_step_style: '', // question_category, one_question_per_step
      is_respondent_details: '',
      is_respondent_name: '',
      is_respondent_email: '',
      is_respondent_phone: '',
      is_respondent_gender: '',
      startOption: 'immediately',
      start_date: '',
      end_date: '',
      is_score: false,
    },
    enableReinitialize: true,
    validate: values => {
      const schema = values.is_anonymous
        ? getValidationSchema(activeStep)
        : getValidationSchemaNamed(activeStep);
      return schema(values);
    },
    onSubmit: values => {
      handleCreateSurvey(values);
    },
  });

  const excludeFields = ['startOption', 'is_respondent_details', 'survey_logo'];

  const handleFileUpload = async () => {
    try {
      setFileUploadLoading(true);
      let fileUploadFormData = new FormData();
      if (fileUploadData.file_name) {
        Object.keys(fileUploadData).forEach(key => {
          fileUploadFormData.append(key, fileUploadData[key]);
        });
        const response = await surveyDocumentUpload(fileUploadFormData);
        setFileUploadLoading(false);
        return response.data;
      }
    } catch (err) {
      setFileUploadLoading(false);
      showToast('Error uploading your image file', 'error');
    }
  };

  const handleCreateSurvey = async values => {
    try {
      setIsLoading(true);
      let formData = new FormData();

      Object.keys(values).forEach(key => {
        if (!excludeFields.includes(key)) {
          formData.append(key, values[key]);
        }
      });

      if (fileUploadData.file_name) {
        const uploadResult = await handleFileUpload();
        if (uploadResult) {
          formData.append('banner_image', uploadResult.id);
        }
      } else {
        formData.append('banner_image', '');
      }

      const response = await postSurvey(formData);
      if (response.status === 201 || response.status === 200) {
        showToast('Survey created successfully', 'success');
        setSurveySuccessResponse(response.data);
        handleNext();
      } else {
        showToast('Error creating survey', 'error');
      }
    } catch (error) {
      showToast('Error creating survey', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleNext = async () => {
    const errors = await surveyFormik.validateForm();
    if (Object.keys(errors).length === 0) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    } else {
      surveyFormik.setTouched(errors);
    }
  };

  const handleNextAndSubmit = async () => {
    if (activeStep === steps.length - 1) {
      surveyFormik.handleSubmit();
    } else {
      await handleNext();
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setFileUploadData({
      document_type: DOCUMENT_UPLOAD_ENUM.SURVEY,
      file_name: null,
    }); // clear file upload data
    surveyFormik.resetForm();
  };

  const surveyTypeValue = surveyFormik.values.is_anonymous;
  useEffect(() => {
    if (surveyTypeValue === false) {
      setSteps(SURVEY_CREATE_NAMED_STEPS);
    } else {
      setSteps(SURVEY_CREATE_STEPS);
    }
  }, [surveyTypeValue]);

  useEffect(() => {
    const handleBeforeUnload = event => {
      event.preventDefault();
      event.returnValue =
        'Are you sure you want to reload? All progress will be lost.';
    };

    const handleLoad = () => {
      const navigationEntry =
        window.performance.getEntriesByType('navigation')[0];
      if (navigationEntry && navigationEntry.type === 'reload') {
        navigate(PATH.CREATE_SURVEY);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  const getActiveStepComponent = activeStep => {
    if (surveyTypeValue === false) {
      switch (activeStep) {
        case 0:
          return <SurveyDetailsStep formik={surveyFormik} />;
        case 1:
          return <NamedSurveyStep formik={surveyFormik} />;
        case 2:
          return (
            <ConfigureSurveyPageStep
              formik={surveyFormik}
              fileUploadData={fileUploadData}
              setFileUploadData={setFileUploadData}
            />
          );
        case 3:
          return <StartingScheduleStep formik={surveyFormik} />;
        default:
          return null;
      }
    } else {
      switch (activeStep) {
        case 0:
          return <SurveyDetailsStep formik={surveyFormik} />;
        case 1:
          return (
            <ConfigureSurveyPageStep
              formik={surveyFormik}
              fileUploadData={fileUploadData}
              setFileUploadData={setFileUploadData}
            />
          );
        case 2:
          return <StartingScheduleStep formik={surveyFormik} />;
        default:
          return null;
      }
    }
  };

  return (
    <Box sx={{ my: 'calc(100% - 98%)' }}>
      <Container
        maxWidth="lg"
        sx={{
          pt: 5,
          pb: 5,
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
          backgroundColor: 'white',
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem',
            }}
          >
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              connector={<ColorlibConnector />}
            >
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel
                    StepIconComponent={props => (
                      <StepIconComp {...props} surveyType={surveyTypeValue} />
                    )}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {activeStep === steps.length ? (
                <StepsCompletedPage
                  handleReset={handleReset}
                  surveySuccessResponse={surveySuccessResponse}
                />
              ) : (
                <form onSubmit={surveyFormik.handleSubmit}>
                  {getActiveStepComponent(activeStep)}
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={
                        activeStep === 0 || isLoading || fileUploadLoading
                      }
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                      variant="outlined"
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button
                      onClick={handleNextAndSubmit}
                      disabled={isLoading || fileUploadLoading}
                      variant="contained"
                      type={activeStep === steps.length ? 'submit' : 'button'}
                    >
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  </Box>
                </form>
              )}
            </div>
          </Box>
        </Box>
      </Container>
      {toast && (
        <Toast
          setToast={setToast}
          isLoading={isLoading}
          toast={toast}
          toastType={toastType}
          toastMessage={toastMessage}
        />
      )}
    </Box>
  );
};

export default SurveyCreate;
