import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography, } from "@mui/material";
import { Dropdown, DropdownMenuItem, DropdownNestedMenuItem } from "./NestedGenericDropDown";

import CheckIcon from '@mui/icons-material/Check';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

export const DynamicDropDown = (props) => {
    const { filteredQuestions, handleAddFilter, filter } = props;

    const getDynamicChoices = (question)=> {
        return question?.choices || question?.answers || [];
    }
    return (
        <Dropdown
            trigger={<Button variant="outlined" endIcon={<UnfoldMoreIcon />}
                sx={{
                    backgroundColor: "#fefefe", // Modern white
                    color: "#000000", // black text color
                    '&:hover': {
                        backgroundColor: "#fefefe", // Darker red on hover
                    },
                }}>Select Filter</Button>}
            menu={
                 filteredQuestions?.map((question) => (
                    <DropdownNestedMenuItem
                        key={question.id}
                        label={question.question}
                        rightIcon={<KeyboardDoubleArrowRightIcon />}
                        menu={getDynamicChoices(question)?.map((choice) => (
                            <DropdownMenuItem key={choice?.id || choice?.choice_id}
                                onClick={() => {
                                    handleAddFilter({
                                        questionId: question?.id || question?.question_id,
                                        choiseId: choice?.id ||choice?.choice_id,
                                        choiseText: choice?.choise_text || choice?.choice_text,
                                        question: question
                                    })
                                }}
                            >
                            {
                                question?.question_type === "multiple_choice" ?
                                  <FormGroup>
                                  <FormControlLabel control={<Checkbox checked={filter.map((f) => f.value.choiseId).includes(choice?.id || choice?.choice_id)}/>}
                                   label={choice?.choise_text || choice?.choice_text} />
                                    
                                  </FormGroup> :
                            <Box sx={{  display: "flex", gap: "0.5rem", alignItems: "center" }}>
                                <Typography variant="body1">{choice?.choise_text || choice?.choice_text}</Typography>
                                {filter.map((f) => f.value.choiseId).includes(choice?.id || choice?.choice_id) && <CheckIcon/>} 
                            </Box> }
                            </DropdownMenuItem>
                        ))}
                    />
                ))
            }
        />
    )
}

