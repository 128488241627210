import { Container, List, ListItem, ListItemText, Typography } from '@mui/material';

import React from 'react';

const Section = ({ title, content }) => (
  <div>
    <Typography variant="h4" gutterBottom>
      {title}
    </Typography>
    {Array.isArray(content) ? (
      <List sx={{ "& .MuiListItemText-root > span": { fontSize: "18px !important",  } }}>
        {content.map((item, index) => (
          <ListItem key={index}>
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
    ) : (
      <Typography variant="body1" sx={{ fontSize: "18px" }} paragraph>
        {content}
      </Typography>
    )}
  </div>
);

const PrivacyPolicy = () => {
  return (
    <Container style={{ padding: '20px' }}>
      <Typography variant="h3" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="body1" sx={{ fontSize: "18px", marginBottom: "2rem" }} paragraph>
        This Privacy Policy explains how our website collects, uses, and protects your personal information.
      </Typography>
      
      <Section 
        title="Information We Collect" 
        content={[
          "Personal identification information (Name, email address, phone number, etc.)",
          "Usage data (Pages visited, time spent on the site, etc.)",
          "Cookies and tracking technologies"
        ]}
      />
      
      <Section 
        title="How We Use Your Information" 
        content={[
          "To provide and maintain our service",
          "To notify you about changes to our service",
          "To provide customer support",
          "To gather analysis or valuable information so that we can improve our service",
          "To monitor the usage of our service",
          "To detect, prevent and address technical issues"
        ]}
      />
      
      <Section 
        title="How We Protect Your Information" 
        content="We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information."
      />
      
      <Section 
        title="Sharing Your Information" 
        content="We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential."
      />
      
      <Section 
        title="Changes to This Privacy Policy" 
        content="We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes."
      />
      
      <Section 
        title="Contact Us" 
        content="If you have any questions about this Privacy Policy, please contact us at support@example.com."
      />
    </Container>
  );
}

export default PrivacyPolicy;