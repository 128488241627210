import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Radio,
  RadioGroup,
  Rating,
  Select,
  Slider,
  TextField,
  Typography,
} from '@mui/material';
import React, { memo, useState } from 'react';

const RenderAnswerView = memo(({ question, answer }) => {
  const [selectedAnswers, setSelectedAnswers] = useState({
    question: question?.id,
    text_answer: '',
    choice_answer: null,
    multiple_choices: [],
    rating_answer: null,
    date_answer: null,
    time_answer: null,
    slider_value: null,
    yes_no_answer: null,
  });

  switch (question?.question_type) {
    case 'text':
      const calculateRows = text => {
        const charPerLine = 50; // Approximate number of characters per line
        const lines = Math.ceil(text.length / charPerLine);
        return Math.min(lines, 10); // Limit to a maximum of 10 rows
      };
      const rows = calculateRows(answer || 'No answer provided');
      return (
        <TextField
          fullWidth
          disabled
          multiline
          minRows={rows}
          maxRows={rows}
          value={answer || 'No answer provided'}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />
      );
    case 'multi_line_text':
      return (
        <TextField
          fullWidth
          disabled
          value={answer || 'No answer provided'}
          InputProps={{
            readOnly: true,
          }}
          multiline={true}
          variant="outlined"
        />
      );
    case 'number':
      return (
        <TextField
          type="number"
          fullWidth
          disabled
          value={answer || 'No answer provided'}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />
      );
    case 'multiple_choice':
      return (
        <FormGroup>
          {question?.choice?.map((option, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={answer.includes(option.choice_text)}
                  disabled
                />
              }
              label={option.choice_text}
            />
          ))}
        </FormGroup>
      );
    case 'radio':
      return (
        <FormControl>
          <RadioGroup value={answer || ''}>
            {question?.choice?.map(option => (
              <FormControlLabel
                key={option.id}
                value={option.choice_text}
                control={<Radio disabled />}
                label={option.choice_text}
              />
            ))}
          </RadioGroup>
        </FormControl>
      );
    case 'rating':
      return (
        <Rating
          value={answer || 0}
          readOnly
          size="large"
          max={question.ratings_max_value || 5}
        />
      );
    case 'yes_no':
      return (
        <FormControl fullWidth>
          <RadioGroup row value={answer || ''}>
            <FormControlLabel
              value={true}
              control={<Radio disabled />}
              label="Yes"
            />
            <FormControlLabel
              value={false}
              control={<Radio disabled />}
              label="No"
            />
          </RadioGroup>
        </FormControl>
      );
    case 'dropdown':
      return (
        <Select value={answer || ''} displayEmpty disabled fullWidth>
          <MenuItem value="" disabled>
            Select an option
          </MenuItem>
          {question?.choice?.map(option => (
            <MenuItem key={option.id} value={option.choice_text}>
              {option.choice_text}
            </MenuItem>
          ))}
        </Select>
      );
    case 'date':
      return (
        <TextField
          type="date"
          fullWidth
          disabled
          value={answer || ''}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />
      );
    case 'time':
      return (
        <TextField
          type="time"
          fullWidth
          disabled
          value={answer || ''}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />
      );
    case 'file_upload':
      return (
        <Typography variant="body1">
          {answer ? 'File uploaded' : 'No file uploaded'}
        </Typography>
      );
    case 'slider':
      return (
        <Box display="flex" alignItems="center">
          <Slider
            value={answer || 0}
            min={question.slider_min_value || 0}
            max={question.slider_max_value || 100}
            step={question.slider_step_size || 1}
            valueLabelDisplay="auto"
            disabled
          />
          <Typography variant="body1" sx={{ ml: 2 }}>
            {answer}
          </Typography>
        </Box>
      );
    default:
      return null;
  }
});

export default React.memo(RenderAnswerView);
