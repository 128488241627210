import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import PropTypes from 'prop-types'
import React from "react";

const ConfirmationBox = ({
  children,
  open = false,
  close= () => {},
  handleClose = () => {},
  headerText = "",
  handleAction = () => {},
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ m: 0, p: 2 }}>{headerText}</DialogTitle>
      {close && (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions sx={{marginRight : '4%'}}>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAction} color="error" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationBox;

ConfirmationBox.propTypes = {
  children: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  headerText: PropTypes.string,
  handleAction: PropTypes.func,
  loading: PropTypes.bool,
};
