import { capitalizeFirstCharacter, epochTimeToDate } from '../../utils/helpers';

import FormatDateTime from '../Functions/FormatDateTime';

export const DOCUMENT_UPLOAD_ENUM = {
  ORGANIZATION: 'organization',
  SURVEY: 'survey',
  QUESTION: 'question',
  ANSWER: 'answer',
  OTHERS: 'others',
};

export const SURVEY_STATUS_TYPE = {
  DRAFT: 'draft',
  LIVE: 'published',
  PAUSED: 'paused',
  COMPLETED: 'completed',
  ARCHIVE: 'archive',
};

export const getSurveyAnalysisViewDetails = surveyDetailsData => {
  if (!Object.keys(surveyDetailsData).length) return [];
  const surveyOverviewDetails = [
    {
      title: 'Survey Title',
      value: capitalizeFirstCharacter(surveyDetailsData?.title) || '--',
    },
    {
      title: 'Start Date',
      value: FormatDateTime(surveyDetailsData?.start_date) || '--',
    },
    // {
    //     title: "Description",
    //     value: capitalizeFirstCharacter(surveyDetailsData?.description) || "--",
    // },
    {
      title: 'Survey Type',
      value:
        `${surveyDetailsData?.is_anonymous ? 'Anonymous' : 'Named'} Survey` ||
        '--',
    },
    {
      title: 'No of Questions',
      value: surveyDetailsData?.questions?.length || '--',
    },
    {
      title: 'Survey Status',
      value: capitalizeFirstCharacter(surveyDetailsData?.status) || '--',
    },
  ];

  return surveyOverviewDetails;
};

export const getSurveyAnalysisUserDetails = surveyDetailsData => {
  if (!Object.keys(surveyDetailsData).length) return [];
  const surveyUserOverviewDetails = [
    {
      title: 'Total Respondents',
      value: surveyDetailsData?.total_respondents || '--',
    },
    {
      title: 'Avg Time Spent',
      value: epochTimeToDate(surveyDetailsData?.avg_response_time) || '--',
    },
  ];
  return surveyUserOverviewDetails;
};

// Function to check is survey is editable or not
export const checkEditSurveyQuestion = (
  status,
  isScore,
  isFilterQuestion,
  questionType
) => {
  // For score questions: must have specific question type and not be a filter question
  if (
    ['multiple_choice', 'radio', 'dropdown'].includes(questionType) &&
    isFilterQuestion === false &&
    isScore === true
  ) {
    return true;
  }

  if (status === 'draft') {
    return true;
  }

  return false;
};
