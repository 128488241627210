import * as React from 'react';

import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';

import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

const PublishSurveyDialog = styled(Dialog)(({ theme }) => ({
  display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
}));

export function SurveyPublishedModel(props) {
    const { open, onClose, handlePushlishSurvey, handlePreviewAndPublish } = props;
    const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState(false);


  const handleClose = () => {
    onClose(false);
  };

  const handlePublishOnly = ()=>{
    if (!isChecked) {
      setError(true);
      return;
    }
    handlePushlishSurvey();
    handleClose();
  }

  const handlePreview = () =>{
    handlePreviewAndPublish()
    handleClose();
  }

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setError(false);
  };

  return (
    <React.Fragment>
      <PublishSurveyDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
      <Box sx={{ maxWidth: "500px", margin: "auto", display: "flex", flexDirection: "column",
       gap: "0.5rem", justifyContent: "center", alignItems: "center", padding: "1rem",}}>
       <Box sx={{ background: "#E0E8FF", borderRadius: "50px", display: "flex", padding: "0.5rem" }}>
      <NewReleasesOutlinedIcon sx={{ fontSize: 60, color: "#5335E9", pt:2,
      "&.MuiSvgIcon-root":{
        padding: "0px",
        width: "0.5em",
        height: "0.5em",
      } }} />
       </Box>
        <Typography variant='h6' sx={{ m: 0, px: 2 }} id="customized-dialog-title">
          You are about to publish this survey
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 14,
            color: theme.palette.grey[500],
            borderRadius: "30px",
            padding: "3px",
            border: "1px solid #ccc",
          })}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant='subtitle2' sx={{ marginTop: 2, px: 2, textAlign: "center" }} color='textSecondary'>
        Note: Survey once published will be in locked mode and its questions can't be edited. Please make sure to verify everything on the preview page before publishing.
        </Typography>
          <FormControl required error={error} component="fieldset" sx={{ mt: 2, px: "1rem", }}>
            <FormControlLabel
              control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
              label="I understand published surveys can't be edited."
              sx={{ '& .MuiFormControlLabel-label': { 
                marginRight: 0,
                fontSize: '14px', color: "rgba(0, 0, 0, 0.6)" } }}
            />
            {error && <FormHelperText sx={{ marginTop: 0, marginRight: 0, marginLeft: "78px" }}
            >You must agree before publishing.</FormHelperText>}
          </FormControl>
        </Box>
        <DialogActions sx={{ background: "#F7F7F7", width: "100%", display: "flex" }}>
          <Button autoFocus onClick={handleClose} variant='contained'
          startIcon={<PreviewOutlinedIcon />}
            sx={{
              backgroundColor: "#9e9e9e",
              color: "white",
              "&:hover": {
                backgroundColor: "#bdbdbd",
              },
            }}>
          Continue Editing 
          </Button>
          <Button autoFocus onClick={handlePreview} variant='contained'
          startIcon={<PreviewOutlinedIcon />}
            sx={{
                backgroundColor: "#080841",
                color: "#fff",
                "&:hover": {
                    backgroundColor: "#3949ab",
                },
            }}>
            Preview
          </Button>
          <Button autoFocus onClick={()=>handlePublishOnly()} variant='contained'
          startIcon={<PublishedWithChangesOutlinedIcon />}
            sx={{
                backgroundColor: "#d50000",
                color: "#fff",
                "&:hover": {
                    backgroundColor: "#c62828",
                },
            }}>
            Publish Survey
          </Button>
        </DialogActions>
      </PublishSurveyDialog>
    </React.Fragment>
  );
}

export default SurveyPublishedModel;