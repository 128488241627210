import { Box, Button, Step, StepContent, Stepper, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';

import RenderAnswerType from '../../components/RenderAnswerType';
import { getMediaType } from '../../utils/helpers';

const MultiStepsSingleQuest = (props) => {
    const { questions, finalDataArry, setFinalDataArry, formErrors, setIsSingleMultiStepFinish, validateAnswers, setErrors } = props
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const { hasError, newErrors } = validateAnswers([questions[activeStep]], finalDataArry);
    if (hasError) {
      setErrors(newErrors);
      return
    }
    if (!hasError) {
      setErrors({});
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(()=>{
    if (activeStep === questions.length-1) {
        setIsSingleMultiStepFinish(true);
    } else {
        setIsSingleMultiStepFinish(false);
    }
  },[activeStep])

  const handleReset = () => {
    setActiveStep(0);
  };

  const isSmallScreen = useMediaQuery('(max-width:600px)');
   const maxWidth = isSmallScreen ? '100%' : '50%';

  return (
    <Box sx={{ maxWidth:"90%", margin: 'auto', mt: 4 }}>
      <Stepper  alternativeLabel activeStep={activeStep} orientation='vertical'>
        {questions?.map((question, index) => (
          <Step key={index} sx={{ width: '100%' }}>
            <StepContent sx={{ width: '100%' }}>
              <Typography variant="subtitle1" fontWeight={700} sx={{ minWidth: "fit-content" }}>
                {index + 1}. {"  "} {question.question} {" "}
                {question?.required && <span style={{ color: "gray", fontSize: "12px", fontWeight: "400" }}>(Required)</span>}{" "}
              </Typography>{" "}
              {/* Render the question component here */}

              {question?.video_or_image_url && (
                        <Box sx={{ maxWidth: maxWidth, mt: 1, mb: 1, ml: 2, }}>
                          {getMediaType(`${question?.video_or_image_url}`) === 'image' ? (
                            <img
                              src={question?.video_or_image_url}
                              alt="Question"
                              width="100%"
                              height={300}
                              style={{ borderRadius: "12px", objectFit: "cover" }}
                            />
                          ) : (
                            <video width="100%" height={300} controls>
                              <source src={`${question?.video_or_image_url}`} type={`video/${question?.image_video.split('.').pop()}`} />
                            </video>
                          )}
                        </Box>
                      )}


              <Box sx={{ ml: 2, mt: 1}}>
                    <RenderAnswerType
                        question={question}
                        setFinalDataArry={setFinalDataArry}
                        finalDataArry={finalDataArry}
                        formErrors={formErrors}
                    />
                    </Box>
              <Box sx={{ my: 2, width: '100%' }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                    variant='outlined'
                  >
                    Back
                  </Button>
                  
                 {index !== questions.length - 1 && <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === questions.length - 1 ? 'Finish' : 'Next'}
                  </Button>}
                </Box>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === questions.length && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" gutterBottom>
            All steps completed - you're finished
          </Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default MultiStepsSingleQuest;