import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  CalendarMonth as CalendarMonthIcon,
  Leaderboard as LeaderboardIcon,
  ViewList as ViewListIcon,
} from '@mui/icons-material';
import { Container, Stack } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import { epochTimeToDate, underscoreToSpaceString } from '../../utils/helpers';
import { useNavigate, useParams } from 'react-router-dom';

import ActionButtonCell from '../TableComponents/TableCells/ActionButtonCell';
import { CustomTable } from '../TableComponents/CustomTable';
import DateCell from '../TableComponents/TableCells/DateCell';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DynamicDropDown } from '../../styles/styledComponent/DropDownStyled';
import EmailPhoneCell from '../TableComponents/TableCells/EmailPhoneCell';
import FilterListIcon from '@mui/icons-material/FilterList';
import NameCell from '../TableComponents/TableCells/NameCell';
import NormalCell from '../TableComponents/TableCells/NormalCell';
import SearchIcon from '@mui/icons-material/Search';
import Toast from '../Tost';
import { format } from 'date-fns';
import useApiService from '../../hooks/api-services/use-service';
import { useTitle } from '../../contexts/TitleContext';
import { useToast } from '../../hooks/custom-hook-functions/useToast';
import ExportModal from '../Models/ExportModel';
import SystemUpdateAltTwoToneIcon from '@mui/icons-material/SystemUpdateAltTwoTone';

const formatDate = date => {
  return format(new Date(date), 'dd-MMM-yyyy');
};

const ResponseCollection = () => {
  const navigate = useNavigate();
  const { surveyId } = useParams();
  // SURVEY ID
  const decodedSurveyId = atob(surveyId).split('_')[1];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');

  const [excelDataLoading, setExcelDataLoading] = useState(false);
  const [isExport, setIsExport] = useState(false);

  const {
    getFullSurveyResponse,
    getSurveyById,
    getSurveyResponsesExcelData,
    getSurveyExecutiveSummary,
  } = useApiService();

  const [isloadingData, setIsloading] = useState(false);
  // USING TOAST FOR ALERTS SHOWING
  const { setToast, showToast, toast, toastMessage, toastType } = useToast();

  // GET SURVEY ONLY DATA NOT IT"S SUBMITED RESPONSES
  const [surveyData, setSurveyData] = useState();

  // SURVEY USER SUBMITTE RESPONSE DATA
  const [surveySubmitResponseData, setSurveySubmitResponseData] = useState({
    responses: [],
    overall_respondents: null,
  });

  const [filter, setFilter] = useState([]);
  const [apiFilterString, setApiFilterString] = useState('');

  const theme = useTheme();
  const isXtraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallToMediumScreen = useMediaQuery(
    theme.breakpoints.between('xs', 'md')
  );

  const initialRender = useRef(true);

  const handleDeleteChip = category => {
    setFilter(prev =>
      prev.filter(item => {
        if (category.value.question.question_type === 'multiple_choice') {
          return item.value.choiseId !== category.value.choiseId;
        } else {
          return item.value.questionId !== category.value.questionId;
        }
      })
    );
  };

  const handleClearFilters = () => {
    setFilter([]);
  };

  const handleAddFilter = value => {
    setFilter(prev => {
      // Check if the value already exists in the filter
      const exists =
        prev.some(item => item.value.choiseText === value.choiseText) &&
        value?.question?.question_type !== 'multiple_choice';
      if (exists) {
        return prev; // Return the previous state if the value already exists
      }

      // Remove any existing choice from the same question
      const updatedFilter = prev.filter(item => {
        if (value?.question?.question_type === 'multiple_choice') {
          return item.value.choiseId !== value.choiseId;
        } else {
          return item.value.questionId !== value.questionId;
        }
      });

      // Add the new choice
      return [...updatedFilter, { value }];
    });
  };

  useEffect(() => {
    if (filter.length === 0) {
      setApiFilterString('');
    }
  }, [filter]);

  const handleExportToExcel = async () => {
    try {
      setExcelDataLoading(true);
      const data = { is_csv: 'True' };
      const response = await getSurveyResponsesExcelData(decodedSurveyId, data);
      if (response.status === 200) {
        const data = new Blob([response.data], { type: 'text/csv' });
        const url = URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Survey Response Report';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      setExcelDataLoading(false);
    } finally {
      setExcelDataLoading(false);
    }
  };

  const handleExport = async (type, questionId, decodedSurveyId) => {
    try {
      setExcelDataLoading(true);
      const data =
        type === 'excel'
          ? { is_excel: 'True' }
          : type === 'excel_with_score'
          ? { is_score_excel: 'True' }
          : { filter_question_id: questionId };

      const filterQuestion = surveyData?.filteredQuestions?.filter(
        item => item.id === Number(questionId)
      )[0];

      const response =
        type === 'executive_summary'
          ? await getSurveyExecutiveSummary(decodedSurveyId, data)
          : await getSurveyResponsesExcelData(decodedSurveyId, data);
      if (response.status === 200) {
        let filename =
          type === 'excel'
            ? `${surveyData?.title}.xlsx`
            : type === 'excel_with_score'
            ? `${surveyData?.title}/Score.xlsx`
            : `${surveyData?.title}:${filterQuestion?.question}.xlsx`;
        const contentDisposition = response.headers.get
          ? response.headers.get('content-disposition')
          : response.headers['content-disposition'];
        if (contentDisposition) {
          const match = contentDisposition.match(/filename="(.+)"/);
          if (match) filename = match[1].replace(/\s/g, '_');
        }
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        setIsExport(false);
      }
    } catch (error) {
      console.error('Error exporting survey responses:', error);
    } finally {
      setExcelDataLoading(false);
    }
  };

  const { setTitle } = useTitle();

  useEffect(() => {
    if (decodedSurveyId) {
      setLoading(true);
      getSurveysData(decodedSurveyId);
    }
  }, [decodedSurveyId]);

  const getSurveysData = async (decodedSurveyId, filterString) => {
    try {
      setIsloading(true);
      let fullSurveySubmitResponse;
      if (filterString) {
        fullSurveySubmitResponse = await getFullSurveyResponse(
          decodedSurveyId,
          true,
          filterString
        );
      } else {
        fullSurveySubmitResponse = await getFullSurveyResponse(decodedSurveyId);
      }
      const surveyDataResponse = await getSurveyById(decodedSurveyId);

      setSurveySubmitResponseData(prev => {
        return {
          ...prev,
          responses: fullSurveySubmitResponse.data.responses.filter(
            value => value.time_spent_seconds !== null
          ),
          overall_respondents:
            fullSurveySubmitResponse.data.overall_respondents,
        };
      });
      const filteredQuestionData =
        surveyDataResponse &&
        surveyDataResponse?.data?.questions.filter(
          question =>
            question?.is_filter_question === true &&
            question?.question_type !== 'yes_no'
        );
      setSurveyData({
        ...surveyDataResponse.data,
        filteredQuestions: filteredQuestionData,
      });
      setTitle(surveyDataResponse?.data?.title);
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
      showToast('Something went wrong. Please try again!', 'error');
    } finally {
      setLoading(false);
    }
  };

  const getHeaders = isAnonymous => {
    const allHeaders = [
      {
        name: 'serial_no',
        displayName: 'S.No',
        width: `${100 / 11}%`,
        cell: ({ row, index }) => (
          <NormalCell row={{ ...row, text: index + 1 }} />
        ),
      },
      {
        name: 'respondent',
        displayName: 'Respondent',
        width: `${100 / 11}%`,
        cell: ({ row }) => <NameCell row={{ ...row }} />,
      },
      {
        name: 'ip_address',
        displayName: 'IP Address',
        width: `${100 / 11}%`,
        cell: ({ row }) => (
          <NormalCell row={{ ...row, text: row.respondent_ip_address }} />
        ),
      },
      {
        name: 'gender',
        displayName: 'Gender',
        width: `${100 / 11}%`,
        cell: ({ row }) => (
          <NormalCell
            row={{ ...row, text: underscoreToSpaceString(row.gender) }}
          />
        ),
      },
      {
        name: 'email-phone',
        displayName: 'Email/Phone',
        width: `${100 / 11}%`,
        cell: ({ row }) => <EmailPhoneCell row={{ ...row }} />,
      },
      {
        name: 'date',
        displayName: 'Date',
        width: isAnonymous ? `${100 / 7}%` : `${100 / 11}%`,
        cell: ({ row }) => <DateCell row={{ ...row, text: row.created_at }} />,
      },
      {
        name: 'timeSpent',
        displayName: 'Time Spent',
        width: isAnonymous ? `${100 / 7}%` : `${100 / 11}%`,
        cell: ({ row }) => (
          <NormalCell
            row={{ ...row, text: epochTimeToDate(row?.time_spent_seconds) }}
          />
        ),
      },
      {
        name: 'completion_rate',
        displayName: 'Completion Rate',
        width: isAnonymous ? `${100 / 7}%` : `${100 / 11}%`,
        cell: ({ row }) => (
          <NormalCell
            row={{
              ...row,
              text: row?.completion_rate
                ? `${Math.floor(row?.completion_rate)?.toFixed(1)}%`
                : 'N/A',
            }}
          />
        ),
      },
      {
        name: 'lastActivity',
        displayName: 'Last Activity',
        width: isAnonymous ? `${100 / 7}%` : `${100 / 11}%`,
        cell: ({ row }) => (
          <DateCell
            row={{ ...row, text: row.updated_at, lastActivity: true }}
          />
        ),
      },
      {
        name: 'action',
        displayName: 'Action',
        width: isAnonymous ? `${100 / 7}%` : `${100 / 11}%`,
        cell: ({ row }) => (
          <ActionButtonCell
            row={{ row }}
            surveyId={surveyId}
            buttonIcon={<ViewListIcon fontSize="small" />}
            buttonLabel={'View Details'}
          />
        ),
      },
    ];

    const anonymousHeaders = [
      ...allHeaders.slice(0, 1),
      ...allHeaders.slice(5),
    ];

    return isAnonymous ? anonymousHeaders : allHeaders;
  };

  const headers = getHeaders(surveyData?.is_anonymous);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleApplyFilter = () => {
    const choiceIds = filter.reduce((acc, curr, index) => {
      return `${acc}${curr.value.choiseId}${
        index === filter.length - 1 ? '' : ','
      }`;
    }, '');
    setApiFilterString(`?choice_filter=${choiceIds}`);
  };

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    if (apiFilterString) {
      getSurveysData(decodedSurveyId, apiFilterString);
    } else if (apiFilterString === '' && filter.length === 0) {
      getSurveysData(decodedSurveyId);
    }
  }, [apiFilterString]);

  console.log('surveyData', surveyData);

  return (
    <Container maxWidth="fuild" sx={{ mt: 6 }}>
      <Box sx={{ mb: 4, px: 4 }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Box sx={{ maxWidth: '90%' }}>
            <Typography variant="h4">
              <strong>Survey Responses: </strong>
              {surveyData?.title}
              {surveyData?.is_anonymous && (
                <Chip
                  label="Anonymous"
                  size="small"
                  sx={{
                    ml: 1,
                    backgroundColor: 'purple',
                    color: '#fff',
                    fontSize: '1rem',
                  }}
                />
              )}
            </Typography>
          </Box>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="contained"
              sx={{ fontSize: 11 }}
              disabled={excelDataLoading}
              startIcon={<SystemUpdateAltTwoToneIcon fontSize="small" />}
              onClick={() => setIsExport(true)}
            >
              Export
            </Button>
            <ExportModal
              isOpen={isExport}
              filterQuestions={surveyData?.filteredQuestions}
              onClose={() => setIsExport(false)}
              onExport={(type, questionId) =>
                handleExport(type, questionId, decodedSurveyId)
              }
              loading={excelDataLoading}
            />
            <Button
              variant="contained"
              sx={{ fontSize: 11 }}
              startIcon={<LeaderboardIcon fontSize="small" />}
              onClick={() => navigate(`/anaylze-responses/${surveyId}`)}
            >
              View Analysis
            </Button>
            {surveyData?.is_score && (
              <Button
                variant="contained"
                sx={{ fontSize: 11 }}
                startIcon={<LeaderboardIcon fontSize="small" />}
                onClick={() => navigate(`/anaylze-responses-score/${surveyId}`)}
              >
                View Analytics With Score
              </Button>
            )}
          </Stack>
        </Box>
        <Box mb={2}>
          {surveyData?.description && (
            <Typography variant="subtitle1" marginBottom={2}>
              <strong>Description:</strong> {surveyData?.description}
            </Typography>
          )}
          <Stack direction={'row'} alignItems={'center'} spacing={0.6}>
            <CalendarMonthIcon style={{ width: '16px', height: '16px' }} />
            <Typography variant="subtitle1">
              {surveyData?.updated_at ? (
                <>
                  <strong>Last Updated Date:</strong>{' '}
                  {surveyData?.updated_at
                    ? formatDate(surveyData?.updated_at)
                    : '--'}
                </>
              ) : (
                <>
                  <strong>Published Date:</strong>{' '}
                  {surveyData?.published_on
                    ? formatDate(surveyData?.published_on)
                    : '--'}
                </>
              )}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={3}>
            {(surveyData?.start_date || surveyData?.published_on) && (
              <Typography variant="subtitle1">
                <strong>Starts On:</strong>{' '}
                {surveyData?.start_date
                  ? formatDate(surveyData?.start_date)
                  : surveyData?.published_on &&
                    formatDate(surveyData?.published_on)}
              </Typography>
            )}
            {surveyData?.end_date && (
              <Typography variant="subtitle1">
                <strong>Ends On:</strong>{' '}
                {surveyData?.end_date && formatDate(surveyData?.end_date)}
              </Typography>
            )}
          </Stack>
          <Typography variant="subtitle1">
            <strong>Total Responses: </strong>
            {surveySubmitResponseData?.responses
              ? surveySubmitResponseData?.responses?.length
              : 0}
          </Typography>
        </Box>

        {surveyData?.filteredQuestions?.length === 0 ||
        surveySubmitResponseData?.overall_respondents ===
          0 ? null : surveyData?.filteredQuestions &&
          surveyData?.filteredQuestions?.length ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: isSmallToMediumScreen ? 'column' : 'row',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              mb: 2,
              gap: '0.5rem',
              border: '1px solid #AFB9C1',
              p: 1,
              borderRadius: '5px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                width: '100%',
                alignSelf: 'flex-start',
              }}
            >
              {filter?.map(category => (
                <Chip
                  key={category.value.choiseId}
                  label={category.value.choiseText}
                  onDelete={() => handleDeleteChip(category)}
                  sx={{
                    // icon color for close icon
                    '& .MuiChip-deleteIcon': {
                      color: '#ffffff',
                    },
                    fontWeight: 'bold',
                    letterSpacing: '0.05rem',
                    mr: 1,
                    mb: 1,
                    backgroundColor: '#E8833A', // Modern orange color
                    color: '#ffffff', // White text color
                    '&:hover': {
                      backgroundColor: '#D0722E', // Darker orange on hover
                    },
                    boxShadow: '0 3px 5px 2px rgba(76, 175, 80, .3)', // Subtle shadow for depth
                  }}
                />
              ))}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: isXtraSmallScreen ? 'column' : 'row',
                gap: '0.5rem',
                alignSelf: 'flex-start',
                justifyContent: isSmallToMediumScreen
                  ? 'flex-start'
                  : 'flex-end',
                minWidth: isSmallToMediumScreen ? '100%' : 'fit-content',
              }}
            >
              {filter.length !== 0 && (
                <Button
                  variant="text"
                  // startIcon={<DeleteForeverIcon/>}
                  sx={{
                    fontWeight: 'bold',
                    color: '#6558F5',
                    fontSize: '0.9rem',
                    letterSpacing: '0.1rem',
                    '&:hover': {
                      borderColor: '#d32f2f', // Darker on hover
                      color: '#6558F5', // Darker on hover
                      backgroundColor: '#D1C4E9', // light color of this #6558F5
                    },
                  }}
                  onClick={handleClearFilters}
                >
                  Reset
                </Button>
              )}

              <DynamicDropDown
                handleAddFilter={handleAddFilter}
                filteredQuestions={surveyData?.filteredQuestions}
                filter={filter}
              />

              <Button
                variant="contained"
                startIcon={<FilterListIcon />}
                sx={{
                  backgroundColor: '#6558F5', // Modern blue color
                  color: '#ffffff', // White text color
                  '&:hover': {
                    backgroundColor: '#4B3DBD', // Darker blue on hover
                  },
                  boxShadow: '0 3px 5px 2px rgba(101, 88, 245, .3)', // Subtle shadow for depth
                }}
                onClick={handleApplyFilter}
                disabled={filter.length === 0}
              >
                Apply Filter
              </Button>
            </Box>
          </Box>
        ) : null}

        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={12} md={surveyData?.is_anonymous ? 6 : 4}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search by respondent or email"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {/* {!responses?.is_anonymous && (
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Gender Filter</InputLabel>
                <Select
                  value={selectedGender}
                  label="Gender Filter"
                  onChange={(e) => setSelectedGender(e.target.value)}
                >
                  <MenuItem value="all">All</MenuItem>
                  {gender?.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )} */}
          <Grid item xs={12} md={surveyData?.is_anonymous ? 6 : 4}>
            <FormControl fullWidth>
              <InputLabel>Status Filter</InputLabel>
              <Select
                value={statusFilter}
                label="Status Filter"
                onChange={e => setStatusFilter(e.target.value)}
              >
                <MenuItem value="all">All Responses</MenuItem>
                <MenuItem value="complete">Complete</MenuItem>
                <MenuItem value="partial">Partial</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <CustomTable
          count={surveySubmitResponseData?.responses?.length}
          headers={headers}
          rows={surveySubmitResponseData?.responses}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          listLoading={loading}
          emptyRecordText={'No record has been found.'}
        />
      </Box>
      {toast && (
        <Toast
          setToast={setToast}
          isLoading={isloadingData}
          toast={toast}
          toastType={toastType}
          toastMessage={toastMessage}
        />
      )}
    </Container>
  );
};

export default ResponseCollection;
