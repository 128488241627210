import "../surveyStyle.css"

import { Box, FormHelperText, Typography } from '@mui/material'
import { EmailIcon, GenderIcon, NameIcon, PhoneIcon } from "../Components/svgs";

import React from 'react'
import { capitalizeFirstCharacter } from '../../../utils/helpers';

const RESPONDENT_DETAILS_COLLECTION = [
    "Name",
    "Email",
    "Phone",
    "Gender",
]

const getFiedlIcon = (field) => {
    switch (field) {
        case "name": return <NameIcon />;
        case "email": return <EmailIcon />;
        case "phone": return <PhoneIcon/>;
        case "gender": return <GenderIcon />;
        default: return null;
}
}

const NamedSurveyStep = (props) => {
    const { formik } = props;
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6" gutterBottom>
                Respondent Details to Be Collected
            </Typography>
            <Box sx={{ width: "100%", justifyContent: 'left', display: "flex" }}>
        <Box sx={{ width:"100%", my:"2rem", display: "flex", gap: "1rem", flexDirection: "row", alignItems: "center", flexWrap: "wrap",
        justifyContent: "space-evenly" }}>
            {RESPONDENT_DETAILS_COLLECTION.map((field, index)=>{
                const fieldName = `is_respondent_${field.toLowerCase()}`;
                return (
                     <div class="checkbox-wrapper-16" key={index}>
                                <label class="checkbox-wrapper">
                                    <input class="checkbox-input" type="checkbox" id={fieldName} name={fieldName}

                                    checked={formik.values[fieldName] === true}
                                    onChange={(event) =>
                                        formik.setFieldValue(
                                            fieldName,
                                            event.target.checked
                                        )
                                    }
                                    />
                                    <span class="checkbox-tile">
                                        <span class="checkbox-icon">
                                            {getFiedlIcon(field.toLowerCase())}
                                        </span>
                                        <span class="checkbox-label">{capitalizeFirstCharacter(field)}</span>
                                    </span>
                                </label>
                            </div>
                )
            })}
            </Box>
            
            </Box>
            {
                 Boolean(formik.errors.is_respondent_details) 
                    ? <FormHelperText sx={{ color: '#D32F2F' }}
                    >{formik.errors.is_respondent_details}{" "}*</FormHelperText> : null
                }
        </Box>
    )
}

export default NamedSurveyStep