import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'

const StartingScheduleStep = (props) => {
  const { formik, editMode } = props;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - (offset * 60 * 1000));
    return adjustedDate.toISOString().slice(0, 16);
  };

  const startDatePreValue = formik.values.start_date;
  const endDatePreValue = formik.values.end_date;

  useEffect(()=>{
    if (startDatePreValue && editMode) {
      const formattedDate = formatDate(startDatePreValue);
      formik.setFieldValue('start_date', formattedDate);
    }
    if (endDatePreValue && editMode) {
      const formattedDate = formatDate(endDatePreValue);
      formik.setFieldValue('end_date', formattedDate);
    }
  },[startDatePreValue])

  const startOptionValue = formik.values.startOption;
  useEffect(()=>{
    if (startOptionValue === 'immediately') {
      formik.setFieldValue('start_date', '');
    }
  }, [startOptionValue])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h6" gutterBottom>
        Survey Starting Schedule
      </Typography>
      <FormControl component="fieldset" margin="normal">
        <FormLabel component="legend">Start Option</FormLabel>
        <RadioGroup
          name="startOption"
          value={formik.values.startOption}
          onChange={formik.handleChange}
        >
          <FormControlLabel value="immediately" control={<Radio />} label="Start Immediately when published" />
          <FormControlLabel value="future" control={<Radio />} label="Start Survey at a future date" />
        </RadioGroup>
      </FormControl>
      {formik.values.startOption === 'future' && (
        <TextField
          fullWidth
          type="datetime-local"
          label="Survey Start Date & Time"
          name="start_date"
          value={formik.values.start_date}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.start_date && Boolean(formik.errors.start_date)}
          helperText={formik.touched.start_date && formik.errors.start_date}
          InputLabelProps={{ shrink: true }}
          margin="normal"
        />
      )}
      <TextField
        fullWidth
        type="datetime-local"
        label="Survey End Date & Time"
        name="end_date"
        value={formik.values.end_date}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.end_date && Boolean(formik.errors.end_date)}
        helperText={formik.touched.end_date && formik.errors.end_date}
        InputLabelProps={{ shrink: true }}
        margin="normal"
      />
    </Box>
  )
}

export default StartingScheduleStep