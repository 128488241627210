/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { IOSSwitch } from '../../../styles/styledComponent/IOSSwitch';

const ConfigureSurveyPageStep = props => {
  const { formik, setFileUploadData, fileUploadData } = props;

  const handleFileChange = event => {
    if (!event.currentTarget.files.length) return;
    formik.setFieldValue('survey_logo', event.currentTarget.files[0]);
    const file = event.currentTarget.files[0];
    setFileUploadData &&
      setFileUploadData(prev => {
        return {
          ...prev,
          file_name: file,
        };
      });
  };

  const handleDeleteImage = () => {
    formik.setFieldValue('survey_logo', null);
    if (formik?.values?.banner_image_url) {
      formik.setFieldValue('banner_image_url', '');
    }
    formik.setFieldValue('banner_image', '');
    setFileUploadData(prev => {
      return {
        ...prev,
        file_name: null,
      };
    });
  };

  const surveyDisplayTypeValue = formik.values.survey_display_type;

  useEffect(() => {
    if (surveyDisplayTypeValue) {
      if (surveyDisplayTypeValue === 'single_form') {
        formik.setFieldValue('multi_step_style', '');
      }
    }
  }, [surveyDisplayTypeValue]);

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Typography variant="h6" gutterBottom>
        Configure Survey Page
      </Typography>

      <FormControl fullWidth margin="normal">
        <InputLabel>Survey Display Type</InputLabel>
        <Select
          label="Survey Display Type"
          name="survey_display_type"
          value={formik.values.survey_display_type}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.survey_display_type &&
            Boolean(formik.errors.survey_display_type)
          }
        >
          <MenuItem value="single_form">Single Form</MenuItem>
          <MenuItem value="multi_steps">Multi Steps</MenuItem>
        </Select>
        {formik.touched.survey_display_type &&
          Boolean(formik.errors.survey_display_type) && (
            <Typography color="error" variant="body2">
              {formik.errors.survey_display_type}
            </Typography>
          )}
      </FormControl>

      {formik.values.survey_display_type === 'multi_steps' && (
        <FormControl fullWidth margin="normal">
          <InputLabel>Survey Step Type</InputLabel>
          <Select
            label="Survey Step Type"
            name="multi_step_style"
            value={formik.values.multi_step_style}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.multi_step_style &&
              Boolean(formik.errors.multi_step_style)
            }
          >
            <MenuItem value="question_category">Question Category</MenuItem>
            <MenuItem value="one_question_per_step">
              1 Question per step
            </MenuItem>
          </Select>
          {formik.touched.multi_step_style &&
            Boolean(formik.errors.multi_step_style) && (
              <Typography color="error" variant="body2">
                {formik.errors.multi_step_style}
              </Typography>
            )}
        </FormControl>
      )}

      <Card sx={{ mt: 2, width: '100%' }}>
        <CardContent
          sx={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'space-between',
            padding: '12px !important',
          }}
        >
          <Box>
            <Typography>
              Enable scores to unlock comprehensive analytical reporting:
            </Typography>
            <Box sx={{ mt: 1 }}>
              <Typography variant="body2" component="div">
                • <strong>Statistical Analysis:</strong> Calculate overall mean
                score, average top & bottom box.
                <br />• <strong>Top/Bottom Box Analysis:</strong> Identify
                strongest and weakest performing areas.
                <br />• <strong>Visual Reports:</strong> Generate bar graphs and
                pie charts.
                <br />• <strong>Frequency Distribution:</strong> View detailed
                response distribution for each question.
                <br />• <strong>Trend Analysis:</strong> Calculate weighted
                averages and identify trends.
              </Typography>
            </Box>
            <Typography variant="body2" sx={{ mt: 1, fontStyle: 'italic' }}>
              Without scoring enabled, you'll receive basic response report.
            </Typography>
          </Box>
          <FormGroup>
            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  checked={formik.values.is_score}
                  onChange={() =>
                    formik.setFieldValue('is_score', !formik.values.is_score)
                  }
                />
              }
            />
          </FormGroup>
        </CardContent>
      </Card>

      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <input
          accept="image/*"
          id="survey-banner"
          type="file"
          onChange={handleFileChange}
          hidden
        />
        <label htmlFor="survey-banner">
          <Button variant="outlined" component="span" sx={{ mt: 2 }}>
            Upload Survey Banner
          </Button>
        </label>
        <Typography
          variant="subtitle2"
          sx={{ color: 'text.secondary', textWrap: 'wrap', mt: 1 }}
        >
          (Upload a banner image to display at the top of the survey page. This
          can be used to provide a description or highlight important
          information about the survey.)
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ color: '#D32F2F', textWrap: 'wrap', mt: 1 }}
        >
          Note: Please upload an image with dimensions of{' '}
          <strong>1600 x 400</strong> for optimal display.
        </Typography>
        {(fileUploadData?.file_name || formik?.values?.banner_image_url) && (
          <Box
            display="flex"
            justifyContent="center"
            sx={{ position: 'relative', mt: 2 }}
          >
            <IconButton
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                zIndex: 1,
                background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                color: 'white',
                '&:hover': {
                  background:
                    'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
                },
                fontSize: '1.5rem',
                borderRadius: '50%',
              }}
              onClick={handleDeleteImage}
            >
              <CloseIcon />
            </IconButton>

            <img
              src={
                formik?.values?.banner_image_url
                  ? formik?.values?.banner_image_url
                  : URL.createObjectURL(fileUploadData?.file_name)
              }
              alt="img"
              style={{
                width: '450px',
                maxHeight: '567px',
                mixBlendMode: 'multiply',
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ConfigureSurveyPageStep;
