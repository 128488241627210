import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import React, { useState } from 'react';

import ConfirmationBox from '../../ConfirmationBox';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import RenderAnswerType from '../../RenderAnswerType';
import { Stack } from '@mui/system';
import { useEffect } from 'react';

const ActionCell = props => {
  const {
    row,
    handleDeleteQuestion,
    handleEditQuestion,
    handleQuestionCopy,
    isSurveyPublished,
    isEditTable,
  } = props;
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [isViewing, setIsViewing] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const questionTypes = [
    { value: 'radio', label: 'Single Choice (radio button)' },
    { value: 'dropdown', label: 'Single Choice (dropdown/select)' },
    { value: 'yes_no', label: 'Single Choice (Yes/No)' },
    { value: 'multiple_choice', label: 'Multiple Choices' },
    { value: 'rating', label: 'Rating' },
    { value: 'date', label: 'Date Picker' },
    { value: 'time', label: 'Time Picker' },
    { value: 'file_upload', label: 'File Upload' },
    { value: 'slider', label: 'Slider' },
    { value: 'text', label: 'Text Answer' },
    { value: 'multi_line_text', label: 'Multi Line Text Answer' },
  ];
  const [finalDataArry, setFinalDataArry] = useState([]);

  useEffect(() => {
    setIsEdited(isEditTable);
    if (Object.keys(row).length) {
      setFinalDataArry([
        {
          question: row.id,
          text_answer: '',
          choice_answer: null,
          multiple_choices: [],
          rating_answer: null,
          date_answer: null,
          time_answer: null,
          slider_value: null,
          yes_no_answer: null,
        },
      ]);
    }
    return () => {
      setFinalDataArry([]);
    };
  }, [row]);

  const questionView = () => (
    <Dialog
      open={isViewing}
      onClose={() => setIsViewing(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ fontWeight: 'bold' }}>Question View</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack>
          <Paper sx={{ p: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              {row.question}
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              display="block"
              gutterBottom
            >
              {questionTypes.find(t => t.value === row?.question_type)?.label}
            </Typography>
            <RenderAnswerType
              question={row}
              setFinalDataArry={setFinalDataArry}
              finalDataArry={finalDataArry}
            />
          </Paper>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2 }}>
        <Button
          onClick={() => {
            setIsViewing(false);
            setFinalDataArry([]);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div>
      <Tooltip title="View Question" arrow>
        <IconButton onClick={() => setIsViewing(true)} color="primary">
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit Question" arrow>
        <IconButton
          onClick={() => handleEditQuestion(row, isEdited)}
          color="info"
          // disabled={isSurveyPublished}
          disabled={!isEdited}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete Question" arrow>
        <IconButton
          onClick={() => setDeleteConfirmOpen(true)}
          color="error"
          disabled={isSurveyPublished}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Copy Question" arrow>
        <IconButton
          onClick={() => handleQuestionCopy(row)}
          color="warning"
          disabled={isSurveyPublished}
        >
          <FileCopyIcon />
        </IconButton>
      </Tooltip>
      {questionView()}
      <ConfirmationBox
        open={deleteConfirmOpen}
        headerText="Confirm Delete"
        close={() => setDeleteConfirmOpen(false)}
        handleClose={() => setDeleteConfirmOpen(false)}
        handleAction={() => {
          handleDeleteQuestion(row?.id);
          setDeleteConfirmOpen(false);
        }}
      >
        <Typography variant="body2">
          Are you sure you want to delete this question?
        </Typography>
      </ConfirmationBox>
    </div>
  );
};

export default ActionCell;
