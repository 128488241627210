import React, { createContext, useContext, useState } from "react";

import useApiService from "../hooks/api-services/use-service";
import { useStorage } from "../hooks/custom-hook-functions/use-Storage";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {

  const userData = JSON.parse(localStorage.getItem("user"));
  let setUserData = {}
  if (userData) { 
    setUserData = userData
  }
  const [user, setUser] = useState(Object.keys(setUserData)?.length > 0 ? setUserData : null);
  const { signOut } = useApiService();
  const { setItem } = useStorage();
  
  const login = (userData) => {
    setUser(userData);
    setItem("user", JSON.stringify(userData));
    setItem("token", JSON.stringify(userData));
  };

  const signUp = (userData) => {
    setItem("token", JSON.stringify(userData));
  };

  const logout = async () => {
    try {
      await signOut({
        refresh_token: `${JSON.parse(localStorage.getItem("token")).refresh}`,
      });
      setUser(null);
      localStorage.clear()
      sessionStorage.clear()
    } catch (error) {
      console.log(error);
    } finally {
      setUser(null);
      localStorage.clear()
      sessionStorage.clear()
    }
  };

  const hasPermission = (requiredRole) => {
    return user && user?.user?.groups?.[0]?.name === requiredRole;
  };

  return (
    <AuthContext.Provider
      value={{ user, login, signUp, logout, hasPermission }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
