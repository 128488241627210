export const SURVEY_CREATE_STEPS = [
    "Survey Details",
    "Configure Survey page",
    "Starting Schedule",
]

export const SURVEY_CREATE_NAMED_STEPS = [
    "Survey Details",
    "Named Survey",
    "Configure Survey page",
    "Starting Schedule",
];