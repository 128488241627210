import { createTheme } from "@mui/material";

// Create theme
export const theme = createTheme({
    palette: {
      primary: {
        main: "#0C0C5E",
      },
      secondary: {
        main: "#dc004e",
      },
      background: {
        default: "#E4E6F5",
      },
    },
    typography: {
      fontFamily: [
        "Manrope-Regular",
        "Manrope-Light",
        "Manrope-Medium",
        "Roboto",
        "-apple-system",
        "sans-serif",
      ].join(","),
      h4: {
        fontWeight: 500,
        color: "#232946",
      },
      h5: {
        fontWeight: 500,
        color: "#232946",
      },
      h6: {
        fontWeight: 500,
        color: "#232946",
      },
      subtitle1: {
        color: "#232946",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      }
    },
    Card: {
      backgroundColor: "pink",
    },
  });