import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Tooltip as ChartTooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
} from "chart.js";
import {
  Assessment,
  Info,
  People,
  PieChart,
  TrendingUp,
  Update,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Line, Pie } from "react-chartjs-2";

import React from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  ChartTooltip,
  Legend
);

const SurveyAnalytics = (props) => {
  const { data } = props;
  const navigate = useNavigate();
  const theme = useTheme();

  // Stats cards data
  const statsCards = [
    {
      title: "Total Surveys",
      value: data?.totalSurveys || 0,
      icon: <Assessment />,
      color: theme.palette.primary.main,
      trend: "+12% vs last month",
      navigate_to: '/my-surveys',
    },
    {
      title: "Active Surveys",
      value: data?.totalActiveSurveys || 0,
      icon: <PieChart />,
      color: theme.palette.warning.main,
      trend: "Same as last month",
      navigate_to: '/my-surveys?status=published',
    },
    {
      title: "Total Responses",
      value: data?.totalResponses || 0,
      icon: <People />,
      color: theme.palette.success.main,
      trend: "+8% vs last month",
      navigate_to: '',
    },
    {
      title: "Response Rate",
      value: data?.responseRate ? `${data.responseRate.toFixed(2)}%` : "0%",
      icon: <TrendingUp />,
      color: theme.palette.info.main,
      trend: "+5% vs last month",
      navigate_to: '',
    },
  ];

  // Chart data
  const monthlyResponses = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "Survey Responses",
        data: [65, 89, 80, 81, 56, 95],
        fill: false,
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light,
        tension: 0.4,
      },
    ],
  };

  const completionRates = {
    labels: ["Completed", "Partial", "Abandoned"],
    datasets: [
      {
        data: [70, 20, 10],
        backgroundColor: [
          theme.palette.success.light,
          theme.palette.warning.light,
          theme.palette.error.light,
        ],
        borderColor: [
          theme.palette.success.main,
          theme.palette.warning.main,
          theme.palette.error.main,
        ],
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const commonOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  const lineOptions = {
    ...commonOptions,
    plugins: {
      ...commonOptions.plugins,
      title: {
        display: true,
        text: "Monthly Survey Responses",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: theme.palette.divider,
        },
      },
      x: {
        grid: {
          color: theme.palette.divider,
        },
      },
    },
  };

  const StatsCard = ({ title, value, icon, color, trend, navigate_to }) => (
    <Card onClick={() => navigate_to && navigate(`${navigate_to}`)} style={{cursor: "pointer"}}>
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography variant="h6" color="text.secondary">
              {title}
            </Typography>
            <Typography variant="h4" sx={{ my: 1 }}>
              {value}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {trend}
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: `${color}15`,
              p: 1,
              borderRadius: 2,
            }}
          >
            {React.cloneElement(icon, { sx: { fontSize: 40, color } })}
          </Box>
        </Stack>
      </CardContent>
    </Card >
  );

  return (
    <Box sx={{ width: "100%", p: 2 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h4">Survey Analytics</Typography>
        <Tooltip title="Last updated 5 minutes ago">
          <IconButton>
            <Update />
          </IconButton>
        </Tooltip>
      </Stack>

      {/* Stats Cards */}
      <Grid container spacing={3} mb={3}>
        {statsCards.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <StatsCard {...card} />
          </Grid>
        ))}
      </Grid>

      {/* Charts */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card>
            <CardHeader
              title="Response Trends"
              action={
                <Tooltip title="Shows survey response trends over time">
                  <IconButton>
                    <Info />
                  </IconButton>
                </Tooltip>
              }
            />
            <CardContent>
              <Box sx={{ height: 400 }}>
                <Line data={monthlyResponses} options={lineOptions} />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader title="Completion Rates" />
            <CardContent>
              <Box sx={{ height: 400 }}>
                <Pie data={completionRates} options={commonOptions} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SurveyAnalytics;
