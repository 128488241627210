import { Box } from "@mui/system";
import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
const ActionButtonCell = ({ row, buttonLabel, buttonIcon, surveyId }) => {
  const navigate = useNavigate();
  const handleSurveyDetail = (data) => {
    navigate(`/survey-response-details/${data.row.id}/${surveyId}`);
  }
  return (
    <Box>
      <Button variant="contained" size="small" sx={{fontSize: 11}} startIcon={buttonIcon} onClick={
        ()=>handleSurveyDetail(row)}>
        {buttonLabel}
      </Button>
    </Box>
  );
};

export default ActionButtonCell;
