/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from "yup";

import { Box, Container } from "@mui/system";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getDeviceId, onSurveyAccess } from "../utils/UUID-each-user-";
import { useNavigate, useParams } from "react-router-dom";

import SurveyAlreadyFilled from "./SurveyAlreadyFilled/SurveyAlreadyFilled";
import SurveyExpired from "./SurveyExpired/SurveyExpired";
import Toast from "../components/Tost";
import useApiService from "../hooks/api-services/use-service";
import { useFormik } from "formik";
import { useToast } from "../hooks/custom-hook-functions/useToast";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const SurveyFormFillHandle = () => {
  const navigate = useNavigate();
  const { surveyId } = useParams();
  const { showToast, toast, toastMessage, toastType, setToast } = useToast();
  const { getSurveyByIdOpen, createResponseForSurvey } = useApiService();
  const decodedSurveyId = atob(surveyId).split("_")[1];

  const [isLoading, setIsLoading] = useState(false);
  const [surveyData, setSurveyData] = useState({});
  const [isSurveyExpired, setIsSurveyExpired] = useState(false);
  
    const [isSurveyAlreadyFilled, setIsSurveyAlreadyFilled] = useState(false);
    const [formValidationState, setFormValidationState] = useState({
      is_respondent_email: false,
      is_respondent_name: false,
      is_respondent_phone: false,
      is_respondent_gender: false
    });

    const { is_respondent_email, is_respondent_name,
      is_respondent_phone, is_respondent_gender } = formValidationState
  
    useEffect(()=>{
      if (surveyData) {
        if (Object?.keys(surveyData)?.length) {
         setFormValidationState({
            is_respondent_email: surveyData?.is_respondent_email,
            is_respondent_name: surveyData?.is_respondent_name,
            is_respondent_phone: surveyData?.is_respondent_phone,
            is_respondent_gender: surveyData?.is_respondent_gender
         })
        }
      }
    },[surveyData])

  useEffect(()=>{
      // Get or generate a device ID (UUID to track the device without registration)
      getDeviceId();

      // check the survey access
      setIsSurveyAlreadyFilled(onSurveyAccess(surveyId))
  },[]);

  const getSurveyByIdData = async (surveyId) => {
    try {
      setIsLoading(true);
      await getSurveyByIdOpen(surveyId).then((res) => {
        if (res.status === 200) {
          // no need to show toast over as only error toast is required
          setSurveyData(res.data);
        }
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showToast("Error fetching survey data", "error");
    }
  }

  useEffect(()=>{
    if (surveyData) {
      if (Object?.keys(surveyData)?.length) {
        const endDate = new Date(surveyData?.end_date)?.getTime();
        const currentDate = new Date().getTime();
        const isSurveyNotAvailable = surveyData?.status === "completed" || surveyData?.status === "paused" || surveyData?.is_archived === true;
        if ((endDate && (endDate < currentDate)) || isSurveyNotAvailable) {
          showToast("Oops! This survey is not available", "error");
          setIsSurveyExpired(true);
        }
      }
    }
    
  }, [surveyData]);

  useEffect(() => {
    if (decodedSurveyId) {
      getSurveyByIdData(decodedSurveyId);
    }
  }, [decodedSurveyId]);

  
  const validate = (values) => {
    const errors = {};

    if (is_respondent_name && !values.respondent_name) {
      errors.respondent_name = "* Name is required";
    }

    if (is_respondent_email) {
      if (!values.respondent_email) {
        errors.respondent_email = "* Email is required";
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.respondent_email)) {
        errors.respondent_email = "Must be a valid email";
      }
    }

    if (is_respondent_phone) {
      if (!values.respondent_phone) {
        errors.respondent_phone = "* Phone is required";
      } else if (!/^\d{10}$/.test(values.respondent_phone)) {
        errors.respondent_phone = "Phone number is not valid";
      }
    }

    if (is_respondent_gender && !values.gender) {
      errors.gender = "* Gender is required";
    }

    return errors;
  };


  const formik = useFormik({
    initialValues: {
      respondent_name: "",
      respondent_email: "",
      respondent_phone: "",
      gender: ""
    },
    validate,
    onSubmit: async (values, helpers) => {
      setIsLoading(true);
      try {
        creatingSurveyResponse(decodedSurveyId, values);
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });


  const dynamicFields = [
    { key: "respondent_name", label: "Your Full Name", value: is_respondent_name },
    { key: "respondent_email", label: "Email", value: is_respondent_email },
    { key: "respondent_phone", label: "Phone Number", value: is_respondent_phone },
    {
      key: "gender", label: "Gender",
      options: [{
        label: "Female", value: "female"
      },
      { label: "Male", value: "male" },
      {label: "Other", value: "other"},
      { label: "Not disclosed", value: "not_disclosed" }],
      value: is_respondent_gender, isRadio: true
    }
  ]

  const creatingSurveyResponse = async (id, payload) => {
    try {
      setIsLoading(true);
      await createResponseForSurvey(id, payload).then((res) => {
        if (res) {
          const responseId = btoa(`${res.id}`);
          localStorage.setItem("responseId", responseId);
          navigate(`/survey-form/${surveyId}`, { replace: true });
          showToast("Response created successfully", "success");
        }
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showToast("Error creating response for survey", "error");
    }
  }

  if (isLoading) {
    return (
      <Container maxWidth="md">
        <Box
          sx={{
            mt: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Paper elevation={3} sx={{ p: 4, maxWidth: 400 }}>
            <Typography variant="h4" textAlign={"center"} gutterBottom>
              Loading...
            </Typography>
          </Paper>
        </Box>
      </Container>
    );
  }
  if (isSurveyAlreadyFilled) {
    return (
      <SurveyAlreadyFilled isSurveyAlreadyFilled={isSurveyAlreadyFilled} />
    );
  }

  if (isSurveyExpired) {
    return (
      <SurveyExpired isSurveyExpired={isSurveyExpired} />
    );
  }

    return (
      <>
      <Container maxWidth="md">
        <Box
          sx={{
            mt: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Paper elevation={3} sx={{ p: 4, maxWidth: 550 }}>
            <Typography variant="h4" textAlign={"center"} gutterBottom>
              Respondent Details
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              {dynamicFields.map((field) => {
                return !field.isRadio && field.value ? (
                  <TextField
                    key={field.key}
                    fullWidth
                    error={!!(formik.touched[field.key] && formik.errors[field.key])}
                    helperText={formik.touched[field.key] && formik.errors[field.key]}
                    margin="normal"
                    label={field.label}
                    type="text"
                    name={field.key}
                    value={formik.values[field.key]}
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => {
                                if (field.key === "respondent_phone") {
                                  formik.setFieldValue(field.key, event.target.value.replace(/[^0-9]/g, ''));
                                } else {
                                    formik.handleChange(event);
                                }
                              }}
                    onBlur={formik.handleBlur}
                  />
                ) :
                  (
                    field.value && field.isRadio && (
                      <FormControl key={field.key}>
                        <FormLabel id={`${field.key}-row-radio-buttons-group-label`}>
                          Gender
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby={`${field.key}-row-radio-buttons-group-label`}
                          name={field.key}
                          value={formik.values[field.key]}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          {
                            field.options.map((option) => {
                              return (
                                <FormControlLabel
                                  key={option.value}
                                  value={option.value}
                                  control={<Radio />}
                                  label={option.label}
                                />
                              )
                            }
                            )}
                        </RadioGroup>
                        {formik.touched[field.key] && formik.errors[field.key] && (
                          <FormHelperText style={{ color: "#D32F2F" }}>{formik.errors[field.key]}</FormHelperText>
                        )}
                      </FormControl>)
                  );
              })}
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                sx={{ mt: 2 }}
                disabled={false}
              >
                Start Survey
              </Button>
            </form>
          </Paper>
        </Box>
        {toast && (
          <Toast
            setToast={setToast}
            isLoading={isLoading}
            toast={toast}
            toastType={toastType}
            toastMessage={toastMessage}
          />
        )}
      </Container>
      </>
    );
};

export default SurveyFormFillHandle;
