import {
  Delete as DeleteIcon,
  Email as EmailIcon,
  Leaderboard as LeaderboardIcon,
  Link as LinkIcon,
  ZoomIn as ZoomInIcon,
} from '@mui/icons-material';

import ArchiveIcon from '@mui/icons-material/Archive';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PauseIcon from '@mui/icons-material/Pause';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';

export const surveyMenuOptions = [
  {
    label: 'Edit Survey',
    action: 'Edit Survey',
    icon: <EditIcon fontSize="small" />,
  },
  {
    label: 'Analyze Results',
    action: 'Analyze Responses',
    icon: <LeaderboardIcon fontSize="small" />,
  },
  {
    label: 'Duplicate this survey',
    action: 'Duplicate this survey',
    icon: <FileCopyIcon fontSize="small" />,
  },
  {
    label: 'Pause',
    action: 'Pause/Continue Survey',
    icon: <PauseIcon fontSize="small" />,
  },
  {
    label: 'Mark as complete',
    action: 'Mark as complete',
    icon: <CheckCircleIcon fontSize="small" />,
  },
  {
    label: 'Archive',
    action: 'Archive',
    icon: <ArchiveIcon fontSize="small" />,
  },
  {
    label: 'Delete',
    action: 'Delete',
    icon: <DeleteIcon fontSize="small" />,
  },
];

export const getScoreColor = score => {
  if (score >= 4.5) return '#2e7d32'; // Excellent - dark green
  if (score >= 3.5) return '#43a047'; // Good - green
  if (score >= 2.5) return '#ffa000'; // Average - yellow/orange
  if (score >= 1.5) return '#e53935'; // Poor - red
  return '#d32f2f'; // Critical - dark red
};

export const getScoreBgColor = score => {
  if (score >= 4.5) return '#e8f5e9'; // Excellent - light green bg
  if (score >= 3.5) return '#c8e6c9'; // Good - slightly darker light green bg
  if (score >= 2.5) return '#fff3e0'; // Average - light orange bg
  if (score >= 1.5) return '#ffebee'; // Poor - light red bg
  return '#ffcdd2'; // Critical - slightly darker light red bg
};

export const getBottomScoreColor = score => {
  if (score >= 0.3) return '#d32f2f'; // Critical - dark red (30-100%)
  if (score >= 0.15) return '#e53935'; // Concerning - red (15-30%)
  if (score >= 0.05) return '#ffa000'; // Low - orange (5-15%)
  if (score >= 0.02) return '#43a047'; // Minimal - light green (2-5%)
  return '#2e7d32'; // Negligible - dark green (0-2%)
};

export const getBottomScoreBgColor = score => {
  if (score >= 0.3) return '#ffcdd2'; // Critical - light red bg (30-100%)
  if (score >= 0.15) return '#ffebee'; // Concerning - lighter red bg (15-30%)
  if (score >= 0.05) return '#fff3e0'; // Low - light orange bg (5-15%)
  if (score >= 0.02) return '#c8e6c9'; // Minimal - light green bg (2-5%)
  return '#e8f5e9'; // Negligible - dark green bg (0-2%)
};

export const getDistributionColor = index => {
  const colors = ['#f44336', '#ff9800', '#ffeb3b', '#8bc34a', '#4caf50'];
  return colors[index];
};
