import BadgeIcon from '@mui/icons-material/Badge';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import DraftsIcon from '@mui/icons-material/Drafts';
import PauseIcon from '@mui/icons-material/Pause';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';

const iconMapping = {
    expired: PriorityHighIcon,
    stopped: DoNotDisturbOnIcon,
    paused: PauseIcon,
    draft: DraftsIcon,
    anonymous: TheaterComedyIcon,
    named: BadgeIcon,
    default: "null"
};


export const getChipDesiredIcon = (iconType) => {
    const IconComponent = iconMapping[iconType] || iconMapping.default;
    return <IconComponent />;
}