import { BASE_URL } from "../config/api-endpoint";
import axios from "axios";

export const axiosAuth = axios.create({
  baseURL: `${BASE_URL}/api/v1`,
});

export const axiosPublic = axios.create({
  baseURL: `${BASE_URL}/api/v1`,
  headers: { "Content-Type": "application/json" },
});

axiosAuth.interceptors.request.use((config) => {
  const access_token = JSON.parse(localStorage.getItem('token'))?.access;
  if (access_token) {
    config.headers["Authorization"] = `Bearer ${access_token}`;
  }
  return config
},
  (error) => {
    return Promise.reject(error)
  }
)