import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  Rating,
  Select,
  Slider,
  TextField,
} from '@mui/material';
import React, { memo, useEffect, useState } from 'react';

const RenderAnswerType = memo(
  ({ question, setFinalDataArry, finalDataArry = [], formErrors = {} }) => {
    const [selectedAnswers, setSelectedAnswers] = useState({
      question: question?.id,
      text_answer: '',
      choice_answer: null,
      multiple_choices: [],
      rating_answer: null,
      date_answer: null,
      time_answer: null,
      slider_value: null,
      yes_no_answer: null,
    });

    useEffect(() => {
      if (question?.id && Array.isArray(finalDataArry)) {
        const currentQuestion = finalDataArry.find(
          item => item?.question === question.id
        );
        if (currentQuestion) {
          setSelectedAnswers(currentQuestion);
        }
      }
    }, [question, finalDataArry]);

    const handleChange = (question, answerType, value) => {
      if (!question?.id) return;

      setFinalDataArry(prev => {
        const updatedAnswers = prev?.map(item =>
          item?.question === question.id
            ? { ...item, [answerType]: value }
            : item
        );
        return updatedAnswers || [];
      });

      setSelectedAnswers(prev => ({ ...prev, [answerType]: value }));
    };

    const handleCheckboxChange = (question, option) => {
      setSelectedAnswers(prev => {
        const answerType = 'multiple_choices';
        const current = prev[answerType] || [];
        const updatedAnswers = current.includes(option)
          ? current.filter(item => item !== option)
          : [...current, option];

        return {
          ...prev,
          [answerType]: updatedAnswers,
        };
      });

      setFinalDataArry(prev => {
        const currentQuestion = prev.find(
          item => item.question === question.id
        );
        if (currentQuestion) {
          const updated = {
            ...currentQuestion,
            multiple_choices: currentQuestion.multiple_choices.includes(option)
              ? currentQuestion.multiple_choices.filter(item => item !== option)
              : [...currentQuestion.multiple_choices, option],
          };
          return prev.map(item =>
            item.question === question.id ? updated : item
          );
        } else {
          return [
            ...prev,
            {
              question: question.id,
              multiple_choices: [option],
            },
          ];
        }
      });
    };

    const handleRadioChange = (question, value) => {
      setSelectedAnswers(prev => {
        const currentQuestion = finalDataArry?.find(
          item => item.question === question?.id
        );
        if (currentQuestion) {
          const updated = { ...currentQuestion, choice_answer: value };
          const updatedFinalDataArry = finalDataArry.map(item => {
            if (item.question === question?.id) {
              return updated;
            }
            return item;
          });
          setFinalDataArry(updatedFinalDataArry);
          return { ...prev, choice_answer: value };
        }
      });
    };

    const handleYesNoChange = (question, value) => {
      setSelectedAnswers(prev => {
        const currentQuestion = finalDataArry?.find(
          item => item.question === question?.id
        );
        if (currentQuestion) {
          const updated = {
            ...currentQuestion,
            yes_no_answer: value === 'true' ? true : false,
          };
          const updatedFinalDataArry = finalDataArry?.map(item => {
            if (item.question === question?.id) {
              return updated;
            }
            return item;
          });
          setFinalDataArry(updatedFinalDataArry);
          return { ...prev, yes_no_answer: value === 'true' ? true : false };
        }
      });
    };

    const getAnswerValue = answerType => {
      if (answerType === 'multiple_choices') {
        return selectedAnswers[answerType] || [];
      }
      if (answerType === 'yes_no_answer') {
        return selectedAnswers[answerType] !== null
          ? selectedAnswers[answerType]?.toString()
          : '';
      }
      return selectedAnswers[answerType] || '';
    };

    switch (question?.question_type) {
      case 'text':
        return (
          <TextField
            fullWidth
            placeholder="Text answer"
            value={getAnswerValue('text_answer')}
            onChange={e =>
              handleChange(question, 'text_answer', e.target.value)
            }
            required={question?.required}
            error={!!formErrors[question?.id]}
            helperText={formErrors[question?.id]}
          />
        );
      case 'number':
        return (
          <TextField
            type="number"
            fullWidth
            placeholder="Numeric answer"
            value={getAnswerValue('text_answer')}
            onChange={e =>
              handleChange(question, 'text_answer', e.target.value)
            }
            required={question?.required}
          />
        );
      case 'multi_line_text':
        return (
          <TextField
            fullWidth
            placeholder="Multi Line Text answer"
            value={getAnswerValue('text_answer')}
            onChange={e => {
              if (
                !question?.text_max_characters ||
                e.target.value.length <= question.text_max_characters
              ) {
                handleChange(question, 'text_answer', e.target.value);
              }
            }}
            required={question?.required}
            error={!!formErrors[question?.id]}
            helperText={
              formErrors[question?.id] ||
              (question?.text_max_characters
                ? `${getAnswerValue('text_answer').length}/${
                    question.text_max_characters
                  } characters`
                : '')
            }
            multiline
            rows={question?.text_display_lines || 2}
            inputProps={{
              maxLength: question?.text_max_characters || null,
            }}
          />
        );
      case 'multiple_choice':
        return (
          <>
            <FormGroup aria-required={true}>
              {question?.choices?.map((option, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={getAnswerValue('multiple_choices')?.includes(
                        option.id
                      )}
                      onChange={() =>
                        handleCheckboxChange(question, Number(option.id))
                      }
                    />
                  }
                  label={option.choise_text}
                />
              ))}
            </FormGroup>
            {formErrors && formErrors[question?.id] && (
              <FormHelperText error={!!formErrors[question?.id]}>
                {formErrors[question?.id]}
              </FormHelperText>
            )}
          </>
        );
      case 'radio':
        return (
          <>
            <FormControl>
              <RadioGroup
                value={getAnswerValue('choice_answer') || ''}
                onChange={e =>
                  handleRadioChange(question, Number(e.target.value))
                }
                aria-labelledby="radio-group"
              >
                {question?.choices?.map(option => (
                  <FormControlLabel
                    sx={{
                      '& .MuiFormControlLabel-asterisk': { display: 'none' },
                    }}
                    key={option.id}
                    value={option.id}
                    required={question?.required}
                    control={<Radio />}
                    label={option.choise_text}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            {formErrors && formErrors[question?.id] && (
              <FormHelperText error={!!formErrors[question?.id]}>
                {formErrors[question?.id]}
              </FormHelperText>
            )}
          </>
        );
      case 'rating':
        return (
          <>
            <Rating
              value={getAnswerValue('rating_answer')}
              onChange={(e, newValue) =>
                handleChange(question, 'rating_answer', newValue)
              }
              size="large"
              max={question.ratings_max_value || 5}
            />
            {formErrors[question?.id] && (
              <FormHelperText error={!!formErrors[question?.id]}>
                {formErrors[question?.id]}
              </FormHelperText>
            )}
          </>
        );
      case 'yes_no':
        return (
          <>
            <FormControl>
              <RadioGroup
                row
                value={getAnswerValue('yes_no_answer') || ''}
                onChange={e => handleYesNoChange(question, e.target.value)}
                aria-labelledby="radio-group-2"
              >
                <FormControlLabel
                  sx={{
                    '& .MuiFormControlLabel-asterisk': { display: 'none' },
                  }}
                  required={question?.required}
                  key={'1_true'}
                  value={'true'}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  sx={{
                    '& .MuiFormControlLabel-asterisk': { display: 'none' },
                  }}
                  required={question?.required}
                  key={'2_false'}
                  value={'false'}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
            {formErrors && formErrors[question?.id] && (
              <FormHelperText error={!!formErrors[question?.id]}>
                {formErrors[question?.id]}
              </FormHelperText>
            )}
          </>
        );
      case 'dropdown':
        return (
          <>
            <Select
              fullWidth
              value={getAnswerValue('choice_answer')}
              onChange={e =>
                handleChange(question, 'choice_answer', Number(e.target.value))
              }
              displayEmpty
              required={question?.required}
            >
              <MenuItem value="" disabled>
                Select an option
              </MenuItem>
              {question?.choices?.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.choise_text}
                </MenuItem>
              ))}
            </Select>
            {formErrors && formErrors[question?.id] && (
              <FormHelperText error={!!formErrors[question?.id]}>
                {formErrors[question?.id]}
              </FormHelperText>
            )}
          </>
        );
      case 'date':
        return (
          <TextField
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={getAnswerValue('date_answer')}
            onChange={e =>
              handleChange(question, 'date_answer', e.target.value)
            }
            required={question?.required}
            error={!!formErrors[question?.id]}
            helperText={formErrors[question?.id]}
          />
        );
      case 'time':
        return (
          <TextField
            type="time"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={getAnswerValue('time_answer')}
            onChange={e =>
              handleChange(question, 'time_answer', e.target.value)
            }
            required={question?.required}
            error={!!formErrors[question?.id]}
            helperText={formErrors[question?.id]}
          />
        );
      case 'file_upload':
        return (
          <TextField
            type="file"
            fullWidth
            InputLabelProps={{ shrink: true }}
            onChange={e => handleChange(question, 'file', e.target.files[0])}
            required={question?.required}
            error={!!formErrors[question?.id]}
            helperText={formErrors[question?.id]}
          />
        );
      case 'slider':
        return (
          <div style={{ display: 'flex', gap: '1.5rem' }}>
            <Slider
              value={getAnswerValue('slider_value')}
              onChange={(e, newValue) =>
                handleChange(question, 'slider_value', newValue)
              }
              sx={{ flexGrow: 1 }}
              min={question.slider_min_value || 0}
              max={question.slider_max_value || 100}
              step={question.slider_step_size || 1}
              valueLabelDisplay="auto"
            />
            <p style={{ fontWeight: 'bold' }}>
              {getAnswerValue('slider_value')}
            </p>
            {formErrors && formErrors[question?.id] && (
              <FormHelperText error={!!formErrors[question?.id]}>
                {formErrors[question?.id]}
              </FormHelperText>
            )}
          </div>
        );
      default:
        return null;
    }
  }
);

export default React.memo(RenderAnswerType);
