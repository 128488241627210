import { Container } from "@mui/material";
import MySurveys from "../components/survey/MySurveys";
import React from "react";

const SurveyPage = () => {
  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <MySurveys />
    </Container>
  );
};

export default SurveyPage;
