import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Box } from '@mui/system';

const ExportModal = ({
  isOpen,
  onClose,
  onExport,
  loading,
  filterQuestions,
}) => {
  const [exportType, setExportType] = React.useState('excel');
  const [questionId, setQuestionId] = React.useState(null);

  const handleSubmit = () => {
    onExport(exportType, questionId);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle
        sx={{
          borderBottom: '1px solid #e0e0e0',
          fontSize: '1.25rem',
          fontWeight: 600,
          py: 2,
        }}
      >
        Export Options
      </DialogTitle>

      <DialogContent sx={{ py: 3, px: 4 }}>
        <Typography variant="subtitle1" fontWeight={500} mb={2} mt={2}>
          How would you like to export?
        </Typography>

        <RadioGroup
          value={exportType}
          onChange={e => {
            const newExportType = e.target.value;
            setExportType(newExportType);

            // Auto-select the first filter question when executive summary is chosen
            if (
              newExportType === 'executive_summary' &&
              filterQuestions?.length > 0
            ) {
              setQuestionId(filterQuestions[0].id);
            }
          }}
        >
          <FormControlLabel
            value="excel"
            control={<Radio color="primary" />}
            label={
              <Box>
                <Typography variant="body1" fontWeight={500}>
                  Export to Excel
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  Standard report format
                </Typography>
              </Box>
            }
            sx={{ mb: 2.5, ml: 0.5 }}
          />

          <FormControlLabel
            value="excel_with_score"
            control={<Radio color="primary" />}
            label={
              <Box>
                <Typography variant="body1" fontWeight={500}>
                  Export to Excel with Score
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  Includes scoring data for pivot tables and frequency
                  distributions
                </Typography>
              </Box>
            }
            sx={{ mb: 2.5, ml: 0.5 }}
          />

          <FormControlLabel
            value="executive_summary"
            control={<Radio color="primary" />}
            label={
              <Box>
                <Typography variant="body1" fontWeight={500}>
                  Executive Summary
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  Concise overview for management review
                </Typography>
              </Box>
            }
            sx={{ ml: 0.5 }}
          />

          {exportType === 'executive_summary' && (
            <Box
              sx={{
                ml: 4,
                p: 2,
                borderLeft: '1px solid',
                borderColor: 'divider',
                bgcolor: 'background.paper',
              }}
            >
              <Typography variant="body2" fontWeight={500} mb={1}>
                Filter by question:
              </Typography>

              <RadioGroup
                value={questionId}
                onChange={e => setQuestionId(e.target.value)}
              >
                {filterQuestions?.map(question => (
                  <FormControlLabel
                    key={question.id}
                    value={question.id}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography variant="body2" color="text.primary">
                        {question.question}
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>
            </Box>
          )}
        </RadioGroup>
      </DialogContent>

      <DialogActions
        sx={{
          p: 2,
          borderTop: '1px solid #e0e0e0',
          justifyContent: 'flex-end',
          gap: 1,
        }}
      >
        <Button onClick={onClose} variant="outlined" color="inherit">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} /> : null}
        >
          {loading ? 'Exporting...' : 'Export'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportModal;
