import { Box, Button, Container, SvgIcon } from '@mui/material';

import React from 'react'
import { West as WestIcon } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';

const Settings = () => {
    const navigate = useNavigate();
    return (
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              mt: 20,
              mb: 4,
              textAlign: "center",
            }}
          >
            <img
              alt="Coming Soon..."
              src="/assets/coming_soon_icon.png"
              width={200}
              height={200}
            />
          </Box>
          <Button
            startIcon={
              <SvgIcon fontSize="medium">
                <WestIcon />
              </SvgIcon>
            }
            variant="contained"
            onClick={() => navigate("/dashboard")}
          >
            Go Back To Dashboard
          </Button>
        </Box>
      </Container>
    );
}

export default Settings;