import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Typography } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';

const DialogAlert = ({ open, onClose, title, children, message, handleAction = false }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <WarningIcon sx={{ color: 'orange' }} />
          <Typography variant="h6">{title}</Typography>
        </Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          disabled={handleAction && handleAction.loading}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
        {message && <Typography variant="body1">
            <strong>{message.categoryName}</strong> {message.text}
          </Typography>}
          {children && children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="primary"
          disabled={handleAction && handleAction.loading}
        >
          Cancel
        </Button>
        {
          handleAction &&
          <Button onClick={handleAction.actionFunction} variant="contained" disabled={handleAction.loading}
           sx={{
            backgroundColor: handleAction.actionColor,
            color: "#ffffff",
            '&:hover': {
              backgroundColor: handleAction.actionHoverColor,
            },
          }}>
          {handleAction.loading ? 
            <CircularProgress size={24} /> : handleAction.actionMessage}
        </Button>
        }
      </DialogActions>
    </Dialog>
  );
};

export default DialogAlert;