import { Box, styled } from "@mui/material"

import { textAlign } from "@mui/system"

export const UserSurveyStyledContainer = styled(Box)(({theme})=>({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "100%",
    width: "100%",
    textAlign: 'center',
    padding: "3rem 0 3rem 0",
    // backgroundColor: "white",
}))

export const SurveyDetailContainer = styled(Box)(({theme})=>({
    padding: "2rem",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    backgroundColor: "white",
    width: "100%",
    maxWidth: "1200px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    border: "0.2px solid rgba(0,0,0,0.20)"
    // marginBottom: `calc(100px + 1rem)`,
}))


export const SurveryHeaderStyled = styled(Box)(({theme})=>({
    display: "flex",
    gap: "1rem",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    padding: "1rem",
    borderRadius: "8px",
    border: "0.2px solid rgba(0,0,0,0.20)"
}))

export const CompanyStyledBox = styled(Box)(({theme})=>({
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    alignItems: "stretch",
    flexGrow: 1,
}))

export const SurveyOverViewStyled = styled(Box)(({theme})=>({
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    justifyContent: "flex-start",
}))
export const OverviewDetailsStyled = styled(Box)(({theme})=>({
    display: "flex",
    flexDirection: "row",
    gap: "0.3rem",
    justifyContent: "space-between",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    borderRadius: "8px",
    border: "0.2px solid rgba(0,0,0,0.20)",
    padding: "1rem",
    ".detailTypes": {
        display: "flex",
        gap: "0.3rem",
        flexDirection: "column",
            ".details": {
                textAlign: "left",
                display: "flex",
                gap: "0.5rem",
                alignItems: "flex-start",
                }
            },
    
}))

export const QuestionsResponses = styled(Box)(({theme})=>({
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    padding: "1rem",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    alignItems: "flex-start",
    borderRadius: "8px",
    border: "0.2px solid rgba(0,0,0,0.20)",
    textAlign: "left",
}))