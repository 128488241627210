import { Box, Container, Stack } from '@mui/system';
import { Button, Divider, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import {
  SURVEY_DISPLAY_TYPES_ENUM,
  SURVEY_STEP_STYLE_ENUM,
  getErrorMessage,
  questionAndAnswerTypes,
} from '../utils/params';
import { useNavigate, useParams } from 'react-router-dom';

import MultiStepsSingleQuest from '../pages/SurveyQuesWizard/MultiStepsSingleQuest';
import RenderAnswerType from './RenderAnswerType';
import SurveyQuestionCategoryWizard from '../pages/SurveyQuesWizard/SurveyQuestionCategoryWizard';
import Toast from './Tost';
import { getMediaType } from '../utils/helpers';
import { getRandomBannerImage } from '../utils/randomBannerPicker';
import { onSurveySubmit } from '../utils/UUID-each-user-';
import useApiService from '../hooks/api-services/use-service';
import { useToast } from '../hooks/custom-hook-functions/useToast';
import DeviceInfo from '../utils/DeviceInfo';

const RenderSurvey = props => {
  const { data, isPreviewOnly, handleGetSurvey } = props;
  const { surveyId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setToast, showToast, toast, toastMessage, toastType } = useToast();
  const {
    createUserAnswerSurvey,
    updateSurveyStatus,
    createResponseForSurvey,
  } = useApiService();
  const [timeSpent, setTimeSpent] = useState(null);
  const [finalDataArry, setFinalDataArry] = useState([]);
  const [surveyPublished, setSurveyPublished] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isFilterQuestionNextClicked, setIsFilterQuestionNextClicked] =
    useState(false);
  const [isFilterQuestionAnswered, setIsFilterQuestionAnswered] =
    useState(false);
  const [isSingleMultiStepFinish, setIsSingleMultiStepFinish] = useState(false);
  useEffect(() => {
    if (data?.is_anonymous === true && !isPreviewOnly) {
      creatingSurveyResponse(data.id, {});
    }
    // if (data?.is_anonymous === true && !isPreviewOnly) {
    //   creatingSurveyResponse(data.id, {
    //     device_name: userDeviceInfo.device,
    //     browser_name: userDeviceInfo.browser,
    //     referrer: userDeviceInfo.referrer,
    //   });
    // }
  }, [data]);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (data?.questions.length) {
      const initialData = data?.questions.map(question => {
        return {
          question: question.id,
          text_answer: null,
          choice_answer: null,
          multiple_choices: [],
          rating_answer: null,
          date_answer: null,
          time_answer: null,
          slider_value: null,
          yes_no_answer: null,
        };
      });
      setFinalDataArry(initialData);
    }
  }, [data]);

  const creatingSurveyResponse = async (id, payload) => {
    try {
      setIsLoading(true);
      await createResponseForSurvey(id, payload).then(res => {
        if (res) {
          const responseId = btoa(`${res.id}`);
          localStorage.setItem('responseId', responseId);
        }
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showToast('Error creating response for survey', 'error');
    }
  };

  const userDeviceInfo = DeviceInfo();
  console.log('userDeviceInfo', userDeviceInfo);

  useEffect(() => {
    setTimeSpent(Date.now());
    setSurveyPublished(false);
  }, []);

  const filteredQuestions = useMemo(() => {
    return data?.questions
      ?.filter(
        question =>
          question.status === 'enable' && question?.is_filter_question === false
      )
      .sort((a, b) => a?.serial_no - b?.serial_no);
  }, [data?.questions]);

  const isFilteredQuestion = useMemo(() => {
    return data?.questions
      ?.filter(
        question =>
          question.status === 'enable' && question?.is_filter_question === true
      )
      .sort((a, b) => a?.serial_no - b?.serial_no);
  }, [data?.questions]);

  const validateAnswers = (questions, answers) => {
    let hasError = false;
    const newErrors = {};
    questions.forEach(question => {
      const answerType = questionAndAnswerTypes.find(
        type => type.questionType === question.question_type
      )?.answerType;
      const answer = answers.find(item => item.question === question.id);

      if (
        question.required &&
        (!answer ||
          answer[answerType] === null ||
          answer[answerType] === '' ||
          answer[answerType] === 0 ||
          (Array.isArray(answer[answerType]) &&
            answer[answerType].length === 0))
      ) {
        newErrors[question.id] = getErrorMessage(question.question_type);
        hasError = true;
      }
    });

    return { hasError, newErrors };
  };

  useEffect(() => {
    if (!isPreviewOnly && finalDataArry && isSubmitted) {
      const { hasError, newErrors } = validateAnswers(
        filteredQuestions,
        finalDataArry
      );
      if (hasError) {
        setErrors(newErrors);
      }
      if (!hasError) {
        setErrors({});
      }
    }

    if (!isPreviewOnly && finalDataArry && isFilterQuestionNextClicked) {
      const { hasError, newErrors } = validateAnswers(
        isFilteredQuestion,
        finalDataArry
      );
      if (hasError) {
        setErrors(newErrors);
      }
      if (!hasError) {
        setErrors({});
      }
    }
  }, [finalDataArry]);

  const handleSubmit = async e => {
    e.preventDefault();
    if (!isPreviewOnly) {
      setIsSubmitted(true);
      const { hasError, newErrors } = validateAnswers(
        filteredQuestions,
        finalDataArry
      );

      if (hasError) {
        setErrors(newErrors);
        showToast('Please answer all questions before submitting.', 'error');
        return;
      }

      const submitTime = Date.now();

      // Calculate the time difference in milliseconds
      const timeDiffMs = Math.floor((submitTime - timeSpent) / 1000); // Time spent in seconds

      if (finalDataArry.length > 0) {
        const responseId = atob(localStorage.getItem('responseId'));
        await createUserAnswerSurvey(
          data?.id?.toString(),
          responseId?.toString(),
          {
            answers: finalDataArry,
            time_spent_seconds: timeDiffMs,
            device_name: userDeviceInfo.device,
            browser_name: userDeviceInfo.browser,
            referrer: userDeviceInfo.referrer,
          }
        )
          .then(res => {
            setIsLoading(true);
            if (res.status === 201) {
              setIsLoading(false);
              showToast('Form submitting successfully.', 'success');
              localStorage.removeItem('responseId');
              onSurveySubmit(surveyId);
              setTimeout(() => {
                navigate('/thank-you', { replace: true });
              }, 500);
            }
          })
          .catch(error => {
            setIsLoading(false);
            showToast('Error submitting form.', 'error');
          })
          .finally(() => {
            localStorage.removeItem('responseId');
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
        showToast('Please answer all questions before submitting.', 'error');
      }
    }
  };

  const handleGetFilteredQuestionsData = e => {
    e.preventDefault();
    if (isFilteredQuestion.length > 0) {
      setIsFilterQuestionNextClicked(true);
      const { hasError, newErrors } = validateAnswers(
        isFilteredQuestion,
        finalDataArry
      );
      if (hasError) {
        setErrors(newErrors);
        return;
      }

      if (!hasError) {
        setIsFilterQuestionNextClicked(false);
        // here i wanted to check whether the question which are in filter question are answered or not
        // if answered then only i will procedd and show the other component which is the rest of the question.
        // if not answered then i will show the error message that please answer the question.
        setIsFilterQuestionAnswered(true);
      }
    }

    if (isPreviewOnly) {
      setIsFilterQuestionAnswered(true);
    }
  };

  // TO Publish the survey in preview mode
  const handlePushlishSurvey = async () => {
    if (isPreviewOnly) {
      try {
        setIsLoading(true);
        const response = await updateSurveyStatus(data?.id?.toString(), {
          status: 'published',
        });
        if (response.status === 200) {
          showToast('Survey Published Successfully!', 'success');
          (await handleGetSurvey) && handleGetSurvey(data?.id?.toString());
          setTimeout(() => {
            setToast(false);
            window.close();
          }, 3000);
          setSurveyPublished(true);
        }
      } catch (error) {
        setIsLoading(false);
        showToast('Something went wrong while publishing the survey!', 'error');
      }
    }
  };

  // Calculate the width dynamically
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isMediumScreen = useMediaQuery('(max-width:960px)');
  const maxWidth = isSmallScreen ? '100%' : '50%';

  if (isPreviewOnly && surveyPublished) {
    return (
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '1.5rem',
          }}
        >
          <Typography variant="h4" gutterBottom>
            Survey Published Successfully!
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            You can close the tab now.
          </Typography>
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <img
              src="/assets/thank-you-icon.png"
              alt="Survey Published"
              width={200}
              height={200}
            />
          </Box>
        </Box>
      </Container>
    );
  }

  if (data?.questions?.length === 0) {
    return (
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '1.5rem',
          }}
        >
          <Typography variant="h4" gutterBottom textAlign={'center'}>
            No Questions Found!
          </Typography>

          <Box display="flex" justifyContent="center">
            <img
              src="/assets/errors/no_data_background.png"
              alt="error"
              style={{
                width: '450px',
                maxHeight: '567px',
                mixBlendMode: 'multiply',
              }}
            />
          </Box>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            textAlign={'center'}
          >
            Please add questions to the survey.
          </Typography>
        </Box>
      </Container>
    );
  }

  if (data) {
    return (
      <form onSubmit={handleSubmit} noValidate>
        <Container maxWidth="xl">
          {/* Header Section */}
          <Box
            sx={{
              width: '100%',
              mb: '1rem',
              backgroundColor: '#f5f5f5',
              p: 2,
              borderRadius: '12px',
              boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
            }}
          >
            <img
              src={`${
                data && data.banner_image_url
                  ? `${data?.banner_image_url}`
                  : `../../assets/surveyImages/default1.jpg`
              }`}
              alt="banner_image"
              style={{
                boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
                marginBottom: '1rem',
                width: '100%',
                // maxHeight: "392px",
                borderRadius: '12px',
                objectFit: 'fill',
              }}
            />
            <Typography variant="h4" gutterBottom>
              {data?.title}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              gutterBottom
              sx={{ mb: 2 }}
            >
              {data?.description}
            </Typography>
          </Box>
          {/* Questions Section */}
          <Box
            sx={{
              backgroundColor: '#ffffff',
              p: 2,
              borderRadius: '12px',
              boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
            }}
          >
            {isFilteredQuestion?.length > 0 && !isFilterQuestionAnswered ? (
              <Stack spacing={3} sx={{ mt: 2, flexGrow: 1 }}>
                {isFilteredQuestion?.map((question, index) => (
                  <Box key={question.id}>
                    <Box
                      sx={{
                        display: 'column',
                        gap: '0rem',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        fontWeight={700}
                        sx={{ minWidth: 'fit-content' }}
                      >
                        {index + 1}. {'  '} {question.question}{' '}
                        {question?.required && (
                          <span
                            style={{
                              color: 'gray',
                              fontSize: '12px',
                              fontWeight: '400',
                            }}
                          >
                            (Required)
                          </span>
                        )}{' '}
                      </Typography>{' '}
                      {question?.help_text && (
                        <Typography
                          variant="caption"
                          color="grey"
                          marginLeft={2}
                        >
                          {question?.help_text}
                        </Typography>
                      )}
                    </Box>
                    {question?.video_or_image_url && (
                      <Box sx={{ maxWidth: maxWidth, mt: 1, mb: 1, ml: 2 }}>
                        {getMediaType(question?.video_or_image_url) ===
                        'image' ? (
                          <img
                            src={question?.video_or_image_url}
                            alt="Question"
                            width="100%"
                            height={300}
                            style={{ borderRadius: '12px', objectFit: 'cover' }}
                          />
                        ) : (
                          <video width="100%" height={300} controls>
                            <source
                              src={question?.video_or_image_url}
                              type={`video/${question?.video_or_image_url
                                .split('.')
                                .pop()}`}
                            />
                          </video>
                        )}
                      </Box>
                    )}
                    <Box sx={{ ml: 2, mt: 1 }}>
                      <RenderAnswerType
                        question={question}
                        setFinalDataArry={setFinalDataArry}
                        finalDataArry={finalDataArry}
                        formErrors={errors}
                      />
                    </Box>
                  </Box>
                ))}
              </Stack>
            ) : (
              <>
                {data?.survey_display_type ===
                SURVEY_DISPLAY_TYPES_ENUM.SINGLE_FORM ? (
                  <Stack spacing={3} sx={{ mt: 2, flexGrow: 1 }}>
                    {filteredQuestions?.length > 0 ? (
                      filteredQuestions?.map((question, index) => (
                        <Box key={question.id}>
                          <Box
                            sx={{
                              display: 'column',
                              gap: '0rem',
                              width: '100%',
                              alignItems: 'center',
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              fontWeight={700}
                              sx={{ minWidth: 'fit-content' }}
                            >
                              {index + 1}. {'  '} {question.question}{' '}
                              {question?.required && (
                                <span
                                  style={{
                                    color: 'gray',
                                    fontSize: '12px',
                                    fontWeight: '400',
                                  }}
                                >
                                  (Required)
                                </span>
                              )}{' '}
                            </Typography>{' '}
                            {question?.help_text && (
                              <Typography
                                variant="caption"
                                color="grey"
                                marginLeft={2}
                              >
                                {question?.help_text}
                              </Typography>
                            )}
                          </Box>
                          {question?.video_or_image_url && (
                            <Box
                              sx={{ maxWidth: maxWidth, mt: 1, mb: 1, ml: 2 }}
                            >
                              {getMediaType(
                                `${question?.video_or_image_url}`
                              ) === 'image' ? (
                                <img
                                  src={question?.video_or_image_url}
                                  alt="Question"
                                  width="100%"
                                  height={300}
                                  style={{
                                    borderRadius: '12px',
                                    objectFit: 'cover',
                                  }}
                                />
                              ) : (
                                <video width="100%" height={300} controls>
                                  <source
                                    src={`${question?.video_or_image_url}`}
                                    type={`video/${question?.video_or_image_url
                                      .split('.')
                                      .pop()}`}
                                  />
                                </video>
                              )}
                            </Box>
                          )}
                          <Box sx={{ ml: 2, mt: 1 }}>
                            <RenderAnswerType
                              question={question}
                              setFinalDataArry={setFinalDataArry}
                              finalDataArry={finalDataArry}
                              formErrors={errors}
                            />
                          </Box>
                        </Box>
                      ))
                    ) : (
                      <Typography
                        variant="h6"
                        sx={{ textAlign: 'center', color: 'gray' }}
                      >
                        No questions found in this survey.
                      </Typography>
                    )}
                  </Stack>
                ) : (
                  <>
                    {data?.survey_display_type ===
                      SURVEY_DISPLAY_TYPES_ENUM.MULTI_STEPS_FORM &&
                    data?.multi_step_style ===
                      SURVEY_STEP_STYLE_ENUM.ONE_QUESTION_PER_STEP ? (
                      <MultiStepsSingleQuest
                        questions={filteredQuestions}
                        finalDataArry={finalDataArry}
                        setFinalDataArry={setFinalDataArry}
                        formErrors={errors}
                        setIsSingleMultiStepFinish={setIsSingleMultiStepFinish}
                        validateAnswers={validateAnswers}
                        setErrors={setErrors}
                      />
                    ) : (
                      <>
                        {data?.survey_display_type ===
                          SURVEY_DISPLAY_TYPES_ENUM.MULTI_STEPS_FORM &&
                        data?.multi_step_style ===
                          SURVEY_STEP_STYLE_ENUM.QUESTION_CATEGORY_PER_STEP ? (
                          <SurveyQuestionCategoryWizard
                            fullData={data && data}
                            questions={filteredQuestions}
                            finalDataArry={finalDataArry}
                            setFinalDataArry={setFinalDataArry}
                            formErrors={errors}
                            validateAnswers={validateAnswers}
                            setErrors={setErrors}
                          />
                        ) : (
                          <Stack spacing={3} sx={{ mt: 2, flexGrow: 1 }}>
                            {filteredQuestions?.map((question, index) => (
                              <Box key={question.id}>
                                <Box
                                  sx={{
                                    display: 'column',
                                    gap: '0rem',
                                    width: '100%',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight={700}
                                    sx={{ minWidth: 'fit-content' }}
                                  >
                                    {index + 1}. {'  '} {question.question}{' '}
                                    {question?.required && (
                                      <span
                                        style={{
                                          color: 'gray',
                                          fontSize: '12px',
                                          fontWeight: '400',
                                        }}
                                      >
                                        (Required)
                                      </span>
                                    )}{' '}
                                  </Typography>{' '}
                                  {question?.help_text && (
                                    <Typography
                                      variant="caption"
                                      color="grey"
                                      marginLeft={2}
                                    >
                                      {question?.help_text}
                                    </Typography>
                                  )}
                                </Box>
                                {question?.video_or_image_url && (
                                  <Box
                                    sx={{
                                      maxWidth: maxWidth,
                                      mt: 1,
                                      mb: 1,
                                      ml: 2,
                                    }}
                                  >
                                    {getMediaType(
                                      `${question?.video_or_image_url}`
                                    ) === 'image' ? (
                                      <img
                                        src={`${question?.video_or_image_url}`}
                                        alt="Question"
                                        width="100%"
                                        height={300}
                                        style={{
                                          borderRadius: '12px',
                                          objectFit: 'cover',
                                        }}
                                      />
                                    ) : (
                                      <video width="100%" height={300} controls>
                                        <source
                                          src={`${question?.video_or_image_url}`}
                                          type={`video/${question?.video_or_image_url
                                            .split('.')
                                            .pop()}`}
                                        />
                                      </video>
                                    )}
                                  </Box>
                                )}
                                <Box sx={{ ml: 2, mt: 1 }}>
                                  <RenderAnswerType
                                    question={question}
                                    setFinalDataArry={setFinalDataArry}
                                    finalDataArry={finalDataArry}
                                    formErrors={errors}
                                  />
                                </Box>
                              </Box>
                            ))}
                          </Stack>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Box>

          {/* Footer Section */}

          <Box
            sx={{
              mt: 3,
              textAlign: 'center',
              backgroundColor: '#f5f5f5',
              p: 2,
              borderRadius: '12px',
              boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
            }}
          >
            {isFilteredQuestion.length > 0 && !isFilterQuestionAnswered ? (
              <Button
                onClick={e => handleGetFilteredQuestionsData(e)}
                type="button"
                variant="contained"
                color="primary"
              >
                Next
              </Button>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.5rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isPreviewOnly}
                  sx={{
                    backgroundColor: isPreviewOnly
                      ? '#FF6665 !important'
                      : '#d50000 !important',
                    color: isPreviewOnly ? '#fff !important' : 'white',
                  }}
                >
                  Submit Survey
                </Button>
                <Typography
                  variant="caption"
                  sx={{ fontSize: '12px', fontWeight: '400', color: '#d32f2f' }}
                >
                  {isPreviewOnly ? '(Submit is disabled in preview mode)' : ''}
                </Typography>
              </div>
            )}
          </Box>
          {toast && (
            <Toast
              setToast={setToast}
              isLoading={isLoading}
              toast={toast}
              toastType={toastType}
              toastMessage={toastMessage}
            />
          )}
        </Container>
      </form>
    );
  }
};

export default RenderSurvey;
