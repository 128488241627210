import { Box } from '@mui/material'
import React from 'react'
import ShowMoreLess from '../../ShowMoreLess'
const ShowMoreLessCell = ({ row }) => {
  return (
    <Box width={row.width ? '100%' : 'fit-content'} sx={{ overflow: 'hidden', whiteSpace: 'wrap' }}>
      {row.text ? <ShowMoreLess n={200} text={row.text} /> : '--'}
    </Box>
  )
}

export default ShowMoreLessCell
